import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Uuid: any;
  DateTime: Date;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: any;
};





export class Query {
  __typename?: 'Query';
  business?: Maybe<Business>;
  session?: Maybe<SessionQueryType>;
  trackingAgent?: Maybe<TrackingAgentQueryType>;
  finance?: Maybe<FinanceQueryType>;
  hr?: Maybe<HrQueryType>;
  timeTracking?: Maybe<TimeTrackingQueryType>;
  attendanceManagement?: Maybe<AttendanceManagementQueryType>;
  support?: Maybe<SupportQueryType>;
  supportTicket?: Maybe<SupportTicketQueryType>;
  businessLookupsWithIn?: Maybe<Array<Maybe<BusinessLookup>>>;
  paginatedBusinessLookups?: Maybe<BusinessLookupCollectionSegment>;
  businessLookups?: Maybe<Array<Maybe<BusinessLookup>>>;
  businessTypes?: Maybe<Array<Maybe<BusinessType>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  industries?: Maybe<Array<Maybe<Industry>>>;
  paginatedUsers?: Maybe<UserCollectionSegment>;
  user?: Maybe<User>;
};


export class QueryBusinessArgs {
  businessId: Scalars['Uuid'];
  where?: Maybe<BusinessFilterInput>;
};


export class QueryBusinessLookupsWithInArgs {
  lookupIds?: Maybe<Array<Scalars['Uuid']>>;
  where?: Maybe<BusinessLookupFilterInput>;
  order?: Maybe<Array<BusinessLookupSortInput>>;
};


export class QueryPaginatedBusinessLookupsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BusinessLookupFilterInput>;
  order?: Maybe<Array<BusinessLookupSortInput>>;
};


export class QueryBusinessLookupsArgs {
  where?: Maybe<BusinessLookupFilterInput>;
  order?: Maybe<Array<BusinessLookupSortInput>>;
};


export class QueryBusinessTypesArgs {
  where?: Maybe<BusinessTypeFilterInput>;
};


export class QueryCountriesArgs {
  where?: Maybe<CountryFilterInput>;
  order?: Maybe<Array<CountrySortInput>>;
};


export class QueryPaginatedUsersArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserFilterInput>;
  order?: Maybe<Array<UserSortInput>>;
};


export class QueryUserArgs {
  id: Scalars['Uuid'];
  where?: Maybe<UserFilterInput>;
  order?: Maybe<Array<UserSortInput>>;
};

export class SessionQueryType {
  __typename?: 'SessionQueryType';
  businesses?: Maybe<Array<Maybe<Business>>>;
  notesDetails?: Maybe<NoteDateilCollectionSegment>;
  notesWithTicketStatus?: Maybe<NoteDateilCollectionSegment>;
  business?: Maybe<Business>;
  businessById?: Maybe<Business>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  notifications?: Maybe<NotificationCollectionSegment>;
  getBusinessLookupsWithIn?: Maybe<Array<Maybe<BusinessLookup>>>;
  getHolidayCalanderWithDate?: Maybe<HolidayCalanderCollectionSegment>;
};


export class SessionQueryTypeNotesDetailsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  objectId: Scalars['Uuid'];
  where?: Maybe<NoteDateilFilterInput>;
  order?: Maybe<Array<NoteDateilSortInput>>;
};


export class SessionQueryTypeNotesWithTicketStatusArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  objectId: Scalars['Uuid'];
  where?: Maybe<NoteDateilFilterInput>;
  order?: Maybe<Array<NoteDateilSortInput>>;
};


export class SessionQueryTypeBusinessArgs {
  where?: Maybe<BusinessFilterInput>;
};


export class SessionQueryTypeBusinessByIdArgs {
  businessId: Scalars['Uuid'];
  where?: Maybe<BusinessFilterInput>;
};


export class SessionQueryTypeAddressesArgs {
  where?: Maybe<AddressFilterInput>;
};


export class SessionQueryTypeNotificationsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<NotificationSortInput>>;
};


export class SessionQueryTypeGetBusinessLookupsWithInArgs {
  lookupIds?: Maybe<Array<Scalars['Uuid']>>;
  where?: Maybe<BusinessLookupFilterInput>;
  order?: Maybe<Array<BusinessLookupSortInput>>;
};


export class SessionQueryTypeGetHolidayCalanderWithDateArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  reportDate: Scalars['DateTime'];
  where?: Maybe<HolidayCalanderFilterInput>;
  order?: Maybe<Array<HolidayCalanderSortInput>>;
};

export class TimeTrackingQueryType {
  __typename?: 'TimeTrackingQueryType';
  session?: Maybe<TimeTrackingSessionQueryType>;
  clients?: Maybe<ClientCollectionSegment>;
  genericTasks?: Maybe<GenericTaskCollectionSegment>;
  projects?: Maybe<ProjectCollectionSegment>;
  projectRoles?: Maybe<Array<Maybe<ProjectRole>>>;
  projectTasks?: Maybe<ProjectTaskCollectionSegment>;
  myProjectUserRoles?: Maybe<Array<Maybe<ProjectUserRole>>>;
  projectUserRoles?: Maybe<Array<Maybe<ProjectUserRole>>>;
  subTasks?: Maybe<SubTaskCollectionSegment>;
};


export class TimeTrackingQueryTypeClientsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientFilterInput>;
  order?: Maybe<Array<ClientSortInput>>;
};


export class TimeTrackingQueryTypeGenericTasksArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GenericTaskFilterInput>;
  order?: Maybe<Array<GenericTaskSortInput>>;
};


export class TimeTrackingQueryTypeProjectsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProjectFilterInput>;
  order?: Maybe<Array<ProjectSortInput>>;
};


export class TimeTrackingQueryTypeProjectTasksArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  projectId: Scalars['Uuid'];
  where?: Maybe<ProjectTaskFilterInput>;
  order?: Maybe<Array<ProjectTaskSortInput>>;
};


export class TimeTrackingQueryTypeMyProjectUserRolesArgs {
  where?: Maybe<ProjectUserRoleFilterInput>;
};


export class TimeTrackingQueryTypeProjectUserRolesArgs {
  projectId: Scalars['Uuid'];
  where?: Maybe<ProjectUserRoleFilterInput>;
};


export class TimeTrackingQueryTypeSubTasksArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  projectId: Scalars['Uuid'];
  parentTaskId: Scalars['Uuid'];
  where?: Maybe<SubTaskFilterInput>;
  order?: Maybe<Array<SubTaskSortInput>>;
};

export class Client {
  __typename?: 'Client';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<Maybe<User>>>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class GenericTask {
  __typename?: 'GenericTask';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Project {
  __typename?: 'Project';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clientId: Scalars['Uuid'];
  state: States;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  client?: Maybe<Client>;
  projectTasks?: Maybe<Array<Maybe<ProjectTask>>>;
  projectUserRoles?: Maybe<Array<Maybe<ProjectUserRole>>>;
  tasksCount: Scalars['Long'];
};

export class ProjectRole {
  __typename?: 'ProjectRole';
  id: Scalars['Byte'];
  name?: Maybe<Scalars['String']>;
};

export class ProjectTask {
  __typename?: 'ProjectTask';
  id: Scalars['Uuid'];
  projectId?: Maybe<Scalars['Uuid']>;
  expectedEffort?: Maybe<Scalars['Decimal']>;
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  subTasks?: Maybe<SubTaskCollectionSegment>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  subTasksCount: Scalars['Long'];
};


export class ProjectTaskSubTasksArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export class ProjectUserRole {
  __typename?: 'ProjectUserRole';
  id: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  projectRole: ProjectRoles;
  user?: Maybe<User>;
};

export class SubTask {
  __typename?: 'SubTask';
  id: Scalars['Uuid'];
  projectId?: Maybe<Scalars['Uuid']>;
  expectedEffort?: Maybe<Scalars['Decimal']>;
  parentTaskId?: Maybe<Scalars['Uuid']>;
  parentTask?: Maybe<ProjectTask>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  state: States;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class TimeLog {
  __typename?: 'TimeLog';
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
  subTaskId?: Maybe<Scalars['Uuid']>;
  genericTaskId?: Maybe<Scalars['Uuid']>;
  timeSpan?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  project: Project;
  task: ProjectTask;
  subTask?: Maybe<SubTask>;
  genericTask?: Maybe<GenericTask>;
  user: User;
};

export class TimeTrackingSessionQueryType {
  __typename?: 'TimeTrackingSessionQueryType';
  projects?: Maybe<ProjectCollectionSegment>;
  activeProjects?: Maybe<ProjectCollectionSegment>;
  projectUserRoles?: Maybe<Array<Maybe<ProjectUserRole>>>;
  myRoleInProject?: Maybe<Array<ProjectRoles>>;
  getMyProjectMembers?: Maybe<Array<Scalars['Uuid']>>;
  timeLogs?: Maybe<Array<Maybe<TimeLog>>>;
};


export class TimeTrackingSessionQueryTypeProjectsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProjectFilterInput>;
  order?: Maybe<Array<ProjectSortInput>>;
};


export class TimeTrackingSessionQueryTypeActiveProjectsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProjectFilterInput>;
  order?: Maybe<Array<ProjectSortInput>>;
};


export class TimeTrackingSessionQueryTypeProjectUserRolesArgs {
  where?: Maybe<ProjectUserRoleFilterInput>;
};


export class TimeTrackingSessionQueryTypeMyRoleInProjectArgs {
  projectId: Scalars['Uuid'];
};


export class TimeTrackingSessionQueryTypeTimeLogsArgs {
  where?: Maybe<TimeLogFilterInput>;
  order?: Maybe<Array<TimeLogSortInput>>;
};

export class SupportQueryType {
  __typename?: 'SupportQueryType';
  ticketsCategories?: Maybe<TicketCategoryCollectionSegment>;
  tickets?: Maybe<TicketCollectionSegment>;
  ticketComments?: Maybe<TicketCollectionSegment>;
};


export class SupportQueryTypeTicketsCategoriesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketCategoryFilterInput>;
  order?: Maybe<Array<TicketCategorySortInput>>;
};


export class SupportQueryTypeTicketsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput>>;
};


export class SupportQueryTypeTicketCommentsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId: Scalars['Uuid'];
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput>>;
};

export class Ticket {
  __typename?: 'Ticket';
  id: Scalars['Uuid'];
  assignedUserId?: Maybe<Scalars['Uuid']>;
  parentTicketId?: Maybe<Scalars['Uuid']>;
  businessId: Scalars['Uuid'];
  categoryId?: Maybe<Scalars['Uuid']>;
  business?: Maybe<Business>;
  parentTicket?: Maybe<Ticket>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  category?: Maybe<TicketCategory>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  comments?: Maybe<Array<Maybe<Ticket>>>;
  createdBy?: Maybe<User>;
  assignedUser?: Maybe<User>;
  state?: Maybe<States>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  attachment?: Maybe<Attachment>;
  ticketAttachments?: Maybe<Array<Maybe<Attachment>>>;
};

export class Attachment {
  __typename?: 'Attachment';
  id: Scalars['Uuid'];
  path: Scalars['String'];
};

export class SupportTicketQueryType {
  __typename?: 'SupportTicketQueryType';
  ticketsSites?: Maybe<SiteCollectionSegment>;
  siteNo?: Maybe<Scalars['String']>;
  tickets?: Maybe<TicketsCollectionSegment>;
  ticketNo?: Maybe<Scalars['String']>;
  ticketStatusChange?: Maybe<TicketStatusChangeCollectionSegment>;
  ticketLabor?: Maybe<TicketLaborWithUserDetailCollectionSegment>;
  ticketMaterial?: Maybe<TicketMaterialCollectionSegment>;
};


export class SupportTicketQueryTypeTicketsSitesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SiteFilterInput>;
  order?: Maybe<Array<SiteSortInput>>;
};


export class SupportTicketQueryTypeTicketsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketsFilterInput>;
  order?: Maybe<Array<TicketsSortInput>>;
};


export class SupportTicketQueryTypeTicketStatusChangeArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['String']>;
  where?: Maybe<TicketStatusChangeFilterInput>;
  order?: Maybe<Array<TicketStatusChangeSortInput>>;
};


export class SupportTicketQueryTypeTicketLaborArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId: Scalars['Uuid'];
  where?: Maybe<TicketLaborWithUserDetailFilterInput>;
  order?: Maybe<Array<TicketLaborWithUserDetailSortInput>>;
};


export class SupportTicketQueryTypeTicketMaterialArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId: Scalars['Uuid'];
  where?: Maybe<TicketMaterialFilterInput>;
  order?: Maybe<Array<TicketMaterialSortInput>>;
};

export class Site {
  __typename?: 'Site';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  siteNo?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Uuid']>;
  siteManagerId?: Maybe<Scalars['Uuid']>;
  parent?: Maybe<Site>;
  siteName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  siteState?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class TicketLabor {
  __typename?: 'TicketLabor';
  id: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
  hours: Scalars['Decimal'];
  hoursType?: Maybe<Scalars['String']>;
  billable: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  workerId: Scalars['Uuid'];
};

export class TicketMaterial {
  __typename?: 'TicketMaterial';
  id: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
  productId: Scalars['Uuid'];
  product?: Maybe<Product>;
  quantity: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  price: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  taxSettings?: Maybe<Scalars['String']>;
};

export class Tickets {
  __typename?: 'Tickets';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  ticketNo?: Maybe<Scalars['String']>;
  ticketDescription?: Maybe<Scalars['String']>;
  siteId: Scalars['Uuid'];
  site?: Maybe<Site>;
  completeDate?: Maybe<Scalars['DateTime']>;
  completionDuration?: Maybe<Scalars['TimeSpan']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  entryDate: Scalars['DateTime'];
  executionEndDate?: Maybe<Scalars['DateTime']>;
  executionStartDate?: Maybe<Scalars['DateTime']>;
  firstResponseDate?: Maybe<Scalars['DateTime']>;
  priorityId?: Maybe<Scalars['Uuid']>;
  priorityLookup?: Maybe<BusinessLookup>;
  state: States;
  problemId?: Maybe<Scalars['Uuid']>;
  problemLookup?: Maybe<BusinessLookup>;
  reportedById: Scalars['Uuid'];
  reportedUser?: Maybe<User>;
  scheduleDate?: Maybe<Scalars['DateTime']>;
  ticketStatusChanges?: Maybe<Array<Maybe<TicketStatusChange>>>;
};

export class TicketStatusChange {
  __typename?: 'TicketStatusChange';
  id: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
  changeStatusId?: Maybe<Scalars['Uuid']>;
  statusLookup?: Maybe<BusinessLookup>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['Uuid']>;
  changeById: Scalars['Uuid'];
  changedUser?: Maybe<User>;
  changeOn: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  state: Scalars['Int'];
  statusType: Scalars['Int'];
};

export class HrQueryType {
  __typename?: 'HrQueryType';
  session?: Maybe<HrSessionQueryType>;
  sites?: Maybe<SiteCollectionSegment>;
  siteNo?: Maybe<Scalars['String']>;
  ticketLabors?: Maybe<TicketLaborWithUserDetailCollectionSegment>;
  ticketMaterials?: Maybe<TicketMaterialCollectionSegment>;
  tickets?: Maybe<TicketsCollectionSegment>;
  ticketNo?: Maybe<Scalars['String']>;
  ticketStatusChange?: Maybe<TicketStatusChangeCollectionSegment>;
  allowanceCategories?: Maybe<AllowanceCategoryCollectionSegment>;
  allowanceTypes?: Maybe<AllowanceTypeCollectionSegment>;
  bonusCategories?: Maybe<BonusCategoryCollectionSegment>;
  bonusTypes?: Maybe<BonusTypeCollectionSegment>;
  candidateEvaluation?: Maybe<CandidateEvaluation>;
  jobCandidates?: Maybe<CandidateCollectionSegment>;
  certifications?: Maybe<CertificationCollectionSegment>;
  departments?: Maybe<DepartmentCollectionSegment>;
  employees?: Maybe<EmployeeCollectionSegment>;
  employmentHistories?: Maybe<Array<Maybe<EmploymentHistory>>>;
  holidayCalanderWithDate?: Maybe<HolidayCalanderCollectionSegment>;
  holidayCalanders?: Maybe<HolidayCalanderCollectionSegment>;
  hrLookups?: Maybe<HrLookupCollectionSegment>;
  institutes?: Maybe<InstituteCollectionSegment>;
  interviewCategories?: Maybe<InterviewCategoryCollectionSegment>;
  interviewCriteria?: Maybe<InterviewCriterionCollectionSegment>;
  interviewers?: Maybe<InterviewerCollectionSegment>;
  jobFunctions?: Maybe<JobFunctionCollectionSegment>;
  jobPositions?: Maybe<JobPositionCollectionSegment>;
  jobTitles?: Maybe<JobTitleCollectionSegment>;
  leaves?: Maybe<Array<Maybe<Leave>>>;
  leave?: Maybe<Leave>;
  paginatedLeaveTypes?: Maybe<BusinessLookupCollectionSegment>;
  leaveTypes?: Maybe<Array<Maybe<BusinessLookup>>>;
  loans?: Maybe<LoanCollectionSegment>;
  loanTypes?: Maybe<LoanTypeCollectionSegment>;
  approvedMedicalClaim: Scalars['Decimal'];
  medicalClaims?: Maybe<MedicalClaimCollectionSegment>;
  medicalClaim?: Maybe<MedicalClaim>;
  reasonTypes?: Maybe<ReasonTypeCollectionSegment>;
  approvedReimbursement: Scalars['Decimal'];
  reimbursements?: Maybe<ReimbursementCollectionSegment>;
  reimbursement?: Maybe<Reimbursement>;
  salaries?: Maybe<Array<Maybe<Salary>>>;
  myYearlyQuotas?: Maybe<Array<Maybe<Quota>>>;
  myCurrentYearQuota?: Maybe<Quota>;
  userCurrentYearQuota?: Maybe<Quota>;
  yearlyQuotas?: Maybe<Array<Maybe<Quota>>>;
};


export class HrQueryTypeSitesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SiteFilterInput>;
  order?: Maybe<Array<SiteSortInput>>;
};


export class HrQueryTypeTicketLaborsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId: Scalars['Uuid'];
  where?: Maybe<TicketLaborWithUserDetailFilterInput>;
  order?: Maybe<Array<TicketLaborWithUserDetailSortInput>>;
};


export class HrQueryTypeTicketMaterialsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId: Scalars['Uuid'];
  where?: Maybe<TicketMaterialFilterInput>;
  order?: Maybe<Array<TicketMaterialSortInput>>;
};


export class HrQueryTypeTicketsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketsFilterInput>;
  order?: Maybe<Array<TicketsSortInput>>;
};


export class HrQueryTypeTicketStatusChangeArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['String']>;
  where?: Maybe<TicketStatusChangeFilterInput>;
  order?: Maybe<Array<TicketStatusChangeSortInput>>;
};


export class HrQueryTypeAllowanceCategoriesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AllowanceCategoryFilterInput>;
  order?: Maybe<Array<AllowanceCategorySortInput>>;
};


export class HrQueryTypeAllowanceTypesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AllowanceTypeFilterInput>;
  order?: Maybe<Array<AllowanceTypeSortInput>>;
};


export class HrQueryTypeBonusCategoriesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BonusCategoryFilterInput>;
  order?: Maybe<Array<BonusCategorySortInput>>;
};


export class HrQueryTypeBonusTypesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BonusTypeFilterInput>;
  order?: Maybe<Array<BonusTypeSortInput>>;
};


export class HrQueryTypeCandidateEvaluationArgs {
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  where?: Maybe<CandidateEvaluationFilterInput>;
};


export class HrQueryTypeJobCandidatesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  jobPositionId: Scalars['Uuid'];
  where?: Maybe<CandidateFilterInput>;
};


export class HrQueryTypeCertificationsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CertificationFilterInput>;
  order?: Maybe<Array<CertificationSortInput>>;
};


export class HrQueryTypeDepartmentsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DepartmentFilterInput>;
  order?: Maybe<Array<DepartmentSortInput>>;
};


export class HrQueryTypeEmployeesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmployeeFilterInput>;
  order?: Maybe<Array<EmployeeSortInput>>;
};


export class HrQueryTypeEmploymentHistoriesArgs {
  employeeId: Scalars['Uuid'];
  where?: Maybe<EmploymentHistoryFilterInput>;
};


export class HrQueryTypeHolidayCalanderWithDateArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  reportDate: Scalars['DateTime'];
  where?: Maybe<HolidayCalanderFilterInput>;
  order?: Maybe<Array<HolidayCalanderSortInput>>;
};


export class HrQueryTypeHolidayCalandersArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HolidayCalanderFilterInput>;
  order?: Maybe<Array<HolidayCalanderSortInput>>;
};


export class HrQueryTypeHrLookupsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HrLookupFilterInput>;
  order?: Maybe<Array<HrLookupSortInput>>;
};


export class HrQueryTypeInstitutesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstituteFilterInput>;
  order?: Maybe<Array<InstituteSortInput>>;
};


export class HrQueryTypeInterviewCategoriesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterviewCategoryFilterInput>;
  order?: Maybe<Array<InterviewCategorySortInput>>;
};


export class HrQueryTypeInterviewCriteriaArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterviewCriterionFilterInput>;
  order?: Maybe<Array<InterviewCriterionSortInput>>;
};


export class HrQueryTypeInterviewersArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterviewerFilterInput>;
};


export class HrQueryTypeJobFunctionsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<JobFunctionFilterInput>;
  order?: Maybe<Array<JobFunctionSortInput>>;
};


export class HrQueryTypeJobPositionsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<JobPositionFilterInput>;
  order?: Maybe<Array<JobPositionSortInput>>;
};


export class HrQueryTypeJobTitlesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<JobTitleFilterInput>;
  order?: Maybe<Array<JobTitleSortInput>>;
};


export class HrQueryTypeLeavesArgs {
  year?: Maybe<Scalars['String']>;
  where?: Maybe<LeaveFilterInput>;
  order?: Maybe<Array<LeaveSortInput>>;
};


export class HrQueryTypeLeaveArgs {
  id: Scalars['Uuid'];
  where?: Maybe<LeaveFilterInput>;
};


export class HrQueryTypePaginatedLeaveTypesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BusinessLookupFilterInput>;
  order?: Maybe<Array<BusinessLookupSortInput>>;
};


export class HrQueryTypeLeaveTypesArgs {
  where?: Maybe<BusinessLookupFilterInput>;
  order?: Maybe<Array<BusinessLookupSortInput>>;
};


export class HrQueryTypeLoansArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoanFilterInput>;
  order?: Maybe<Array<LoanSortInput>>;
};


export class HrQueryTypeLoanTypesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoanTypeFilterInput>;
  order?: Maybe<Array<LoanTypeSortInput>>;
};


export class HrQueryTypeApprovedMedicalClaimArgs {
  userId: Scalars['Uuid'];
  claimLookupId: Scalars['Uuid'];
};


export class HrQueryTypeMedicalClaimsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MedicalClaimFilterInput>;
  order?: Maybe<Array<MedicalClaimSortInput>>;
};


export class HrQueryTypeMedicalClaimArgs {
  id: Scalars['Uuid'];
};


export class HrQueryTypeReasonTypesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  type: ReasonTypes;
  where?: Maybe<ReasonTypeFilterInput>;
  order?: Maybe<Array<ReasonTypeSortInput>>;
};


export class HrQueryTypeApprovedReimbursementArgs {
  userId: Scalars['Uuid'];
  reimbursementTypeId: Scalars['Uuid'];
};


export class HrQueryTypeReimbursementsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReimbursementFilterInput>;
  order?: Maybe<Array<ReimbursementSortInput>>;
};


export class HrQueryTypeReimbursementArgs {
  id: Scalars['Uuid'];
};


export class HrQueryTypeSalariesArgs {
  userId: Scalars['Uuid'];
  where?: Maybe<SalaryFilterInput>;
  order?: Maybe<Array<SalarySortInput>>;
};


export class HrQueryTypeMyYearlyQuotasArgs {
  where?: Maybe<QuotaFilterInput>;
  order?: Maybe<Array<QuotaSortInput>>;
};


export class HrQueryTypeMyCurrentYearQuotaArgs {
  where?: Maybe<QuotaFilterInput>;
};


export class HrQueryTypeUserCurrentYearQuotaArgs {
  userId: Scalars['Uuid'];
  where?: Maybe<QuotaFilterInput>;
};


export class HrQueryTypeYearlyQuotasArgs {
  where?: Maybe<QuotaFilterInput>;
  order?: Maybe<Array<QuotaSortInput>>;
};

export class AllowanceCategory {
  __typename?: 'AllowanceCategory';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  allowanceTypes?: Maybe<Array<Maybe<AllowanceType>>>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class AllowanceType {
  __typename?: 'AllowanceType';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: States;
  ratio: Scalars['Decimal'];
  categoryId: Scalars['Uuid'];
  category?: Maybe<AllowanceCategory>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class BonusCategory {
  __typename?: 'BonusCategory';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  bonusTypes?: Maybe<Array<Maybe<BonusType>>>;
};

export class BonusType {
  __typename?: 'BonusType';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  percentage: Scalars['Decimal'];
  categoryId: Scalars['Uuid'];
  category?: Maybe<BonusCategory>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class CandidateEvaluation {
  __typename?: 'CandidateEvaluation';
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  strength?: Maybe<Scalars['String']>;
  weakness?: Maybe<Scalars['String']>;
  finalComment?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  candidate?: Maybe<Candidate>;
  jobPosition?: Maybe<JobPosition>;
  scores?: Maybe<Array<Maybe<CandidateScore>>>;
};

export class Candidate {
  __typename?: 'Candidate';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender: Gender;
  pic?: Maybe<Scalars['String']>;
  state: States;
  fatherName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  dateOfAvailability: Scalars['DateTime'];
  dateOfBirth: Scalars['DateTime'];
  placeOfBirth?: Maybe<Scalars['String']>;
  presentAddress?: Maybe<Scalars['String']>;
  permanentAddress?: Maybe<Scalars['String']>;
  bloodGroup?: Maybe<Scalars['String']>;
  maritalStatus: MaritalStatus;
  nameOfSpouse?: Maybe<Scalars['String']>;
  drivingLicenceNumber?: Maybe<Scalars['String']>;
  drivingLicenceIssueDate?: Maybe<Scalars['DateTime']>;
  currentSalary?: Maybe<Scalars['Decimal']>;
  expectedSalary?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<Scalars['String']>;
  religion?: Maybe<Scalars['String']>;
  cnic?: Maybe<Scalars['String']>;
  cnicExpiryDate: Scalars['DateTime'];
  domicile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  isProfileComplete: Scalars['Boolean'];
  candidatePositions?: Maybe<Array<Maybe<CandidatePosition>>>;
  children?: Maybe<Array<Maybe<CandidateChildren>>>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  experience?: Maybe<Array<Maybe<CandidateExperience>>>;
  references?: Maybe<Array<Maybe<CandidateReferences>>>;
  relatives?: Maybe<Array<Maybe<CandidateRelatives>>>;
  jobPositions?: Maybe<Array<Maybe<JobPosition>>>;
  candidateEvaluations?: Maybe<Array<Maybe<CandidateEvaluation>>>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class Certification {
  __typename?: 'Certification';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Department {
  __typename?: 'Department';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Employee {
  __typename?: 'Employee';
  id: Scalars['Uuid'];
  officialEmail?: Maybe<Scalars['String']>;
  state: States;
  fatherName: Scalars['String'];
  phone: Scalars['String'];
  mobilePhone: Scalars['String'];
  dateOfAvailability?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  presentAddress: Scalars['String'];
  permanentAddress?: Maybe<Scalars['String']>;
  bloodGroup?: Maybe<Scalars['String']>;
  maritalStatus: MaritalStatus;
  nameOfSpouse: Scalars['String'];
  drivingLicenceNumber: Scalars['String'];
  drivingLicenceIssueDate?: Maybe<Scalars['DateTime']>;
  currentSalary?: Maybe<Scalars['Decimal']>;
  expectedSalary?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<Scalars['String']>;
  religion: Scalars['String'];
  cnic: Scalars['String'];
  cnicExpiryDate?: Maybe<Scalars['DateTime']>;
  domicile: Scalars['String'];
  userId?: Maybe<Scalars['Uuid']>;
  interviewStartDate?: Maybe<Scalars['DateTime']>;
  interviewEndDate?: Maybe<Scalars['DateTime']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  employmentHistories: Array<EmploymentHistory>;
  employeePositions: Array<CandidatePosition>;
  children: Array<EmployeeChildren>;
  educations: Array<EmployeeEducation>;
  experiences: Array<EmployeeExperience>;
  references: Array<EmployeeReferences>;
  relatives: Array<EmployeeRelatives>;
  reportedToId?: Maybe<Scalars['Uuid']>;
  user?: Maybe<User>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class EmploymentHistory {
  __typename?: 'EmploymentHistory';
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  reasonTypeId: Scalars['Uuid'];
  state: States;
  joiningDate?: Maybe<Scalars['DateTime']>;
  terminationDate?: Maybe<Scalars['DateTime']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  createdBy?: Maybe<User>;
  employee?: Maybe<Employee>;
  reasonType?: Maybe<HrLookup>;
};

export class HolidayCalander {
  __typename?: 'HolidayCalander';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  eventName?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class HrLookup {
  __typename?: 'HrLookup';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Uuid']>;
  parent?: Maybe<HrLookup>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupSetting?: Maybe<Scalars['String']>;
  state: States;
  isSystemDefined: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Institute {
  __typename?: 'Institute';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class InterviewCategory {
  __typename?: 'InterviewCategory';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  interviewCriteria?: Maybe<Array<Maybe<InterviewCriterion>>>;
};

export class InterviewCriterion {
  __typename?: 'InterviewCriterion';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  score: Scalars['Decimal'];
  categoryId: Scalars['Uuid'];
  category?: Maybe<InterviewCategory>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Interviewer {
  __typename?: 'Interviewer';
  id: Scalars['Uuid'];
  jobPositionId?: Maybe<Scalars['Uuid']>;
  userId?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  jobPosition?: Maybe<JobPosition>;
};

export class JobFunction {
  __typename?: 'JobFunction';
  id: Scalars['Uuid'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class JobPosition {
  __typename?: 'JobPosition';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  departmentId: Scalars['Uuid'];
  jobTitleId: Scalars['Uuid'];
  state: States;
  positions: Scalars['Byte'];
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  careerLevel?: Maybe<CareerLevel>;
  employmentType?: Maybe<EmploymentType>;
  educationLevel?: Maybe<EducationLevel>;
  experience?: Maybe<WorkExperience>;
  monthlySalary?: Maybe<MonthlySalary>;
  companyName?: Maybe<Scalars['String']>;
  companySize: CompanySize;
  postDate?: Maybe<Scalars['DateTime']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  isCvRequired: Scalars['Boolean'];
  performanceBonus: Scalars['Boolean'];
  medical: Scalars['Boolean'];
  childrenEducation: Scalars['Boolean'];
  providentFund: Scalars['Boolean'];
  knowledgeSharing: Scalars['Boolean'];
  competitiveSalary: Scalars['Boolean'];
  otherBenefit?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  department?: Maybe<HrLookup>;
  jobTitle?: Maybe<HrLookup>;
  candidatePositionInfo?: Maybe<Array<Maybe<CandidatePosition>>>;
  candidates?: Maybe<Array<Maybe<Candidate>>>;
  interviewers?: Maybe<Array<Maybe<Interviewer>>>;
  candidateEvaluations?: Maybe<Array<Maybe<CandidateEvaluation>>>;
};

export class JobTitle {
  __typename?: 'JobTitle';
  id: Scalars['Uuid'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Leave {
  __typename?: 'Leave';
  id: Scalars['Uuid'];
  leaveTypeId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  weight: Scalars['Float'];
  isShortLeave: Scalars['Boolean'];
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  leaveType?: Maybe<BusinessLookup>;
  user?: Maybe<User>;
};

export class Loan {
  __typename?: 'Loan';
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  purposeOfLoan?: Maybe<Scalars['String']>;
  amountApplied: Scalars['Float'];
  amountApproved: Scalars['Float'];
  numberOfInstallments: Scalars['Byte'];
  remarks?: Maybe<Scalars['String']>;
  state: States;
  loanTypeId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  loanType?: Maybe<BusinessLookup>;
  user?: Maybe<User>;
};

export class LoanType {
  __typename?: 'LoanType';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class MedicalClaim {
  __typename?: 'MedicalClaim';
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  claimLookupId: Scalars['Uuid'];
  amount: Scalars['Float'];
  amountApproved: Scalars['Float'];
  balanceCf: Scalars['Float'];
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  user?: Maybe<User>;
  medicalClaimBreakdowns?: Maybe<Array<Maybe<MedicalClaimBreakdown>>>;
};

export class ReasonType {
  __typename?: 'ReasonType';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: States;
  code?: Maybe<Scalars['String']>;
  type: ReasonTypes;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Reimbursement {
  __typename?: 'Reimbursement';
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  reimbursementTypeId: Scalars['Uuid'];
  amount: Scalars['Float'];
  amountApproved: Scalars['Float'];
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  appliedOn?: Maybe<Scalars['DateTime']>;
  authorizedById?: Maybe<Scalars['Uuid']>;
  authorizedOn?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  reimbursementDetails?: Maybe<Array<Maybe<ReimbursementDetail>>>;
  businessLookups?: Maybe<BusinessLookup>;
  authorizedBy?: Maybe<User>;
};

export class Salary {
  __typename?: 'Salary';
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  user?: Maybe<User>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  basicSalary: Scalars['Decimal'];
  netSalary: Scalars['Decimal'];
  createdBy?: Maybe<User>;
  createdOn: Scalars['DateTime'];
  allowances?: Maybe<Array<Maybe<HrLookup>>>;
  bonuses?: Maybe<Array<Maybe<HrLookup>>>;
};

export class Quota {
  __typename?: 'Quota';
  id: Scalars['Uuid'];
  year?: Maybe<Scalars['String']>;
  userId: Scalars['Uuid'];
  lookupId: Scalars['Uuid'];
  lookup?: Maybe<BusinessLookup>;
  assignedQuota: Scalars['Float'];
  user?: Maybe<User>;
};

export class HrSessionQueryType {
  __typename?: 'HrSessionQueryType';
  loans?: Maybe<LoanCollectionSegment>;
  medicalClaims?: Maybe<MedicalClaimCollectionSegment>;
  approvedAmount: Scalars['Decimal'];
  myselfAsEmployee?: Maybe<Employee>;
  employeesReportsToMeIds?: Maybe<Array<Maybe<Scalars['Uuid']>>>;
  myYearlyQuotas?: Maybe<Array<Maybe<Quota>>>;
  myCurrentYearQuota?: Maybe<Quota>;
  userCurrentYearQuota?: Maybe<Quota>;
  reimbursements?: Maybe<ReimbursementCollectionSegment>;
  approvedReimbursementAmount: Scalars['Decimal'];
};


export class HrSessionQueryTypeLoansArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoanFilterInput>;
  order?: Maybe<Array<LoanSortInput>>;
};


export class HrSessionQueryTypeMedicalClaimsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MedicalClaimFilterInput>;
  order?: Maybe<Array<MedicalClaimSortInput>>;
};


export class HrSessionQueryTypeApprovedAmountArgs {
  userId: Scalars['Uuid'];
  claimLookupId: Scalars['Uuid'];
};


export class HrSessionQueryTypeMyYearlyQuotasArgs {
  where?: Maybe<QuotaFilterInput>;
  order?: Maybe<Array<QuotaSortInput>>;
};


export class HrSessionQueryTypeMyCurrentYearQuotaArgs {
  where?: Maybe<QuotaFilterInput>;
};


export class HrSessionQueryTypeUserCurrentYearQuotaArgs {
  userId: Scalars['Uuid'];
  where?: Maybe<QuotaFilterInput>;
};


export class HrSessionQueryTypeReimbursementsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReimbursementFilterInput>;
  order?: Maybe<Array<ReimbursementSortInput>>;
};


export class HrSessionQueryTypeApprovedReimbursementAmountArgs {
  userId: Scalars['Uuid'];
  reimbursementTypeId: Scalars['Uuid'];
};

export class SystemAccount {
  __typename?: 'SystemAccount';
  name: Scalars['String'];
  roleNumber: Scalars['Long'];
  description?: Maybe<Scalars['String']>;
  account?: Maybe<ThirdLevelAccount>;
};

export class CashVoucher {
  __typename?: 'CashVoucher';
  id: Scalars['Uuid'];
  number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  systemAccountId: Scalars['Uuid'];
  systemAccount?: Maybe<ThirdLevelAccount>;
  accountId: Scalars['Uuid'];
  account?: Maybe<ThirdLevelAccount>;
  amount: Scalars['Decimal'];
  date: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  attachment?: Maybe<Attachment>;
};

export class Contact {
  __typename?: 'Contact';
  id: Scalars['Uuid'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Uuid']>;
  address?: Maybe<Address>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class FinancialYear {
  __typename?: 'FinancialYear';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export class FirstLevelAccount {
  __typename?: 'FirstLevelAccount';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  roleNumber: Scalars['Long'];
  sortOrder: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  secondLevelAccounts?: Maybe<Array<Maybe<SecondLevelAccount>>>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Invoice {
  __typename?: 'Invoice';
  id: Scalars['Uuid'];
  number?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  accountId: Scalars['Uuid'];
  account?: Maybe<ThirdLevelAccount>;
  commissionAccountId?: Maybe<Scalars['Uuid']>;
  commissionAccount?: Maybe<ThirdLevelAccount>;
  discountSystemAccountId?: Maybe<Scalars['Uuid']>;
  discountSystemAccount?: Maybe<ThirdLevelAccount>;
  systemAccountId: Scalars['Uuid'];
  systemAccount?: Maybe<ThirdLevelAccount>;
  state?: Maybe<VoucherStates>;
  date: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  deliveryAddressId?: Maybe<Scalars['Uuid']>;
  details?: Maybe<Array<Maybe<InvoiceDetail>>>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  attachment?: Maybe<Attachment>;
  amount: Scalars['Decimal'];
};

export class InvoiceDetail {
  __typename?: 'InvoiceDetail';
  id: Scalars['Uuid'];
  productId: Scalars['Uuid'];
  product?: Maybe<Product>;
  purchaseVoucherId: Scalars['Uuid'];
  quantity: Scalars['Decimal'];
  price: Scalars['Decimal'];
  discount: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  sortOrder: Scalars['Short'];
  tax: Scalars['Decimal'];
  invoiceDetailTaxs?: Maybe<Array<Maybe<InvoiceDetailTax>>>;
};

export class InvoiceDetailTax {
  __typename?: 'InvoiceDetailTax';
  id: Scalars['Uuid'];
  invoiceDetailID: Scalars['Uuid'];
  taxId: Scalars['Uuid'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
};

export class Product {
  __typename?: 'Product';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCatchWeightProduct?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  categoryId?: Maybe<Scalars['Uuid']>;
  productType?: Maybe<ProductTypes>;
  purchaseUnit?: Maybe<ProductUnits>;
  saleUnit?: Maybe<ProductUnits>;
  inventoryUnit?: Maybe<ProductUnits>;
  incomeAccountId?: Maybe<Scalars['Uuid']>;
  expenseAccountId?: Maybe<Scalars['Uuid']>;
  state?: Maybe<States>;
  number?: Maybe<Scalars['String']>;
  upcNumber?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  category?: Maybe<ThirdLevelAccount>;
  incomeAccount?: Maybe<ThirdLevelAccount>;
  expenseAccount?: Maybe<ThirdLevelAccount>;
  taxIds?: Maybe<Array<Scalars['Uuid']>>;
};

export class ProductType {
  __typename?: 'ProductType';
  id: Scalars['Byte'];
  name?: Maybe<Scalars['String']>;
};

export class ProductUnit {
  __typename?: 'ProductUnit';
  id: Scalars['Byte'];
  name?: Maybe<Scalars['String']>;
};

export class SecondLevelAccount {
  __typename?: 'SecondLevelAccount';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  roleNumber: Scalars['Long'];
  sortOrder: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  firstLevelAccountId: Scalars['Uuid'];
  thirdLevelAccounts?: Maybe<Array<Maybe<ThirdLevelAccount>>>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class Tax {
  __typename?: 'Tax';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  rate: Scalars['Decimal'];
  isCompoundTax: Scalars['Boolean'];
  showTaxOnInvoices: Scalars['Boolean'];
  isTaxRecoverable: Scalars['Boolean'];
};

export class ThirdLevelAccount {
  __typename?: 'ThirdLevelAccount';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  roleNumber: Scalars['Long'];
  roles?: Maybe<Array<Scalars['Long']>>;
  sortOrder: Scalars['Int'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  secondLevelAccountId: Scalars['Uuid'];
  businessAddressId?: Maybe<Scalars['Uuid']>;
  deliveryAddressId?: Maybe<Scalars['Uuid']>;
  billingAddressId?: Maybe<Scalars['Uuid']>;
  businessContactId?: Maybe<Scalars['Uuid']>;
  deliveryContactId?: Maybe<Scalars['Uuid']>;
  billingContactId?: Maybe<Scalars['Uuid']>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  billingAddress?: Maybe<Address>;
  deliveryAddress?: Maybe<Address>;
  businessAddress?: Maybe<Address>;
  billingContact?: Maybe<Contact>;
  deliveryContact?: Maybe<Contact>;
  businessContact?: Maybe<Contact>;
  isPayRollAccount: Scalars['Boolean'];
};

export class Voucher {
  __typename?: 'Voucher';
  id: Scalars['Uuid'];
  voucherTypeId: Scalars['Uuid'];
  number: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  lineItems: Array<VoucherLineItem>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  attachment?: Maybe<Attachment>;
};

export class FinanceSessionQueryType {
  __typename?: 'FinanceSessionQueryType';
  taxes?: Maybe<Array<Maybe<Tax>>>;
  financialYears?: Maybe<Array<Maybe<FinancialYear>>>;
  financialYear?: Maybe<FinancialYear>;
  activeFinancialYear?: Maybe<FinancialYear>;
};


export class FinanceSessionQueryTypeTaxesArgs {
  where?: Maybe<TaxFilterInput>;
};


export class FinanceSessionQueryTypeFinancialYearsArgs {
  where?: Maybe<FinancialYearFilterInput>;
};


export class FinanceSessionQueryTypeFinancialYearArgs {
  id: Scalars['Uuid'];
};

export class Address {
  __typename?: 'Address';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  zipCode?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export class BusinessLookup {
  __typename?: 'BusinessLookup';
  id: Scalars['Uuid'];
  parentId?: Maybe<Scalars['Uuid']>;
  parent?: Maybe<BusinessLookup>;
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: States;
  isSystemDefined: Scalars['Boolean'];
  setting?: Maybe<Scalars['String']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupCategory?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class Business {
  __typename?: 'Business';
  id: Scalars['Uuid'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  tollFree?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  currency: Scalars['String'];
  businessTypeId: BusinessTypes;
  industryId: Industries;
  logo?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  salaryAccounts?: Maybe<SalaryAccount>;
  agentConfiguration?: Maybe<AgentConfiguration>;
  businessUsers: Array<User>;
  departments?: Maybe<Array<Maybe<Department>>>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  configuration?: Maybe<Scalars['String']>;
  config?: Maybe<BusinessConfig>;
  childCounts?: Maybe<ChildCount>;
};

export class BusinessType {
  __typename?: 'BusinessType';
  id: Scalars['Byte'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  category: BusinessCategories;
};

export class Country {
  __typename?: 'Country';
  id: Scalars['Byte'];
  name?: Maybe<Scalars['String']>;
  financialYearStartDate?: Maybe<Scalars['String']>;
  financialYearEndDate?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  callingCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  subRegion?: Maybe<Scalars['String']>;
  timezones?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericCode?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  flag?: Maybe<Scalars['String']>;
  currencyString?: Maybe<Scalars['String']>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
};

export class Industry {
  __typename?: 'Industry';
  id: Scalars['Byte'];
  name?: Maybe<Scalars['String']>;
};

export class Note {
  __typename?: 'Note';
  id: Scalars['Uuid'];
  objectID: Scalars['Uuid'];
  noteText?: Maybe<Scalars['String']>;
  noteModuleCode?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  users?: Maybe<Array<Maybe<User>>>;
};

export class Notification {
  __typename?: 'Notification';
  id: Scalars['Uuid'];
  message?: Maybe<Scalars['String']>;
  isRead: Scalars['Boolean'];
  type: NotificationTypes;
  createdOn: Scalars['DateTime'];
  systemObject?: Maybe<Scalars['String']>;
  systemObjectId?: Maybe<Scalars['Uuid']>;
};

export class User {
  __typename?: 'User';
  id: Scalars['Uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName: Scalars['String'];
  email: Scalars['String'];
  gender: Gender;
  pic?: Maybe<Scalars['String']>;
  employee?: Maybe<Employee>;
  userAgentConfigurations: Array<UserAgentConfiguration>;
  attendanceUserConfigurations: Array<AttendanceUserConfiguration>;
  state: States;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  loans: Array<Loan>;
  leaves: Array<Leave>;
  medicalClaims: Array<MedicalClaim>;
  departmentId?: Maybe<Scalars['Uuid']>;
  jobTitleId?: Maybe<Scalars['Uuid']>;
  jobFunctionId?: Maybe<Scalars['Uuid']>;
  payrollAccountId?: Maybe<Scalars['Uuid']>;
  department?: Maybe<HrLookup>;
  jobTitle?: Maybe<HrLookup>;
  jobFunction?: Maybe<HrLookup>;
  benefits: UserBenefits;
  salaries: Array<Salary>;
  payrollAccount: ThirdLevelAccount;
  quotas: Array<Quota>;
  userFullName?: Maybe<Scalars['String']>;
};


export class UserAttendanceUserConfigurationsArgs {
  where?: Maybe<AttendanceUserConfigurationFilterInput>;
};


export class UserLeavesArgs {
  where?: Maybe<LeaveFilterInput>;
};


export class UserQuotasArgs {
  where?: Maybe<QuotaFilterInput>;
  order?: Maybe<Array<QuotaSortInput>>;
};

export class UserAgentConfiguration {
  __typename?: 'UserAgentConfiguration';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  captureScreenInterval: Scalars['Long'];
  isCaptureScreen: Scalars['Boolean'];
  isLogEnable: Scalars['Boolean'];
  onAppSwitching: Scalars['Boolean'];
  state: States;
  useUserConfiguration: Scalars['Boolean'];
  userId: Scalars['Uuid'];
};

export class AttendanceManagementQueryType {
  __typename?: 'AttendanceManagementQueryType';
  attendance?: Maybe<AttendanceConfigurationQueryType>;
  attendanceRecord?: Maybe<AttendanceRecordQueryType>;
};

export class AttendanceConfiguration {
  __typename?: 'AttendanceConfiguration';
  id: Scalars['Uuid'];
  businessId?: Maybe<Scalars['Uuid']>;
  business?: Maybe<Business>;
  state: States;
  secondShift?: Maybe<SecondShift>;
  firstShift?: Maybe<FirstShift>;
  secondShiftId: Scalars['Uuid'];
  firstShiftId: Scalars['Uuid'];
  syncInterval: Scalars['Long'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class AttendanceUserConfiguration {
  __typename?: 'AttendanceUserConfiguration';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  business?: Maybe<Business>;
  state: States;
  shiftId?: Maybe<Scalars['Uuid']>;
  attendanceId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Uuid']>;
  useUserConfiguration: Scalars['Boolean'];
  closingTime?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['String']>;
  attendanceRecords?: Maybe<Array<Maybe<AttendanceRecord>>>;
  workingDays?: Maybe<Scalars['String']>;
  weekDays?: Maybe<Array<Scalars['Int']>>;
  businessShift?: Maybe<BusinessShift>;
};


export class AttendanceUserConfigurationAttendanceRecordsArgs {
  where?: Maybe<AttendanceRecordFilterInput>;
};

export class FirstShift {
  __typename?: 'FirstShift';
  id: Scalars['Uuid'];
  attendanceConfigurationId?: Maybe<Scalars['Uuid']>;
  state: States;
  closingTime?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['String']>;
};

export class SecondShift {
  __typename?: 'SecondShift';
  id: Scalars['Uuid'];
  attendanceConfigurationId?: Maybe<Scalars['Uuid']>;
  state: States;
  closingTime?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['String']>;
};

export class AttendanceRecord {
  __typename?: 'AttendanceRecord';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  business?: Maybe<Business>;
  isUpdated: Scalars['Boolean'];
  inDateTime: Scalars['DateTime'];
  outDateTime: Scalars['DateTime'];
  attendanceId: Scalars['Int'];
  attendanceUserConfigurationId: Scalars['Uuid'];
  attendanceUserConfiguration?: Maybe<AttendanceUserConfiguration>;
  backupCode: Scalars['Byte'];
  workType: Scalars['Int'];
  agentTime?: Maybe<AgentTime>;
  isLate: Scalars['Boolean'];
};

export class TrackingAgentQueryType {
  __typename?: 'TrackingAgentQueryType';
  agent?: Maybe<AgentQueryType>;
  logs?: Maybe<LogsQueryType>;
  snaps?: Maybe<SnapsQueryType>;
  apps?: Maybe<AppsQueryType>;
};

export class AgentConfiguration {
  __typename?: 'AgentConfiguration';
  id: Scalars['Uuid'];
  state: States;
  isLogEnable: Scalars['Boolean'];
  isCaptureScreen: Scalars['Boolean'];
  onAppSwitching: Scalars['Boolean'];
  captureScreenInterval: Scalars['Long'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class App {
  __typename?: 'App';
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  state: AppStates;
  appUsageSummary?: Maybe<AppUsageSummary>;
};

export class AppUsage {
  __typename?: 'AppUsage';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  userNetwork?: Maybe<Scalars['String']>;
  appId: Scalars['Uuid'];
  username?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  machine?: Maybe<Scalars['String']>;
  iPAddress?: Maybe<Scalars['String']>;
  usageBreakDown?: Maybe<Scalars['String']>;
  hour: Scalars['Byte'];
  date: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  idleMinutes: Scalars['Float'];
  app?: Maybe<App>;
  usage?: Maybe<Array<Maybe<UsageBreakdown>>>;
};

export class UsageBreakdown {
  __typename?: 'UsageBreakdown';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description: Scalars['String'];
};

export class Snapshot {
  __typename?: 'Snapshot';
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  machine?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  networkName?: Maybe<Scalars['String']>;
  iPAddress?: Maybe<Scalars['String']>;
  hour: Scalars['Byte'];
  date: Scalars['DateTime'];
  snapshotBreakdowns?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
  breakdownCount?: Maybe<Scalars['Long']>;
  snapshotCount: Scalars['Long'];
};

export class TrackingAgentMutationType {
  __typename?: 'TrackingAgentMutationType';
  appUsages?: Maybe<AppUsageMutationType>;
  userAgent?: Maybe<UserAgentMutationType>;
  apps?: Maybe<AppsMutationType>;
};

export class TimeTrackingMutationType {
  __typename?: 'TimeTrackingMutationType';
  clients?: Maybe<ClientsMutationType>;
  genericTasks?: Maybe<GenericTasksMutationType>;
  projects?: Maybe<ProjectsMutationType>;
  projectTasks?: Maybe<ProjectTasksMutationType>;
  subTasks?: Maybe<SubTasksMutationType>;
  timeLogs?: Maybe<TimeLogsMutationType>;
};

export class SupportMutationType {
  __typename?: 'SupportMutationType';
  ticketCategories?: Maybe<TicketCategoryMutationType>;
  tickets?: Maybe<TicketMutationType>;
  comments?: Maybe<CommentMutationType>;
};

export class SupportTicketMutationType {
  __typename?: 'SupportTicketMutationType';
  site?: Maybe<SiteMutationType>;
  tickets?: Maybe<TicketsMutationType>;
  ticketStatusChange?: Maybe<TicketStatusChangeMutationType>;
  ticketLabor?: Maybe<TicketLaborMutationType>;
  ticketMaterial?: Maybe<TicketMaterialMutationType>;
};

export class HrMutationType {
  __typename?: 'HrMutationType';
  allowanceCategories?: Maybe<AllowanceCategoriesMutationType>;
  allowanceTypes?: Maybe<AllowanceTypesMutationType>;
  bonusCategories?: Maybe<BonusCategoriesMutationType>;
  bonusTypes?: Maybe<BonusTypesMutationType>;
  candidates?: Maybe<CandidatesMutationType>;
  certifications?: Maybe<CertificationsMutationType>;
  departments?: Maybe<DepartmentsMutationType>;
  hrLookup?: Maybe<HrLookupMutationType>;
  employees?: Maybe<EmployeesMutationType>;
  institutes?: Maybe<InstitutesMutationType>;
  interviewCategories?: Maybe<InterviewCategoriesMutationType>;
  interviewCriteria?: Maybe<InterviewCriteriaMutationType>;
  jobFunctions?: Maybe<JobFunctionsMutationType>;
  jobPositions?: Maybe<JobPositionsMutationType>;
  jobTitles?: Maybe<JobTitlesMutationType>;
  leaves?: Maybe<LeavesMutationType>;
  leaveTypes?: Maybe<LeaveTypesMutationType>;
  loanTypes?: Maybe<LoanTypesMutationType>;
  loans?: Maybe<LoansMutationType>;
  medicalClaims?: Maybe<MedicalClaimsMutationType>;
  reasonTypes?: Maybe<ReasonTypesMutationType>;
  yearlyQuotas?: Maybe<YearlyQuotasMutationType>;
  holidayCalander?: Maybe<HolidayCalanderMutationType>;
  reimbursement?: Maybe<ReimbursementMutationType>;
};

export class FinanceMutationType {
  __typename?: 'FinanceMutationType';
  financialYears?: Maybe<FinancialYearsMutationType>;
  taxes?: Maybe<TaxMutationType>;
  thirdLevelAccounts?: Maybe<ThirdLevelAccountsMutationType>;
  products?: Maybe<ProductsMutationType>;
  contacts?: Maybe<ContactsMutationType>;
  purchaseVouchers?: Maybe<InvoicesMutationType>;
};

export class BusinessMutationType {
  __typename?: 'BusinessMutationType';
  busLookup?: Maybe<BusLookupMutationType>;
  notifications?: Maybe<NotificationMutationType>;
  updateEmails?: Maybe<Scalars['String']>;
  updateHrConfigurationAndWorkingDays?: Maybe<Scalars['String']>;
  updateHrConfiguration?: Maybe<Scalars['String']>;
  updateWorkingDays?: Maybe<Scalars['String']>;
  syncBusinessConfiguration?: Maybe<Scalars['String']>;
};


export class BusinessMutationTypeUpdateEmailsArgs {
  vm?: Maybe<AttendanceNotificationVmInput>;
};


export class BusinessMutationTypeUpdateHrConfigurationAndWorkingDaysArgs {
  workingDays?: Maybe<Array<WeekDays>>;
  hrConfiguration?: Maybe<HrConfigurationVmInput>;
};


export class BusinessMutationTypeUpdateHrConfigurationArgs {
  hrConfiguration?: Maybe<HrConfigurationVmInput>;
};


export class BusinessMutationTypeUpdateWorkingDaysArgs {
  workingDays?: Maybe<Array<WeekDays>>;
};

export class AttendanceManagementMutationType {
  __typename?: 'AttendanceManagementMutationType';
  records?: Maybe<AttendanceRecordMutationType>;
  configuration?: Maybe<AttendanceConfigurationMutationType>;
};

export class FinanceQueryType {
  __typename?: 'FinanceQueryType';
  session?: Maybe<FinanceSessionQueryType>;
  cashInHand: Scalars['Decimal'];
  closingBalance: Scalars['Decimal'];
  paginatedAccountRoles?: Maybe<AccountRoleCollectionSegment>;
  systemAccounts: Array<SystemAccountGroup>;
  isAccountExistWithRole: Scalars['Boolean'];
  cashVouchers?: Maybe<CashVoucherCollectionSegment>;
  paginatedContacts?: Maybe<ContactCollectionSegment>;
  firstLevelAccounts?: Maybe<Array<Maybe<FirstLevelAccount>>>;
  invoicesByType?: Maybe<InvoiceCollectionSegment>;
  products?: Maybe<Array<Maybe<Product>>>;
  paginatedProducts?: Maybe<ProductCollectionSegment>;
  productTypes?: Maybe<Array<Maybe<ProductType>>>;
  productUnits?: Maybe<Array<Maybe<ProductUnit>>>;
  secondLevelAccounts?: Maybe<Array<Maybe<SecondLevelAccount>>>;
  accountSequenceNo: Scalars['Int'];
  thirdLevelAccounts?: Maybe<Array<Maybe<ThirdLevelAccount>>>;
  paginatedThirdLevelAccounts?: Maybe<ThirdLevelAccountCollectionSegment>;
  cashInHandAccounts?: Maybe<ThirdLevelAccountCollectionSegment>;
  paginatedThirdLevelAccountsByRole?: Maybe<ThirdLevelAccountCollectionSegment>;
  vouchers?: Maybe<VoucherCollectionSegment>;
  vouchersInTypes?: Maybe<VoucherCollectionSegment>;
  voucherSequenceNumber?: Maybe<Scalars['String']>;
  accountVouchers?: Maybe<VoucherCollectionSegment>;
  salaryVouchers?: Maybe<VoucherCollectionSegment>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  address?: Maybe<Address>;
};


export class FinanceQueryTypeCashInHandArgs {
  date: Scalars['DateTime'];
};


export class FinanceQueryTypeClosingBalanceArgs {
  date: Scalars['DateTime'];
};


export class FinanceQueryTypePaginatedAccountRolesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountRoleFilterInput>;
  order?: Maybe<Array<AccountRoleSortInput>>;
};


export class FinanceQueryTypeIsAccountExistWithRoleArgs {
  roleNumber: Scalars['Long'];
};


export class FinanceQueryTypeCashVouchersArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CashVoucherFilterInput>;
  order?: Maybe<Array<CashVoucherSortInput>>;
};


export class FinanceQueryTypePaginatedContactsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ContactFilterInput>;
  order?: Maybe<Array<ContactSortInput>>;
};


export class FinanceQueryTypeFirstLevelAccountsArgs {
  where?: Maybe<FirstLevelAccountFilterInput>;
};


export class FinanceQueryTypeInvoicesByTypeArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  type: VoucherTypes;
  where?: Maybe<InvoiceFilterInput>;
  order?: Maybe<Array<InvoiceSortInput>>;
};


export class FinanceQueryTypeProductsArgs {
  where?: Maybe<ProductFilterInput>;
};


export class FinanceQueryTypePaginatedProductsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductFilterInput>;
  order?: Maybe<Array<ProductSortInput>>;
};


export class FinanceQueryTypeSecondLevelAccountsArgs {
  where?: Maybe<SecondLevelAccountFilterInput>;
};


export class FinanceQueryTypeAccountSequenceNoArgs {
  accountId: Scalars['Uuid'];
};


export class FinanceQueryTypeThirdLevelAccountsArgs {
  where?: Maybe<ThirdLevelAccountFilterInput>;
};


export class FinanceQueryTypePaginatedThirdLevelAccountsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ThirdLevelAccountFilterInput>;
  order?: Maybe<Array<ThirdLevelAccountSortInput>>;
};


export class FinanceQueryTypeCashInHandAccountsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ThirdLevelAccountFilterInput>;
  order?: Maybe<Array<ThirdLevelAccountSortInput>>;
};


export class FinanceQueryTypePaginatedThirdLevelAccountsByRoleArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  roleNumber: Scalars['Long'];
  where?: Maybe<ThirdLevelAccountFilterInput>;
  order?: Maybe<Array<ThirdLevelAccountSortInput>>;
};


export class FinanceQueryTypeVouchersArgs {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VoucherFilterInput>;
  order?: Maybe<Array<VoucherSortInput>>;
};


export class FinanceQueryTypeVouchersInTypesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<VoucherTypes>>;
  where?: Maybe<VoucherFilterInput>;
  order?: Maybe<Array<VoucherSortInput>>;
};


export class FinanceQueryTypeVoucherSequenceNumberArgs {
  voucherCode?: Maybe<Scalars['String']>;
};


export class FinanceQueryTypeAccountVouchersArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  accountId: Scalars['Uuid'];
  where?: Maybe<VoucherFilterInput>;
  order?: Maybe<Array<VoucherSortInput>>;
};


export class FinanceQueryTypeSalaryVouchersArgs {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  departmentId?: Maybe<Scalars['Uuid']>;
  where?: Maybe<VoucherFilterInput>;
  order?: Maybe<Array<VoucherSortInput>>;
};


export class FinanceQueryTypeAddressesArgs {
  where?: Maybe<AddressFilterInput>;
};


export class FinanceQueryTypeAddressArgs {
  contactId: Scalars['Uuid'];
};

export class AccountRole {
  __typename?: 'AccountRole';
  id: Scalars['Uuid'];
  name: Scalars['String'];
  roleNumber: Scalars['Long'];
  description?: Maybe<Scalars['String']>;
};

export class BusinessFilterInput {
  and?: Maybe<Array<BusinessFilterInput>>;
  or?: Maybe<Array<BusinessFilterInput>>;
  agentConfigurationId?: Maybe<ComparableGuidOperationFilterInput>;
  agentConfiguration?: Maybe<AgentConfigurationFilterInput>;
  userAgentConfigurations?: Maybe<ListFilterInputTypeOfUserAgentConfigurationFilterInput>;
  snapshots?: Maybe<ListFilterInputTypeOfSnapshotFilterInput>;
  appUsages?: Maybe<ListFilterInputTypeOfAppUsageFilterInput>;
  userAgentLogins?: Maybe<ListFilterInputTypeOfUserAgentLoginFilterInput>;
  attendanceConfigurationId?: Maybe<ComparableGuidOperationFilterInput>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationFilterInput>;
  attendanceUserConfigurations?: Maybe<ListFilterInputTypeOfAttendanceUserConfigurationFilterInput>;
  attendanceRecords?: Maybe<ListFilterInputTypeOfAttendanceRecordFilterInput>;
  stg_AttendanceRecords?: Maybe<ListFilterInputTypeOfStg_AttendanceRecordFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  fax?: Maybe<StringOperationFilterInput>;
  mobile?: Maybe<StringOperationFilterInput>;
  tollFree?: Maybe<StringOperationFilterInput>;
  website?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  currency?: Maybe<StringOperationFilterInput>;
  businessTypeId?: Maybe<BusinessTypesOperationFilterInput>;
  industryId?: Maybe<IndustriesOperationFilterInput>;
  logo?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  addresses?: Maybe<ListFilterInputTypeOfAddressFilterInput>;
  contacts?: Maybe<ListFilterInputTypeOfContactFilterInput>;
  notes?: Maybe<ListFilterInputTypeOfNoteFilterInput>;
  holidayCalanders?: Maybe<ListFilterInputTypeOfHolidayCalanderFilterInput>;
  businessUsers?: Maybe<ListFilterInputTypeOfUserFilterInput>;
  businessUserInfos?: Maybe<ListFilterInputTypeOfBusinessUserFilterInput>;
  apps?: Maybe<ListFilterInputTypeOfAppFilterInput>;
  lookups?: Maybe<ListFilterInputTypeOfBusinessLookupFilterInput>;
  configuration?: Maybe<StringOperationFilterInput>;
  vouchers?: Maybe<ListFilterInputTypeOfVoucherFilterInput>;
  cashVouchers?: Maybe<ListFilterInputTypeOfCashVoucherFilterInput>;
  invoices?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  voucherTypes?: Maybe<ListFilterInputTypeOfVoucherTypeFilterInput>;
  accounts?: Maybe<ListFilterInputTypeOfAccountFilterInput>;
  financialYears?: Maybe<ListFilterInputTypeOfFinancialYearFilterInput>;
  financialYearVouchers?: Maybe<ListFilterInputTypeOfFinancialYearVoucherFilterInput>;
  taxes?: Maybe<ListFilterInputTypeOfTaxFilterInput>;
  products?: Maybe<ListFilterInputTypeOfProductFilterInput>;
  salaryAccounts?: Maybe<SalaryAccountFilterInput>;
  departments?: Maybe<ListFilterInputTypeOfDepartmentFilterInput>;
  certifications?: Maybe<ListFilterInputTypeOfCertificationFilterInput>;
  allowanceTypes?: Maybe<ListFilterInputTypeOfAllowanceTypeFilterInput>;
  allowanceCategories?: Maybe<ListFilterInputTypeOfAllowanceCategoryFilterInput>;
  bonusCategories?: Maybe<ListFilterInputTypeOfBonusCategoryFilterInput>;
  bonusTypes?: Maybe<ListFilterInputTypeOfBonusTypeFilterInput>;
  institutes?: Maybe<ListFilterInputTypeOfInstituteFilterInput>;
  interviewCategories?: Maybe<ListFilterInputTypeOfInterviewCategoryFilterInput>;
  interviewCriteria?: Maybe<ListFilterInputTypeOfInterviewCriterionFilterInput>;
  jobFunctions?: Maybe<ListFilterInputTypeOfJobFunctionFilterInput>;
  jobTitles?: Maybe<ListFilterInputTypeOfJobTitleFilterInput>;
  loanTypes?: Maybe<ListFilterInputTypeOfLoanTypeFilterInput>;
  reasonTypes?: Maybe<ListFilterInputTypeOfReasonTypeFilterInput>;
  employees?: Maybe<ListFilterInputTypeOfEmployeeFilterInput>;
  jobPositions?: Maybe<ListFilterInputTypeOfJobPositionFilterInput>;
  candidates?: Maybe<ListFilterInputTypeOfCandidateFilterInput>;
  quotas?: Maybe<ListFilterInputTypeOfQuotaFilterInput>;
  hrLookups?: Maybe<ListFilterInputTypeOfHrLookupFilterInput>;
  sites?: Maybe<ListFilterInputTypeOfSiteFilterInput>;
  ticketss?: Maybe<ListFilterInputTypeOfTicketsFilterInput>;
  tickets?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
  categories?: Maybe<ListFilterInputTypeOfTicketCategoryFilterInput>;
  clients?: Maybe<ListFilterInputTypeOfClientFilterInput>;
  genericTasks?: Maybe<ListFilterInputTypeOfGenericTaskFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfNotificationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class NoteDateilFilterInput {
  and?: Maybe<Array<NoteDateilFilterInput>>;
  or?: Maybe<Array<NoteDateilFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  objectID?: Maybe<ComparableGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  noteText?: Maybe<StringOperationFilterInput>;
  noteModuleCode?: Maybe<StringOperationFilterInput>;
  noteTypeId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  path?: Maybe<StringOperationFilterInput>;
  noteType?: Maybe<StringOperationFilterInput>;
  noteTypeCode?: Maybe<StringOperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  attachments?: Maybe<ListFilterInputTypeOfAttachmentFilterInput>;
};

export class NoteDateilSortInput {
  id?: Maybe<SortEnumType>;
  objectID?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  noteText?: Maybe<SortEnumType>;
  noteModuleCode?: Maybe<SortEnumType>;
  noteTypeId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  path?: Maybe<SortEnumType>;
  noteType?: Maybe<SortEnumType>;
  noteTypeCode?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
};

export class AddressFilterInput {
  and?: Maybe<Array<AddressFilterInput>>;
  or?: Maybe<Array<AddressFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  zipCode?: Maybe<StringOperationFilterInput>;
  addressLine1?: Maybe<StringOperationFilterInput>;
  addressLine2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  timeZone?: Maybe<StringOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  purchaseVouchers?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class NotificationSortInput {
  isRead?: Maybe<SortEnumType>;
  message?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  systemObject?: Maybe<SortEnumType>;
  systemObjectId?: Maybe<SortEnumType>;
  user?: Maybe<UserSortInput>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class BusinessLookupFilterInput {
  and?: Maybe<Array<BusinessLookupFilterInput>>;
  or?: Maybe<Array<BusinessLookupFilterInput>>;
  parentId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  isSystemDefined?: Maybe<BooleanOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  setting?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  lookupCategory?: Maybe<StringOperationFilterInput>;
  lookupModuleCode?: Maybe<StringOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  parent?: Maybe<BusinessLookupFilterInput>;
  quotas?: Maybe<ListFilterInputTypeOfQuotaFilterInput>;
  medicalClaims?: Maybe<ListFilterInputTypeOfMedicalClaimFilterInput>;
  ticketsPriority?: Maybe<ListFilterInputTypeOfTicketsFilterInput>;
  ticketsProblem?: Maybe<ListFilterInputTypeOfTicketsFilterInput>;
  ticketStatus?: Maybe<ListFilterInputTypeOfTicketStatusChangeFilterInput>;
  reimbursements?: Maybe<ListFilterInputTypeOfReimbursementFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class BusinessLookupSortInput {
  parentId?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  isSystemDefined?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  setting?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  lookupCategory?: Maybe<SortEnumType>;
  lookupModuleCode?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  parent?: Maybe<BusinessLookupSortInput>;
  id?: Maybe<SortEnumType>;
};

export class HolidayCalanderFilterInput {
  and?: Maybe<Array<HolidayCalanderFilterInput>>;
  or?: Maybe<Array<HolidayCalanderFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  eventName?: Maybe<StringOperationFilterInput>;
  fromDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  toDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class HolidayCalanderSortInput {
  businessId?: Maybe<SortEnumType>;
  eventName?: Maybe<SortEnumType>;
  fromDate?: Maybe<SortEnumType>;
  toDate?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class NoteDateilCollectionSegment {
  __typename?: 'NoteDateilCollectionSegment';
  items?: Maybe<Array<Maybe<NoteDateil>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class NotificationCollectionSegment {
  __typename?: 'NotificationCollectionSegment';
  items?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class HolidayCalanderCollectionSegment {
  __typename?: 'HolidayCalanderCollectionSegment';
  items?: Maybe<Array<Maybe<HolidayCalander>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class SubTaskCollectionSegment {
  __typename?: 'SubTaskCollectionSegment';
  items?: Maybe<Array<Maybe<SubTask>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ProjectFilterInput {
  and?: Maybe<Array<ProjectFilterInput>>;
  or?: Maybe<Array<ProjectFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  clientId?: Maybe<ComparableGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  client?: Maybe<ClientFilterInput>;
  projectTasks?: Maybe<ListFilterInputTypeOfProjectTaskFilterInput>;
  projectUserRoles?: Maybe<ListFilterInputTypeOfProjectUserRoleFilterInput>;
  timeLogs?: Maybe<ListFilterInputTypeOfTimeLogFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ProjectSortInput {
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  clientId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  client?: Maybe<ClientSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ProjectUserRoleFilterInput {
  and?: Maybe<Array<ProjectUserRoleFilterInput>>;
  or?: Maybe<Array<ProjectUserRoleFilterInput>>;
  projectId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  projectRole?: Maybe<ProjectRolesOperationFilterInput>;
  project?: Maybe<ProjectFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ProjectCollectionSegment {
  __typename?: 'ProjectCollectionSegment';
  items?: Maybe<Array<Maybe<Project>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TicketCategoryFilterInput {
  and?: Maybe<Array<TicketCategoryFilterInput>>;
  or?: Maybe<Array<TicketCategoryFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  tickets?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class TicketCategorySortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TicketFilterInput {
  and?: Maybe<Array<TicketFilterInput>>;
  or?: Maybe<Array<TicketFilterInput>>;
  parentTicketId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  title?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  categoryId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  assignedUserId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: Maybe<NullableOfStatesOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  parentTicket?: Maybe<TicketFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  category?: Maybe<TicketCategoryFilterInput>;
  createdBy?: Maybe<UserFilterInput>;
  assignedUser?: Maybe<UserFilterInput>;
  comments?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class TicketSortInput {
  parentTicketId?: Maybe<SortEnumType>;
  title?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  assignedUserId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  parentTicket?: Maybe<TicketSortInput>;
  business?: Maybe<BusinessSortInput>;
  category?: Maybe<TicketCategorySortInput>;
  createdBy?: Maybe<UserSortInput>;
  assignedUser?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TicketCategoryCollectionSegment {
  __typename?: 'TicketCategoryCollectionSegment';
  items?: Maybe<Array<Maybe<TicketCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TicketCollectionSegment {
  __typename?: 'TicketCollectionSegment';
  items?: Maybe<Array<Maybe<Ticket>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class SiteFilterInput {
  and?: Maybe<Array<SiteFilterInput>>;
  or?: Maybe<Array<SiteFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  siteNo?: Maybe<StringOperationFilterInput>;
  siteName?: Maybe<StringOperationFilterInput>;
  siteManagerId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  address1?: Maybe<StringOperationFilterInput>;
  address2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  siteState?: Maybe<StringOperationFilterInput>;
  zip?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  latitude?: Maybe<StringOperationFilterInput>;
  longitude?: Maybe<StringOperationFilterInput>;
  completeAddress?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  parentId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  tickets?: Maybe<ListFilterInputTypeOfTicketsFilterInput>;
  parent?: Maybe<SiteFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class SiteSortInput {
  businessId?: Maybe<SortEnumType>;
  siteNo?: Maybe<SortEnumType>;
  siteName?: Maybe<SortEnumType>;
  siteManagerId?: Maybe<SortEnumType>;
  address1?: Maybe<SortEnumType>;
  address2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  siteState?: Maybe<SortEnumType>;
  zip?: Maybe<SortEnumType>;
  phone?: Maybe<SortEnumType>;
  latitude?: Maybe<SortEnumType>;
  longitude?: Maybe<SortEnumType>;
  completeAddress?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  parent?: Maybe<SiteSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TicketsFilterInput {
  and?: Maybe<Array<TicketsFilterInput>>;
  or?: Maybe<Array<TicketsFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  ticketNo?: Maybe<StringOperationFilterInput>;
  ticketDescription?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<ComparableGuidOperationFilterInput>;
  reportedById?: Maybe<ComparableGuidOperationFilterInput>;
  entryDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  scheduleDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dueDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  completeDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  firstResponseDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  executionStartDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  executionEndDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  completionDuration?: Maybe<ComparableNullableOfTimeSpanOperationFilterInput>;
  problemId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  priorityId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  site?: Maybe<SiteFilterInput>;
  problemLookup?: Maybe<BusinessLookupFilterInput>;
  priorityLookup?: Maybe<BusinessLookupFilterInput>;
  ticketStatusChanges?: Maybe<ListFilterInputTypeOfTicketStatusChangeFilterInput>;
  ticketLabor?: Maybe<ListFilterInputTypeOfTicketLaborFilterInput>;
  ticketMaterial?: Maybe<ListFilterInputTypeOfTicketMaterialFilterInput>;
  reportedUser?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class TicketsSortInput {
  businessId?: Maybe<SortEnumType>;
  ticketNo?: Maybe<SortEnumType>;
  ticketDescription?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  reportedById?: Maybe<SortEnumType>;
  entryDate?: Maybe<SortEnumType>;
  scheduleDate?: Maybe<SortEnumType>;
  dueDate?: Maybe<SortEnumType>;
  completeDate?: Maybe<SortEnumType>;
  firstResponseDate?: Maybe<SortEnumType>;
  executionStartDate?: Maybe<SortEnumType>;
  executionEndDate?: Maybe<SortEnumType>;
  completionDuration?: Maybe<SortEnumType>;
  problemId?: Maybe<SortEnumType>;
  priorityId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  site?: Maybe<SiteSortInput>;
  problemLookup?: Maybe<BusinessLookupSortInput>;
  priorityLookup?: Maybe<BusinessLookupSortInput>;
  reportedUser?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TicketStatusChangeFilterInput {
  and?: Maybe<Array<TicketStatusChangeFilterInput>>;
  or?: Maybe<Array<TicketStatusChangeFilterInput>>;
  ticketId?: Maybe<ComparableGuidOperationFilterInput>;
  latitude?: Maybe<StringOperationFilterInput>;
  longitude?: Maybe<StringOperationFilterInput>;
  changeStatusId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  changeOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  changeById?: Maybe<ComparableGuidOperationFilterInput>;
  assignedUserId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: Maybe<ComparableInt32OperationFilterInput>;
  statusType?: Maybe<ComparableInt32OperationFilterInput>;
  ticket?: Maybe<TicketsFilterInput>;
  changedUser?: Maybe<UserFilterInput>;
  assignedUser?: Maybe<UserFilterInput>;
  statusLookup?: Maybe<BusinessLookupFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class TicketStatusChangeSortInput {
  ticketId?: Maybe<SortEnumType>;
  latitude?: Maybe<SortEnumType>;
  longitude?: Maybe<SortEnumType>;
  changeStatusId?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  changeOn?: Maybe<SortEnumType>;
  changeById?: Maybe<SortEnumType>;
  assignedUserId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  statusType?: Maybe<SortEnumType>;
  ticket?: Maybe<TicketsSortInput>;
  changedUser?: Maybe<UserSortInput>;
  assignedUser?: Maybe<UserSortInput>;
  statusLookup?: Maybe<BusinessLookupSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TicketLaborWithUserDetailFilterInput {
  and?: Maybe<Array<TicketLaborWithUserDetailFilterInput>>;
  or?: Maybe<Array<TicketLaborWithUserDetailFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  ticketId?: Maybe<ComparableGuidOperationFilterInput>;
  hours?: Maybe<ComparableDecimalOperationFilterInput>;
  hoursType?: Maybe<StringOperationFilterInput>;
  billable?: Maybe<ComparableDecimalOperationFilterInput>;
  rate?: Maybe<ComparableDecimalOperationFilterInput>;
  workerId?: Maybe<ComparableGuidOperationFilterInput>;
  workerName?: Maybe<StringOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
};

export class TicketLaborWithUserDetailSortInput {
  id?: Maybe<SortEnumType>;
  ticketId?: Maybe<SortEnumType>;
  hours?: Maybe<SortEnumType>;
  hoursType?: Maybe<SortEnumType>;
  billable?: Maybe<SortEnumType>;
  rate?: Maybe<SortEnumType>;
  workerId?: Maybe<SortEnumType>;
  workerName?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
};

export class TicketMaterialFilterInput {
  and?: Maybe<Array<TicketMaterialFilterInput>>;
  or?: Maybe<Array<TicketMaterialFilterInput>>;
  ticketId?: Maybe<ComparableGuidOperationFilterInput>;
  productId?: Maybe<ComparableGuidOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  price?: Maybe<ComparableDecimalOperationFilterInput>;
  quantity?: Maybe<ComparableDecimalOperationFilterInput>;
  discount?: Maybe<ComparableDecimalOperationFilterInput>;
  tax?: Maybe<ComparableDecimalOperationFilterInput>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  taxSettings?: Maybe<StringOperationFilterInput>;
  state?: Maybe<NullableOfVoucherStatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  product?: Maybe<ProductFilterInput>;
  tickets?: Maybe<TicketsFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class TicketMaterialSortInput {
  ticketId?: Maybe<SortEnumType>;
  productId?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  price?: Maybe<SortEnumType>;
  quantity?: Maybe<SortEnumType>;
  discount?: Maybe<SortEnumType>;
  tax?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  taxSettings?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  product?: Maybe<ProductSortInput>;
  tickets?: Maybe<TicketsSortInput>;
  id?: Maybe<SortEnumType>;
};

export class SiteCollectionSegment {
  __typename?: 'SiteCollectionSegment';
  items?: Maybe<Array<Maybe<Site>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TicketsCollectionSegment {
  __typename?: 'TicketsCollectionSegment';
  items?: Maybe<Array<Maybe<Tickets>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TicketStatusChangeCollectionSegment {
  __typename?: 'TicketStatusChangeCollectionSegment';
  items?: Maybe<Array<Maybe<TicketStatusChange>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TicketLaborWithUserDetailCollectionSegment {
  __typename?: 'TicketLaborWithUserDetailCollectionSegment';
  items?: Maybe<Array<Maybe<TicketLaborWithUserDetail>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TicketMaterialCollectionSegment {
  __typename?: 'TicketMaterialCollectionSegment';
  items?: Maybe<Array<Maybe<TicketMaterial>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class LoanFilterInput {
  and?: Maybe<Array<LoanFilterInput>>;
  or?: Maybe<Array<LoanFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  purposeOfLoan?: Maybe<StringOperationFilterInput>;
  amountApplied?: Maybe<ComparableSingleOperationFilterInput>;
  amountApproved?: Maybe<ComparableSingleOperationFilterInput>;
  numberOfInstallments?: Maybe<ComparableByteOperationFilterInput>;
  remarks?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  loanTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  loanType?: Maybe<BusinessLookupFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class LoanSortInput {
  userId?: Maybe<SortEnumType>;
  purposeOfLoan?: Maybe<SortEnumType>;
  amountApplied?: Maybe<SortEnumType>;
  amountApproved?: Maybe<SortEnumType>;
  numberOfInstallments?: Maybe<SortEnumType>;
  remarks?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  loanTypeId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  loanType?: Maybe<BusinessLookupSortInput>;
  user?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class MedicalClaimFilterInput {
  and?: Maybe<Array<MedicalClaimFilterInput>>;
  or?: Maybe<Array<MedicalClaimFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  claimLookupId?: Maybe<ComparableGuidOperationFilterInput>;
  amount?: Maybe<ComparableSingleOperationFilterInput>;
  amountApproved?: Maybe<ComparableSingleOperationFilterInput>;
  balanceCf?: Maybe<ComparableSingleOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  businessLookups?: Maybe<BusinessLookupFilterInput>;
  medicalClaimBreakdowns?: Maybe<ListFilterInputTypeOfMedicalClaimBreakdownFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class MedicalClaimSortInput {
  userId?: Maybe<SortEnumType>;
  claimLookupId?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  amountApproved?: Maybe<SortEnumType>;
  balanceCf?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  user?: Maybe<UserSortInput>;
  businessLookups?: Maybe<BusinessLookupSortInput>;
  id?: Maybe<SortEnumType>;
};

export class QuotaFilterInput {
  and?: Maybe<Array<QuotaFilterInput>>;
  or?: Maybe<Array<QuotaFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  year?: Maybe<StringOperationFilterInput>;
  lookupId?: Maybe<ComparableGuidOperationFilterInput>;
  assignedQuota?: Maybe<ComparableDoubleOperationFilterInput>;
  availableValue?: Maybe<ComparableDoubleOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  lookup?: Maybe<BusinessLookupFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class QuotaSortInput {
  businessId?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  lookupId?: Maybe<SortEnumType>;
  assignedQuota?: Maybe<SortEnumType>;
  availableValue?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  lookup?: Maybe<BusinessLookupSortInput>;
  user?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ReimbursementFilterInput {
  and?: Maybe<Array<ReimbursementFilterInput>>;
  or?: Maybe<Array<ReimbursementFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  reimbursementTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  amount?: Maybe<ComparableSingleOperationFilterInput>;
  amountApproved?: Maybe<ComparableSingleOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  appliedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  authorizedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  authorizedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  authorizedBy?: Maybe<UserFilterInput>;
  businessLookups?: Maybe<BusinessLookupFilterInput>;
  reimbursementDetails?: Maybe<ListFilterInputTypeOfReimbursementDetailFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ReimbursementSortInput {
  businessId?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  reimbursementTypeId?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  amountApproved?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  appliedOn?: Maybe<SortEnumType>;
  authorizedById?: Maybe<SortEnumType>;
  authorizedOn?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  user?: Maybe<UserSortInput>;
  authorizedBy?: Maybe<UserSortInput>;
  businessLookups?: Maybe<BusinessLookupSortInput>;
  id?: Maybe<SortEnumType>;
};

export class LoanCollectionSegment {
  __typename?: 'LoanCollectionSegment';
  items?: Maybe<Array<Maybe<Loan>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class MedicalClaimCollectionSegment {
  __typename?: 'MedicalClaimCollectionSegment';
  items?: Maybe<Array<Maybe<MedicalClaim>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ReimbursementCollectionSegment {
  __typename?: 'ReimbursementCollectionSegment';
  items?: Maybe<Array<Maybe<Reimbursement>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class TaxFilterInput {
  and?: Maybe<Array<TaxFilterInput>>;
  or?: Maybe<Array<TaxFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  abbreviation?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  number?: Maybe<StringOperationFilterInput>;
  rate?: Maybe<ComparableDecimalOperationFilterInput>;
  isCompoundTax?: Maybe<BooleanOperationFilterInput>;
  showTaxOnInvoices?: Maybe<BooleanOperationFilterInput>;
  isTaxRecoverable?: Maybe<BooleanOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  invoiceDetailTaxs?: Maybe<ListFilterInputTypeOfInvoiceDetailTaxFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class BusinessConfig {
  __typename?: 'BusinessConfig';
  invoiceString: Scalars['String'];
  invoiceConfig: InvoiceConfiguration;
  attendanceNotificationString: Scalars['String'];
  attendanceNotification: AttendanceNotification;
  hrConfigurationString: Scalars['String'];
  hrConfig: HrConfiguration;
  workingDaysString: Scalars['String'];
  workingDays: Array<WeekDays>;
};

export class Currency {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export class AttendanceUserConfigurationFilterInput {
  and?: Maybe<Array<AttendanceUserConfigurationFilterInput>>;
  or?: Maybe<Array<AttendanceUserConfigurationFilterInput>>;
  userId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  attendanceId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  shiftId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  useUserConfiguration?: Maybe<BooleanOperationFilterInput>;
  openingTime?: Maybe<StringOperationFilterInput>;
  closingTime?: Maybe<StringOperationFilterInput>;
  workingDays?: Maybe<StringOperationFilterInput>;
  weekDays?: Maybe<ListWeekDaysOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  user?: Maybe<UserFilterInput>;
  businessShift?: Maybe<BusinessShiftFilterInput>;
  attendanceRecords?: Maybe<ListFilterInputTypeOfAttendanceRecordFilterInput>;
  stg_AttendanceRecords?: Maybe<ListFilterInputTypeOfStg_AttendanceRecordFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class LeaveFilterInput {
  and?: Maybe<Array<LeaveFilterInput>>;
  or?: Maybe<Array<LeaveFilterInput>>;
  leaveTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  reason?: Maybe<StringOperationFilterInput>;
  remarks?: Maybe<StringOperationFilterInput>;
  fromDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  toDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  weight?: Maybe<ComparableDoubleOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  leaveType?: Maybe<BusinessLookupFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AttendanceRecordFilterInput {
  and?: Maybe<Array<AttendanceRecordFilterInput>>;
  or?: Maybe<Array<AttendanceRecordFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  attendanceUserConfigurationId?: Maybe<ComparableGuidOperationFilterInput>;
  attendanceId?: Maybe<ComparableInt32OperationFilterInput>;
  inDateTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  outDateTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  backupCode?: Maybe<ComparableByteOperationFilterInput>;
  isUpdated?: Maybe<BooleanOperationFilterInput>;
  workType?: Maybe<ComparableInt32OperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  attendanceUserConfiguration?: Maybe<AttendanceUserConfigurationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};


export class AccountRoleFilterInput {
  and?: Maybe<Array<AccountRoleFilterInput>>;
  or?: Maybe<Array<AccountRoleFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  roleNumber?: Maybe<ComparableInt64OperationFilterInput>;
  roles?: Maybe<ListComparableInt64OperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
};

export class AccountRoleSortInput {
  id?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  roleNumber?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
};

export class AccountRoleCollectionSegment {
  __typename?: 'AccountRoleCollectionSegment';
  items?: Maybe<Array<AccountRole>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export class ComparableGuidOperationFilterInput {
  eq?: Maybe<Scalars['Uuid']>;
  neq?: Maybe<Scalars['Uuid']>;
  in?: Maybe<Array<Scalars['Uuid']>>;
  nin?: Maybe<Array<Scalars['Uuid']>>;
  gt?: Maybe<Scalars['Uuid']>;
  ngt?: Maybe<Scalars['Uuid']>;
  gte?: Maybe<Scalars['Uuid']>;
  ngte?: Maybe<Scalars['Uuid']>;
  lt?: Maybe<Scalars['Uuid']>;
  nlt?: Maybe<Scalars['Uuid']>;
  lte?: Maybe<Scalars['Uuid']>;
  nlte?: Maybe<Scalars['Uuid']>;
};

export class AgentConfigurationFilterInput {
  and?: Maybe<Array<AgentConfigurationFilterInput>>;
  or?: Maybe<Array<AgentConfigurationFilterInput>>;
  businessId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  isLogEnable?: Maybe<BooleanOperationFilterInput>;
  isCaptureScreen?: Maybe<BooleanOperationFilterInput>;
  onAppSwitching?: Maybe<BooleanOperationFilterInput>;
  captureScreenInterval?: Maybe<ComparableInt64OperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ListFilterInputTypeOfUserAgentConfigurationFilterInput {
  all?: Maybe<UserAgentConfigurationFilterInput>;
  none?: Maybe<UserAgentConfigurationFilterInput>;
  some?: Maybe<UserAgentConfigurationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfSnapshotFilterInput {
  all?: Maybe<SnapshotFilterInput>;
  none?: Maybe<SnapshotFilterInput>;
  some?: Maybe<SnapshotFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAppUsageFilterInput {
  all?: Maybe<AppUsageFilterInput>;
  none?: Maybe<AppUsageFilterInput>;
  some?: Maybe<AppUsageFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfUserAgentLoginFilterInput {
  all?: Maybe<UserAgentLoginFilterInput>;
  none?: Maybe<UserAgentLoginFilterInput>;
  some?: Maybe<UserAgentLoginFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class AttendanceConfigurationFilterInput {
  and?: Maybe<Array<AttendanceConfigurationFilterInput>>;
  or?: Maybe<Array<AttendanceConfigurationFilterInput>>;
  businessId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  firstShiftId?: Maybe<ComparableGuidOperationFilterInput>;
  secondShiftId?: Maybe<ComparableGuidOperationFilterInput>;
  syncInterval?: Maybe<ComparableInt64OperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  firstShift?: Maybe<FirstShiftFilterInput>;
  secondShift?: Maybe<SecondShiftFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ListFilterInputTypeOfAttendanceUserConfigurationFilterInput {
  all?: Maybe<AttendanceUserConfigurationFilterInput>;
  none?: Maybe<AttendanceUserConfigurationFilterInput>;
  some?: Maybe<AttendanceUserConfigurationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAttendanceRecordFilterInput {
  all?: Maybe<AttendanceRecordFilterInput>;
  none?: Maybe<AttendanceRecordFilterInput>;
  some?: Maybe<AttendanceRecordFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfStg_AttendanceRecordFilterInput {
  all?: Maybe<Stg_AttendanceRecordFilterInput>;
  none?: Maybe<Stg_AttendanceRecordFilterInput>;
  some?: Maybe<Stg_AttendanceRecordFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class StringOperationFilterInput {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export class BusinessTypesOperationFilterInput {
  eq?: Maybe<BusinessTypes>;
  neq?: Maybe<BusinessTypes>;
  in?: Maybe<Array<BusinessTypes>>;
  nin?: Maybe<Array<BusinessTypes>>;
};

export class IndustriesOperationFilterInput {
  eq?: Maybe<Industries>;
  neq?: Maybe<Industries>;
  in?: Maybe<Array<Industries>>;
  nin?: Maybe<Array<Industries>>;
};

export class StatesOperationFilterInput {
  eq?: Maybe<States>;
  neq?: Maybe<States>;
  in?: Maybe<Array<States>>;
  nin?: Maybe<Array<States>>;
};

export class ComparableDateTimeOperationFilterInput {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export class ComparableNullableOfGuidOperationFilterInput {
  eq?: Maybe<Scalars['Uuid']>;
  neq?: Maybe<Scalars['Uuid']>;
  in?: Maybe<Array<Maybe<Scalars['Uuid']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Uuid']>>>;
  gt?: Maybe<Scalars['Uuid']>;
  ngt?: Maybe<Scalars['Uuid']>;
  gte?: Maybe<Scalars['Uuid']>;
  ngte?: Maybe<Scalars['Uuid']>;
  lt?: Maybe<Scalars['Uuid']>;
  nlt?: Maybe<Scalars['Uuid']>;
  lte?: Maybe<Scalars['Uuid']>;
  nlte?: Maybe<Scalars['Uuid']>;
};

export class ListFilterInputTypeOfAddressFilterInput {
  all?: Maybe<AddressFilterInput>;
  none?: Maybe<AddressFilterInput>;
  some?: Maybe<AddressFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfContactFilterInput {
  all?: Maybe<ContactFilterInput>;
  none?: Maybe<ContactFilterInput>;
  some?: Maybe<ContactFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfNoteFilterInput {
  all?: Maybe<NoteFilterInput>;
  none?: Maybe<NoteFilterInput>;
  some?: Maybe<NoteFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfHolidayCalanderFilterInput {
  all?: Maybe<HolidayCalanderFilterInput>;
  none?: Maybe<HolidayCalanderFilterInput>;
  some?: Maybe<HolidayCalanderFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfUserFilterInput {
  all?: Maybe<UserFilterInput>;
  none?: Maybe<UserFilterInput>;
  some?: Maybe<UserFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfBusinessUserFilterInput {
  all?: Maybe<BusinessUserFilterInput>;
  none?: Maybe<BusinessUserFilterInput>;
  some?: Maybe<BusinessUserFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAppFilterInput {
  all?: Maybe<AppFilterInput>;
  none?: Maybe<AppFilterInput>;
  some?: Maybe<AppFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfBusinessLookupFilterInput {
  all?: Maybe<BusinessLookupFilterInput>;
  none?: Maybe<BusinessLookupFilterInput>;
  some?: Maybe<BusinessLookupFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfVoucherFilterInput {
  all?: Maybe<VoucherFilterInput>;
  none?: Maybe<VoucherFilterInput>;
  some?: Maybe<VoucherFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCashVoucherFilterInput {
  all?: Maybe<CashVoucherFilterInput>;
  none?: Maybe<CashVoucherFilterInput>;
  some?: Maybe<CashVoucherFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInvoiceFilterInput {
  all?: Maybe<InvoiceFilterInput>;
  none?: Maybe<InvoiceFilterInput>;
  some?: Maybe<InvoiceFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfVoucherTypeFilterInput {
  all?: Maybe<VoucherTypeFilterInput>;
  none?: Maybe<VoucherTypeFilterInput>;
  some?: Maybe<VoucherTypeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAccountFilterInput {
  all?: Maybe<AccountFilterInput>;
  none?: Maybe<AccountFilterInput>;
  some?: Maybe<AccountFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfFinancialYearFilterInput {
  all?: Maybe<FinancialYearFilterInput>;
  none?: Maybe<FinancialYearFilterInput>;
  some?: Maybe<FinancialYearFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfFinancialYearVoucherFilterInput {
  all?: Maybe<FinancialYearVoucherFilterInput>;
  none?: Maybe<FinancialYearVoucherFilterInput>;
  some?: Maybe<FinancialYearVoucherFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTaxFilterInput {
  all?: Maybe<TaxFilterInput>;
  none?: Maybe<TaxFilterInput>;
  some?: Maybe<TaxFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfProductFilterInput {
  all?: Maybe<ProductFilterInput>;
  none?: Maybe<ProductFilterInput>;
  some?: Maybe<ProductFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class SalaryAccountFilterInput {
  and?: Maybe<Array<SalaryAccountFilterInput>>;
  or?: Maybe<Array<SalaryAccountFilterInput>>;
  eobiAccount?: Maybe<EobiAccountFilterInput>;
  pfAccount?: Maybe<PfAccountFilterInput>;
  fbrAccount?: Maybe<FbrAccountFilterInput>;
};

export class ListFilterInputTypeOfDepartmentFilterInput {
  all?: Maybe<DepartmentFilterInput>;
  none?: Maybe<DepartmentFilterInput>;
  some?: Maybe<DepartmentFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCertificationFilterInput {
  all?: Maybe<CertificationFilterInput>;
  none?: Maybe<CertificationFilterInput>;
  some?: Maybe<CertificationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAllowanceTypeFilterInput {
  all?: Maybe<AllowanceTypeFilterInput>;
  none?: Maybe<AllowanceTypeFilterInput>;
  some?: Maybe<AllowanceTypeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAllowanceCategoryFilterInput {
  all?: Maybe<AllowanceCategoryFilterInput>;
  none?: Maybe<AllowanceCategoryFilterInput>;
  some?: Maybe<AllowanceCategoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfBonusCategoryFilterInput {
  all?: Maybe<BonusCategoryFilterInput>;
  none?: Maybe<BonusCategoryFilterInput>;
  some?: Maybe<BonusCategoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfBonusTypeFilterInput {
  all?: Maybe<BonusTypeFilterInput>;
  none?: Maybe<BonusTypeFilterInput>;
  some?: Maybe<BonusTypeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInstituteFilterInput {
  all?: Maybe<InstituteFilterInput>;
  none?: Maybe<InstituteFilterInput>;
  some?: Maybe<InstituteFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInterviewCategoryFilterInput {
  all?: Maybe<InterviewCategoryFilterInput>;
  none?: Maybe<InterviewCategoryFilterInput>;
  some?: Maybe<InterviewCategoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInterviewCriterionFilterInput {
  all?: Maybe<InterviewCriterionFilterInput>;
  none?: Maybe<InterviewCriterionFilterInput>;
  some?: Maybe<InterviewCriterionFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfJobFunctionFilterInput {
  all?: Maybe<JobFunctionFilterInput>;
  none?: Maybe<JobFunctionFilterInput>;
  some?: Maybe<JobFunctionFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfJobTitleFilterInput {
  all?: Maybe<JobTitleFilterInput>;
  none?: Maybe<JobTitleFilterInput>;
  some?: Maybe<JobTitleFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfLoanTypeFilterInput {
  all?: Maybe<LoanTypeFilterInput>;
  none?: Maybe<LoanTypeFilterInput>;
  some?: Maybe<LoanTypeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfReasonTypeFilterInput {
  all?: Maybe<ReasonTypeFilterInput>;
  none?: Maybe<ReasonTypeFilterInput>;
  some?: Maybe<ReasonTypeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmployeeFilterInput {
  all?: Maybe<EmployeeFilterInput>;
  none?: Maybe<EmployeeFilterInput>;
  some?: Maybe<EmployeeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfJobPositionFilterInput {
  all?: Maybe<JobPositionFilterInput>;
  none?: Maybe<JobPositionFilterInput>;
  some?: Maybe<JobPositionFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCandidateFilterInput {
  all?: Maybe<CandidateFilterInput>;
  none?: Maybe<CandidateFilterInput>;
  some?: Maybe<CandidateFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfQuotaFilterInput {
  all?: Maybe<QuotaFilterInput>;
  none?: Maybe<QuotaFilterInput>;
  some?: Maybe<QuotaFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfHrLookupFilterInput {
  all?: Maybe<HrLookupFilterInput>;
  none?: Maybe<HrLookupFilterInput>;
  some?: Maybe<HrLookupFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfSiteFilterInput {
  all?: Maybe<SiteFilterInput>;
  none?: Maybe<SiteFilterInput>;
  some?: Maybe<SiteFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTicketsFilterInput {
  all?: Maybe<TicketsFilterInput>;
  none?: Maybe<TicketsFilterInput>;
  some?: Maybe<TicketsFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTicketFilterInput {
  all?: Maybe<TicketFilterInput>;
  none?: Maybe<TicketFilterInput>;
  some?: Maybe<TicketFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTicketCategoryFilterInput {
  all?: Maybe<TicketCategoryFilterInput>;
  none?: Maybe<TicketCategoryFilterInput>;
  some?: Maybe<TicketCategoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfClientFilterInput {
  all?: Maybe<ClientFilterInput>;
  none?: Maybe<ClientFilterInput>;
  some?: Maybe<ClientFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfGenericTaskFilterInput {
  all?: Maybe<GenericTaskFilterInput>;
  none?: Maybe<GenericTaskFilterInput>;
  some?: Maybe<GenericTaskFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfNotificationFilterInput {
  all?: Maybe<NotificationFilterInput>;
  none?: Maybe<NotificationFilterInput>;
  some?: Maybe<NotificationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfAttachmentFilterInput {
  all?: Maybe<AttachmentFilterInput>;
  none?: Maybe<AttachmentFilterInput>;
  some?: Maybe<AttachmentFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export class UserSortInput {
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  fullName?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  gender?: Maybe<SortEnumType>;
  pic?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  departmentId?: Maybe<SortEnumType>;
  jobTitleId?: Maybe<SortEnumType>;
  jobFunctionId?: Maybe<SortEnumType>;
  payrollAccountId?: Maybe<SortEnumType>;
  employee?: Maybe<EmployeeSortInput>;
  department?: Maybe<HrLookupSortInput>;
  jobTitle?: Maybe<HrLookupSortInput>;
  jobFunction?: Maybe<HrLookupSortInput>;
  payrollAccount?: Maybe<ThirdLevelAccountSortInput>;
  benefits?: Maybe<UserBenefitsSortInput>;
  id?: Maybe<SortEnumType>;
};

export class BusinessSortInput {
  agentConfigurationId?: Maybe<SortEnumType>;
  agentConfiguration?: Maybe<AgentConfigurationSortInput>;
  attendanceConfigurationId?: Maybe<SortEnumType>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationSortInput>;
  name?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  phone?: Maybe<SortEnumType>;
  fax?: Maybe<SortEnumType>;
  mobile?: Maybe<SortEnumType>;
  tollFree?: Maybe<SortEnumType>;
  website?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  currency?: Maybe<SortEnumType>;
  businessTypeId?: Maybe<SortEnumType>;
  industryId?: Maybe<SortEnumType>;
  logo?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  configuration?: Maybe<SortEnumType>;
  salaryAccounts?: Maybe<SalaryAccountSortInput>;
  id?: Maybe<SortEnumType>;
};

export class BooleanOperationFilterInput {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfMedicalClaimFilterInput {
  all?: Maybe<MedicalClaimFilterInput>;
  none?: Maybe<MedicalClaimFilterInput>;
  some?: Maybe<MedicalClaimFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTicketStatusChangeFilterInput {
  all?: Maybe<TicketStatusChangeFilterInput>;
  none?: Maybe<TicketStatusChangeFilterInput>;
  some?: Maybe<TicketStatusChangeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfReimbursementFilterInput {
  all?: Maybe<ReimbursementFilterInput>;
  none?: Maybe<ReimbursementFilterInput>;
  some?: Maybe<ReimbursementFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class NoteDateil {
  __typename?: 'NoteDateil';
  id: Scalars['Uuid'];
  objectID: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  noteText?: Maybe<Scalars['String']>;
  noteModuleCode?: Maybe<Scalars['String']>;
  noteTypeId?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  path?: Maybe<Scalars['String']>;
  noteType?: Maybe<Scalars['String']>;
  noteTypeCode?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
};

/** Information about the offset pagination. */
export class CollectionSegmentInfo {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export class ClientFilterInput {
  and?: Maybe<Array<ClientFilterInput>>;
  or?: Maybe<Array<ClientFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  projects?: Maybe<ListFilterInputTypeOfProjectFilterInput>;
  owners?: Maybe<ListFilterInputTypeOfUserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ListFilterInputTypeOfProjectTaskFilterInput {
  all?: Maybe<ProjectTaskFilterInput>;
  none?: Maybe<ProjectTaskFilterInput>;
  some?: Maybe<ProjectTaskFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfProjectUserRoleFilterInput {
  all?: Maybe<ProjectUserRoleFilterInput>;
  none?: Maybe<ProjectUserRoleFilterInput>;
  some?: Maybe<ProjectUserRoleFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTimeLogFilterInput {
  all?: Maybe<TimeLogFilterInput>;
  none?: Maybe<TimeLogFilterInput>;
  some?: Maybe<TimeLogFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ClientSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ProjectRolesOperationFilterInput {
  eq?: Maybe<ProjectRoles>;
  neq?: Maybe<ProjectRoles>;
  in?: Maybe<Array<ProjectRoles>>;
  nin?: Maybe<Array<ProjectRoles>>;
};

export class UserFilterInput {
  and?: Maybe<Array<UserFilterInput>>;
  or?: Maybe<Array<UserFilterInput>>;
  userAgentConfigurations?: Maybe<ListFilterInputTypeOfUserAgentConfigurationFilterInput>;
  appUsages?: Maybe<ListFilterInputTypeOfAppUsageFilterInput>;
  userAgentLogins?: Maybe<ListFilterInputTypeOfUserAgentLoginFilterInput>;
  attendanceUserConfigurations?: Maybe<ListFilterInputTypeOfAttendanceUserConfigurationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  fullName?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  gender?: Maybe<GenderOperationFilterInput>;
  pic?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  departmentId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  jobTitleId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  jobFunctionId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  payrollAccountId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  associatedBusinesses?: Maybe<ListFilterInputTypeOfBusinessFilterInput>;
  employmentHistory?: Maybe<ListFilterInputTypeOfEmploymentHistoryFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  interviewers?: Maybe<ListFilterInputTypeOfInterviewerFilterInput>;
  candidateEvaluations?: Maybe<ListFilterInputTypeOfCandidateEvaluationFilterInput>;
  projectUserRoles?: Maybe<ListFilterInputTypeOfProjectUserRoleFilterInput>;
  timeLogs?: Maybe<ListFilterInputTypeOfTimeLogFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfNotificationFilterInput>;
  loans?: Maybe<ListFilterInputTypeOfLoanFilterInput>;
  leaves?: Maybe<ListFilterInputTypeOfLeaveFilterInput>;
  medicalClaims?: Maybe<ListFilterInputTypeOfMedicalClaimFilterInput>;
  reimbursements?: Maybe<ListFilterInputTypeOfReimbursementFilterInput>;
  reimbursementAuthorized?: Maybe<ListFilterInputTypeOfReimbursementFilterInput>;
  department?: Maybe<HrLookupFilterInput>;
  jobTitle?: Maybe<HrLookupFilterInput>;
  jobFunction?: Maybe<HrLookupFilterInput>;
  salaries?: Maybe<ListFilterInputTypeOfSalaryFilterInput>;
  payrollAccount?: Maybe<ThirdLevelAccountFilterInput>;
  benefits?: Maybe<UserBenefitsFilterInput>;
  quotas?: Maybe<ListFilterInputTypeOfQuotaFilterInput>;
  ticketReported?: Maybe<ListFilterInputTypeOfTicketsFilterInput>;
  assignedTicketStatus?: Maybe<ListFilterInputTypeOfTicketStatusChangeFilterInput>;
  changedTicketStatus?: Maybe<ListFilterInputTypeOfTicketStatusChangeFilterInput>;
  tickets?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
  assignedTickets?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
  clients?: Maybe<ListFilterInputTypeOfClientFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class NullableOfStatesOperationFilterInput {
  eq?: Maybe<States>;
  neq?: Maybe<States>;
  in?: Maybe<Array<Maybe<States>>>;
  nin?: Maybe<Array<Maybe<States>>>;
};

export class TicketCategory {
  __typename?: 'TicketCategory';
  name?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  businessId: Scalars['Uuid'];
  business?: Maybe<Business>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  id: Scalars['Uuid'];
};

export class ComparableNullableOfDateTimeOperationFilterInput {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export class ComparableNullableOfTimeSpanOperationFilterInput {
  eq?: Maybe<Scalars['TimeSpan']>;
  neq?: Maybe<Scalars['TimeSpan']>;
  in?: Maybe<Array<Maybe<Scalars['TimeSpan']>>>;
  nin?: Maybe<Array<Maybe<Scalars['TimeSpan']>>>;
  gt?: Maybe<Scalars['TimeSpan']>;
  ngt?: Maybe<Scalars['TimeSpan']>;
  gte?: Maybe<Scalars['TimeSpan']>;
  ngte?: Maybe<Scalars['TimeSpan']>;
  lt?: Maybe<Scalars['TimeSpan']>;
  nlt?: Maybe<Scalars['TimeSpan']>;
  lte?: Maybe<Scalars['TimeSpan']>;
  nlte?: Maybe<Scalars['TimeSpan']>;
};

export class ListFilterInputTypeOfTicketLaborFilterInput {
  all?: Maybe<TicketLaborFilterInput>;
  none?: Maybe<TicketLaborFilterInput>;
  some?: Maybe<TicketLaborFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfTicketMaterialFilterInput {
  all?: Maybe<TicketMaterialFilterInput>;
  none?: Maybe<TicketMaterialFilterInput>;
  some?: Maybe<TicketMaterialFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ComparableInt32OperationFilterInput {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export class ComparableDecimalOperationFilterInput {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  nin?: Maybe<Array<Scalars['Decimal']>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
};

export class NullableOfVoucherStatesOperationFilterInput {
  eq?: Maybe<VoucherStates>;
  neq?: Maybe<VoucherStates>;
  in?: Maybe<Array<Maybe<VoucherStates>>>;
  nin?: Maybe<Array<Maybe<VoucherStates>>>;
};

export class ProductFilterInput {
  and?: Maybe<Array<ProductFilterInput>>;
  or?: Maybe<Array<ProductFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  isCatchWeightProduct?: Maybe<BooleanOperationFilterInput>;
  price?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  categoryId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  productType?: Maybe<NullableOfProductTypesOperationFilterInput>;
  purchaseUnit?: Maybe<NullableOfProductUnitsOperationFilterInput>;
  saleUnit?: Maybe<NullableOfProductUnitsOperationFilterInput>;
  inventoryUnit?: Maybe<NullableOfProductUnitsOperationFilterInput>;
  incomeAccountId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  expenseAccountId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  number?: Maybe<StringOperationFilterInput>;
  upcNumber?: Maybe<StringOperationFilterInput>;
  roleNumber?: Maybe<ComparableInt64OperationFilterInput>;
  state?: Maybe<NullableOfStatesOperationFilterInput>;
  category?: Maybe<ThirdLevelAccountFilterInput>;
  incomeAccount?: Maybe<ThirdLevelAccountFilterInput>;
  expenseAccount?: Maybe<ThirdLevelAccountFilterInput>;
  taxes?: Maybe<ListFilterInputTypeOfProductTaxFilterInput>;
  purchaseVouchers?: Maybe<ListFilterInputTypeOfInvoiceDetailFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ProductSortInput {
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  isCatchWeightProduct?: Maybe<SortEnumType>;
  price?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  productType?: Maybe<SortEnumType>;
  purchaseUnit?: Maybe<SortEnumType>;
  saleUnit?: Maybe<SortEnumType>;
  inventoryUnit?: Maybe<SortEnumType>;
  incomeAccountId?: Maybe<SortEnumType>;
  expenseAccountId?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  upcNumber?: Maybe<SortEnumType>;
  roleNumber?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  category?: Maybe<ThirdLevelAccountSortInput>;
  incomeAccount?: Maybe<ThirdLevelAccountSortInput>;
  expenseAccount?: Maybe<ThirdLevelAccountSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TicketLaborWithUserDetail {
  __typename?: 'TicketLaborWithUserDetail';
  id: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
  hours: Scalars['Decimal'];
  hoursType?: Maybe<Scalars['String']>;
  billable: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  workerId: Scalars['Uuid'];
  workerName?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
};

export class ComparableSingleOperationFilterInput {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export class ComparableByteOperationFilterInput {
  eq?: Maybe<Scalars['Byte']>;
  neq?: Maybe<Scalars['Byte']>;
  in?: Maybe<Array<Scalars['Byte']>>;
  nin?: Maybe<Array<Scalars['Byte']>>;
  gt?: Maybe<Scalars['Byte']>;
  ngt?: Maybe<Scalars['Byte']>;
  gte?: Maybe<Scalars['Byte']>;
  ngte?: Maybe<Scalars['Byte']>;
  lt?: Maybe<Scalars['Byte']>;
  nlt?: Maybe<Scalars['Byte']>;
  lte?: Maybe<Scalars['Byte']>;
  nlte?: Maybe<Scalars['Byte']>;
};

export class ListFilterInputTypeOfMedicalClaimBreakdownFilterInput {
  all?: Maybe<MedicalClaimBreakdownFilterInput>;
  none?: Maybe<MedicalClaimBreakdownFilterInput>;
  some?: Maybe<MedicalClaimBreakdownFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ComparableDoubleOperationFilterInput {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export class ListFilterInputTypeOfReimbursementDetailFilterInput {
  all?: Maybe<ReimbursementDetailFilterInput>;
  none?: Maybe<ReimbursementDetailFilterInput>;
  some?: Maybe<ReimbursementDetailFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInvoiceDetailTaxFilterInput {
  all?: Maybe<InvoiceDetailTaxFilterInput>;
  none?: Maybe<InvoiceDetailTaxFilterInput>;
  some?: Maybe<InvoiceDetailTaxFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ComparableNullableOfInt32OperationFilterInput {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export class ListWeekDaysOperationFilterInput {
  all?: Maybe<WeekDaysOperationFilterInput>;
  none?: Maybe<WeekDaysOperationFilterInput>;
  some?: Maybe<WeekDaysOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class BusinessShiftFilterInput {
  and?: Maybe<Array<BusinessShiftFilterInput>>;
  or?: Maybe<Array<BusinessShiftFilterInput>>;
  state?: Maybe<StatesOperationFilterInput>;
  openingTime?: Maybe<StringOperationFilterInput>;
  closingTime?: Maybe<StringOperationFilterInput>;
  attendanceConfigurationId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  attendanceUserConfigurations?: Maybe<ListFilterInputTypeOfAttendanceUserConfigurationFilterInput>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ComparableInt64OperationFilterInput {
  eq?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Scalars['Long']>>;
  nin?: Maybe<Array<Scalars['Long']>>;
  gt?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  nlt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
};

export class ListComparableInt64OperationFilterInput {
  all?: Maybe<ComparableInt64OperationFilterInput>;
  none?: Maybe<ComparableInt64OperationFilterInput>;
  some?: Maybe<ComparableInt64OperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class UserAgentConfigurationFilterInput {
  and?: Maybe<Array<UserAgentConfigurationFilterInput>>;
  or?: Maybe<Array<UserAgentConfigurationFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  isLogEnable?: Maybe<BooleanOperationFilterInput>;
  isCaptureScreen?: Maybe<BooleanOperationFilterInput>;
  onAppSwitching?: Maybe<BooleanOperationFilterInput>;
  captureScreenInterval?: Maybe<ComparableInt64OperationFilterInput>;
  useUserConfiguration?: Maybe<BooleanOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class SnapshotFilterInput {
  and?: Maybe<Array<SnapshotFilterInput>>;
  or?: Maybe<Array<SnapshotFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  machine?: Maybe<StringOperationFilterInput>;
  username?: Maybe<StringOperationFilterInput>;
  networkName?: Maybe<StringOperationFilterInput>;
  iPAddress?: Maybe<StringOperationFilterInput>;
  hour?: Maybe<ComparableByteOperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  snapshotBreakdowns?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AppUsageFilterInput {
  and?: Maybe<Array<AppUsageFilterInput>>;
  or?: Maybe<Array<AppUsageFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  userNetwork?: Maybe<StringOperationFilterInput>;
  appId?: Maybe<ComparableGuidOperationFilterInput>;
  username?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  machine?: Maybe<StringOperationFilterInput>;
  iPAddress?: Maybe<StringOperationFilterInput>;
  usageBreakDown?: Maybe<StringOperationFilterInput>;
  hour?: Maybe<ComparableByteOperationFilterInput>;
  usageMinutes?: Maybe<ComparableDoubleOperationFilterInput>;
  idleMinutes?: Maybe<ComparableDoubleOperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  user?: Maybe<UserFilterInput>;
  app?: Maybe<AppFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class UserAgentLoginFilterInput {
  and?: Maybe<Array<UserAgentLoginFilterInput>>;
  or?: Maybe<Array<UserAgentLoginFilterInput>>;
  loginAt?: Maybe<ComparableDateTimeOperationFilterInput>;
  logoutAt?: Maybe<ComparableDateTimeOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class FirstShiftFilterInput {
  and?: Maybe<Array<FirstShiftFilterInput>>;
  or?: Maybe<Array<FirstShiftFilterInput>>;
  state?: Maybe<StatesOperationFilterInput>;
  openingTime?: Maybe<StringOperationFilterInput>;
  closingTime?: Maybe<StringOperationFilterInput>;
  attendanceConfigurationId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  attendanceUserConfigurations?: Maybe<ListFilterInputTypeOfAttendanceUserConfigurationFilterInput>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class SecondShiftFilterInput {
  and?: Maybe<Array<SecondShiftFilterInput>>;
  or?: Maybe<Array<SecondShiftFilterInput>>;
  state?: Maybe<StatesOperationFilterInput>;
  openingTime?: Maybe<StringOperationFilterInput>;
  closingTime?: Maybe<StringOperationFilterInput>;
  attendanceConfigurationId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  attendanceUserConfigurations?: Maybe<ListFilterInputTypeOfAttendanceUserConfigurationFilterInput>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class Stg_AttendanceRecordFilterInput {
  and?: Maybe<Array<Stg_AttendanceRecordFilterInput>>;
  or?: Maybe<Array<Stg_AttendanceRecordFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  attendanceUserConfigurationId?: Maybe<ComparableGuidOperationFilterInput>;
  attendanceId?: Maybe<ComparableInt32OperationFilterInput>;
  dateTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  backupCode?: Maybe<ComparableByteOperationFilterInput>;
  recordType?: Maybe<RecordTypeOperationFilterInput>;
  isConflictedRecord?: Maybe<BooleanOperationFilterInput>;
  workType?: Maybe<ComparableInt32OperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  attendanceUserConfiguration?: Maybe<AttendanceUserConfigurationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ContactFilterInput {
  and?: Maybe<Array<ContactFilterInput>>;
  or?: Maybe<Array<ContactFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  addressId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  address?: Maybe<AddressFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class NoteFilterInput {
  and?: Maybe<Array<NoteFilterInput>>;
  or?: Maybe<Array<NoteFilterInput>>;
  objectID?: Maybe<ComparableGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  noteText?: Maybe<StringOperationFilterInput>;
  noteModuleCode?: Maybe<StringOperationFilterInput>;
  noteTypeId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  attachment?: Maybe<NullableOfStatesOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  attachments?: Maybe<ListFilterInputTypeOfAttachmentFilterInput>;
  users?: Maybe<ListFilterInputTypeOfUserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class BusinessUserFilterInput {
  and?: Maybe<Array<BusinessUserFilterInput>>;
  or?: Maybe<Array<BusinessUserFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  business?: Maybe<BusinessFilterInput>;
};

export class AppFilterInput {
  and?: Maybe<Array<AppFilterInput>>;
  or?: Maybe<Array<AppFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  state?: Maybe<AppStatesOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  appUsages?: Maybe<ListFilterInputTypeOfAppUsageFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class VoucherFilterInput {
  and?: Maybe<Array<VoucherFilterInput>>;
  or?: Maybe<Array<VoucherFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  voucherTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  number?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  lineItems?: Maybe<ListFilterInputTypeOfVoucherLineItemFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CashVoucherFilterInput {
  and?: Maybe<Array<CashVoucherFilterInput>>;
  or?: Maybe<Array<CashVoucherFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  voucherTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  number?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  accountId?: Maybe<ComparableGuidOperationFilterInput>;
  systemAccountId?: Maybe<ComparableGuidOperationFilterInput>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  systemAccount?: Maybe<ThirdLevelAccountFilterInput>;
  account?: Maybe<ThirdLevelAccountFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class InvoiceFilterInput {
  and?: Maybe<Array<InvoiceFilterInput>>;
  or?: Maybe<Array<InvoiceFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  voucherTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  accountId?: Maybe<ComparableGuidOperationFilterInput>;
  systemAccountId?: Maybe<ComparableGuidOperationFilterInput>;
  discountSystemAccountId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  commissionAccountId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  number?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  state?: Maybe<NullableOfVoucherStatesOperationFilterInput>;
  deliveryAddressId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  dueDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  deliveryAddress?: Maybe<AddressFilterInput>;
  account?: Maybe<ThirdLevelAccountFilterInput>;
  systemAccount?: Maybe<ThirdLevelAccountFilterInput>;
  discountSystemAccount?: Maybe<ThirdLevelAccountFilterInput>;
  commissionAccount?: Maybe<ThirdLevelAccountFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  details?: Maybe<ListFilterInputTypeOfInvoiceDetailFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class VoucherTypeFilterInput {
  and?: Maybe<Array<VoucherTypeFilterInput>>;
  or?: Maybe<Array<VoucherTypeFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AccountFilterInput {
  and?: Maybe<Array<AccountFilterInput>>;
  or?: Maybe<Array<AccountFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  roleNumber?: Maybe<ComparableInt64OperationFilterInput>;
  roles?: Maybe<ListComparableInt64OperationFilterInput>;
  sortOrder?: Maybe<ComparableInt32OperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class FinancialYearFilterInput {
  and?: Maybe<Array<FinancialYearFilterInput>>;
  or?: Maybe<Array<FinancialYearFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  startDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  endDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  financialYearVouchers?: Maybe<ListFilterInputTypeOfFinancialYearVoucherFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class FinancialYearVoucherFilterInput {
  and?: Maybe<Array<FinancialYearVoucherFilterInput>>;
  or?: Maybe<Array<FinancialYearVoucherFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  financialYearId?: Maybe<ComparableGuidOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  number?: Maybe<ComparableInt32OperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  financialYear?: Maybe<FinancialYearFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EobiAccountFilterInput {
  and?: Maybe<Array<EobiAccountFilterInput>>;
  or?: Maybe<Array<EobiAccountFilterInput>>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  percentage?: Maybe<ComparableInt16OperationFilterInput>;
};

export class PfAccountFilterInput {
  and?: Maybe<Array<PfAccountFilterInput>>;
  or?: Maybe<Array<PfAccountFilterInput>>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  percentage?: Maybe<ComparableInt16OperationFilterInput>;
};

export class FbrAccountFilterInput {
  and?: Maybe<Array<FbrAccountFilterInput>>;
  or?: Maybe<Array<FbrAccountFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
};

export class DepartmentFilterInput {
  and?: Maybe<Array<DepartmentFilterInput>>;
  or?: Maybe<Array<DepartmentFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CertificationFilterInput {
  and?: Maybe<Array<CertificationFilterInput>>;
  or?: Maybe<Array<CertificationFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AllowanceTypeFilterInput {
  and?: Maybe<Array<AllowanceTypeFilterInput>>;
  or?: Maybe<Array<AllowanceTypeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  ratio?: Maybe<ComparableDecimalOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  categoryId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  category?: Maybe<AllowanceCategoryFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AllowanceCategoryFilterInput {
  and?: Maybe<Array<AllowanceCategoryFilterInput>>;
  or?: Maybe<Array<AllowanceCategoryFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  allowanceTypes?: Maybe<ListFilterInputTypeOfAllowanceTypeFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class BonusCategoryFilterInput {
  and?: Maybe<Array<BonusCategoryFilterInput>>;
  or?: Maybe<Array<BonusCategoryFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  bonusTypes?: Maybe<ListFilterInputTypeOfBonusTypeFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class BonusTypeFilterInput {
  and?: Maybe<Array<BonusTypeFilterInput>>;
  or?: Maybe<Array<BonusTypeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  percentage?: Maybe<ComparableDecimalOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  categoryId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  category?: Maybe<BonusCategoryFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class InstituteFilterInput {
  and?: Maybe<Array<InstituteFilterInput>>;
  or?: Maybe<Array<InstituteFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class InterviewCategoryFilterInput {
  and?: Maybe<Array<InterviewCategoryFilterInput>>;
  or?: Maybe<Array<InterviewCategoryFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  interviewCriteria?: Maybe<ListFilterInputTypeOfInterviewCriterionFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class InterviewCriterionFilterInput {
  and?: Maybe<Array<InterviewCriterionFilterInput>>;
  or?: Maybe<Array<InterviewCriterionFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  score?: Maybe<ComparableDecimalOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  categoryId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  category?: Maybe<InterviewCategoryFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class JobFunctionFilterInput {
  and?: Maybe<Array<JobFunctionFilterInput>>;
  or?: Maybe<Array<JobFunctionFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class JobTitleFilterInput {
  and?: Maybe<Array<JobTitleFilterInput>>;
  or?: Maybe<Array<JobTitleFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class LoanTypeFilterInput {
  and?: Maybe<Array<LoanTypeFilterInput>>;
  or?: Maybe<Array<LoanTypeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ReasonTypeFilterInput {
  and?: Maybe<Array<ReasonTypeFilterInput>>;
  or?: Maybe<Array<ReasonTypeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  type?: Maybe<ReasonTypesOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmployeeFilterInput {
  and?: Maybe<Array<EmployeeFilterInput>>;
  or?: Maybe<Array<EmployeeFilterInput>>;
  reportedToId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  officialEmail?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  fatherName?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  mobilePhone?: Maybe<StringOperationFilterInput>;
  dateOfAvailability?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dateOfBirth?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  placeOfBirth?: Maybe<StringOperationFilterInput>;
  presentAddress?: Maybe<StringOperationFilterInput>;
  permanentAddress?: Maybe<StringOperationFilterInput>;
  bloodGroup?: Maybe<StringOperationFilterInput>;
  maritalStatus?: Maybe<MaritalStatusOperationFilterInput>;
  nameOfSpouse?: Maybe<StringOperationFilterInput>;
  drivingLicenceNumber?: Maybe<StringOperationFilterInput>;
  drivingLicenceIssueDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  currentSalary?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  expectedSalary?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  paymentMethod?: Maybe<StringOperationFilterInput>;
  religion?: Maybe<StringOperationFilterInput>;
  cnic?: Maybe<StringOperationFilterInput>;
  cnicExpiryDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  domicile?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  userId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  interviewStartDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  interviewEndDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  employmentHistories?: Maybe<ListFilterInputTypeOfEmploymentHistoryFilterInput>;
  employeePositions?: Maybe<ListFilterInputTypeOfCandidatePositionFilterInput>;
  children?: Maybe<ListFilterInputTypeOfEmployeeChildrenFilterInput>;
  educations?: Maybe<ListFilterInputTypeOfEmployeeEducationFilterInput>;
  experiences?: Maybe<ListFilterInputTypeOfEmployeeExperienceFilterInput>;
  references?: Maybe<ListFilterInputTypeOfEmployeeReferencesFilterInput>;
  relatives?: Maybe<ListFilterInputTypeOfEmployeeRelativesFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class JobPositionFilterInput {
  and?: Maybe<Array<JobPositionFilterInput>>;
  or?: Maybe<Array<JobPositionFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  departmentId?: Maybe<ComparableGuidOperationFilterInput>;
  jobTitleId?: Maybe<ComparableGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  positions?: Maybe<ComparableByteOperationFilterInput>;
  comment?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  careerLevel?: Maybe<NullableOfCareerLevelOperationFilterInput>;
  employmentType?: Maybe<NullableOfEmploymentTypeOperationFilterInput>;
  educationLevel?: Maybe<NullableOfEducationLevelOperationFilterInput>;
  experience?: Maybe<NullableOfWorkExperienceOperationFilterInput>;
  monthlySalary?: Maybe<NullableOfMonthlySalaryOperationFilterInput>;
  companyName?: Maybe<StringOperationFilterInput>;
  companySize?: Maybe<CompanySizeOperationFilterInput>;
  postDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  expiryDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isCvRequired?: Maybe<BooleanOperationFilterInput>;
  performanceBonus?: Maybe<BooleanOperationFilterInput>;
  medical?: Maybe<BooleanOperationFilterInput>;
  childrenEducation?: Maybe<BooleanOperationFilterInput>;
  providentFund?: Maybe<BooleanOperationFilterInput>;
  knowledgeSharing?: Maybe<BooleanOperationFilterInput>;
  competitiveSalary?: Maybe<BooleanOperationFilterInput>;
  otherBenefit?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  department?: Maybe<HrLookupFilterInput>;
  jobTitle?: Maybe<HrLookupFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  candidatePositionInfo?: Maybe<ListFilterInputTypeOfCandidatePositionFilterInput>;
  candidates?: Maybe<ListFilterInputTypeOfCandidateFilterInput>;
  interviewers?: Maybe<ListFilterInputTypeOfInterviewerFilterInput>;
  candidateEvaluations?: Maybe<ListFilterInputTypeOfCandidateEvaluationFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateFilterInput {
  and?: Maybe<Array<CandidateFilterInput>>;
  or?: Maybe<Array<CandidateFilterInput>>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  fullName?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  gender?: Maybe<GenderOperationFilterInput>;
  pic?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  fatherName?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  mobilePhone?: Maybe<StringOperationFilterInput>;
  dateOfAvailability?: Maybe<ComparableDateTimeOperationFilterInput>;
  dateOfBirth?: Maybe<ComparableDateTimeOperationFilterInput>;
  placeOfBirth?: Maybe<StringOperationFilterInput>;
  presentAddress?: Maybe<StringOperationFilterInput>;
  permanentAddress?: Maybe<StringOperationFilterInput>;
  bloodGroup?: Maybe<StringOperationFilterInput>;
  maritalStatus?: Maybe<MaritalStatusOperationFilterInput>;
  nameOfSpouse?: Maybe<StringOperationFilterInput>;
  drivingLicenceNumber?: Maybe<StringOperationFilterInput>;
  drivingLicenceIssueDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  currentSalary?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  expectedSalary?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  paymentMethod?: Maybe<StringOperationFilterInput>;
  religion?: Maybe<StringOperationFilterInput>;
  cnic?: Maybe<StringOperationFilterInput>;
  cnicExpiryDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  domicile?: Maybe<StringOperationFilterInput>;
  password?: Maybe<StringOperationFilterInput>;
  resume?: Maybe<StringOperationFilterInput>;
  isProfileComplete?: Maybe<BooleanOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  candidatePositions?: Maybe<ListFilterInputTypeOfCandidatePositionFilterInput>;
  children?: Maybe<ListFilterInputTypeOfCandidateChildrenFilterInput>;
  education?: Maybe<ListFilterInputTypeOfCandidateEducationFilterInput>;
  experience?: Maybe<ListFilterInputTypeOfCandidateExperienceFilterInput>;
  references?: Maybe<ListFilterInputTypeOfCandidateReferencesFilterInput>;
  relatives?: Maybe<ListFilterInputTypeOfCandidateRelativesFilterInput>;
  jobPositions?: Maybe<ListFilterInputTypeOfJobPositionFilterInput>;
  candidateEvaluations?: Maybe<ListFilterInputTypeOfCandidateEvaluationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class HrLookupFilterInput {
  and?: Maybe<Array<HrLookupFilterInput>>;
  or?: Maybe<Array<HrLookupFilterInput>>;
  parentId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  isSystemDefined?: Maybe<BooleanOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  lookupSetting?: Maybe<StringOperationFilterInput>;
  lookupModuleCode?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  departmentUsers?: Maybe<ListFilterInputTypeOfUserFilterInput>;
  jobFunctionUsers?: Maybe<ListFilterInputTypeOfUserFilterInput>;
  jobTitleUsers?: Maybe<ListFilterInputTypeOfUserFilterInput>;
  salariesAllowence?: Maybe<ListFilterInputTypeOfSalaryFilterInput>;
  salariesBonus?: Maybe<ListFilterInputTypeOfSalaryFilterInput>;
  employmentHistory?: Maybe<ListFilterInputTypeOfEmploymentHistoryFilterInput>;
  jobPositions_JobTitle?: Maybe<ListFilterInputTypeOfJobPositionFilterInput>;
  jobPositions_Department?: Maybe<ListFilterInputTypeOfJobPositionFilterInput>;
  parent?: Maybe<HrLookupFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class GenericTaskFilterInput {
  and?: Maybe<Array<GenericTaskFilterInput>>;
  or?: Maybe<Array<GenericTaskFilterInput>>;
  timeLogs?: Maybe<ListFilterInputTypeOfTimeLogFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class NotificationFilterInput {
  and?: Maybe<Array<NotificationFilterInput>>;
  or?: Maybe<Array<NotificationFilterInput>>;
  isRead?: Maybe<BooleanOperationFilterInput>;
  message?: Maybe<StringOperationFilterInput>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  type?: Maybe<NotificationTypesOperationFilterInput>;
  systemObject?: Maybe<StringOperationFilterInput>;
  systemObjectId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AttachmentFilterInput {
  and?: Maybe<Array<AttachmentFilterInput>>;
  or?: Maybe<Array<AttachmentFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  attachmentId?: Maybe<ComparableGuidOperationFilterInput>;
  path?: Maybe<StringOperationFilterInput>;
};

export class EmployeeSortInput {
  reportedToId?: Maybe<SortEnumType>;
  officialEmail?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  fatherName?: Maybe<SortEnumType>;
  phone?: Maybe<SortEnumType>;
  mobilePhone?: Maybe<SortEnumType>;
  dateOfAvailability?: Maybe<SortEnumType>;
  dateOfBirth?: Maybe<SortEnumType>;
  placeOfBirth?: Maybe<SortEnumType>;
  presentAddress?: Maybe<SortEnumType>;
  permanentAddress?: Maybe<SortEnumType>;
  bloodGroup?: Maybe<SortEnumType>;
  maritalStatus?: Maybe<SortEnumType>;
  nameOfSpouse?: Maybe<SortEnumType>;
  drivingLicenceNumber?: Maybe<SortEnumType>;
  drivingLicenceIssueDate?: Maybe<SortEnumType>;
  currentSalary?: Maybe<SortEnumType>;
  expectedSalary?: Maybe<SortEnumType>;
  paymentMethod?: Maybe<SortEnumType>;
  religion?: Maybe<SortEnumType>;
  cnic?: Maybe<SortEnumType>;
  cnicExpiryDate?: Maybe<SortEnumType>;
  domicile?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  interviewStartDate?: Maybe<SortEnumType>;
  interviewEndDate?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  user?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class HrLookupSortInput {
  parentId?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  isSystemDefined?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  lookupSetting?: Maybe<SortEnumType>;
  lookupModuleCode?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  parent?: Maybe<HrLookupSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ThirdLevelAccountSortInput {
  secondLevelAccountId?: Maybe<SortEnumType>;
  businessContactId?: Maybe<SortEnumType>;
  deliveryContactId?: Maybe<SortEnumType>;
  billingContactId?: Maybe<SortEnumType>;
  businessAddressId?: Maybe<SortEnumType>;
  deliveryAddressId?: Maybe<SortEnumType>;
  billingAddressId?: Maybe<SortEnumType>;
  secondLevelAccount?: Maybe<SecondLevelAccountSortInput>;
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  roleNumber?: Maybe<SortEnumType>;
  sortOrder?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class UserBenefitsSortInput {
  eobiBenefit?: Maybe<EobiBenefitSortInput>;
  pfBenefit?: Maybe<PfBenefitSortInput>;
};

export class AgentConfigurationSortInput {
  businessId?: Maybe<SortEnumType>;
  isLogEnable?: Maybe<SortEnumType>;
  isCaptureScreen?: Maybe<SortEnumType>;
  onAppSwitching?: Maybe<SortEnumType>;
  captureScreenInterval?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AttendanceConfigurationSortInput {
  businessId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  firstShiftId?: Maybe<SortEnumType>;
  secondShiftId?: Maybe<SortEnumType>;
  syncInterval?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  firstShift?: Maybe<FirstShiftSortInput>;
  secondShift?: Maybe<SecondShiftSortInput>;
  id?: Maybe<SortEnumType>;
};

export class SalaryAccountSortInput {
  eobiAccount?: Maybe<EobiAccountSortInput>;
  pfAccount?: Maybe<PfAccountSortInput>;
  fbrAccount?: Maybe<FbrAccountSortInput>;
};

export class ListFilterInputTypeOfProjectFilterInput {
  all?: Maybe<ProjectFilterInput>;
  none?: Maybe<ProjectFilterInput>;
  some?: Maybe<ProjectFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ProjectTaskFilterInput {
  and?: Maybe<Array<ProjectTaskFilterInput>>;
  or?: Maybe<Array<ProjectTaskFilterInput>>;
  projectId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  expectedEffort?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  project?: Maybe<ProjectFilterInput>;
  subTasks?: Maybe<ListFilterInputTypeOfSubTaskFilterInput>;
  timeLogs?: Maybe<ListFilterInputTypeOfTimeLogFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class TimeLogFilterInput {
  and?: Maybe<Array<TimeLogFilterInput>>;
  or?: Maybe<Array<TimeLogFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  taskId?: Maybe<ComparableGuidOperationFilterInput>;
  projectId?: Maybe<ComparableGuidOperationFilterInput>;
  subTaskId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  genericTaskId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  timeSpan?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  project?: Maybe<ProjectFilterInput>;
  task?: Maybe<ProjectTaskFilterInput>;
  subTask?: Maybe<SubTaskFilterInput>;
  genericTask?: Maybe<GenericTaskFilterInput>;
  user?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class GenderOperationFilterInput {
  eq?: Maybe<Gender>;
  neq?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  nin?: Maybe<Array<Gender>>;
};

export class ListFilterInputTypeOfBusinessFilterInput {
  all?: Maybe<BusinessFilterInput>;
  none?: Maybe<BusinessFilterInput>;
  some?: Maybe<BusinessFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmploymentHistoryFilterInput {
  all?: Maybe<EmploymentHistoryFilterInput>;
  none?: Maybe<EmploymentHistoryFilterInput>;
  some?: Maybe<EmploymentHistoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInterviewerFilterInput {
  all?: Maybe<InterviewerFilterInput>;
  none?: Maybe<InterviewerFilterInput>;
  some?: Maybe<InterviewerFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCandidateEvaluationFilterInput {
  all?: Maybe<CandidateEvaluationFilterInput>;
  none?: Maybe<CandidateEvaluationFilterInput>;
  some?: Maybe<CandidateEvaluationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfLoanFilterInput {
  all?: Maybe<LoanFilterInput>;
  none?: Maybe<LoanFilterInput>;
  some?: Maybe<LoanFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfLeaveFilterInput {
  all?: Maybe<LeaveFilterInput>;
  none?: Maybe<LeaveFilterInput>;
  some?: Maybe<LeaveFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfSalaryFilterInput {
  all?: Maybe<SalaryFilterInput>;
  none?: Maybe<SalaryFilterInput>;
  some?: Maybe<SalaryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ThirdLevelAccountFilterInput {
  and?: Maybe<Array<ThirdLevelAccountFilterInput>>;
  or?: Maybe<Array<ThirdLevelAccountFilterInput>>;
  secondLevelAccountId?: Maybe<ComparableGuidOperationFilterInput>;
  businessContactId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  deliveryContactId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  billingContactId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  businessAddressId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  deliveryAddressId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  billingAddressId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  secondLevelAccount?: Maybe<SecondLevelAccountFilterInput>;
  lineItems?: Maybe<ListFilterInputTypeOfVoucherLineItemFilterInput>;
  accountCashVouchers?: Maybe<ListFilterInputTypeOfCashVoucherFilterInput>;
  systemAccountCashVouchers?: Maybe<ListFilterInputTypeOfCashVoucherFilterInput>;
  accountPurchaseVouchers?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  systemAccountPurchaseVouchers?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  systemDiscountAccountPurchaseVouchers?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  commissionAccountPurchaseVouchers?: Maybe<ListFilterInputTypeOfInvoiceFilterInput>;
  purchaseProducts?: Maybe<ListFilterInputTypeOfProductFilterInput>;
  saleProducts?: Maybe<ListFilterInputTypeOfProductFilterInput>;
  products?: Maybe<ListFilterInputTypeOfProductFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  roleNumber?: Maybe<ComparableInt64OperationFilterInput>;
  roles?: Maybe<ListComparableInt64OperationFilterInput>;
  sortOrder?: Maybe<ComparableInt32OperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class UserBenefitsFilterInput {
  and?: Maybe<Array<UserBenefitsFilterInput>>;
  or?: Maybe<Array<UserBenefitsFilterInput>>;
  eobiBenefit?: Maybe<EobiBenefitFilterInput>;
  pfBenefit?: Maybe<PfBenefitFilterInput>;
};

export class TicketLaborFilterInput {
  and?: Maybe<Array<TicketLaborFilterInput>>;
  or?: Maybe<Array<TicketLaborFilterInput>>;
  ticketId?: Maybe<ComparableGuidOperationFilterInput>;
  hours?: Maybe<ComparableDecimalOperationFilterInput>;
  hoursType?: Maybe<StringOperationFilterInput>;
  billable?: Maybe<ComparableDecimalOperationFilterInput>;
  rate?: Maybe<ComparableDecimalOperationFilterInput>;
  workerId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  tickets?: Maybe<TicketsFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ComparableNullableOfDecimalOperationFilterInput {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
};

export class NullableOfProductTypesOperationFilterInput {
  eq?: Maybe<ProductTypes>;
  neq?: Maybe<ProductTypes>;
  in?: Maybe<Array<Maybe<ProductTypes>>>;
  nin?: Maybe<Array<Maybe<ProductTypes>>>;
};

export class NullableOfProductUnitsOperationFilterInput {
  eq?: Maybe<ProductUnits>;
  neq?: Maybe<ProductUnits>;
  in?: Maybe<Array<Maybe<ProductUnits>>>;
  nin?: Maybe<Array<Maybe<ProductUnits>>>;
};

export class ListFilterInputTypeOfProductTaxFilterInput {
  all?: Maybe<ProductTaxFilterInput>;
  none?: Maybe<ProductTaxFilterInput>;
  some?: Maybe<ProductTaxFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfInvoiceDetailFilterInput {
  all?: Maybe<InvoiceDetailFilterInput>;
  none?: Maybe<InvoiceDetailFilterInput>;
  some?: Maybe<InvoiceDetailFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class MedicalClaimBreakdownFilterInput {
  and?: Maybe<Array<MedicalClaimBreakdownFilterInput>>;
  or?: Maybe<Array<MedicalClaimBreakdownFilterInput>>;
  medicalClaimId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  billNumber?: Maybe<StringOperationFilterInput>;
  amount?: Maybe<ComparableSingleOperationFilterInput>;
  file?: Maybe<StringOperationFilterInput>;
  medicalClaim?: Maybe<MedicalClaimFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ReimbursementDetailFilterInput {
  and?: Maybe<Array<ReimbursementDetailFilterInput>>;
  or?: Maybe<Array<ReimbursementDetailFilterInput>>;
  reimbursementId?: Maybe<ComparableGuidOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  referenceNumber?: Maybe<StringOperationFilterInput>;
  amount?: Maybe<ComparableSingleOperationFilterInput>;
  file?: Maybe<StringOperationFilterInput>;
  reimbursement?: Maybe<ReimbursementFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class InvoiceDetailTaxFilterInput {
  and?: Maybe<Array<InvoiceDetailTaxFilterInput>>;
  or?: Maybe<Array<InvoiceDetailTaxFilterInput>>;
  invoiceDetailID?: Maybe<ComparableGuidOperationFilterInput>;
  taxId?: Maybe<ComparableGuidOperationFilterInput>;
  taxRate?: Maybe<ComparableDecimalOperationFilterInput>;
  taxAmount?: Maybe<ComparableDecimalOperationFilterInput>;
  invoiceDetail?: Maybe<InvoiceDetailFilterInput>;
  tax?: Maybe<TaxFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class WeekDaysOperationFilterInput {
  eq?: Maybe<WeekDays>;
  neq?: Maybe<WeekDays>;
  in?: Maybe<Array<WeekDays>>;
  nin?: Maybe<Array<WeekDays>>;
};

export class RecordTypeOperationFilterInput {
  eq?: Maybe<RecordType>;
  neq?: Maybe<RecordType>;
  in?: Maybe<Array<RecordType>>;
  nin?: Maybe<Array<RecordType>>;
};

export class AppStatesOperationFilterInput {
  eq?: Maybe<AppStates>;
  neq?: Maybe<AppStates>;
  in?: Maybe<Array<AppStates>>;
  nin?: Maybe<Array<AppStates>>;
};

export class ListFilterInputTypeOfVoucherLineItemFilterInput {
  all?: Maybe<VoucherLineItemFilterInput>;
  none?: Maybe<VoucherLineItemFilterInput>;
  some?: Maybe<VoucherLineItemFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ComparableInt16OperationFilterInput {
  eq?: Maybe<Scalars['Short']>;
  neq?: Maybe<Scalars['Short']>;
  in?: Maybe<Array<Scalars['Short']>>;
  nin?: Maybe<Array<Scalars['Short']>>;
  gt?: Maybe<Scalars['Short']>;
  ngt?: Maybe<Scalars['Short']>;
  gte?: Maybe<Scalars['Short']>;
  ngte?: Maybe<Scalars['Short']>;
  lt?: Maybe<Scalars['Short']>;
  nlt?: Maybe<Scalars['Short']>;
  lte?: Maybe<Scalars['Short']>;
  nlte?: Maybe<Scalars['Short']>;
};

export class ReasonTypesOperationFilterInput {
  eq?: Maybe<ReasonTypes>;
  neq?: Maybe<ReasonTypes>;
  in?: Maybe<Array<ReasonTypes>>;
  nin?: Maybe<Array<ReasonTypes>>;
};

export class MaritalStatusOperationFilterInput {
  eq?: Maybe<MaritalStatus>;
  neq?: Maybe<MaritalStatus>;
  in?: Maybe<Array<MaritalStatus>>;
  nin?: Maybe<Array<MaritalStatus>>;
};

export class ListFilterInputTypeOfCandidatePositionFilterInput {
  all?: Maybe<CandidatePositionFilterInput>;
  none?: Maybe<CandidatePositionFilterInput>;
  some?: Maybe<CandidatePositionFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmployeeChildrenFilterInput {
  all?: Maybe<EmployeeChildrenFilterInput>;
  none?: Maybe<EmployeeChildrenFilterInput>;
  some?: Maybe<EmployeeChildrenFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmployeeEducationFilterInput {
  all?: Maybe<EmployeeEducationFilterInput>;
  none?: Maybe<EmployeeEducationFilterInput>;
  some?: Maybe<EmployeeEducationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmployeeExperienceFilterInput {
  all?: Maybe<EmployeeExperienceFilterInput>;
  none?: Maybe<EmployeeExperienceFilterInput>;
  some?: Maybe<EmployeeExperienceFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmployeeReferencesFilterInput {
  all?: Maybe<EmployeeReferencesFilterInput>;
  none?: Maybe<EmployeeReferencesFilterInput>;
  some?: Maybe<EmployeeReferencesFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfEmployeeRelativesFilterInput {
  all?: Maybe<EmployeeRelativesFilterInput>;
  none?: Maybe<EmployeeRelativesFilterInput>;
  some?: Maybe<EmployeeRelativesFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class NullableOfCareerLevelOperationFilterInput {
  eq?: Maybe<CareerLevel>;
  neq?: Maybe<CareerLevel>;
  in?: Maybe<Array<Maybe<CareerLevel>>>;
  nin?: Maybe<Array<Maybe<CareerLevel>>>;
};

export class NullableOfEmploymentTypeOperationFilterInput {
  eq?: Maybe<EmploymentType>;
  neq?: Maybe<EmploymentType>;
  in?: Maybe<Array<Maybe<EmploymentType>>>;
  nin?: Maybe<Array<Maybe<EmploymentType>>>;
};

export class NullableOfEducationLevelOperationFilterInput {
  eq?: Maybe<EducationLevel>;
  neq?: Maybe<EducationLevel>;
  in?: Maybe<Array<Maybe<EducationLevel>>>;
  nin?: Maybe<Array<Maybe<EducationLevel>>>;
};

export class NullableOfWorkExperienceOperationFilterInput {
  eq?: Maybe<WorkExperience>;
  neq?: Maybe<WorkExperience>;
  in?: Maybe<Array<Maybe<WorkExperience>>>;
  nin?: Maybe<Array<Maybe<WorkExperience>>>;
};

export class NullableOfMonthlySalaryOperationFilterInput {
  eq?: Maybe<MonthlySalary>;
  neq?: Maybe<MonthlySalary>;
  in?: Maybe<Array<Maybe<MonthlySalary>>>;
  nin?: Maybe<Array<Maybe<MonthlySalary>>>;
};

export class CompanySizeOperationFilterInput {
  eq?: Maybe<CompanySize>;
  neq?: Maybe<CompanySize>;
  in?: Maybe<Array<CompanySize>>;
  nin?: Maybe<Array<CompanySize>>;
};

export class ListFilterInputTypeOfCandidateChildrenFilterInput {
  all?: Maybe<CandidateChildrenFilterInput>;
  none?: Maybe<CandidateChildrenFilterInput>;
  some?: Maybe<CandidateChildrenFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCandidateEducationFilterInput {
  all?: Maybe<CandidateEducationFilterInput>;
  none?: Maybe<CandidateEducationFilterInput>;
  some?: Maybe<CandidateEducationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCandidateExperienceFilterInput {
  all?: Maybe<CandidateExperienceFilterInput>;
  none?: Maybe<CandidateExperienceFilterInput>;
  some?: Maybe<CandidateExperienceFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCandidateReferencesFilterInput {
  all?: Maybe<CandidateReferencesFilterInput>;
  none?: Maybe<CandidateReferencesFilterInput>;
  some?: Maybe<CandidateReferencesFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfCandidateRelativesFilterInput {
  all?: Maybe<CandidateRelativesFilterInput>;
  none?: Maybe<CandidateRelativesFilterInput>;
  some?: Maybe<CandidateRelativesFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class NotificationTypesOperationFilterInput {
  eq?: Maybe<NotificationTypes>;
  neq?: Maybe<NotificationTypes>;
  in?: Maybe<Array<NotificationTypes>>;
  nin?: Maybe<Array<NotificationTypes>>;
};

export class SecondLevelAccountSortInput {
  firstLevelAccountId?: Maybe<SortEnumType>;
  firstLevelAccount?: Maybe<FirstLevelAccountSortInput>;
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  roleNumber?: Maybe<SortEnumType>;
  sortOrder?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class EobiBenefitSortInput {
  amount?: Maybe<SortEnumType>;
  percentage?: Maybe<SortEnumType>;
  enabled?: Maybe<SortEnumType>;
  overrideAmount?: Maybe<SortEnumType>;
  overridePercentage?: Maybe<SortEnumType>;
};

export class PfBenefitSortInput {
  enabled?: Maybe<SortEnumType>;
  overrideAmount?: Maybe<SortEnumType>;
  overridePercentage?: Maybe<SortEnumType>;
};

export class FirstShiftSortInput {
  state?: Maybe<SortEnumType>;
  openingTime?: Maybe<SortEnumType>;
  closingTime?: Maybe<SortEnumType>;
  attendanceConfigurationId?: Maybe<SortEnumType>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationSortInput>;
  id?: Maybe<SortEnumType>;
};

export class SecondShiftSortInput {
  state?: Maybe<SortEnumType>;
  openingTime?: Maybe<SortEnumType>;
  closingTime?: Maybe<SortEnumType>;
  attendanceConfigurationId?: Maybe<SortEnumType>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationSortInput>;
  id?: Maybe<SortEnumType>;
};

export class EobiAccountSortInput {
  amount?: Maybe<SortEnumType>;
  percentage?: Maybe<SortEnumType>;
};

export class PfAccountSortInput {
  amount?: Maybe<SortEnumType>;
  percentage?: Maybe<SortEnumType>;
};

export class FbrAccountSortInput {
  code?: Maybe<SortEnumType>;
};

export class ListFilterInputTypeOfSubTaskFilterInput {
  all?: Maybe<SubTaskFilterInput>;
  none?: Maybe<SubTaskFilterInput>;
  some?: Maybe<SubTaskFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class SubTaskFilterInput {
  and?: Maybe<Array<SubTaskFilterInput>>;
  or?: Maybe<Array<SubTaskFilterInput>>;
  projectId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  expectedEffort?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  parentTaskId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  parentTask?: Maybe<ProjectTaskFilterInput>;
  project?: Maybe<ProjectFilterInput>;
  timeLogs?: Maybe<ListFilterInputTypeOfTimeLogFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmploymentHistoryFilterInput {
  and?: Maybe<Array<EmploymentHistoryFilterInput>>;
  or?: Maybe<Array<EmploymentHistoryFilterInput>>;
  employeeId?: Maybe<ComparableGuidOperationFilterInput>;
  reasonTypeId?: Maybe<ComparableGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  joiningDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  terminationDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  createdBy?: Maybe<UserFilterInput>;
  reasonType?: Maybe<HrLookupFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class InterviewerFilterInput {
  and?: Maybe<Array<InterviewerFilterInput>>;
  or?: Maybe<Array<InterviewerFilterInput>>;
  jobPositionId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  jobPosition?: Maybe<JobPositionFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateEvaluationFilterInput {
  and?: Maybe<Array<CandidateEvaluationFilterInput>>;
  or?: Maybe<Array<CandidateEvaluationFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  jobPositionId?: Maybe<ComparableGuidOperationFilterInput>;
  strength?: Maybe<StringOperationFilterInput>;
  weakness?: Maybe<StringOperationFilterInput>;
  finalComment?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  jobPosition?: Maybe<JobPositionFilterInput>;
  scores?: Maybe<ListFilterInputTypeOfCandidateScoreFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class SalaryFilterInput {
  and?: Maybe<Array<SalaryFilterInput>>;
  or?: Maybe<Array<SalaryFilterInput>>;
  userId?: Maybe<ComparableGuidOperationFilterInput>;
  startDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  endDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  basicSalary?: Maybe<ComparableDecimalOperationFilterInput>;
  netSalary?: Maybe<ComparableDecimalOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  createdBy?: Maybe<UserFilterInput>;
  user?: Maybe<UserFilterInput>;
  allowances?: Maybe<ListFilterInputTypeOfHrLookupFilterInput>;
  bonuses?: Maybe<ListFilterInputTypeOfHrLookupFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class SecondLevelAccountFilterInput {
  and?: Maybe<Array<SecondLevelAccountFilterInput>>;
  or?: Maybe<Array<SecondLevelAccountFilterInput>>;
  firstLevelAccountId?: Maybe<ComparableGuidOperationFilterInput>;
  firstLevelAccount?: Maybe<FirstLevelAccountFilterInput>;
  thirdLevelAccounts?: Maybe<ListFilterInputTypeOfThirdLevelAccountFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  roleNumber?: Maybe<ComparableInt64OperationFilterInput>;
  roles?: Maybe<ListComparableInt64OperationFilterInput>;
  sortOrder?: Maybe<ComparableInt32OperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EobiBenefitFilterInput {
  and?: Maybe<Array<EobiBenefitFilterInput>>;
  or?: Maybe<Array<EobiBenefitFilterInput>>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  percentage?: Maybe<ComparableInt16OperationFilterInput>;
  enabled?: Maybe<BooleanOperationFilterInput>;
  overrideAmount?: Maybe<ComparableDecimalOperationFilterInput>;
  overridePercentage?: Maybe<ComparableInt16OperationFilterInput>;
};

export class PfBenefitFilterInput {
  and?: Maybe<Array<PfBenefitFilterInput>>;
  or?: Maybe<Array<PfBenefitFilterInput>>;
  enabled?: Maybe<BooleanOperationFilterInput>;
  overrideAmount?: Maybe<ComparableDecimalOperationFilterInput>;
  overridePercentage?: Maybe<ComparableInt16OperationFilterInput>;
};

export class ProductTaxFilterInput {
  and?: Maybe<Array<ProductTaxFilterInput>>;
  or?: Maybe<Array<ProductTaxFilterInput>>;
  productId?: Maybe<ComparableGuidOperationFilterInput>;
  taxId?: Maybe<ComparableGuidOperationFilterInput>;
  product?: Maybe<ProductFilterInput>;
  tax?: Maybe<TaxFilterInput>;
};

export class InvoiceDetailFilterInput {
  and?: Maybe<Array<InvoiceDetailFilterInput>>;
  or?: Maybe<Array<InvoiceDetailFilterInput>>;
  purchaseVoucherId?: Maybe<ComparableGuidOperationFilterInput>;
  productId?: Maybe<ComparableGuidOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  price?: Maybe<ComparableDecimalOperationFilterInput>;
  quantity?: Maybe<ComparableDecimalOperationFilterInput>;
  discount?: Maybe<ComparableDecimalOperationFilterInput>;
  tax?: Maybe<ComparableDecimalOperationFilterInput>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  sortOrder?: Maybe<ComparableInt16OperationFilterInput>;
  product?: Maybe<ProductFilterInput>;
  invoice?: Maybe<InvoiceFilterInput>;
  invoiceDetailTaxs?: Maybe<ListFilterInputTypeOfInvoiceDetailTaxFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class VoucherLineItemFilterInput {
  and?: Maybe<Array<VoucherLineItemFilterInput>>;
  or?: Maybe<Array<VoucherLineItemFilterInput>>;
  accountId?: Maybe<ComparableGuidOperationFilterInput>;
  voucherId?: Maybe<ComparableGuidOperationFilterInput>;
  voucherLineType?: Maybe<VoucherLineTypesOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  sortOrder?: Maybe<ComparableInt32OperationFilterInput>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
  voucher?: Maybe<VoucherFilterInput>;
  account?: Maybe<ThirdLevelAccountFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidatePositionFilterInput {
  and?: Maybe<Array<CandidatePositionFilterInput>>;
  or?: Maybe<Array<CandidatePositionFilterInput>>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  jobPositionId?: Maybe<ComparableGuidOperationFilterInput>;
  employeeId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  interviewDateTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  jobPosition?: Maybe<JobPositionFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmployeeChildrenFilterInput {
  and?: Maybe<Array<EmployeeChildrenFilterInput>>;
  or?: Maybe<Array<EmployeeChildrenFilterInput>>;
  employeeId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  gender?: Maybe<GenderOperationFilterInput>;
  age?: Maybe<ComparableByteOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmployeeEducationFilterInput {
  and?: Maybe<Array<EmployeeEducationFilterInput>>;
  or?: Maybe<Array<EmployeeEducationFilterInput>>;
  employeeId?: Maybe<ComparableGuidOperationFilterInput>;
  passingYear?: Maybe<ComparableInt32OperationFilterInput>;
  certificationId?: Maybe<ComparableGuidOperationFilterInput>;
  instituteId?: Maybe<ComparableGuidOperationFilterInput>;
  grade?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  certification?: Maybe<HrLookupFilterInput>;
  institute?: Maybe<HrLookupFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmployeeExperienceFilterInput {
  and?: Maybe<Array<EmployeeExperienceFilterInput>>;
  or?: Maybe<Array<EmployeeExperienceFilterInput>>;
  employeeId?: Maybe<ComparableGuidOperationFilterInput>;
  employer?: Maybe<StringOperationFilterInput>;
  from?: Maybe<ComparableDateTimeOperationFilterInput>;
  to?: Maybe<ComparableDateTimeOperationFilterInput>;
  reasonForLeaving?: Maybe<StringOperationFilterInput>;
  designation?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmployeeReferencesFilterInput {
  and?: Maybe<Array<EmployeeReferencesFilterInput>>;
  or?: Maybe<Array<EmployeeReferencesFilterInput>>;
  employeeId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  address?: Maybe<StringOperationFilterInput>;
  contactNumber?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class EmployeeRelativesFilterInput {
  and?: Maybe<Array<EmployeeRelativesFilterInput>>;
  or?: Maybe<Array<EmployeeRelativesFilterInput>>;
  employeeId?: Maybe<ComparableGuidOperationFilterInput>;
  relativeId?: Maybe<ComparableGuidOperationFilterInput>;
  relationship?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  employee?: Maybe<EmployeeFilterInput>;
  relative?: Maybe<UserFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateChildrenFilterInput {
  and?: Maybe<Array<CandidateChildrenFilterInput>>;
  or?: Maybe<Array<CandidateChildrenFilterInput>>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  gender?: Maybe<GenderOperationFilterInput>;
  age?: Maybe<ComparableByteOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateEducationFilterInput {
  and?: Maybe<Array<CandidateEducationFilterInput>>;
  or?: Maybe<Array<CandidateEducationFilterInput>>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  passingYear?: Maybe<ComparableInt32OperationFilterInput>;
  certificationId?: Maybe<ComparableGuidOperationFilterInput>;
  instituteId?: Maybe<ComparableGuidOperationFilterInput>;
  grade?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  certification?: Maybe<CertificationFilterInput>;
  institute?: Maybe<InstituteFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateExperienceFilterInput {
  and?: Maybe<Array<CandidateExperienceFilterInput>>;
  or?: Maybe<Array<CandidateExperienceFilterInput>>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  employer?: Maybe<StringOperationFilterInput>;
  from?: Maybe<ComparableDateTimeOperationFilterInput>;
  to?: Maybe<ComparableDateTimeOperationFilterInput>;
  reasonForLeaving?: Maybe<StringOperationFilterInput>;
  designation?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateReferencesFilterInput {
  and?: Maybe<Array<CandidateReferencesFilterInput>>;
  or?: Maybe<Array<CandidateReferencesFilterInput>>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  address?: Maybe<StringOperationFilterInput>;
  contactNumber?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CandidateRelativesFilterInput {
  and?: Maybe<Array<CandidateRelativesFilterInput>>;
  or?: Maybe<Array<CandidateRelativesFilterInput>>;
  candidateId?: Maybe<ComparableGuidOperationFilterInput>;
  relativeName?: Maybe<StringOperationFilterInput>;
  relationship?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  candidate?: Maybe<CandidateFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class FirstLevelAccountSortInput {
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  roleNumber?: Maybe<SortEnumType>;
  sortOrder?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ListFilterInputTypeOfCandidateScoreFilterInput {
  all?: Maybe<CandidateScoreFilterInput>;
  none?: Maybe<CandidateScoreFilterInput>;
  some?: Maybe<CandidateScoreFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class FirstLevelAccountFilterInput {
  and?: Maybe<Array<FirstLevelAccountFilterInput>>;
  or?: Maybe<Array<FirstLevelAccountFilterInput>>;
  secondLevelAccounts?: Maybe<ListFilterInputTypeOfSecondLevelAccountFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  roleNumber?: Maybe<ComparableInt64OperationFilterInput>;
  roles?: Maybe<ListComparableInt64OperationFilterInput>;
  sortOrder?: Maybe<ComparableInt32OperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  business?: Maybe<BusinessFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ListFilterInputTypeOfThirdLevelAccountFilterInput {
  all?: Maybe<ThirdLevelAccountFilterInput>;
  none?: Maybe<ThirdLevelAccountFilterInput>;
  some?: Maybe<ThirdLevelAccountFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class VoucherLineTypesOperationFilterInput {
  eq?: Maybe<VoucherLineTypes>;
  neq?: Maybe<VoucherLineTypes>;
  in?: Maybe<Array<VoucherLineTypes>>;
  nin?: Maybe<Array<VoucherLineTypes>>;
};

export class CandidateScoreFilterInput {
  and?: Maybe<Array<CandidateScoreFilterInput>>;
  or?: Maybe<Array<CandidateScoreFilterInput>>;
  candidateEvaluationId?: Maybe<ComparableGuidOperationFilterInput>;
  interviewCriterionId?: Maybe<ComparableGuidOperationFilterInput>;
  interviewCategoryId?: Maybe<ComparableGuidOperationFilterInput>;
  score?: Maybe<ComparableByteOperationFilterInput>;
  remarks?: Maybe<StringOperationFilterInput>;
  candidateEvaluation?: Maybe<CandidateEvaluationFilterInput>;
  interviewCategory?: Maybe<InterviewCategoryFilterInput>;
  interviewCriterion?: Maybe<InterviewCriterionFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class ListFilterInputTypeOfSecondLevelAccountFilterInput {
  all?: Maybe<SecondLevelAccountFilterInput>;
  none?: Maybe<SecondLevelAccountFilterInput>;
  some?: Maybe<SecondLevelAccountFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class Mutation {
  __typename?: 'Mutation';
  business?: Maybe<BusinessMutationType>;
  user?: Maybe<UserMutationType>;
  finance?: Maybe<FinanceMutationType>;
  hr?: Maybe<HrMutationType>;
  timeTracking?: Maybe<TimeTrackingMutationType>;
  trackingAgent?: Maybe<TrackingAgentMutationType>;
  attendanceManagement?: Maybe<AttendanceManagementMutationType>;
  support?: Maybe<SupportMutationType>;
  supportTicket?: Maybe<SupportTicketMutationType>;
};

export class Subscription {
  __typename?: 'Subscription';
  showLatestNotification?: Maybe<Notification>;
};


export class SubscriptionShowLatestNotificationArgs {
  sessionId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
};

export class TicketCategoryQueryType {
  __typename?: 'TicketCategoryQueryType';
  categories?: Maybe<TicketCategoryCollectionSegment>;
};


export class TicketCategoryQueryTypeCategoriesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketCategoryFilterInput>;
  order?: Maybe<Array<TicketCategorySortInput>>;
};

export class TicketQueryType {
  __typename?: 'TicketQueryType';
  tickets?: Maybe<TicketCollectionSegment>;
  ticketComments?: Maybe<TicketCollectionSegment>;
};


export class TicketQueryTypeTicketsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput>>;
};


export class TicketQueryTypeTicketCommentsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ticketId: Scalars['Uuid'];
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput>>;
};

export class TaxQueryType {
  __typename?: 'TaxQueryType';
  taxes?: Maybe<Array<Maybe<Tax>>>;
};


export class TaxQueryTypeTaxesArgs {
  where?: Maybe<TaxFilterInput>;
};

export class NotificationQueryType {
  __typename?: 'NotificationQueryType';
  notifications?: Maybe<NotificationCollectionSegment>;
};


export class NotificationQueryTypeNotificationsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<NotificationSortInput>>;
};

export enum States {
  Na = 'NA',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  InProgress = 'IN_PROGRESS',
  Hired = 'HIRED',
  Terminated = 'TERMINATED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  ShortListed = 'SHORT_LISTED',
  InterviewScheduled = 'INTERVIEW_SCHEDULED',
  Withdrawn = 'WITHDRAWN',
  Resigned = 'RESIGNED'
}



export enum ProjectRoles {
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export class InvoiceConfiguration {
  __typename?: 'InvoiceConfiguration';
  showPurchaseOrder: Scalars['Boolean'];
  showPurchaseInvoice: Scalars['Boolean'];
  showSaleInvoice: Scalars['Boolean'];
  showQuotation: Scalars['Boolean'];
};


export class CandidateScore {
  __typename?: 'CandidateScore';
  candidateEvaluationId: Scalars['Uuid'];
  interviewCriterionId: Scalars['Uuid'];
  interviewCategoryId: Scalars['Uuid'];
  score: Scalars['Byte'];
  remarks?: Maybe<Scalars['String']>;
  candidateEvaluation?: Maybe<CandidateEvaluation>;
  interviewCategory?: Maybe<InterviewCategory>;
  interviewCriterion?: Maybe<InterviewCriterion>;
  id: Scalars['Uuid'];
};

export enum Gender {
  Na = 'NA',
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum MaritalStatus {
  Single = 'SINGLE',
  Married = 'MARRIED',
  Other = 'OTHER'
}

export class CandidatePosition {
  __typename?: 'CandidatePosition';
  isInterviewScheduled: Scalars['Boolean'];
  isHired: Scalars['Boolean'];
  isShortListed: Scalars['Boolean'];
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  employeeId?: Maybe<Scalars['Uuid']>;
  state: States;
  interviewDateTime?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<Employee>;
  candidate?: Maybe<Candidate>;
  jobPosition?: Maybe<JobPosition>;
  id: Scalars['Uuid'];
};

export class CandidateChildren {
  __typename?: 'CandidateChildren';
  candidateId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  gender: Gender;
  age: Scalars['Byte'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  candidate?: Maybe<Candidate>;
  id: Scalars['Uuid'];
};

export class CandidateEducation {
  __typename?: 'CandidateEducation';
  candidateId: Scalars['Uuid'];
  passingYear: Scalars['Int'];
  certificationId: Scalars['Uuid'];
  instituteId: Scalars['Uuid'];
  grade?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  candidate?: Maybe<Candidate>;
  certification?: Maybe<Certification>;
  institute?: Maybe<Institute>;
  id: Scalars['Uuid'];
};

export class CandidateExperience {
  __typename?: 'CandidateExperience';
  candidateId: Scalars['Uuid'];
  employer?: Maybe<Scalars['String']>;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  reasonForLeaving?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  candidate?: Maybe<Candidate>;
  id: Scalars['Uuid'];
};

export class CandidateReferences {
  __typename?: 'CandidateReferences';
  candidateId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  candidate?: Maybe<Candidate>;
  id: Scalars['Uuid'];
};

export class CandidateRelatives {
  __typename?: 'CandidateRelatives';
  candidateId: Scalars['Uuid'];
  relativeName?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  candidate?: Maybe<Candidate>;
  id: Scalars['Uuid'];
};

export class EmployeeChildren {
  __typename?: 'EmployeeChildren';
  employeeId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  gender: Gender;
  age: Scalars['Byte'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  employee?: Maybe<Employee>;
  id: Scalars['Uuid'];
};

export class EmployeeEducation {
  __typename?: 'EmployeeEducation';
  employeeId: Scalars['Uuid'];
  passingYear: Scalars['Int'];
  certificationId: Scalars['Uuid'];
  instituteId: Scalars['Uuid'];
  grade?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  employee?: Maybe<Employee>;
  certification?: Maybe<HrLookup>;
  institute?: Maybe<HrLookup>;
  id: Scalars['Uuid'];
};

export class EmployeeExperience {
  __typename?: 'EmployeeExperience';
  employeeId: Scalars['Uuid'];
  employer?: Maybe<Scalars['String']>;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  reasonForLeaving?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  employee?: Maybe<Employee>;
  id: Scalars['Uuid'];
};

export class EmployeeReferences {
  __typename?: 'EmployeeReferences';
  employeeId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  employee?: Maybe<Employee>;
  id: Scalars['Uuid'];
};

export class EmployeeRelatives {
  __typename?: 'EmployeeRelatives';
  employeeId: Scalars['Uuid'];
  relativeId: Scalars['Uuid'];
  relationship?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
  employee?: Maybe<Employee>;
  relative?: Maybe<User>;
  id: Scalars['Uuid'];
};

export enum CareerLevel {
  ExecutiveOrDirector = 'EXECUTIVE_OR_DIRECTOR',
  Manager = 'MANAGER',
  Senior = 'SENIOR',
  MidLevel = 'MID_LEVEL',
  Junior = 'JUNIOR',
  StudentOrIntern = 'STUDENT_OR_INTERN'
}

export enum EmploymentType {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Contract = 'CONTRACT',
  Temporary = 'TEMPORARY',
  Other = 'OTHER'
}

export enum EducationLevel {
  HighSchoolOrSecondary = 'HIGH_SCHOOL_OR_SECONDARY',
  BachelorsDegree = 'BACHELORS_DEGREE',
  MasterDegree = 'MASTER_DEGREE',
  PhD = 'PH_D'
}

export enum WorkExperience {
  From0To1Year = 'FROM0_TO1_YEAR',
  From1To2Year = 'FROM1_TO2_YEAR',
  From2To5Year = 'FROM2_TO5_YEAR',
  From5To10Year = 'FROM5_TO10_YEAR',
  From10To15Year = 'FROM10_TO15_YEAR',
  From15PlusYear = 'FROM15_PLUS_YEAR'
}

export enum MonthlySalary {
  From0To1999 = 'FROM0_TO1999',
  From2000To3999 = 'FROM2000_TO3999',
  From4000To5999 = 'FROM4000_TO5999',
  From6000To7999 = 'FROM6000_TO7999',
  From8000To11999 = 'FROM8000_TO11999',
  From12000To19000 = 'FROM12000_TO19000',
  From20000To29000 = 'FROM20000_TO29000',
  From30000To49000 = 'FROM30000_TO49000',
  From50000To99999 = 'FROM50000_TO99999',
  From100000Plus = 'FROM100000_PLUS'
}

export enum CompanySize {
  From1To10 = 'FROM1_TO10',
  From11To50 = 'FROM11_TO50',
  From51To200 = 'FROM51_TO200',
  From201To500 = 'FROM201_TO500',
  From501To1000 = 'FROM501_TO1000',
  From1001To5000 = 'FROM1001_TO5000',
  From5001To10000 = 'FROM5001_TO10000',
  From10000Plus = 'FROM10000_PLUS'
}

export class MedicalClaimBreakdown {
  __typename?: 'MedicalClaimBreakdown';
  medicalClaimId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  billNumber?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  file?: Maybe<Scalars['String']>;
  medicalClaim?: Maybe<MedicalClaim>;
  id: Scalars['Uuid'];
};

export enum ReasonTypes {
  Hiring = 'HIRING',
  Termination = 'TERMINATION'
}

export class ReimbursementDetail {
  __typename?: 'ReimbursementDetail';
  reimbursementId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  file?: Maybe<Scalars['String']>;
  reimbursement?: Maybe<Reimbursement>;
  id: Scalars['Uuid'];
};

export enum VoucherStates {
  Draft = 'DRAFT',
  Approved = 'APPROVED'
}


export enum ProductTypes {
  Item = 'ITEM',
  Service = 'SERVICE'
}

export enum ProductUnits {
  Cm = 'CM',
  M = 'M',
  Ft = 'FT',
  G = 'G',
  Lb = 'LB',
  Kg = 'KG',
  Ml = 'ML',
  L = 'L'
}

export class VoucherLineItem {
  __typename?: 'VoucherLineItem';
  accountId: Scalars['Uuid'];
  voucherId: Scalars['Uuid'];
  voucherLineType: VoucherLineTypes;
  description?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  amount: Scalars['Decimal'];
  voucher: Voucher;
  account: ThirdLevelAccount;
  id: Scalars['Uuid'];
};

export enum BusinessTypes {
  CementRetail = 'CEMENT_RETAIL',
  ShoesRetail = 'SHOES_RETAIL',
  Export = 'EXPORT',
  GroceryRetail = 'GROCERY_RETAIL',
  Generic = 'GENERIC'
}

export enum Industries {
  SoleProprietorShip = 'SOLE_PROPRIETOR_SHIP',
  Partnership = 'PARTNERSHIP',
  Corporation = 'CORPORATION'
}

export class SalaryAccount {
  __typename?: 'SalaryAccount';
  eobiAccount?: Maybe<EobiAccount>;
  pfAccount?: Maybe<PfAccount>;
  fbrAccount?: Maybe<FbrAccount>;
};

export class ChildCount {
  __typename?: 'ChildCount';
  usersCount: Scalars['Long'];
  departmentsCount: Scalars['Long'];
};

export enum BusinessCategories {
  Retail = 'RETAIL',
  Export = 'EXPORT'
}

export enum NotificationTypes {
  Alert = 'ALERT',
  Event = 'EVENT',
  Log = 'LOG',
  Command = 'COMMAND'
}

export class UserBenefits {
  __typename?: 'UserBenefits';
  eobiBenefit?: Maybe<EobiBenefit>;
  pfBenefit?: Maybe<PfBenefit>;
};

export class AttendanceConfigurationQueryType {
  __typename?: 'AttendanceConfigurationQueryType';
  businessAttendanceConfig?: Maybe<AttendanceConfiguration>;
  myBusinessAttendanceConfig?: Maybe<AttendanceConfiguration>;
  businessUserAttendanceConfig?: Maybe<AttendanceConfiguration>;
  usersConfig?: Maybe<AttendanceUserConfigurationCollectionSegment>;
  attendanceConfig?: Maybe<AttendanceUserConfiguration>;
};


export class AttendanceConfigurationQueryTypeBusinessAttendanceConfigArgs {
  businessId: Scalars['Uuid'];
};


export class AttendanceConfigurationQueryTypeUsersConfigArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AttendanceUserConfigurationFilterInput>;
  order?: Maybe<Array<AttendanceUserConfigurationSortInput>>;
};


export class AttendanceConfigurationQueryTypeAttendanceConfigArgs {
  userId: Scalars['Uuid'];
};

export class AttendanceRecordQueryType {
  __typename?: 'AttendanceRecordQueryType';
  userWithAttendanceRecords?: Maybe<UserCollectionSegment>;
  yesterdayAttendanceReport?: Maybe<YesterdayAttendanceReport>;
  todayAttendanceReport?: Maybe<TodayAttendanceReport>;
};


export class AttendanceRecordQueryTypeUserWithAttendanceRecordsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Uuid']>;
  present?: Scalars['Boolean'];
  absent?: Scalars['Boolean'];
  leave?: Scalars['Boolean'];
  onTimeLogin?: Scalars['Boolean'];
  late?: Scalars['Boolean'];
  onWeekend?: Scalars['Boolean'];
  where?: Maybe<UserFilterInput>;
  order?: Maybe<Array<UserSortInput>>;
};


export class AttendanceRecordQueryTypeYesterdayAttendanceReportArgs {
  date: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Uuid']>;
};


export class AttendanceRecordQueryTypeTodayAttendanceReportArgs {
  date: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Uuid']>;
};

export class BusinessShift {
  __typename?: 'BusinessShift';
  state: States;
  openingTime?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['String']>;
  attendanceConfigurationId?: Maybe<Scalars['Uuid']>;
  attendanceUserConfigurations?: Maybe<Array<Maybe<AttendanceUserConfiguration>>>;
  attendanceConfiguration?: Maybe<AttendanceConfiguration>;
  id: Scalars['Uuid'];
};

export class AgentTime {
  __typename?: 'AgentTime';
  id?: Maybe<Scalars['Uuid']>;
  idleTime: Scalars['Float'];
  productiveTime: Scalars['Float'];
  loginTime?: Maybe<Scalars['DateTime']>;
  logoutTime?: Maybe<Scalars['DateTime']>;
};

export class AgentQueryType {
  __typename?: 'AgentQueryType';
  businessAgent?: Maybe<Array<Maybe<AgentConfiguration>>>;
  businessUserAgent?: Maybe<AgentConfiguration>;
};


export class AgentQueryTypeBusinessAgentArgs {
  businessId: Scalars['Uuid'];
};

export class LogsQueryType {
  __typename?: 'LogsQueryType';
  idleStates?: Maybe<AppUsageCollectionSegment>;
  idleStateMilliseconds: Scalars['Float'];
  idleStateLiveReport?: Maybe<Array<Maybe<LiveReportVm>>>;
  productiveStates?: Maybe<AppUsageCollectionSegment>;
  productiveStateMilliseconds: Scalars['Float'];
  productiveStateLiveReport?: Maybe<Array<Maybe<LiveReportVm>>>;
};


export class LogsQueryTypeIdleStatesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppUsageFilterInput>;
  order?: Maybe<Array<AppUsageSortInput>>;
};


export class LogsQueryTypeIdleStateMillisecondsArgs {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export class LogsQueryTypeIdleStateLiveReportArgs {
  userId?: Maybe<Scalars['Uuid']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export class LogsQueryTypeProductiveStatesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppUsageFilterInput>;
  order?: Maybe<Array<AppUsageSortInput>>;
};


export class LogsQueryTypeProductiveStateMillisecondsArgs {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export class LogsQueryTypeProductiveStateLiveReportArgs {
  userId?: Maybe<Scalars['Uuid']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export class SnapsQueryType {
  __typename?: 'SnapsQueryType';
  snapshots?: Maybe<SnapshotCollectionSegment>;
  snapshotBreakdowns?: Maybe<Array<Maybe<Snapshot>>>;
  machines?: Maybe<MachineVmCollectionSegment>;
};


export class SnapsQueryTypeSnapshotsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SnapshotFilterInput>;
};


export class SnapsQueryTypeMachinesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MachineVmFilterInput>;
};

export class AppsQueryType {
  __typename?: 'AppsQueryType';
  paginatedBusinessApps?: Maybe<AppCollectionSegment>;
};


export class AppsQueryTypePaginatedBusinessAppsArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppFilterInput>;
  order?: Maybe<Array<AppSortInput>>;
};

export enum AppStates {
  Pending = 'PENDING',
  Productive = 'PRODUCTIVE',
  Unproductive = 'UNPRODUCTIVE'
}

export class AppUsageSummary {
  __typename?: 'AppUsageSummary';
  usersCount: Scalars['Long'];
  usageMinutes: Scalars['Float'];
};

export class AppUsageMutationType {
  __typename?: 'AppUsageMutationType';
  writeLogs?: Maybe<Scalars['String']>;
};


export class AppUsageMutationTypeWriteLogsArgs {
  model?: Maybe<Array<Maybe<AppUsageVmInput>>>;
};

export class UserAgentMutationType {
  __typename?: 'UserAgentMutationType';
  updateUserAgent?: Maybe<Scalars['String']>;
  disableUserAgent?: Maybe<Scalars['String']>;
  enableBusinessAgent?: Maybe<Scalars['String']>;
  updateBusinessAgent?: Maybe<Scalars['String']>;
  disableBusinessAgent?: Maybe<Scalars['String']>;
  addLogin?: Maybe<Scalars['String']>;
};


export class UserAgentMutationTypeUpdateUserAgentArgs {
  userId: Scalars['Uuid'];
  model?: Maybe<UserAgentConfigurationVmInput>;
};


export class UserAgentMutationTypeDisableUserAgentArgs {
  userId: Scalars['Uuid'];
};


export class UserAgentMutationTypeUpdateBusinessAgentArgs {
  model?: Maybe<UpdateAgentConfigurationInput>;
};


export class UserAgentMutationTypeAddLoginArgs {
  date?: Maybe<Scalars['String']>;
};

export class AppsMutationType {
  __typename?: 'AppsMutationType';
  updateState?: Maybe<Scalars['String']>;
};


export class AppsMutationTypeUpdateStateArgs {
  appId: Scalars['Uuid'];
  state: AppStates;
};

export class ClientsMutationType {
  __typename?: 'ClientsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class ClientsMutationTypeAddArgs {
  model?: Maybe<CreateClientInput>;
};


export class ClientsMutationTypeUpdateArgs {
  model?: Maybe<UpdateClientInput>;
};


export class ClientsMutationTypeArchiveArgs {
  clientId: Scalars['Uuid'];
};


export class ClientsMutationTypeRestoreArgs {
  clientId: Scalars['Uuid'];
};

export class GenericTasksMutationType {
  __typename?: 'GenericTasksMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class GenericTasksMutationTypeAddArgs {
  model?: Maybe<CreateGenericTaskInput>;
};


export class GenericTasksMutationTypeUpdateArgs {
  model?: Maybe<UpdateGenericTaskInput>;
};


export class GenericTasksMutationTypeArchiveArgs {
  taskId: Scalars['Uuid'];
};


export class GenericTasksMutationTypeRestoreArgs {
  taskId: Scalars['Uuid'];
};

export class ProjectsMutationType {
  __typename?: 'ProjectsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['String']>;
  assignUsers?: Maybe<Scalars['String']>;
};


export class ProjectsMutationTypeAddArgs {
  model?: Maybe<CreateProjectInput>;
};


export class ProjectsMutationTypeUpdateArgs {
  model?: Maybe<UpdateProjectInput>;
};


export class ProjectsMutationTypeArchiveArgs {
  clientId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
};


export class ProjectsMutationTypeRestoreArgs {
  clientId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
};


export class ProjectsMutationTypeCloseArgs {
  clientId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
};


export class ProjectsMutationTypeAssignUsersArgs {
  model?: Maybe<AssignProjectUsersInput>;
};

export class ProjectTasksMutationType {
  __typename?: 'ProjectTasksMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class ProjectTasksMutationTypeAddArgs {
  model?: Maybe<CreateProjectTaskInput>;
};


export class ProjectTasksMutationTypeUpdateArgs {
  model?: Maybe<UpdateProjectTaskInput>;
};


export class ProjectTasksMutationTypeArchiveArgs {
  projectId: Scalars['Uuid'];
  id: Scalars['Uuid'];
};


export class ProjectTasksMutationTypeRestoreArgs {
  projectId: Scalars['Uuid'];
  id: Scalars['Uuid'];
};

export class SubTasksMutationType {
  __typename?: 'SubTasksMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class SubTasksMutationTypeAddArgs {
  model?: Maybe<CreateSubTaskInput>;
};


export class SubTasksMutationTypeUpdateArgs {
  model?: Maybe<UpdateSubTaskInput>;
};


export class SubTasksMutationTypeArchiveArgs {
  projectId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
  subTaskId: Scalars['Uuid'];
};


export class SubTasksMutationTypeRestoreArgs {
  projectId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
  subTaskId: Scalars['Uuid'];
};

export class TimeLogsMutationType {
  __typename?: 'TimeLogsMutationType';
  add?: Maybe<Scalars['String']>;
  remove?: Maybe<Scalars['String']>;
};


export class TimeLogsMutationTypeAddArgs {
  model?: Maybe<CreateTimeLogInput>;
};


export class TimeLogsMutationTypeRemoveArgs {
  timeLogIds?: Maybe<Array<Scalars['Uuid']>>;
};

export class TicketCategoryMutationType {
  __typename?: 'TicketCategoryMutationType';
  create?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class TicketCategoryMutationTypeCreateArgs {
  model?: Maybe<CreateTicketCategoryVmInput>;
};


export class TicketCategoryMutationTypeUpdateArgs {
  model?: Maybe<UpdateTicketCategoryVmInput>;
};


export class TicketCategoryMutationTypeArchiveArgs {
  categoryId: Scalars['Uuid'];
};


export class TicketCategoryMutationTypeRestoreArgs {
  categoryId: Scalars['Uuid'];
};

export class TicketMutationType {
  __typename?: 'TicketMutationType';
  assign?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['String']>;
  resolve?: Maybe<Scalars['String']>;
  withdraw?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class TicketMutationTypeAssignArgs {
  assignedUserId: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
};


export class TicketMutationTypeArchiveArgs {
  ticketId: Scalars['Uuid'];
};


export class TicketMutationTypeProcessArgs {
  ticketId: Scalars['Uuid'];
};


export class TicketMutationTypeResolveArgs {
  ticketId: Scalars['Uuid'];
};


export class TicketMutationTypeWithdrawArgs {
  ticketId: Scalars['Uuid'];
};


export class TicketMutationTypeRestoreArgs {
  ticketId: Scalars['Uuid'];
};

export class CommentMutationType {
  __typename?: 'CommentMutationType';
  delete?: Maybe<Scalars['String']>;
};


export class CommentMutationTypeDeleteArgs {
  ticketId: Scalars['Uuid'];
  commentId: Scalars['Uuid'];
};

export class SiteMutationType {
  __typename?: 'SiteMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class SiteMutationTypeAddArgs {
  model?: Maybe<CreateSiteInput>;
};


export class SiteMutationTypeUpdateArgs {
  model?: Maybe<UpdateSiteInput>;
};


export class SiteMutationTypeArchiveArgs {
  siteId: Scalars['Uuid'];
};


export class SiteMutationTypeRestoreArgs {
  siteId: Scalars['Uuid'];
};

export class TicketsMutationType {
  __typename?: 'TicketsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  scheduleTicket?: Maybe<Scalars['String']>;
  ticketPriority?: Maybe<Scalars['String']>;
  ticketStatus?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class TicketsMutationTypeAddArgs {
  model?: Maybe<CreateTicketsInput>;
};


export class TicketsMutationTypeUpdateArgs {
  model?: Maybe<UpdateTicketsInput>;
};


export class TicketsMutationTypeScheduleTicketArgs {
  model?: Maybe<UpdateTicketsInput>;
};


export class TicketsMutationTypeTicketPriorityArgs {
  model?: Maybe<UpdateTicketsInput>;
};


export class TicketsMutationTypeTicketStatusArgs {
  model?: Maybe<UpdateTicketsInput>;
};


export class TicketsMutationTypeArchiveArgs {
  ticketId: Scalars['Uuid'];
};


export class TicketsMutationTypeRestoreArgs {
  ticketId: Scalars['Uuid'];
};

export class TicketStatusChangeMutationType {
  __typename?: 'TicketStatusChangeMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
};


export class TicketStatusChangeMutationTypeAddArgs {
  model?: Maybe<CreateTicketStatusChangeInput>;
};


export class TicketStatusChangeMutationTypeUpdateArgs {
  model?: Maybe<UpdateTicketStatusChangeInput>;
};

export class TicketLaborMutationType {
  __typename?: 'TicketLaborMutationType';
  add?: Maybe<Scalars['String']>;
  updateBillable?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
};


export class TicketLaborMutationTypeAddArgs {
  model?: Maybe<CreateTicketLaborInput>;
};


export class TicketLaborMutationTypeUpdateBillableArgs {
  model?: Maybe<UpdateTicketLaborInput>;
};


export class TicketLaborMutationTypeUpdateArgs {
  model?: Maybe<UpdateTicketLaborInput>;
};

export class TicketMaterialMutationType {
  __typename?: 'TicketMaterialMutationType';
  add?: Maybe<Scalars['String']>;
};


export class TicketMaterialMutationTypeAddArgs {
  model?: Maybe<Array<Maybe<CreateTicketMaterialInput>>>;
};

export class AllowanceCategoriesMutationType {
  __typename?: 'AllowanceCategoriesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class AllowanceCategoriesMutationTypeAddArgs {
  model?: Maybe<CreateAllowanceCategoryInput>;
};


export class AllowanceCategoriesMutationTypeUpdateArgs {
  model?: Maybe<UpdateAllowanceCategoryInput>;
};


export class AllowanceCategoriesMutationTypeArchiveArgs {
  allowanceCategoryId: Scalars['Uuid'];
};


export class AllowanceCategoriesMutationTypeRestoreArgs {
  allowanceCategoryId: Scalars['Uuid'];
};

export class AllowanceTypesMutationType {
  __typename?: 'AllowanceTypesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class AllowanceTypesMutationTypeAddArgs {
  model?: Maybe<CreateAllowanceTypeInput>;
};


export class AllowanceTypesMutationTypeUpdateArgs {
  model?: Maybe<UpdateAllowanceTypeInput>;
};


export class AllowanceTypesMutationTypeArchiveArgs {
  allowanceTypeId: Scalars['Uuid'];
};


export class AllowanceTypesMutationTypeRestoreArgs {
  allowanceTypeId: Scalars['Uuid'];
};

export class BonusCategoriesMutationType {
  __typename?: 'BonusCategoriesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class BonusCategoriesMutationTypeAddArgs {
  model?: Maybe<CreateBonusCategoryInput>;
};


export class BonusCategoriesMutationTypeUpdateArgs {
  model?: Maybe<UpdateBonusCategoryInput>;
};


export class BonusCategoriesMutationTypeArchiveArgs {
  bonusCategoryId: Scalars['Uuid'];
};


export class BonusCategoriesMutationTypeRestoreArgs {
  bonusCategoryId: Scalars['Uuid'];
};

export class BonusTypesMutationType {
  __typename?: 'BonusTypesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class BonusTypesMutationTypeAddArgs {
  model?: Maybe<CreateBonusTypeInput>;
};


export class BonusTypesMutationTypeUpdateArgs {
  model?: Maybe<UpdateBonusTypeInput>;
};


export class BonusTypesMutationTypeArchiveArgs {
  bonusTypeId: Scalars['Uuid'];
};


export class BonusTypesMutationTypeRestoreArgs {
  bonusTypeId: Scalars['Uuid'];
};

export class CandidatesMutationType {
  __typename?: 'CandidatesMutationType';
  hire?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['String']>;
  sendInvite?: Maybe<Scalars['String']>;
  addFeedBack?: Maybe<Scalars['String']>;
  updateFeedBack?: Maybe<Scalars['String']>;
};


export class CandidatesMutationTypeHireArgs {
  businessId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  id: Scalars['Uuid'];
};


export class CandidatesMutationTypeCloseArgs {
  businessId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  id: Scalars['Uuid'];
};


export class CandidatesMutationTypeSendInviteArgs {
  jobPositionId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  model?: Maybe<SendInviteInput>;
};


export class CandidatesMutationTypeAddFeedBackArgs {
  businessId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  model?: Maybe<CreateCandidateEvaluationInput>;
};


export class CandidatesMutationTypeUpdateFeedBackArgs {
  businessId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  model?: Maybe<UpdateCandidateEvaluationInput>;
};

export class CertificationsMutationType {
  __typename?: 'CertificationsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class CertificationsMutationTypeAddArgs {
  model?: Maybe<CreateCertificationInput>;
};


export class CertificationsMutationTypeUpdateArgs {
  model?: Maybe<UpdateCertificationInput>;
};


export class CertificationsMutationTypeArchiveArgs {
  certificationId: Scalars['Uuid'];
};


export class CertificationsMutationTypeRestoreArgs {
  certificationId: Scalars['Uuid'];
};

export class DepartmentsMutationType {
  __typename?: 'DepartmentsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class DepartmentsMutationTypeAddArgs {
  model?: Maybe<CreateDepartmentInput>;
};


export class DepartmentsMutationTypeUpdateArgs {
  model?: Maybe<UpdateDepartmentInput>;
};


export class DepartmentsMutationTypeArchiveArgs {
  departmentId: Scalars['Uuid'];
};


export class DepartmentsMutationTypeRestoreArgs {
  departmentId: Scalars['Uuid'];
};

export class HrLookupMutationType {
  __typename?: 'HrLookupMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class HrLookupMutationTypeAddArgs {
  model?: Maybe<CreateHrLookupInput>;
};


export class HrLookupMutationTypeUpdateArgs {
  model?: Maybe<UpdateHrLookupInput>;
};


export class HrLookupMutationTypeArchiveArgs {
  hrLookupId: Scalars['Uuid'];
};


export class HrLookupMutationTypeRestoreArgs {
  hrLookupId: Scalars['Uuid'];
};

export class EmployeesMutationType {
  __typename?: 'EmployeesMutationType';
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
  hireOrTerminate?: Maybe<Scalars['String']>;
  updateHireOrTerminate?: Maybe<Scalars['String']>;
  updateSalary?: Maybe<Scalars['String']>;
  addUpdateEmployeePfBenefit?: Maybe<Scalars['String']>;
  addUpdateEmployeeEobiBenefit?: Maybe<Scalars['String']>;
  generatPayroll?: Maybe<Scalars['String']>;
};


export class EmployeesMutationTypeUpdateArgs {
  model?: Maybe<UpdateEmployeeInput>;
};


export class EmployeesMutationTypeArchiveArgs {
  employeeId: Scalars['Uuid'];
};


export class EmployeesMutationTypeRestoreArgs {
  employeeId: Scalars['Uuid'];
};


export class EmployeesMutationTypeHireOrTerminateArgs {
  employeeId: Scalars['Uuid'];
  reasonTypeId: Scalars['Uuid'];
  joiningDate?: Maybe<Scalars['DateTime']>;
  terminationDate?: Maybe<Scalars['DateTime']>;
  state: States;
};


export class EmployeesMutationTypeUpdateHireOrTerminateArgs {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  reasonTypeId: Scalars['Uuid'];
  joiningDate?: Maybe<Scalars['DateTime']>;
  terminationDate?: Maybe<Scalars['DateTime']>;
  state: States;
};


export class EmployeesMutationTypeUpdateSalaryArgs {
  employeeId: Scalars['Uuid'];
  vm?: Maybe<CreateSalaryVmInput>;
};


export class EmployeesMutationTypeAddUpdateEmployeePfBenefitArgs {
  employeeId: Scalars['Uuid'];
  pfAccount?: Maybe<PfBenefitVmInput>;
};


export class EmployeesMutationTypeAddUpdateEmployeeEobiBenefitArgs {
  employeeId: Scalars['Uuid'];
  eobiAccount?: Maybe<EobiBenefitVmInput>;
};

export class InstitutesMutationType {
  __typename?: 'InstitutesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class InstitutesMutationTypeAddArgs {
  model?: Maybe<CreateInstituteInput>;
};


export class InstitutesMutationTypeUpdateArgs {
  model?: Maybe<UpdateInstituteInput>;
};


export class InstitutesMutationTypeArchiveArgs {
  instituteId: Scalars['Uuid'];
};


export class InstitutesMutationTypeRestoreArgs {
  instituteId: Scalars['Uuid'];
};

export class InterviewCategoriesMutationType {
  __typename?: 'InterviewCategoriesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class InterviewCategoriesMutationTypeAddArgs {
  model?: Maybe<CreateInterviewCategoryInput>;
};


export class InterviewCategoriesMutationTypeUpdateArgs {
  model?: Maybe<UpdateInterviewCategoryInput>;
};


export class InterviewCategoriesMutationTypeArchiveArgs {
  interviewCategoryId: Scalars['Uuid'];
};


export class InterviewCategoriesMutationTypeRestoreArgs {
  interviewCategoryId: Scalars['Uuid'];
};

export class InterviewCriteriaMutationType {
  __typename?: 'InterviewCriteriaMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class InterviewCriteriaMutationTypeAddArgs {
  model?: Maybe<CreateInterviewCriterionInput>;
};


export class InterviewCriteriaMutationTypeUpdateArgs {
  model?: Maybe<UpdateInterviewCriterionInput>;
};


export class InterviewCriteriaMutationTypeArchiveArgs {
  interviewCriterionId: Scalars['Uuid'];
};


export class InterviewCriteriaMutationTypeRestoreArgs {
  interviewCriterionId: Scalars['Uuid'];
};

export class JobFunctionsMutationType {
  __typename?: 'JobFunctionsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class JobFunctionsMutationTypeAddArgs {
  model?: Maybe<CreateJobFunctionInput>;
};


export class JobFunctionsMutationTypeUpdateArgs {
  model?: Maybe<UpdateJobFunctionInput>;
};


export class JobFunctionsMutationTypeArchiveArgs {
  jobFunctionId: Scalars['Uuid'];
};


export class JobFunctionsMutationTypeRestoreArgs {
  jobFunctionId: Scalars['Uuid'];
};

export class JobPositionsMutationType {
  __typename?: 'JobPositionsMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  approve?: Maybe<Scalars['String']>;
  reject?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['String']>;
  updateInterviewers?: Maybe<Scalars['String']>;
};


export class JobPositionsMutationTypeAddArgs {
  model?: Maybe<CreateJobPositionInput>;
};


export class JobPositionsMutationTypeUpdateArgs {
  model?: Maybe<UpdateJobPositionInput>;
};


export class JobPositionsMutationTypeApproveArgs {
  model?: Maybe<PostJobPositionInput>;
};


export class JobPositionsMutationTypeRejectArgs {
  jobPositionId: Scalars['Uuid'];
  comment?: Maybe<Scalars['String']>;
};


export class JobPositionsMutationTypeCloseArgs {
  jobPositionId: Scalars['Uuid'];
  comment?: Maybe<Scalars['String']>;
};


export class JobPositionsMutationTypeUpdateInterviewersArgs {
  model?: Maybe<UpdateInterviewerInput>;
};

export class JobTitlesMutationType {
  __typename?: 'JobTitlesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class JobTitlesMutationTypeAddArgs {
  model?: Maybe<CreateJobTitleInput>;
};


export class JobTitlesMutationTypeUpdateArgs {
  model?: Maybe<UpdateJobTitleInput>;
};


export class JobTitlesMutationTypeArchiveArgs {
  jobTitleId: Scalars['Uuid'];
};


export class JobTitlesMutationTypeRestoreArgs {
  jobTitleId: Scalars['Uuid'];
};

export class LeavesMutationType {
  __typename?: 'LeavesMutationType';
  recall?: Maybe<Scalars['String']>;
  add?: Maybe<Scalars['String']>;
  addUserLeave?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
};


export class LeavesMutationTypeRecallArgs {
  id: Scalars['Uuid'];
};


export class LeavesMutationTypeAddArgs {
  model?: Maybe<CreateLeaveInput>;
};


export class LeavesMutationTypeAddUserLeaveArgs {
  model?: Maybe<CreateLeaveInput>;
  userId: Scalars['Uuid'];
};


export class LeavesMutationTypeUpdateArgs {
  model?: Maybe<UpdateLeaveInput>;
};

export class LeaveTypesMutationType {
  __typename?: 'LeaveTypesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class LeaveTypesMutationTypeAddArgs {
  model?: Maybe<CreateLeaveTypeInput>;
  updateExisting: Scalars['Boolean'];
};


export class LeaveTypesMutationTypeUpdateArgs {
  model?: Maybe<UpdateLeaveTypeInput>;
  updateExisting: Scalars['Boolean'];
};


export class LeaveTypesMutationTypeArchiveArgs {
  leaveTypeId: Scalars['Uuid'];
  updateExisting: Scalars['Boolean'];
};


export class LeaveTypesMutationTypeRestoreArgs {
  leaveTypeId: Scalars['Uuid'];
  updateExisting: Scalars['Boolean'];
};

export class LoanTypesMutationType {
  __typename?: 'LoanTypesMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class LoanTypesMutationTypeAddArgs {
  model?: Maybe<CreateLoanTypeInput>;
};


export class LoanTypesMutationTypeUpdateArgs {
  model?: Maybe<UpdateLoanTypeInput>;
};


export class LoanTypesMutationTypeArchiveArgs {
  loanTypeId: Scalars['Uuid'];
};


export class LoanTypesMutationTypeRestoreArgs {
  loanTypeId: Scalars['Uuid'];
};

export class LoansMutationType {
  __typename?: 'LoansMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
};


export class LoansMutationTypeAddArgs {
  model?: Maybe<CreateLoanInput>;
};


export class LoansMutationTypeUpdateArgs {
  model?: Maybe<UpdateLoanInput>;
};

export class MedicalClaimsMutationType {
  __typename?: 'MedicalClaimsMutationType';
  update?: Maybe<Scalars['String']>;
};


export class MedicalClaimsMutationTypeUpdateArgs {
  model?: Maybe<UpdateMedicalClaimInput>;
};

export class ReasonTypesMutationType {
  __typename?: 'ReasonTypesMutationType';
  addTerminationReasonType?: Maybe<Scalars['String']>;
  addHiringReasonType?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class ReasonTypesMutationTypeAddTerminationReasonTypeArgs {
  model?: Maybe<CreateReasonTypeInput>;
};


export class ReasonTypesMutationTypeAddHiringReasonTypeArgs {
  model?: Maybe<CreateReasonTypeInput>;
};


export class ReasonTypesMutationTypeUpdateArgs {
  model?: Maybe<UpdateReasonTypeInput>;
};


export class ReasonTypesMutationTypeArchiveArgs {
  reasonId: Scalars['Uuid'];
};


export class ReasonTypesMutationTypeRestoreArgs {
  reasonId: Scalars['Uuid'];
};

export class YearlyQuotasMutationType {
  __typename?: 'YearlyQuotasMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
};


export class YearlyQuotasMutationTypeAddArgs {
  model?: Maybe<CreateYearlyQuotaInput>;
};


export class YearlyQuotasMutationTypeUpdateArgs {
  model?: Maybe<UpdateYearlyQuotaInput>;
};

export class HolidayCalanderMutationType {
  __typename?: 'HolidayCalanderMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class HolidayCalanderMutationTypeAddArgs {
  model?: Maybe<CreateHolidayCalanderInput>;
};


export class HolidayCalanderMutationTypeUpdateArgs {
  model?: Maybe<UpdateHolidayCalanderInput>;
};


export class HolidayCalanderMutationTypeArchiveArgs {
  calanderId: Scalars['Uuid'];
};


export class HolidayCalanderMutationTypeRestoreArgs {
  calanderId: Scalars['Uuid'];
};

export class ReimbursementMutationType {
  __typename?: 'ReimbursementMutationType';
  update?: Maybe<Scalars['String']>;
};


export class ReimbursementMutationTypeUpdateArgs {
  model?: Maybe<UpdateReimbursementInput>;
};

export class FinancialYearsMutationType {
  __typename?: 'FinancialYearsMutationType';
  createFinancialYear?: Maybe<Scalars['String']>;
  updateFinancialYear?: Maybe<Scalars['String']>;
};


export class FinancialYearsMutationTypeCreateFinancialYearArgs {
  model?: Maybe<CreateFinancialYearInput>;
};


export class FinancialYearsMutationTypeUpdateFinancialYearArgs {
  model?: Maybe<UpdateFinancialYearInput>;
};

export class TaxMutationType {
  __typename?: 'TaxMutationType';
  createTax?: Maybe<Scalars['String']>;
  updateTax?: Maybe<Scalars['String']>;
};


export class TaxMutationTypeCreateTaxArgs {
  model?: Maybe<AddTaxInput>;
};


export class TaxMutationTypeUpdateTaxArgs {
  model?: Maybe<UpdateTaxInput>;
};

export class ThirdLevelAccountsMutationType {
  __typename?: 'ThirdLevelAccountsMutationType';
  addThirdLevelAccount?: Maybe<Scalars['String']>;
  updateThirdLevelAccount?: Maybe<Scalars['String']>;
  addUpdateBusinessPFAccount?: Maybe<Scalars['String']>;
  addUpdateBusinessEOBIAccount?: Maybe<Scalars['String']>;
  addUpdateBusinessFBRAccount?: Maybe<Scalars['String']>;
  updateAccountRole?: Maybe<Scalars['String']>;
};


export class ThirdLevelAccountsMutationTypeAddThirdLevelAccountArgs {
  model?: Maybe<CreateThirdLevelAccountInput>;
};


export class ThirdLevelAccountsMutationTypeUpdateThirdLevelAccountArgs {
  model?: Maybe<UpdateThirdLevelAccountInput>;
};


export class ThirdLevelAccountsMutationTypeAddUpdateBusinessPfAccountArgs {
  pfAccount?: Maybe<PfAccountVmInput>;
};


export class ThirdLevelAccountsMutationTypeAddUpdateBusinessEobiAccountArgs {
  eobiAccount?: Maybe<EobiAccountVmInput>;
};


export class ThirdLevelAccountsMutationTypeAddUpdateBusinessFbrAccountArgs {
  eobiAccount?: Maybe<FbrAccountVmInput>;
};


export class ThirdLevelAccountsMutationTypeUpdateAccountRoleArgs {
  model?: Maybe<UpdateThirdLevelAccountRoleInput>;
};

export class ProductsMutationType {
  __typename?: 'ProductsMutationType';
  createProduct?: Maybe<Scalars['String']>;
  updateProduct?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class ProductsMutationTypeCreateProductArgs {
  model?: Maybe<CreateProductInput>;
};


export class ProductsMutationTypeUpdateProductArgs {
  model?: Maybe<UpdateProductInput>;
};


export class ProductsMutationTypeArchiveArgs {
  productId: Scalars['Uuid'];
};


export class ProductsMutationTypeRestoreArgs {
  productId: Scalars['Uuid'];
};

export class ContactsMutationType {
  __typename?: 'ContactsMutationType';
  createContact?: Maybe<Scalars['String']>;
  updateContact?: Maybe<Scalars['String']>;
};


export class ContactsMutationTypeCreateContactArgs {
  model?: Maybe<CreateContactInput>;
};


export class ContactsMutationTypeUpdateContactArgs {
  model?: Maybe<UpdateContactInput>;
};

export class InvoicesMutationType {
  __typename?: 'InvoicesMutationType';
  draftPurchaseOrder?: Maybe<Scalars['String']>;
  approvePurchaseOrder?: Maybe<Scalars['String']>;
  updateInvoiceConfiguration?: Maybe<Scalars['String']>;
};


export class InvoicesMutationTypeDraftPurchaseOrderArgs {
  purchaseOrderId: Scalars['Uuid'];
};


export class InvoicesMutationTypeApprovePurchaseOrderArgs {
  purchaseOrderId: Scalars['Uuid'];
};


export class InvoicesMutationTypeUpdateInvoiceConfigurationArgs {
  model?: Maybe<InvoiceConfigInput>;
};

export class BusLookupMutationType {
  __typename?: 'BusLookupMutationType';
  add?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class BusLookupMutationTypeAddArgs {
  model?: Maybe<CreateBusLookupInput>;
};


export class BusLookupMutationTypeUpdateArgs {
  model?: Maybe<UpdateBusLookupInput>;
};


export class BusLookupMutationTypeArchiveArgs {
  lookupId: Scalars['Uuid'];
};


export class BusLookupMutationTypeRestoreArgs {
  lookupId: Scalars['Uuid'];
};

export class NotificationMutationType {
  __typename?: 'NotificationMutationType';
  markAsRead?: Maybe<Scalars['String']>;
  markAllAsRead?: Maybe<Scalars['String']>;
};


export class NotificationMutationTypeMarkAsReadArgs {
  id: Scalars['Uuid'];
};


export class NotificationMutationTypeMarkAllAsReadArgs {
  ids?: Maybe<Array<Scalars['Uuid']>>;
};

export class AttendanceNotificationVmInput {
  enable: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum WeekDays {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export class HrConfigurationVmInput {
  loansEntitlement: Scalars['Float'];
  medicalClaimEntitlement: Scalars['Float'];
};

export class AttendanceRecordMutationType {
  __typename?: 'AttendanceRecordMutationType';
  addRecords?: Maybe<Scalars['String']>;
  editRecords?: Maybe<Scalars['String']>;
  removeRecords?: Maybe<Scalars['String']>;
  transformWholeData?: Maybe<Scalars['String']>;
  removeAttendanceRecords?: Maybe<Scalars['String']>;
  syncAttendanceRecordsFromScratch?: Maybe<Scalars['String']>;
};


export class AttendanceRecordMutationTypeAddRecordsArgs {
  model?: Maybe<Array<Maybe<AttendanceRecordVmInput>>>;
};


export class AttendanceRecordMutationTypeEditRecordsArgs {
  model?: Maybe<Array<Maybe<EditAttendanceRecordInput>>>;
};


export class AttendanceRecordMutationTypeRemoveRecordsArgs {
  ids?: Maybe<Array<Scalars['Uuid']>>;
};

export class AttendanceConfigurationMutationType {
  __typename?: 'AttendanceConfigurationMutationType';
  updateUserConfig?: Maybe<Scalars['String']>;
  disableUserConfig?: Maybe<Scalars['String']>;
  updateBusinessAgent?: Maybe<Scalars['String']>;
  disableBusinessAgent?: Maybe<Scalars['String']>;
  removeAttendanceRecords?: Maybe<Scalars['String']>;
  sendReport?: Maybe<Scalars['String']>;
};


export class AttendanceConfigurationMutationTypeUpdateUserConfigArgs {
  model?: Maybe<Array<Maybe<UserAttendanceConfigVmInput>>>;
};


export class AttendanceConfigurationMutationTypeDisableUserConfigArgs {
  userId: Scalars['Uuid'];
};


export class AttendanceConfigurationMutationTypeUpdateBusinessAgentArgs {
  model?: Maybe<UpdateAttendanceConfigurationInput>;
};


export class AttendanceConfigurationMutationTypeSendReportArgs {
  dateString?: Maybe<Scalars['String']>;
};

export class SystemAccountGroup {
  __typename?: 'SystemAccountGroup';
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  systemAccounts: Array<SystemAccount>;
};

export class AttendanceNotification {
  __typename?: 'AttendanceNotification';
  enable: Scalars['Boolean'];
  time: Scalars['String'];
  emails: Array<Scalars['String']>;
};

export class HrConfiguration {
  __typename?: 'HrConfiguration';
  loansEntitlement: Scalars['Float'];
  medicalClaimEntitlement: Scalars['Float'];
};

export enum RecordType {
  In = 'IN',
  Out = 'OUT',
  Break = 'BREAK'
}

export enum VoucherLineTypes {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export class ClientCollectionSegment {
  __typename?: 'ClientCollectionSegment';
  items?: Maybe<Array<Maybe<Client>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class GenericTaskSortInput {
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class GenericTaskCollectionSegment {
  __typename?: 'GenericTaskCollectionSegment';
  items?: Maybe<Array<Maybe<GenericTask>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ProjectTaskSortInput {
  projectId?: Maybe<SortEnumType>;
  expectedEffort?: Maybe<SortEnumType>;
  project?: Maybe<ProjectSortInput>;
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ProjectTaskCollectionSegment {
  __typename?: 'ProjectTaskCollectionSegment';
  items?: Maybe<Array<Maybe<ProjectTask>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class SubTaskSortInput {
  projectId?: Maybe<SortEnumType>;
  expectedEffort?: Maybe<SortEnumType>;
  parentTaskId?: Maybe<SortEnumType>;
  parentTask?: Maybe<ProjectTaskSortInput>;
  project?: Maybe<ProjectSortInput>;
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TimeLogSortInput {
  userId?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  projectId?: Maybe<SortEnumType>;
  subTaskId?: Maybe<SortEnumType>;
  genericTaskId?: Maybe<SortEnumType>;
  timeSpan?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  project?: Maybe<ProjectSortInput>;
  task?: Maybe<ProjectTaskSortInput>;
  subTask?: Maybe<SubTaskSortInput>;
  genericTask?: Maybe<GenericTaskSortInput>;
  user?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AllowanceCategorySortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AllowanceCategoryCollectionSegment {
  __typename?: 'AllowanceCategoryCollectionSegment';
  items?: Maybe<Array<Maybe<AllowanceCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class AllowanceTypeSortInput {
  name?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  ratio?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  category?: Maybe<AllowanceCategorySortInput>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AllowanceTypeCollectionSegment {
  __typename?: 'AllowanceTypeCollectionSegment';
  items?: Maybe<Array<Maybe<AllowanceType>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class BonusCategorySortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class BonusCategoryCollectionSegment {
  __typename?: 'BonusCategoryCollectionSegment';
  items?: Maybe<Array<Maybe<BonusCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class BonusTypeSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  percentage?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  category?: Maybe<BonusCategorySortInput>;
  id?: Maybe<SortEnumType>;
};

export class BonusTypeCollectionSegment {
  __typename?: 'BonusTypeCollectionSegment';
  items?: Maybe<Array<Maybe<BonusType>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class CandidateCollectionSegment {
  __typename?: 'CandidateCollectionSegment';
  items?: Maybe<Array<Maybe<Candidate>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class CertificationSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class CertificationCollectionSegment {
  __typename?: 'CertificationCollectionSegment';
  items?: Maybe<Array<Maybe<Certification>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class DepartmentSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class DepartmentCollectionSegment {
  __typename?: 'DepartmentCollectionSegment';
  items?: Maybe<Array<Maybe<Department>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class EmployeeCollectionSegment {
  __typename?: 'EmployeeCollectionSegment';
  items?: Maybe<Array<Maybe<Employee>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class HrLookupCollectionSegment {
  __typename?: 'HrLookupCollectionSegment';
  items?: Maybe<Array<Maybe<HrLookup>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class InstituteSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class InstituteCollectionSegment {
  __typename?: 'InstituteCollectionSegment';
  items?: Maybe<Array<Maybe<Institute>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class InterviewCategorySortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class InterviewCategoryCollectionSegment {
  __typename?: 'InterviewCategoryCollectionSegment';
  items?: Maybe<Array<Maybe<InterviewCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class InterviewCriterionSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  score?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  category?: Maybe<InterviewCategorySortInput>;
  id?: Maybe<SortEnumType>;
};

export class InterviewCriterionCollectionSegment {
  __typename?: 'InterviewCriterionCollectionSegment';
  items?: Maybe<Array<Maybe<InterviewCriterion>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class InterviewerCollectionSegment {
  __typename?: 'InterviewerCollectionSegment';
  items?: Maybe<Array<Maybe<Interviewer>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class JobFunctionSortInput {
  code?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class JobFunctionCollectionSegment {
  __typename?: 'JobFunctionCollectionSegment';
  items?: Maybe<Array<Maybe<JobFunction>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class JobPositionSortInput {
  businessId?: Maybe<SortEnumType>;
  departmentId?: Maybe<SortEnumType>;
  jobTitleId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  positions?: Maybe<SortEnumType>;
  comment?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  careerLevel?: Maybe<SortEnumType>;
  employmentType?: Maybe<SortEnumType>;
  educationLevel?: Maybe<SortEnumType>;
  experience?: Maybe<SortEnumType>;
  monthlySalary?: Maybe<SortEnumType>;
  companyName?: Maybe<SortEnumType>;
  companySize?: Maybe<SortEnumType>;
  postDate?: Maybe<SortEnumType>;
  expiryDate?: Maybe<SortEnumType>;
  isCvRequired?: Maybe<SortEnumType>;
  performanceBonus?: Maybe<SortEnumType>;
  medical?: Maybe<SortEnumType>;
  childrenEducation?: Maybe<SortEnumType>;
  providentFund?: Maybe<SortEnumType>;
  knowledgeSharing?: Maybe<SortEnumType>;
  competitiveSalary?: Maybe<SortEnumType>;
  otherBenefit?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  department?: Maybe<HrLookupSortInput>;
  jobTitle?: Maybe<HrLookupSortInput>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class JobPositionCollectionSegment {
  __typename?: 'JobPositionCollectionSegment';
  items?: Maybe<Array<Maybe<JobPosition>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class JobTitleSortInput {
  code?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class JobTitleCollectionSegment {
  __typename?: 'JobTitleCollectionSegment';
  items?: Maybe<Array<Maybe<JobTitle>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class LeaveSortInput {
  leaveTypeId?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  reason?: Maybe<SortEnumType>;
  remarks?: Maybe<SortEnumType>;
  fromDate?: Maybe<SortEnumType>;
  toDate?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  user?: Maybe<UserSortInput>;
  leaveType?: Maybe<BusinessLookupSortInput>;
  id?: Maybe<SortEnumType>;
};

export class BusinessLookupCollectionSegment {
  __typename?: 'BusinessLookupCollectionSegment';
  items?: Maybe<Array<Maybe<BusinessLookup>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class LoanTypeSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class LoanTypeCollectionSegment {
  __typename?: 'LoanTypeCollectionSegment';
  items?: Maybe<Array<Maybe<LoanType>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ReasonTypeSortInput {
  name?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  code?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ReasonTypeCollectionSegment {
  __typename?: 'ReasonTypeCollectionSegment';
  items?: Maybe<Array<Maybe<ReasonType>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class SalarySortInput {
  userId?: Maybe<SortEnumType>;
  startDate?: Maybe<SortEnumType>;
  endDate?: Maybe<SortEnumType>;
  basicSalary?: Maybe<SortEnumType>;
  netSalary?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  createdBy?: Maybe<UserSortInput>;
  user?: Maybe<UserSortInput>;
  id?: Maybe<SortEnumType>;
};

export class CashVoucherSortInput {
  businessId?: Maybe<SortEnumType>;
  voucherTypeId?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  accountId?: Maybe<SortEnumType>;
  systemAccountId?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  systemAccount?: Maybe<ThirdLevelAccountSortInput>;
  account?: Maybe<ThirdLevelAccountSortInput>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class CashVoucherCollectionSegment {
  __typename?: 'CashVoucherCollectionSegment';
  items?: Maybe<Array<Maybe<CashVoucher>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ContactSortInput {
  businessId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  phone?: Maybe<SortEnumType>;
  addressId?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  address?: Maybe<AddressSortInput>;
  id?: Maybe<SortEnumType>;
};

export class ContactCollectionSegment {
  __typename?: 'ContactCollectionSegment';
  items?: Maybe<Array<Maybe<Contact>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class InvoiceSortInput {
  businessId?: Maybe<SortEnumType>;
  voucherTypeId?: Maybe<SortEnumType>;
  accountId?: Maybe<SortEnumType>;
  systemAccountId?: Maybe<SortEnumType>;
  discountSystemAccountId?: Maybe<SortEnumType>;
  commissionAccountId?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  deliveryAddressId?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  dueDate?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  deliveryAddress?: Maybe<AddressSortInput>;
  account?: Maybe<ThirdLevelAccountSortInput>;
  systemAccount?: Maybe<ThirdLevelAccountSortInput>;
  discountSystemAccount?: Maybe<ThirdLevelAccountSortInput>;
  commissionAccount?: Maybe<ThirdLevelAccountSortInput>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class InvoiceCollectionSegment {
  __typename?: 'InvoiceCollectionSegment';
  items?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ProductCollectionSegment {
  __typename?: 'ProductCollectionSegment';
  items?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class ThirdLevelAccountCollectionSegment {
  __typename?: 'ThirdLevelAccountCollectionSegment';
  items?: Maybe<Array<Maybe<ThirdLevelAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class VoucherSortInput {
  businessId?: Maybe<SortEnumType>;
  voucherTypeId?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class VoucherCollectionSegment {
  __typename?: 'VoucherCollectionSegment';
  items?: Maybe<Array<Maybe<Voucher>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class BusinessTypeFilterInput {
  and?: Maybe<Array<BusinessTypeFilterInput>>;
  or?: Maybe<Array<BusinessTypeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  category?: Maybe<BusinessCategoriesOperationFilterInput>;
  id?: Maybe<ComparableByteOperationFilterInput>;
};

export class CountryFilterInput {
  and?: Maybe<Array<CountryFilterInput>>;
  or?: Maybe<Array<CountryFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  isoCode?: Maybe<StringOperationFilterInput>;
  currencyString?: Maybe<StringOperationFilterInput>;
  financialYearStartDate?: Maybe<StringOperationFilterInput>;
  financialYearEndDate?: Maybe<StringOperationFilterInput>;
  callingCodes?: Maybe<ListStringOperationFilterInput>;
  region?: Maybe<StringOperationFilterInput>;
  subRegion?: Maybe<StringOperationFilterInput>;
  timezones?: Maybe<ListStringOperationFilterInput>;
  numericCode?: Maybe<StringOperationFilterInput>;
  languages?: Maybe<ListStringOperationFilterInput>;
  flag?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableByteOperationFilterInput>;
};

export class CountrySortInput {
  name?: Maybe<SortEnumType>;
  isoCode?: Maybe<SortEnumType>;
  currencyString?: Maybe<SortEnumType>;
  financialYearStartDate?: Maybe<SortEnumType>;
  financialYearEndDate?: Maybe<SortEnumType>;
  region?: Maybe<SortEnumType>;
  subRegion?: Maybe<SortEnumType>;
  numericCode?: Maybe<SortEnumType>;
  flag?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export class UserCollectionSegment {
  __typename?: 'UserCollectionSegment';
  items?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class AttendanceUserConfigurationSortInput {
  userId?: Maybe<SortEnumType>;
  attendanceId?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  shiftId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  useUserConfiguration?: Maybe<SortEnumType>;
  openingTime?: Maybe<SortEnumType>;
  closingTime?: Maybe<SortEnumType>;
  workingDays?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  user?: Maybe<UserSortInput>;
  businessShift?: Maybe<BusinessShiftSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AttendanceUserConfigurationCollectionSegment {
  __typename?: 'AttendanceUserConfigurationCollectionSegment';
  items?: Maybe<Array<Maybe<AttendanceUserConfiguration>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class AppUsageSortInput {
  businessId?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  userNetwork?: Maybe<SortEnumType>;
  appId?: Maybe<SortEnumType>;
  username?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  machine?: Maybe<SortEnumType>;
  iPAddress?: Maybe<SortEnumType>;
  usageBreakDown?: Maybe<SortEnumType>;
  hour?: Maybe<SortEnumType>;
  usageMinutes?: Maybe<SortEnumType>;
  idleMinutes?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  user?: Maybe<UserSortInput>;
  app?: Maybe<AppSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AppUsageCollectionSegment {
  __typename?: 'AppUsageCollectionSegment';
  items?: Maybe<Array<Maybe<AppUsage>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class MachineVmFilterInput {
  and?: Maybe<Array<MachineVmFilterInput>>;
  or?: Maybe<Array<MachineVmFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
};

export class SnapshotCollectionSegment {
  __typename?: 'SnapshotCollectionSegment';
  items?: Maybe<Array<Maybe<Snapshot>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class MachineVmCollectionSegment {
  __typename?: 'MachineVmCollectionSegment';
  items?: Maybe<Array<Maybe<MachineVm>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class AppSortInput {
  name?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class AppCollectionSegment {
  __typename?: 'AppCollectionSegment';
  items?: Maybe<Array<Maybe<App>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class AddressSortInput {
  businessId?: Maybe<SortEnumType>;
  zipCode?: Maybe<SortEnumType>;
  addressLine1?: Maybe<SortEnumType>;
  addressLine2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  timeZone?: Maybe<SortEnumType>;
  business?: Maybe<BusinessSortInput>;
  id?: Maybe<SortEnumType>;
};

export class BusinessCategoriesOperationFilterInput {
  eq?: Maybe<BusinessCategories>;
  neq?: Maybe<BusinessCategories>;
  in?: Maybe<Array<BusinessCategories>>;
  nin?: Maybe<Array<BusinessCategories>>;
};

export class ListStringOperationFilterInput {
  all?: Maybe<StringOperationFilterInput>;
  none?: Maybe<StringOperationFilterInput>;
  some?: Maybe<StringOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class BusinessShiftSortInput {
  state?: Maybe<SortEnumType>;
  openingTime?: Maybe<SortEnumType>;
  closingTime?: Maybe<SortEnumType>;
  attendanceConfigurationId?: Maybe<SortEnumType>;
  attendanceConfiguration?: Maybe<AttendanceConfigurationSortInput>;
  id?: Maybe<SortEnumType>;
};

export class MachineVm {
  __typename?: 'MachineVm';
  name?: Maybe<Scalars['String']>;
};

export class UpdateAttendanceConfigurationInput {
  id: Scalars['Uuid'];
  syncInterval: Scalars['Long'];
  firstShift?: Maybe<FirstShiftVmInput>;
  secondShift?: Maybe<SecondShiftVmInput>;
};

export class UserAttendanceConfigVmInput {
  id: Scalars['Uuid'];
  userId?: Maybe<Scalars['Uuid']>;
  attendanceId?: Maybe<Scalars['Int']>;
  businessId: Scalars['Uuid'];
  shiftId?: Maybe<Scalars['Uuid']>;
  state: States;
  useUserConfiguration: Scalars['Boolean'];
  openingTime?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['String']>;
  weekDays: Array<WeekDays>;
};

export class EditAttendanceRecordInput {
  id?: Maybe<Scalars['Uuid']>;
  attendanceId: Scalars['Int'];
  attendanceUserConfigurationId: Scalars['Uuid'];
  inDateTime?: Maybe<Scalars['String']>;
  outDateTime?: Maybe<Scalars['String']>;
};

export class AttendanceRecordVmInput {
  userId: Scalars['Int'];
  dateTime: Scalars['String'];
  backupCode: Scalars['Byte'];
  recordType: RecordType;
  workType: Scalars['Int'];
};

export class UpdateBusLookupInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  parentId?: Maybe<Scalars['Uuid']>;
  state: States;
  lookupSetting?: Maybe<Scalars['String']>;
  isSystemDefined: Scalars['Boolean'];
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupCategory?: Maybe<Scalars['String']>;
};

export class CreateBusLookupInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  parentId?: Maybe<Scalars['Uuid']>;
  state: States;
  lookupSetting?: Maybe<Scalars['String']>;
  isSystemDefined: Scalars['Boolean'];
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupCategory?: Maybe<Scalars['String']>;
};

export class InvoiceConfigInput {
  businessId: Scalars['Uuid'];
  showPurchaseOrder: Scalars['Boolean'];
  showPurchaseInvoice: Scalars['Boolean'];
  showSaleInvoice: Scalars['Boolean'];
  showQuotation: Scalars['Boolean'];
};

export class UpdateContactInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address?: Maybe<AddressVmInput>;
};

export class CreateContactInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address?: Maybe<AddressVmInput>;
};

export class UpdateProductInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isCatchWeightProduct: Scalars['Boolean'];
  price?: Maybe<Scalars['Decimal']>;
  productType: ProductTypes;
  purchaseUnit?: Maybe<ProductUnits>;
  saleUnit?: Maybe<ProductUnits>;
  inventoryUnit?: Maybe<ProductUnits>;
  incomeAccountId: Scalars['Uuid'];
  expenseAccountId: Scalars['Uuid'];
  number?: Maybe<Scalars['String']>;
  upcNumber?: Maybe<Scalars['String']>;
  taxIds?: Maybe<Array<Scalars['Uuid']>>;
};

export class CreateProductInput {
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isCatchWeightProduct: Scalars['Boolean'];
  price?: Maybe<Scalars['Decimal']>;
  productType: ProductTypes;
  purchaseUnit?: Maybe<ProductUnits>;
  saleUnit?: Maybe<ProductUnits>;
  inventoryUnit?: Maybe<ProductUnits>;
  incomeAccountId: Scalars['Uuid'];
  expenseAccountId: Scalars['Uuid'];
  number?: Maybe<Scalars['String']>;
  upcNumber?: Maybe<Scalars['String']>;
  taxIds?: Maybe<Array<Scalars['Uuid']>>;
};

export class UpdateThirdLevelAccountRoleInput {
  id: Scalars['Uuid'];
  roleNumber: Scalars['Long'];
  businessId: Scalars['Uuid'];
};

export class FbrAccountVmInput {
  code?: Maybe<Scalars['String']>;
};

export class EobiAccountVmInput {
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
};

export class PfAccountVmInput {
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
};

export class UpdateThirdLevelAccountInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  secondLevelAccountId: Scalars['Uuid'];
  name: Scalars['String'];
  description: Scalars['String'];
  roleNumber: Scalars['Long'];
  sortOrder: Scalars['Int'];
  businessContactId?: Maybe<Scalars['Uuid']>;
  billingContactId?: Maybe<Scalars['Uuid']>;
  deliveryContactId?: Maybe<Scalars['Uuid']>;
  businessAddress?: Maybe<AddressVmInput>;
  billingAddress?: Maybe<AddressVmInput>;
  deliveryAddress?: Maybe<AddressVmInput>;
};

export class CreateThirdLevelAccountInput {
  businessId: Scalars['Uuid'];
  secondLevelAccountId: Scalars['Uuid'];
  name: Scalars['String'];
  description: Scalars['String'];
  roleNumber: Scalars['Long'];
  sortOrder: Scalars['Int'];
  businessContactId?: Maybe<Scalars['Uuid']>;
  billingContactId?: Maybe<Scalars['Uuid']>;
  deliveryContactId?: Maybe<Scalars['Uuid']>;
  businessAddress?: Maybe<AddressVmInput>;
  billingAddress?: Maybe<AddressVmInput>;
  deliveryAddress?: Maybe<AddressVmInput>;
};

export class UpdateTaxInput {
  id: Scalars['Uuid'];
  isCompoundTax: Scalars['Boolean'];
  showTaxOnInvoices: Scalars['Boolean'];
  isTaxRecoverable: Scalars['Boolean'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  rate: Scalars['Decimal'];
};

export class AddTaxInput {
  isCompoundTax: Scalars['Boolean'];
  showTaxOnInvoices: Scalars['Boolean'];
  isTaxRecoverable: Scalars['Boolean'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  rate: Scalars['Decimal'];
};

export class UpdateFinancialYearInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
};

export class CreateFinancialYearInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
};

export class UpdateReimbursementInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  state: States;
  reimbursementTypeId: Scalars['Uuid'];
  amount: Scalars['Float'];
  amountApproved: Scalars['Float'];
  employee?: Maybe<EmployeeVmInput>;
  reimbursementDetails?: Maybe<Array<Maybe<ReimbursementDetailVmInput>>>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateHolidayCalanderInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  eventName: Scalars['String'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};

export class CreateHolidayCalanderInput {
  businessId: Scalars['Uuid'];
  eventName: Scalars['String'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};

export class UpdateYearlyQuotaInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  lookupId: Scalars['Uuid'];
  assignedQuota: Scalars['Float'];
  year: Scalars['String'];
};

export class CreateYearlyQuotaInput {
  businessId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  lookupId: Scalars['Uuid'];
  assignedQuota: Scalars['Float'];
  year: Scalars['String'];
  id: Scalars['Uuid'];
};

export class UpdateReasonTypeInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
  type: ReasonTypes;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateReasonTypeInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
  type: ReasonTypes;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateMedicalClaimInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  state: States;
  claimLookupId: Scalars['Uuid'];
  amount: Scalars['Float'];
  amountApproved: Scalars['Float'];
  balanceCf: Scalars['Float'];
  employee?: Maybe<EmployeeVmInput>;
  medicalClaimBreakdowns?: Maybe<Array<Maybe<MedicalClaimBreakdownVmInput>>>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateLoanInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  updatedBy: Scalars['Uuid'];
  state: States;
  purposeOfLoan?: Maybe<Scalars['String']>;
  amountApplied: Scalars['Float'];
  amountApproved: Scalars['Float'];
  numberOfInstallments: Scalars['Byte'];
  remarks?: Maybe<Scalars['String']>;
  loanTypeId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateLoanInput {
  loanTypeId: Scalars['Uuid'];
  purposeOfLoan: Scalars['String'];
  amountApplied: Scalars['Float'];
  numberOfInstallments: Scalars['Byte'];
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  amountApproved: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateLoanTypeInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateLoanTypeInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateLeaveTypeInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  limit: Scalars['Float'];
  state: States;
  weight: Scalars['Float'];
  isCarryForward: Scalars['Boolean'];
};

export class CreateLeaveTypeInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  limit: Scalars['Float'];
  id: Scalars['Uuid'];
  state: States;
  weight: Scalars['Float'];
  isCarryForward: Scalars['Boolean'];
};

export class UpdateLeaveInput {
  id: Scalars['Uuid'];
  updatedById: Scalars['Uuid'];
  state: States;
  isShortLeave: Scalars['Boolean'];
  leaveTypeId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeVmInput>;
  leaveType?: Maybe<LeaveTypeVmInput>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
};

export class CreateLeaveInput {
  leaveTypeId: Scalars['Uuid'];
  reason: Scalars['String'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  isShortLeave: Scalars['Boolean'];
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  remarks?: Maybe<Scalars['String']>;
  state: States;
  employee?: Maybe<EmployeeVmInput>;
  leaveType?: Maybe<LeaveTypeVmInput>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateJobTitleInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class CreateJobTitleInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class UpdateInterviewerInput {
  jobPositionId: Scalars['Uuid'];
  users: Array<Scalars['Uuid']>;
};

export class PostJobPositionInput {
  id: Scalars['Uuid'];
  careerLevel?: Maybe<CareerLevel>;
  employmentType?: Maybe<EmploymentType>;
  educationLevel?: Maybe<EducationLevel>;
  experience?: Maybe<WorkExperience>;
  monthlySalary?: Maybe<MonthlySalary>;
  companyName?: Maybe<Scalars['String']>;
  companySize: CompanySize;
  postDate?: Maybe<Scalars['DateTime']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  isCvRequired: Scalars['Boolean'];
  performanceBonus: Scalars['Boolean'];
  medical: Scalars['Boolean'];
  childrenEducation: Scalars['Boolean'];
  providentFund: Scalars['Boolean'];
  knowledgeSharing: Scalars['Boolean'];
  competitiveSalary: Scalars['Boolean'];
  otherBenefit?: Maybe<Scalars['String']>;
  departmentId: Scalars['Uuid'];
  jobTitleId: Scalars['Uuid'];
  positions: Scalars['Byte'];
  description?: Maybe<Scalars['String']>;
};

export class UpdateJobPositionInput {
  id: Scalars['Uuid'];
  departmentId: Scalars['Uuid'];
  jobTitleId: Scalars['Uuid'];
  positions: Scalars['Byte'];
  description?: Maybe<Scalars['String']>;
};

export class CreateJobPositionInput {
  departmentId: Scalars['Uuid'];
  jobTitleId: Scalars['Uuid'];
  positions: Scalars['Byte'];
  description?: Maybe<Scalars['String']>;
};

export class UpdateJobFunctionInput {
  id: Scalars['Uuid'];
  createdBy: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
};

export class CreateJobFunctionInput {
  createdBy: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
};

export class UpdateInterviewCriterionInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
  score: Scalars['Decimal'];
  category?: Maybe<InterviewCategoryVmInput>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateInterviewCriterionInput {
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
  score: Scalars['Decimal'];
  category?: Maybe<InterviewCategoryVmInput>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateInterviewCategoryInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
};

export class CreateInterviewCategoryInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
};

export class UpdateInstituteInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateInstituteInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class EobiBenefitVmInput {
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
  enabled: Scalars['Boolean'];
  overrideAmount: Scalars['Decimal'];
  overridePercentage: Scalars['Short'];
};

export class PfBenefitVmInput {
  enabled: Scalars['Boolean'];
  overrideAmount: Scalars['Decimal'];
  overridePercentage: Scalars['Short'];
};

export class CreateSalaryVmInput {
  userId: Scalars['Uuid'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  basicSalary: Scalars['Decimal'];
  netSalary: Scalars['Decimal'];
  allowanceIds?: Maybe<Array<Scalars['Uuid']>>;
  bonusIds?: Maybe<Array<Scalars['Uuid']>>;
  id: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<CreatedByVmInput>;
  employee?: Maybe<EmployeeVmInput>;
  allowances?: Maybe<Array<Maybe<CreateHrLookupInput>>>;
  bonuses?: Maybe<Array<Maybe<CreateHrLookupInput>>>;
};

export class UpdateEmployeeInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  gender: Gender;
  state: States;
  fatherName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  mobilePhone: Scalars['String'];
  departmentId?: Maybe<Scalars['Uuid']>;
  jobTitleId?: Maybe<Scalars['Uuid']>;
  jobFunctionId?: Maybe<Scalars['Uuid']>;
  dateOfAvailability?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  presentAddress: Scalars['String'];
  permanentAddress?: Maybe<Scalars['String']>;
  bloodGroup?: Maybe<Scalars['String']>;
  maritalStatus: MaritalStatus;
  nameOfSpouse?: Maybe<Scalars['String']>;
  drivingLicenceNumber?: Maybe<Scalars['String']>;
  drivingLicenceIssueDate?: Maybe<Scalars['DateTime']>;
  currentSalary?: Maybe<Scalars['Decimal']>;
  expectedSalary?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<Scalars['String']>;
  religion: Scalars['String'];
  cnic: Scalars['String'];
  cnicExpiryDate?: Maybe<Scalars['DateTime']>;
  domicile: Scalars['String'];
  interviewStartDate?: Maybe<Scalars['DateTime']>;
  interviewEndDate?: Maybe<Scalars['DateTime']>;
  children?: Maybe<Array<Maybe<EmployeeChildrenVmInput>>>;
  educations?: Maybe<Array<Maybe<EmployeeEducationVmInput>>>;
  experiences?: Maybe<Array<Maybe<EmployeeExperienceVmInput>>>;
  references?: Maybe<Array<Maybe<EmployeeReferencesVmInput>>>;
  relatives?: Maybe<Array<Maybe<EmployeeRelativesVmInput>>>;
  reportedToId?: Maybe<Scalars['Uuid']>;
  officialEmail?: Maybe<Scalars['String']>;
  pic?: Maybe<Scalars['String']>;
  businessId: Scalars['Uuid'];
  medicalLoanEntitlement: Scalars['Decimal'];
  employeePositions?: Maybe<Array<Maybe<CandidatePositionMapVmInput>>>;
  employmentHistories?: Maybe<Array<Maybe<EmploymentHistoryVmInput>>>;
  loans?: Maybe<Array<Maybe<LoanVmInput>>>;
  leaves?: Maybe<Array<Maybe<LeaveVmInput>>>;
  medicalClaims?: Maybe<Array<Maybe<MedicalClaimVmInput>>>;
  availableBalance: Scalars['Decimal'];
  benefits?: Maybe<UserBenefitsInput>;
  payrollAccountId?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateHrLookupInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  parentId: Scalars['Uuid'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupSetting?: Maybe<Scalars['String']>;
};

export class CreateHrLookupInput {
  businessId: Scalars['Uuid'];
  parentId: Scalars['Uuid'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupSetting?: Maybe<Scalars['String']>;
};

export class UpdateDepartmentInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class CreateDepartmentInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class UpdateCertificationInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class CreateCertificationInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class UpdateCandidateEvaluationInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  strength?: Maybe<Scalars['String']>;
  weakness?: Maybe<Scalars['String']>;
  finalComment?: Maybe<Scalars['String']>;
  scores?: Maybe<Array<Maybe<CandidateScoreVmInput>>>;
};

export class CreateCandidateEvaluationInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  strength?: Maybe<Scalars['String']>;
  weakness?: Maybe<Scalars['String']>;
  finalComment?: Maybe<Scalars['String']>;
  scores?: Maybe<Array<Maybe<CandidateScoreVmInput>>>;
};

export class SendInviteInput {
  message?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export class UpdateBonusTypeInput {
  id: Scalars['Uuid'];
  createdBy: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  percentage: Scalars['Decimal'];
  state: States;
  category?: Maybe<BonusCategoryVmInput>;
};

export class CreateBonusTypeInput {
  createdBy: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  percentage: Scalars['Decimal'];
  id: Scalars['Uuid'];
  state: States;
  category?: Maybe<BonusCategoryVmInput>;
};

export class UpdateBonusCategoryInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateBonusCategoryInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class UpdateAllowanceTypeInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  ratio: Scalars['Decimal'];
  state: States;
  category?: Maybe<AllowanceCategoryVmInput>;
};

export class CreateAllowanceTypeInput {
  businessId: Scalars['Uuid'];
  categoryId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  ratio: Scalars['Decimal'];
  id: Scalars['Uuid'];
  state: States;
  category?: Maybe<AllowanceCategoryVmInput>;
};

export class UpdateAllowanceCategoryInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateAllowanceCategoryInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Uuid'];
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CreateTicketMaterialInput {
  ticketId: Scalars['Uuid'];
  productId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  quantity: Scalars['Decimal'];
  price: Scalars['Decimal'];
  discount: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  taxSettings?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UpdateTicketLaborInput {
  id: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
  hours: Scalars['Decimal'];
  hoursType?: Maybe<Scalars['String']>;
  billable: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  workerId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class CreateTicketLaborInput {
  ticketId: Scalars['Uuid'];
  hours: Scalars['Decimal'];
  hoursType?: Maybe<Scalars['String']>;
  billable: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  workerId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UpdateTicketStatusChangeInput {
  id: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  changeStatusId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  changeOn: Scalars['DateTime'];
  changeById: Scalars['Uuid'];
  assignedUserId: Scalars['Uuid'];
  state: Scalars['Int'];
  statusType: Scalars['Int'];
};

export class CreateTicketStatusChangeInput {
  ticketId: Scalars['Uuid'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  changeStatusId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  changeOn: Scalars['DateTime'];
  changeById: Scalars['Uuid'];
  assignedUserId: Scalars['Uuid'];
  state: Scalars['Int'];
  statusType: Scalars['Int'];
};

export class UpdateTicketsInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  ticketDescription?: Maybe<Scalars['String']>;
  ticketNo?: Maybe<Scalars['String']>;
  siteId: Scalars['Uuid'];
  reportedById: Scalars['Uuid'];
  entryDate: Scalars['DateTime'];
  scheduleDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  completeDate?: Maybe<Scalars['DateTime']>;
  firstResponseDate?: Maybe<Scalars['DateTime']>;
  executionStartDate?: Maybe<Scalars['DateTime']>;
  executionEndDate?: Maybe<Scalars['DateTime']>;
  completionDuration?: Maybe<Scalars['TimeSpan']>;
  problemId?: Maybe<Scalars['Uuid']>;
  priorityId?: Maybe<Scalars['Uuid']>;
  state: States;
  ticketStatusChanges?: Maybe<Array<Maybe<TicketStatusChangeInput>>>;
};

export class CreateTicketsInput {
  businessId: Scalars['Uuid'];
  ticketDescription?: Maybe<Scalars['String']>;
  ticketNo?: Maybe<Scalars['String']>;
  siteId: Scalars['Uuid'];
  reportedById: Scalars['Uuid'];
  entryDate: Scalars['DateTime'];
  scheduleDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  completeDate?: Maybe<Scalars['DateTime']>;
  firstResponseDate?: Maybe<Scalars['DateTime']>;
  executionStartDate?: Maybe<Scalars['DateTime']>;
  executionEndDate?: Maybe<Scalars['DateTime']>;
  completionDuration?: Maybe<Scalars['TimeSpan']>;
  problemId?: Maybe<Scalars['Uuid']>;
  priorityId?: Maybe<Scalars['Uuid']>;
  state: States;
  ticketStatusChanges?: Maybe<Array<Maybe<TicketStatusChangeInput>>>;
};

export class UpdateSiteInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  siteNo: Scalars['String'];
  siteName: Scalars['String'];
  siteManagerId?: Maybe<Scalars['Uuid']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  siteState?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  completeAddress?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Uuid']>;
};

export class CreateSiteInput {
  businessId: Scalars['Uuid'];
  siteNo: Scalars['String'];
  siteName: Scalars['String'];
  siteManagerId?: Maybe<Scalars['Uuid']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  siteState?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  completeAddress?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Uuid']>;
};

export class UpdateTicketCategoryVmInput {
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
};

export class CreateTicketCategoryVmInput {
  name?: Maybe<Scalars['String']>;
};

export class CreateTimeLogInput {
  createdById: Scalars['Uuid'];
  date: Scalars['String'];
  businessId: Scalars['Uuid'];
  timeLogs?: Maybe<Array<Maybe<TimeLogVmInput>>>;
};

export class UpdateSubTaskInput {
  id: Scalars['Uuid'];
  expectedEffort?: Maybe<Scalars['Decimal']>;
  businessId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
  parentTaskId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class CreateSubTaskInput {
  expectedEffort?: Maybe<Scalars['Decimal']>;
  businessId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
  parentTaskId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class UpdateProjectTaskInput {
  id: Scalars['Uuid'];
  expectedEffort?: Maybe<Scalars['Decimal']>;
  businessId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class CreateProjectTaskInput {
  expectedEffort?: Maybe<Scalars['Decimal']>;
  businessId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class AssignProjectUsersInput {
  projectId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  clientId: Scalars['Uuid'];
  userProjectRoles: Array<Maybe<UserProjectRoleVmInput>>;
};

export class UpdateProjectInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  clientId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class CreateProjectInput {
  businessId: Scalars['Uuid'];
  clientId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class UpdateGenericTaskInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class CreateGenericTaskInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export class UpdateClientInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class CreateClientInput {
  businessId: Scalars['Uuid'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export class UpdateAgentConfigurationInput {
  id: Scalars['Uuid'];
  businessId?: Maybe<Scalars['Uuid']>;
  isLogEnable: Scalars['Boolean'];
  isCaptureScreen: Scalars['Boolean'];
  onAppSwitching: Scalars['Boolean'];
  captureScreenInterval: Scalars['Long'];
  updatedById: Scalars['Uuid'];
};

export class UserAgentConfigurationVmInput {
  useUserConfiguration: Scalars['Boolean'];
  isLogEnable: Scalars['Boolean'];
  isCaptureScreen: Scalars['Boolean'];
  onAppSwitching: Scalars['Boolean'];
  captureScreenInterval: Scalars['Long'];
};

export class AppUsageVmInput {
  businessId?: Maybe<Scalars['Uuid']>;
  userId: Scalars['Uuid'];
  machine: Scalars['String'];
  username: Scalars['String'];
  userNetwork: Scalars['String'];
  iPAddress: Scalars['String'];
  appTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  date: Scalars['DateTime'];
  hour: Scalars['Byte'];
  usageMinutes: Scalars['Float'];
  idleMinutes: Scalars['Float'];
  usageBreakDown?: Maybe<Scalars['String']>;
};

export class LiveReportVm {
  __typename?: 'LiveReportVm';
  date: Scalars['DateTime'];
  totalMilliseconds: Scalars['Float'];
};

export class TodayAttendanceReport {
  __typename?: 'TodayAttendanceReport';
  total: Scalars['Long'];
  absent: Scalars['Long'];
  onWeekend: Scalars['Long'];
  onLeave: Scalars['Long'];
  present: Scalars['Long'];
  lateComers: Scalars['Long'];
  onTimeComers: Scalars['Long'];
};

export class YesterdayAttendanceReport {
  __typename?: 'YesterdayAttendanceReport';
  avgCheckin?: Maybe<Scalars['String']>;
  avgCheckout?: Maybe<Scalars['String']>;
  productive: Scalars['Float'];
  effective: Scalars['Float'];
};

export class PfBenefit {
  __typename?: 'PFBenefit';
  enabled: Scalars['Boolean'];
  overrideAmount: Scalars['Decimal'];
  overridePercentage: Scalars['Short'];
};

export class EobiBenefit {
  __typename?: 'EOBIBenefit';
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
  enabled: Scalars['Boolean'];
  overrideAmount: Scalars['Decimal'];
  overridePercentage: Scalars['Short'];
};

export class FbrAccount {
  __typename?: 'FBRAccount';
  code: Scalars['String'];
};

export class PfAccount {
  __typename?: 'PFAccount';
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
};

export class EobiAccount {
  __typename?: 'EOBIAccount';
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
};

export enum VoucherTypes {
  CashPayment = 'CASH__PAYMENT',
  CashReceive = 'CASH__RECEIVE',
  JournalVoucher = 'JOURNAL__VOUCHER',
  PayrollVoucher = 'PAYROLL__VOUCHER',
  SalaryVoucher = 'SALARY__VOUCHER',
  PurchaseOrder = 'PURCHASE__ORDER',
  PurchaseInvoice = 'PURCHASE__INVOICE',
  Quotation = 'QUOTATION',
  SaleInvoice = 'SALE__INVOICE'
}

export class UserMutationType {
  __typename?: 'UserMutationType';
  createUser?: Maybe<Scalars['String']>;
};


export class UserMutationTypeCreateUserArgs {
  model?: Maybe<CreateUserInput>;
};

export class CreateUserInput {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  pic?: Maybe<Scalars['String']>;
};

export class UserProjectRoleVmInput {
  userId: Scalars['Uuid'];
  projectRoleId: Scalars['Byte'];
};

export class TimeLogVmInput {
  id: Scalars['Uuid'];
  genericTaskId?: Maybe<Scalars['Uuid']>;
  projectId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
  subTaskId?: Maybe<Scalars['Uuid']>;
  timeSpan: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export class TicketStatusChangeInput {
  ticketId: Scalars['Uuid'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  changeStatusId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  changeOn: Scalars['DateTime'];
  changeById: Scalars['Uuid'];
  assignedUserId: Scalars['Uuid'];
  state: Scalars['Int'];
  statusType: Scalars['Int'];
};

export class AllowanceCategoryVmInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class BonusCategoryVmInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: States;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class CandidateScoreVmInput {
  id: Scalars['Uuid'];
  candidateEvaluationId: Scalars['Uuid'];
  interviewCriterionId: Scalars['Uuid'];
  interviewCategoryId: Scalars['Uuid'];
  score: Scalars['Byte'];
  remarks?: Maybe<Scalars['String']>;
};

export class UserBenefitsInput {
  eobiBenefit?: Maybe<EobiBenefitInput>;
  pfBenefit?: Maybe<PfBenefitInput>;
};

export class MedicalClaimVmInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  state: States;
  claimLookupId: Scalars['Uuid'];
  amount: Scalars['Float'];
  amountApproved: Scalars['Float'];
  balanceCf: Scalars['Float'];
  employee?: Maybe<EmployeeVmInput>;
  medicalClaimBreakdowns?: Maybe<Array<Maybe<MedicalClaimBreakdownVmInput>>>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class LeaveVmInput {
  id: Scalars['Uuid'];
  state: States;
  leaveTypeId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeVmInput>;
  leaveType?: Maybe<LeaveTypeVmInput>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class LoanVmInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  state: States;
  purposeOfLoan?: Maybe<Scalars['String']>;
  amountApplied: Scalars['Float'];
  amountApproved: Scalars['Float'];
  numberOfInstallments: Scalars['Byte'];
  remarks?: Maybe<Scalars['String']>;
  loanTypeId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class EmploymentHistoryVmInput {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  reasonTypeId: Scalars['Uuid'];
  state: States;
  createdBy?: Maybe<CreatedByVmInput>;
  employee?: Maybe<EmployeeVmInput>;
  reasonType?: Maybe<ReasonTypeVmInput>;
  createdOn: Scalars['DateTime'];
};

export class CandidatePositionMapVmInput {
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  employeeId?: Maybe<Scalars['Uuid']>;
  state: States;
  interviewDateTime?: Maybe<Scalars['DateTime']>;
};

export class EmployeeRelativesVmInput {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  relativeId: Scalars['Uuid'];
  relationship: Scalars['String'];
  createdOn: Scalars['DateTime'];
  createById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class EmployeeReferencesVmInput {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  name: Scalars['String'];
  address: Scalars['String'];
  contactNumber: Scalars['String'];
  createdOn: Scalars['DateTime'];
  createById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class EmployeeExperienceVmInput {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  employer: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  reasonForLeaving: Scalars['String'];
  designation: Scalars['String'];
  createdOn: Scalars['DateTime'];
  createById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class EmployeeEducationVmInput {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  passingYear: Scalars['Int'];
  certificationId: Scalars['Uuid'];
  instituteId: Scalars['Uuid'];
  grade: Scalars['String'];
  createdOn: Scalars['DateTime'];
  createById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class EmployeeChildrenVmInput {
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  name: Scalars['String'];
  gender: Gender;
  age: Scalars['Byte'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class CreatedByVmInput {
  id: Scalars['Uuid'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export class InterviewCategoryVmInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: States;
};

export class LeaveTypeVmInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  limit: Scalars['Float'];
  state: States;
  weight: Scalars['Float'];
  isCarryForward: Scalars['Boolean'];
};

export class MedicalClaimBreakdownVmInput {
  id: Scalars['Uuid'];
  medicalClaimId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  billNumber?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  file?: Maybe<Scalars['String']>;
};

export class ReimbursementDetailVmInput {
  id: Scalars['Uuid'];
  reimbursementId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  file?: Maybe<Scalars['String']>;
};

export class EmployeeVmInput {
  id: Scalars['Uuid'];
  userId: Scalars['Uuid'];
  gender: Gender;
  state: States;
  fatherName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  dateOfAvailability?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  presentAddress?: Maybe<Scalars['String']>;
  permanentAddress?: Maybe<Scalars['String']>;
  bloodGroup?: Maybe<Scalars['String']>;
  maritalStatus: MaritalStatus;
  nameOfSpouse?: Maybe<Scalars['String']>;
  drivingLicenceNumber?: Maybe<Scalars['String']>;
  drivingLicenceIssueDate?: Maybe<Scalars['DateTime']>;
  currentSalary?: Maybe<Scalars['Decimal']>;
  expectedSalary?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<Scalars['String']>;
  religion?: Maybe<Scalars['String']>;
  cnic?: Maybe<Scalars['String']>;
  cnicExpiryDate?: Maybe<Scalars['DateTime']>;
  domicile?: Maybe<Scalars['String']>;
  interviewStartDate?: Maybe<Scalars['DateTime']>;
  interviewEndDate?: Maybe<Scalars['DateTime']>;
  children?: Maybe<Array<Maybe<EmployeeChildrenVmInput>>>;
  educations?: Maybe<Array<Maybe<EmployeeEducationVmInput>>>;
  experiences?: Maybe<Array<Maybe<EmployeeExperienceVmInput>>>;
  references?: Maybe<Array<Maybe<EmployeeReferencesVmInput>>>;
  relatives?: Maybe<Array<Maybe<EmployeeRelativesVmInput>>>;
  reportedToId?: Maybe<Scalars['Uuid']>;
  officialEmail?: Maybe<Scalars['String']>;
  pic?: Maybe<Scalars['String']>;
  businessId: Scalars['Uuid'];
  medicalLoanEntitlement: Scalars['Decimal'];
  employeePositions?: Maybe<Array<Maybe<CandidatePositionMapVmInput>>>;
  employmentHistories?: Maybe<Array<Maybe<EmploymentHistoryVmInput>>>;
  loans?: Maybe<Array<Maybe<LoanVmInput>>>;
  leaves?: Maybe<Array<Maybe<LeaveVmInput>>>;
  medicalClaims?: Maybe<Array<Maybe<MedicalClaimVmInput>>>;
  availableBalance: Scalars['Decimal'];
  benefits?: Maybe<UserBenefitsInput>;
  payrollAccountId?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class AddressVmInput {
  id: Scalars['Uuid'];
  zipCode?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export class SecondShiftVmInput {
  openingTime?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['String']>;
};

export class FirstShiftVmInput {
  openingTime?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['String']>;
};

export class ReasonTypeVmInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: States;
  type: ReasonTypes;
  createdOn: Scalars['DateTime'];
  createdById: Scalars['Uuid'];
  updatedOn: Scalars['DateTime'];
  updatedById: Scalars['Uuid'];
};

export class PfBenefitInput {
  enabled: Scalars['Boolean'];
  overrideAmount: Scalars['Decimal'];
  overridePercentage: Scalars['Short'];
};

export class EobiBenefitInput {
  amount: Scalars['Decimal'];
  percentage: Scalars['Short'];
  enabled: Scalars['Boolean'];
  overrideAmount: Scalars['Decimal'];
  overridePercentage: Scalars['Short'];
};

export type GetBusinessSalaryAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessSalaryAccountsQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { business?: Maybe<(
      { __typename?: 'Business' }
      & { salaryAccounts?: Maybe<(
        { __typename?: 'SalaryAccount' }
        & { eobiAccount?: Maybe<(
          { __typename?: 'EOBIAccount' }
          & Pick<EobiAccount, 'amount' | 'percentage'>
        )>, pfAccount?: Maybe<(
          { __typename?: 'PFAccount' }
          & Pick<PfAccount, 'amount' | 'percentage'>
        )>, fbrAccount?: Maybe<(
          { __typename?: 'FBRAccount' }
          & Pick<FbrAccount, 'code'>
        )> }
      )> }
    )> }
  )> };

export type GetSessionBusinessesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionBusinessesQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { businesses?: Maybe<Array<Maybe<(
      { __typename?: 'Business' }
      & Pick<Business, 'id' | 'name' | 'state'>
      & { childCounts?: Maybe<(
        { __typename?: 'ChildCount' }
        & Pick<ChildCount, 'departmentsCount' | 'usersCount'>
      )> }
    )>>> }
  )> };

export type GetBusinessAgentConfigQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetBusinessAgentConfigQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { agent?: Maybe<(
      { __typename?: 'AgentQueryType' }
      & { businessAgent?: Maybe<Array<Maybe<(
        { __typename?: 'AgentConfiguration' }
        & Pick<AgentConfiguration, 'state' | 'isLogEnable' | 'isCaptureScreen' | 'onAppSwitching' | 'captureScreenInterval'>
      )>>> }
    )> }
  )> };

export type GetUserAgentConfigQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetUserAgentConfigQuery = { user?: Maybe<(
    { __typename?: 'User' }
    & { userAgentConfigurations: Array<(
      { __typename?: 'UserAgentConfiguration' }
      & Pick<UserAgentConfiguration, 'state' | 'isLogEnable' | 'isCaptureScreen' | 'onAppSwitching' | 'captureScreenInterval' | 'useUserConfiguration'>
    )> }
  )> };

export type GetInvoiceConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoiceConfigurationQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { business?: Maybe<(
      { __typename?: 'Business' }
      & Pick<Business, 'configuration'>
      & { config?: Maybe<(
        { __typename?: 'BusinessConfig' }
        & { invoiceConfig: (
          { __typename?: 'InvoiceConfiguration' }
          & Pick<InvoiceConfiguration, 'showPurchaseInvoice' | 'showPurchaseOrder' | 'showQuotation' | 'showSaleInvoice'>
        ) }
      )> }
    )> }
  )> };

export type GetInvoiceConfigurationByBusinessQueryVariables = Exact<{
  businessId: Scalars['Uuid'];
}>;


export type GetInvoiceConfigurationByBusinessQuery = { business?: Maybe<(
    { __typename?: 'Business' }
    & Pick<Business, 'configuration'>
    & { config?: Maybe<(
      { __typename?: 'BusinessConfig' }
      & { invoiceConfig: (
        { __typename?: 'InvoiceConfiguration' }
        & Pick<InvoiceConfiguration, 'showPurchaseInvoice' | 'showPurchaseOrder' | 'showQuotation' | 'showSaleInvoice'>
      ) }
    )> }
  )> };

export type UpdateBusinessAgentConfigMutationVariables = Exact<{
  model?: Maybe<UpdateAgentConfigurationInput>;
}>;


export type UpdateBusinessAgentConfigMutation = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentMutationType' }
    & { userAgent?: Maybe<(
      { __typename?: 'UserAgentMutationType' }
      & Pick<UserAgentMutationType, 'updateBusinessAgent'>
    )> }
  )> };

export type UpdateInvoiceConfigMutationVariables = Exact<{
  model?: Maybe<InvoiceConfigInput>;
}>;


export type UpdateInvoiceConfigMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { purchaseVouchers?: Maybe<(
      { __typename?: 'InvoicesMutationType' }
      & Pick<InvoicesMutationType, 'updateInvoiceConfiguration'>
    )> }
  )> };

export type EnableBusinessAgentConfigMutationVariables = Exact<{ [key: string]: never; }>;


export type EnableBusinessAgentConfigMutation = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentMutationType' }
    & { userAgent?: Maybe<(
      { __typename?: 'UserAgentMutationType' }
      & Pick<UserAgentMutationType, 'enableBusinessAgent'>
    )> }
  )> };

export type DisableBusinessAgentConfigMutationVariables = Exact<{ [key: string]: never; }>;


export type DisableBusinessAgentConfigMutation = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentMutationType' }
    & { userAgent?: Maybe<(
      { __typename?: 'UserAgentMutationType' }
      & Pick<UserAgentMutationType, 'disableBusinessAgent'>
    )> }
  )> };

export type GetBusinessAttendanceConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessAttendanceConfigQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceConfigurationQueryType' }
      & { myBusinessAttendanceConfig?: Maybe<(
        { __typename?: 'AttendanceConfiguration' }
        & Pick<AttendanceConfiguration, 'state' | 'id' | 'businessId' | 'secondShiftId' | 'syncInterval' | 'firstShiftId'>
        & { secondShift?: Maybe<(
          { __typename?: 'SecondShift' }
          & Pick<SecondShift, 'state' | 'closingTime' | 'openingTime'>
        )>, firstShift?: Maybe<(
          { __typename?: 'FirstShift' }
          & Pick<FirstShift, 'state' | 'closingTime' | 'openingTime'>
        )> }
      )> }
    )> }
  )> };

export type UpdateBusinessAttendanceConfigMutationVariables = Exact<{
  model?: Maybe<UpdateAttendanceConfigurationInput>;
}>;


export type UpdateBusinessAttendanceConfigMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { configuration?: Maybe<(
      { __typename?: 'AttendanceConfigurationMutationType' }
      & Pick<AttendanceConfigurationMutationType, 'updateBusinessAgent'>
    )> }
  )> };

export type DisableBusinessAttendanceConfigMutationVariables = Exact<{ [key: string]: never; }>;


export type DisableBusinessAttendanceConfigMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { configuration?: Maybe<(
      { __typename?: 'AttendanceConfigurationMutationType' }
      & Pick<AttendanceConfigurationMutationType, 'disableBusinessAgent'>
    )> }
  )> };

export type GetBusinessUserAttendanceConfigQueryVariables = Exact<{
  filter?: Maybe<AttendanceUserConfigurationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetBusinessUserAttendanceConfigQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceConfigurationQueryType' }
      & { usersConfig?: Maybe<(
        { __typename?: 'AttendanceUserConfigurationCollectionSegment' }
        & Pick<AttendanceUserConfigurationCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AttendanceUserConfiguration' }
          & Pick<AttendanceUserConfiguration, 'id' | 'attendanceId' | 'shiftId' | 'businessId' | 'closingTime' | 'openingTime' | 'state' | 'userId' | 'useUserConfiguration'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'email' | 'firstName' | 'lastName'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type UpdateUserAttendanceConfigurationsMutationVariables = Exact<{
  model?: Maybe<Array<Maybe<UserAttendanceConfigVmInput>> | Maybe<UserAttendanceConfigVmInput>>;
}>;


export type UpdateUserAttendanceConfigurationsMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { configuration?: Maybe<(
      { __typename?: 'AttendanceConfigurationMutationType' }
      & Pick<AttendanceConfigurationMutationType, 'updateUserConfig'>
    )> }
  )> };

export type GetBusinessEmailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessEmailsQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { business?: Maybe<(
      { __typename?: 'Business' }
      & { config?: Maybe<(
        { __typename?: 'BusinessConfig' }
        & { attendanceNotification: (
          { __typename?: 'AttendanceNotification' }
          & Pick<AttendanceNotification, 'enable' | 'time' | 'emails'>
        ) }
      )> }
    )> }
  )> };

export type UpdateBusinessEmailsMutationVariables = Exact<{
  vm?: Maybe<AttendanceNotificationVmInput>;
}>;


export type UpdateBusinessEmailsMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'updateEmails'>
  )> };

export type GetHrConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHrConfigurationQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { business?: Maybe<(
      { __typename?: 'Business' }
      & Pick<Business, 'configuration'>
      & { config?: Maybe<(
        { __typename?: 'BusinessConfig' }
        & { hrConfig: (
          { __typename?: 'HrConfiguration' }
          & Pick<HrConfiguration, 'loansEntitlement' | 'medicalClaimEntitlement'>
        ) }
      )> }
    )> }
  )> };

export type GetHrConfigurationByBusinessQueryVariables = Exact<{
  businessId: Scalars['Uuid'];
}>;


export type GetHrConfigurationByBusinessQuery = { business?: Maybe<(
    { __typename?: 'Business' }
    & Pick<Business, 'configuration'>
    & { config?: Maybe<(
      { __typename?: 'BusinessConfig' }
      & { hrConfig: (
        { __typename?: 'HrConfiguration' }
        & Pick<HrConfiguration, 'loansEntitlement' | 'medicalClaimEntitlement'>
      ) }
    )> }
  )> };

export type UpdateBusinessHrConfigAndWorkingDaysMutationVariables = Exact<{
  hrConfiguration?: Maybe<HrConfigurationVmInput>;
  workingDays?: Maybe<Array<WeekDays> | WeekDays>;
}>;


export type UpdateBusinessHrConfigAndWorkingDaysMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'updateHrConfigurationAndWorkingDays'>
  )> };

export type SyncBusinessHrConfigMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncBusinessHrConfigMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'syncBusinessConfiguration'>
  )> };

export type GetWorkingDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkingDaysQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { business?: Maybe<(
      { __typename?: 'Business' }
      & Pick<Business, 'configuration'>
      & { config?: Maybe<(
        { __typename?: 'BusinessConfig' }
        & Pick<BusinessConfig, 'workingDays'>
      )> }
    )> }
  )> };

export type GetWorkingDaysByBusinessQueryVariables = Exact<{
  businessId: Scalars['Uuid'];
}>;


export type GetWorkingDaysByBusinessQuery = { business?: Maybe<(
    { __typename?: 'Business' }
    & Pick<Business, 'configuration'>
    & { config?: Maybe<(
      { __typename?: 'BusinessConfig' }
      & Pick<BusinessConfig, 'workingDays'>
    )> }
  )> };

export type FirstLevelAccountDetailFragment = (
  { __typename?: 'FirstLevelAccount' }
  & Pick<FirstLevelAccount, 'id' | 'name' | 'code' | 'description' | 'sortOrder'>
);

export type SecondLevelAccountDetailFragment = (
  { __typename?: 'SecondLevelAccount' }
  & Pick<SecondLevelAccount, 'id' | 'firstLevelAccountId' | 'name' | 'code' | 'description' | 'sortOrder'>
);

export type ThirdLevelAccountDetailFragment = (
  { __typename?: 'ThirdLevelAccount' }
  & Pick<ThirdLevelAccount, 'id' | 'secondLevelAccountId' | 'name' | 'roleNumber' | 'code' | 'description' | 'sortOrder'>
);

export type GetFirstLevelAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstLevelAccountsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { firstLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'FirstLevelAccount' }
      & FirstLevelAccountDetailFragment
    )>>> }
  )> };

export type GetFirstWithSecondLevelAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstWithSecondLevelAccountsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { firstLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'FirstLevelAccount' }
      & { secondLevelAccounts?: Maybe<Array<Maybe<(
        { __typename?: 'SecondLevelAccount' }
        & { thirdLevelAccounts?: Maybe<Array<Maybe<(
          { __typename?: 'ThirdLevelAccount' }
          & ThirdLevelAccountDetailFragment
        )>>> }
        & SecondLevelAccountDetailFragment
      )>>> }
      & FirstLevelAccountDetailFragment
    )>>> }
  )> };

export type GetSecondLevelAccountsQueryVariables = Exact<{
  firstLevelAccountId: Scalars['Uuid'];
}>;


export type GetSecondLevelAccountsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { secondLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'SecondLevelAccount' }
      & { thirdLevelAccounts?: Maybe<Array<Maybe<(
        { __typename?: 'ThirdLevelAccount' }
        & ThirdLevelAccountDetailFragment
      )>>> }
      & SecondLevelAccountDetailFragment
    )>>> }
  )> };

export type GetSecondLevelAccountsByBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSecondLevelAccountsByBusinessQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { secondLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'SecondLevelAccount' }
      & { thirdLevelAccounts?: Maybe<Array<Maybe<(
        { __typename?: 'ThirdLevelAccount' }
        & ThirdLevelAccountDetailFragment
      )>>> }
      & SecondLevelAccountDetailFragment
    )>>> }
  )> };

export type PaginatedThirdAccountsQueryVariables = Exact<{
  filter?: Maybe<ThirdLevelAccountFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type PaginatedThirdAccountsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedThirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountCollectionSegment' }
      & Pick<ThirdLevelAccountCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ThirdLevelAccount' }
        & ThirdLevelAccountDetailFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetThirdLevelAccountWithAddressQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetThirdLevelAccountWithAddressQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { thirdLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'ThirdLevelAccount' }
      & Pick<ThirdLevelAccount, 'businessAddressId' | 'deliveryAddressId' | 'billingAddressId' | 'businessContactId' | 'billingContactId' | 'deliveryContactId'>
      & { businessAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFragment
      )>, deliveryAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFragment
      )>, billingAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFragment
      )> }
      & ThirdLevelAccountDetailFragment
    )>>> }
  )> };

export type GetThirdLevelAccountWithAddressAndRolesQueryVariables = Exact<{
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetThirdLevelAccountWithAddressAndRolesQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { thirdLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'ThirdLevelAccount' }
      & Pick<ThirdLevelAccount, 'businessAddressId' | 'deliveryAddressId' | 'billingAddressId' | 'businessContactId' | 'billingContactId' | 'deliveryContactId' | 'roleNumber' | 'roles'>
      & { businessAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFragment
      )>, deliveryAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFragment
      )>, billingAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFragment
      )> }
      & ThirdLevelAccountDetailFragment
    )>>> }
  )> };

export type GetThirdLevelAccountQueryVariables = Exact<{
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetThirdLevelAccountQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { thirdLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'ThirdLevelAccount' }
      & ThirdLevelAccountDetailFragment
    )>>> }
  )> };

export type GetSecondLevelAccountByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetSecondLevelAccountByIdQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { secondLevelAccounts?: Maybe<Array<Maybe<(
      { __typename?: 'SecondLevelAccount' }
      & SecondLevelAccountDetailFragment
    )>>> }
  )> };

export type GetAccountSequenceNumberQueryVariables = Exact<{
  accountId: Scalars['Uuid'];
}>;


export type GetAccountSequenceNumberQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & Pick<FinanceQueryType, 'accountSequenceNo'>
  )> };

export type AddThirdLevelAccountMutationVariables = Exact<{
  model?: Maybe<CreateThirdLevelAccountInput>;
}>;


export type AddThirdLevelAccountMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { thirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountsMutationType' }
      & Pick<ThirdLevelAccountsMutationType, 'addThirdLevelAccount'>
    )> }
  )> };

export type UpdateThirdLevelAccountMutationVariables = Exact<{
  model?: Maybe<UpdateThirdLevelAccountInput>;
}>;


export type UpdateThirdLevelAccountMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { thirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountsMutationType' }
      & Pick<ThirdLevelAccountsMutationType, 'updateThirdLevelAccount'>
    )> }
  )> };

export type AddUpdateBusinessPfAccountMutationVariables = Exact<{
  model: PfAccountVmInput;
}>;


export type AddUpdateBusinessPfAccountMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { thirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountsMutationType' }
      & Pick<ThirdLevelAccountsMutationType, 'addUpdateBusinessPFAccount'>
    )> }
  )> };

export type AddUpdateBusinessEobiAccountMutationVariables = Exact<{
  model: EobiAccountVmInput;
}>;


export type AddUpdateBusinessEobiAccountMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { thirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountsMutationType' }
      & Pick<ThirdLevelAccountsMutationType, 'addUpdateBusinessEOBIAccount'>
    )> }
  )> };

export type AddUpdateBusinessFbrAccountMutationVariables = Exact<{
  model: FbrAccountVmInput;
}>;


export type AddUpdateBusinessFbrAccountMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { thirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountsMutationType' }
      & Pick<ThirdLevelAccountsMutationType, 'addUpdateBusinessFBRAccount'>
    )> }
  )> };

export type GetThirdLevelAccountByRoleQueryVariables = Exact<{
  roleNumber: Scalars['Long'];
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetThirdLevelAccountByRoleQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedThirdLevelAccountsByRole?: Maybe<(
      { __typename?: 'ThirdLevelAccountCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ThirdLevelAccount' }
        & Pick<ThirdLevelAccount, 'id' | 'name'>
      )>>> }
    )> }
  )> };

export type GetThirdLevelAccountsByRoleQueryVariables = Exact<{
  roleNumber: Scalars['Long'];
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetThirdLevelAccountsByRoleQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedThirdLevelAccountsByRole?: Maybe<(
      { __typename?: 'ThirdLevelAccountCollectionSegment' }
      & Pick<ThirdLevelAccountCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ThirdLevelAccount' }
        & ThirdLevelAccountDetailFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetPaginatedAccountRolesQueryVariables = Exact<{
  filter?: Maybe<AccountRoleFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedAccountRolesQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedAccountRoles?: Maybe<(
      { __typename?: 'AccountRoleCollectionSegment' }
      & Pick<AccountRoleCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<(
        { __typename?: 'AccountRole' }
        & Pick<AccountRole, 'id' | 'name' | 'roleNumber'>
      )>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetSystemAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSystemAccountsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { systemAccounts: Array<(
      { __typename?: 'SystemAccountGroup' }
      & Pick<SystemAccountGroup, 'name'>
      & { systemAccounts: Array<(
        { __typename?: 'SystemAccount' }
        & Pick<SystemAccount, 'name' | 'roleNumber'>
        & { account?: Maybe<(
          { __typename?: 'ThirdLevelAccount' }
          & Pick<ThirdLevelAccount, 'id' | 'name' | 'code' | 'description'>
        )> }
      )> }
    )> }
  )> };

export type IsAccountExistWithRoleQueryVariables = Exact<{
  roleNumber: Scalars['Long'];
}>;


export type IsAccountExistWithRoleQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & Pick<FinanceQueryType, 'isAccountExistWithRole'>
  )> };

export type UpdateAccountRoleMutationVariables = Exact<{
  model?: Maybe<UpdateThirdLevelAccountRoleInput>;
}>;


export type UpdateAccountRoleMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { thirdLevelAccounts?: Maybe<(
      { __typename?: 'ThirdLevelAccountsMutationType' }
      & Pick<ThirdLevelAccountsMutationType, 'updateAccountRole'>
    )> }
  )> };

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'zipCode' | 'city' | 'state' | 'country' | 'timeZone'>
);

export type GetBusinessAddressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessAddressQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { addresses?: Maybe<Array<Maybe<(
      { __typename?: 'Address' }
      & AddressFragment
    )>>> }
  )> };

export type GetAddressQueryVariables = Exact<{
  contactId: Scalars['Uuid'];
}>;


export type GetAddressQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & AddressFragment
    )> }
  )> };

export type AllowanceCategoriesFragment = (
  { __typename?: 'AllowanceCategory' }
  & Pick<AllowanceCategory, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedAllowanceCategoriesQueryVariables = Exact<{
  filter?: Maybe<AllowanceCategoryFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedAllowanceCategoriesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoryCollectionSegment' }
      & Pick<AllowanceCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AllowanceCategory' }
        & AllowanceCategoriesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetAllowanceCategoriesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAllowanceCategoriesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoryCollectionSegment' }
      & Pick<AllowanceCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AllowanceCategory' }
        & Pick<AllowanceCategory, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetAllowanceCategoryQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetAllowanceCategoryQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoryCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AllowanceCategory' }
        & AllowanceCategoriesFragment
      )>>> }
    )> }
  )> };

export type AddAllowanceCategoryMutationVariables = Exact<{
  model?: Maybe<CreateAllowanceCategoryInput>;
}>;


export type AddAllowanceCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoriesMutationType' }
      & Pick<AllowanceCategoriesMutationType, 'add'>
    )> }
  )> };

export type UpdateAllowanceCategoryMutationVariables = Exact<{
  model?: Maybe<UpdateAllowanceCategoryInput>;
}>;


export type UpdateAllowanceCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoriesMutationType' }
      & Pick<AllowanceCategoriesMutationType, 'update'>
    )> }
  )> };

export type ArchiveAllowanceCategoryMutationVariables = Exact<{
  allowanceCategoryId: Scalars['Uuid'];
}>;


export type ArchiveAllowanceCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoriesMutationType' }
      & Pick<AllowanceCategoriesMutationType, 'archive'>
    )> }
  )> };

export type RestoreAllowanceCategoryMutationVariables = Exact<{
  allowanceCategoryId: Scalars['Uuid'];
}>;


export type RestoreAllowanceCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceCategories?: Maybe<(
      { __typename?: 'AllowanceCategoriesMutationType' }
      & Pick<AllowanceCategoriesMutationType, 'restore'>
    )> }
  )> };

export type AllowanceTypesFragment = (
  { __typename?: 'AllowanceType' }
  & Pick<AllowanceType, 'id' | 'name' | 'code' | 'ratio' | 'updatedOn' | 'state' | 'categoryId'>
  & { category?: Maybe<(
    { __typename?: 'AllowanceCategory' }
    & Pick<AllowanceCategory, 'name'>
  )> }
);

export type GetPaginatedAllowanceTypesQueryVariables = Exact<{
  filter?: Maybe<AllowanceTypeFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedAllowanceTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypeCollectionSegment' }
      & Pick<AllowanceTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AllowanceType' }
        & AllowanceTypesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetAllowanceTypesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAllowanceTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypeCollectionSegment' }
      & Pick<AllowanceTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AllowanceType' }
        & Pick<AllowanceType, 'id' | 'name' | 'ratio'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetAllowanceTypeQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetAllowanceTypeQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AllowanceType' }
        & AllowanceTypesFragment
      )>>> }
    )> }
  )> };

export type AddAllowanceTypeMutationVariables = Exact<{
  model?: Maybe<CreateAllowanceTypeInput>;
}>;


export type AddAllowanceTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypesMutationType' }
      & Pick<AllowanceTypesMutationType, 'add'>
    )> }
  )> };

export type UpdateAllowanceTypeMutationVariables = Exact<{
  model?: Maybe<UpdateAllowanceTypeInput>;
}>;


export type UpdateAllowanceTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypesMutationType' }
      & Pick<AllowanceTypesMutationType, 'update'>
    )> }
  )> };

export type ArchiveAllowanceTypeMutationVariables = Exact<{
  allowanceTypeId: Scalars['Uuid'];
}>;


export type ArchiveAllowanceTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypesMutationType' }
      & Pick<AllowanceTypesMutationType, 'archive'>
    )> }
  )> };

export type RestoreAllowanceTypeMutationVariables = Exact<{
  allowanceTypeId: Scalars['Uuid'];
}>;


export type RestoreAllowanceTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { allowanceTypes?: Maybe<(
      { __typename?: 'AllowanceTypesMutationType' }
      & Pick<AllowanceTypesMutationType, 'restore'>
    )> }
  )> };

export type GetBusinessAppsQueryVariables = Exact<{
  filter?: Maybe<AppFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetBusinessAppsQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { apps?: Maybe<(
      { __typename?: 'AppsQueryType' }
      & { paginatedBusinessApps?: Maybe<(
        { __typename?: 'AppCollectionSegment' }
        & Pick<AppCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'App' }
          & Pick<App, 'id' | 'name' | 'state'>
          & { appUsageSummary?: Maybe<(
            { __typename?: 'AppUsageSummary' }
            & Pick<AppUsageSummary, 'usersCount' | 'usageMinutes'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
        ) }
      )> }
    )> }
  )> };

export type UpdateAppStateMutationVariables = Exact<{
  appId: Scalars['Uuid'];
  state: AppStates;
}>;


export type UpdateAppStateMutation = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentMutationType' }
    & { apps?: Maybe<(
      { __typename?: 'AppsMutationType' }
      & Pick<AppsMutationType, 'updateState'>
    )> }
  )> };

export type GetAgentProductiveStatesQueryVariables = Exact<{
  filter?: Maybe<AppUsageFilterInput>;
  sort?: Maybe<Array<AppUsageSortInput> | AppUsageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAgentProductiveStatesQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & { productiveStates?: Maybe<(
        { __typename?: 'AppUsageCollectionSegment' }
        & Pick<AppUsageCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AppUsage' }
          & Pick<AppUsage, 'businessId' | 'machine' | 'appId' | 'date' | 'iPAddress' | 'username' | 'hour' | 'description' | 'userNetwork' | 'usageBreakDown'>
          & { app?: Maybe<(
            { __typename?: 'App' }
            & Pick<App, 'name'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAgentIdleStatesQueryVariables = Exact<{
  filter?: Maybe<AppUsageFilterInput>;
  sort?: Maybe<Array<AppUsageSortInput> | AppUsageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAgentIdleStatesQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & { idleStates?: Maybe<(
        { __typename?: 'AppUsageCollectionSegment' }
        & Pick<AppUsageCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AppUsage' }
          & Pick<AppUsage, 'businessId' | 'machine' | 'appId' | 'date' | 'iPAddress' | 'username' | 'hour' | 'description' | 'usageBreakDown' | 'userNetwork'>
          & { app?: Maybe<(
            { __typename?: 'App' }
            & Pick<App, 'name'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAgentIdleStateApplicationsQueryVariables = Exact<{
  filter?: Maybe<AppUsageFilterInput>;
  sort?: Maybe<Array<AppUsageSortInput> | AppUsageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAgentIdleStateApplicationsQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & { idleStates?: Maybe<(
        { __typename?: 'AppUsageCollectionSegment' }
        & Pick<AppUsageCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AppUsage' }
          & Pick<AppUsage, 'id' | 'appId' | 'date' | 'hour' | 'description'>
          & { app?: Maybe<(
            { __typename?: 'App' }
            & Pick<App, 'name'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAgentProductiveStateApplicationsQueryVariables = Exact<{
  filter?: Maybe<AppUsageFilterInput>;
  sort?: Maybe<Array<AppUsageSortInput> | AppUsageSortInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAgentProductiveStateApplicationsQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & { productiveStates?: Maybe<(
        { __typename?: 'AppUsageCollectionSegment' }
        & Pick<AppUsageCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AppUsage' }
          & Pick<AppUsage, 'id' | 'appId' | 'date' | 'hour' | 'description'>
          & { app?: Maybe<(
            { __typename?: 'App' }
            & Pick<App, 'name'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAgentProductiveMillisecondsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetAgentProductiveMillisecondsQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & Pick<LogsQueryType, 'productiveStateMilliseconds'>
    )> }
  )> };

export type GetAgentIdleMillisecondsQueryVariables = Exact<{
  starDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetAgentIdleMillisecondsQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & Pick<LogsQueryType, 'idleStateMilliseconds'>
    )> }
  )> };

export type GetAgentProductiveStateLiveReportQueryVariables = Exact<{
  userId?: Maybe<Scalars['Uuid']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetAgentProductiveStateLiveReportQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & { productiveStateLiveReport?: Maybe<Array<Maybe<(
        { __typename?: 'LiveReportVm' }
        & Pick<LiveReportVm, 'date' | 'totalMilliseconds'>
      )>>> }
    )> }
  )> };

export type GetAgentIdleStateLiveReportQueryVariables = Exact<{
  userId?: Maybe<Scalars['Uuid']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetAgentIdleStateLiveReportQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { logs?: Maybe<(
      { __typename?: 'LogsQueryType' }
      & { idleStateLiveReport?: Maybe<Array<Maybe<(
        { __typename?: 'LiveReportVm' }
        & Pick<LiveReportVm, 'date' | 'totalMilliseconds'>
      )>>> }
    )> }
  )> };

export type GetAttendanceRecordsQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Uuid']>;
  present?: Maybe<Scalars['Boolean']>;
  absent?: Maybe<Scalars['Boolean']>;
  onWeekend?: Maybe<Scalars['Boolean']>;
  leave?: Maybe<Scalars['Boolean']>;
  onTimeLogin?: Maybe<Scalars['Boolean']>;
  late?: Maybe<Scalars['Boolean']>;
}>;


export type GetAttendanceRecordsQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendanceRecord?: Maybe<(
      { __typename?: 'AttendanceRecordQueryType' }
      & { userWithAttendanceRecords?: Maybe<(
        { __typename?: 'UserCollectionSegment' }
        & Pick<UserCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
          & { department?: Maybe<(
            { __typename?: 'HrLookup' }
            & Pick<HrLookup, 'name'>
          )>, jobTitle?: Maybe<(
            { __typename?: 'HrLookup' }
            & Pick<HrLookup, 'name'>
          )>, leaves: Array<(
            { __typename?: 'Leave' }
            & LeaveFragmentFragment
          )>, attendanceUserConfigurations: Array<(
            { __typename?: 'AttendanceUserConfiguration' }
            & Pick<AttendanceUserConfiguration, 'id' | 'attendanceId' | 'useUserConfiguration' | 'openingTime' | 'shiftId' | 'businessId' | 'workingDays' | 'weekDays'>
            & { business?: Maybe<(
              { __typename?: 'Business' }
              & Pick<Business, 'configuration'>
            )>, businessShift?: Maybe<(
              { __typename?: 'BusinessShift' }
              & Pick<BusinessShift, 'id' | 'openingTime'>
            )>, attendanceRecords?: Maybe<Array<Maybe<(
              { __typename?: 'AttendanceRecord' }
              & Pick<AttendanceRecord, 'id' | 'inDateTime' | 'outDateTime' | 'isLate' | 'attendanceUserConfigurationId'>
              & { agentTime?: Maybe<(
                { __typename?: 'AgentTime' }
                & Pick<AgentTime, 'idleTime' | 'productiveTime' | 'loginTime' | 'logoutTime'>
              )> }
            )>>> }
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
        ) }
      )> }
    )> }
  )> };

export type GetUserAttendanceConfigsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetUserAttendanceConfigsQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceConfigurationQueryType' }
      & { usersConfig?: Maybe<(
        { __typename?: 'AttendanceUserConfigurationCollectionSegment' }
        & Pick<AttendanceUserConfigurationCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AttendanceUserConfiguration' }
          & Pick<AttendanceUserConfiguration, 'id' | 'attendanceId' | 'userId'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'email' | 'firstName' | 'lastName' | 'fullName'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAttendanceIdsQueryVariables = Exact<{
  filter?: Maybe<AttendanceUserConfigurationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAttendanceIdsQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceConfigurationQueryType' }
      & { usersConfig?: Maybe<(
        { __typename?: 'AttendanceUserConfigurationCollectionSegment' }
        & Pick<AttendanceUserConfigurationCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'AttendanceUserConfiguration' }
          & Pick<AttendanceUserConfiguration, 'id' | 'attendanceId'>
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetUserAttendanceConfigQueryVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type GetUserAttendanceConfigQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceConfigurationQueryType' }
      & { attendanceConfig?: Maybe<(
        { __typename?: 'AttendanceUserConfiguration' }
        & Pick<AttendanceUserConfiguration, 'id' | 'attendanceId' | 'closingTime' | 'openingTime' | 'businessId' | 'workingDays' | 'weekDays' | 'state' | 'shiftId' | 'userId' | 'useUserConfiguration'>
        & { business?: Maybe<(
          { __typename?: 'Business' }
          & Pick<Business, 'configuration'>
        )>, user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'email' | 'firstName' | 'lastName' | 'fullName'>
        )> }
      )> }
    )> }
  )> };

export type YesterdayAttendanceReportQueryVariables = Exact<{
  date: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Uuid']>;
}>;


export type YesterdayAttendanceReportQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendanceRecord?: Maybe<(
      { __typename?: 'AttendanceRecordQueryType' }
      & { yesterdayAttendanceReport?: Maybe<(
        { __typename?: 'YesterdayAttendanceReport' }
        & Pick<YesterdayAttendanceReport, 'avgCheckin' | 'avgCheckout' | 'productive' | 'effective'>
      )> }
    )> }
  )> };

export type TodayAttendanceReportQueryVariables = Exact<{
  date: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Uuid']>;
}>;


export type TodayAttendanceReportQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendanceRecord?: Maybe<(
      { __typename?: 'AttendanceRecordQueryType' }
      & { todayAttendanceReport?: Maybe<(
        { __typename?: 'TodayAttendanceReport' }
        & Pick<TodayAttendanceReport, 'absent' | 'onLeave' | 'onWeekend' | 'present' | 'lateComers' | 'onTimeComers' | 'total'>
      )> }
    )> }
  )> };

export type SyncAttendanceRecordsFromScratchMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncAttendanceRecordsFromScratchMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { records?: Maybe<(
      { __typename?: 'AttendanceRecordMutationType' }
      & Pick<AttendanceRecordMutationType, 'syncAttendanceRecordsFromScratch'>
    )> }
  )> };

export type ClearAttendanceRecordsMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearAttendanceRecordsMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { records?: Maybe<(
      { __typename?: 'AttendanceRecordMutationType' }
      & Pick<AttendanceRecordMutationType, 'removeAttendanceRecords'>
    )> }
  )> };

export type EditAttendanceMutationVariables = Exact<{
  model?: Maybe<Array<Maybe<EditAttendanceRecordInput>> | Maybe<EditAttendanceRecordInput>>;
}>;


export type EditAttendanceMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { records?: Maybe<(
      { __typename?: 'AttendanceRecordMutationType' }
      & Pick<AttendanceRecordMutationType, 'editRecords'>
    )> }
  )> };

export type RemoveAttendanceMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type RemoveAttendanceMutation = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementMutationType' }
    & { records?: Maybe<(
      { __typename?: 'AttendanceRecordMutationType' }
      & Pick<AttendanceRecordMutationType, 'removeRecords'>
    )> }
  )> };

export type GetAttendanceConfigQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetAttendanceConfigQuery = { attendanceManagement?: Maybe<(
    { __typename?: 'AttendanceManagementQueryType' }
    & { attendance?: Maybe<(
      { __typename?: 'AttendanceConfigurationQueryType' }
      & { businessAttendanceConfig?: Maybe<(
        { __typename?: 'AttendanceConfiguration' }
        & Pick<AttendanceConfiguration, 'businessId' | 'state' | 'id' | 'secondShiftId' | 'firstShiftId'>
        & { firstShift?: Maybe<(
          { __typename?: 'FirstShift' }
          & Pick<FirstShift, 'attendanceConfigurationId' | 'closingTime' | 'openingTime' | 'state' | 'id'>
        )>, secondShift?: Maybe<(
          { __typename?: 'SecondShift' }
          & Pick<SecondShift, 'attendanceConfigurationId' | 'closingTime' | 'openingTime' | 'state' | 'id'>
        )> }
      )> }
    )> }
  )> };

export type CollectionInfoFragment = (
  { __typename?: 'CollectionSegmentInfo' }
  & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
);

export type BonusCategoriesFragment = (
  { __typename?: 'BonusCategory' }
  & Pick<BonusCategory, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedBonusCategoriesQueryVariables = Exact<{
  filter?: Maybe<BonusCategoryFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedBonusCategoriesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoryCollectionSegment' }
      & Pick<BonusCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BonusCategory' }
        & BonusCategoriesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetBonusCategoriesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetBonusCategoriesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoryCollectionSegment' }
      & Pick<BonusCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BonusCategory' }
        & Pick<BonusCategory, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetBonusCategoryQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetBonusCategoryQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoryCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BonusCategory' }
        & BonusCategoriesFragment
      )>>> }
    )> }
  )> };

export type AddBonusCategoryMutationVariables = Exact<{
  model?: Maybe<CreateBonusCategoryInput>;
}>;


export type AddBonusCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoriesMutationType' }
      & Pick<BonusCategoriesMutationType, 'add'>
    )> }
  )> };

export type UpdateBonusCategoryMutationVariables = Exact<{
  model?: Maybe<UpdateBonusCategoryInput>;
}>;


export type UpdateBonusCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoriesMutationType' }
      & Pick<BonusCategoriesMutationType, 'update'>
    )> }
  )> };

export type ArchiveBonusCategoryMutationVariables = Exact<{
  bonusCategoryId: Scalars['Uuid'];
}>;


export type ArchiveBonusCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoriesMutationType' }
      & Pick<BonusCategoriesMutationType, 'archive'>
    )> }
  )> };

export type RestoreBonusCategoryMutationVariables = Exact<{
  bonusCategoryId: Scalars['Uuid'];
}>;


export type RestoreBonusCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusCategories?: Maybe<(
      { __typename?: 'BonusCategoriesMutationType' }
      & Pick<BonusCategoriesMutationType, 'restore'>
    )> }
  )> };

export type BonusTypesFragment = (
  { __typename?: 'BonusType' }
  & Pick<BonusType, 'id' | 'name' | 'code' | 'percentage' | 'updatedOn' | 'state' | 'categoryId'>
  & { category?: Maybe<(
    { __typename?: 'BonusCategory' }
    & Pick<BonusCategory, 'name'>
  )> }
);

export type GetPaginatedBonusTypesQueryVariables = Exact<{
  filter?: Maybe<BonusTypeFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedBonusTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypeCollectionSegment' }
      & Pick<BonusTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BonusType' }
        & BonusTypesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetBonusTypesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetBonusTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BonusType' }
        & Pick<BonusType, 'id' | 'name' | 'percentage'>
      )>>> }
    )> }
  )> };

export type GetBonusTypeQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetBonusTypeQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BonusType' }
        & BonusTypesFragment
      )>>> }
    )> }
  )> };

export type AddBonusTypeMutationVariables = Exact<{
  model?: Maybe<CreateBonusTypeInput>;
}>;


export type AddBonusTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypesMutationType' }
      & Pick<BonusTypesMutationType, 'add'>
    )> }
  )> };

export type UpdateBonusTypeMutationVariables = Exact<{
  model?: Maybe<UpdateBonusTypeInput>;
}>;


export type UpdateBonusTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypesMutationType' }
      & Pick<BonusTypesMutationType, 'update'>
    )> }
  )> };

export type ArchiveBonusTypeMutationVariables = Exact<{
  bonusTypeId: Scalars['Uuid'];
}>;


export type ArchiveBonusTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypesMutationType' }
      & Pick<BonusTypesMutationType, 'archive'>
    )> }
  )> };

export type RestoreBonusTypeMutationVariables = Exact<{
  bonusTypeId: Scalars['Uuid'];
}>;


export type RestoreBonusTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { bonusTypes?: Maybe<(
      { __typename?: 'BonusTypesMutationType' }
      & Pick<BonusTypesMutationType, 'restore'>
    )> }
  )> };

export type BusinessLookupFragment = (
  { __typename?: 'BusinessLookup' }
  & Pick<BusinessLookup, 'id' | 'name' | 'code' | 'createdOn' | 'updatedOn' | 'state' | 'isSystemDefined' | 'parentId' | 'businessId' | 'setting'>
  & { parent?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'id' | 'name' | 'code' | 'createdOn' | 'updatedOn' | 'state' | 'isSystemDefined'>
  )> }
);

export type GetBusLookupsByLookupCodeQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  parentCode?: Maybe<Scalars['String']>;
}>;


export type GetBusLookupsByLookupCodeQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetBusLookupQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetBusLookupQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupByCodeQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;


export type GetBusLookupByCodeQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetBusLookupsQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupsByLookupModuleCodeQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
}>;


export type GetBusLookupsByLookupModuleCodeQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupsByLookupModuleCodeWithOutParentIdQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Uuid']>;
}>;


export type GetBusLookupsByLookupModuleCodeWithOutParentIdQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupsByLookupModuleCodeWithParentIdQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Uuid']>;
  status?: Maybe<States>;
}>;


export type GetBusLookupsByLookupModuleCodeWithParentIdQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupsBySelectedInQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  idList?: Maybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type GetBusLookupsBySelectedInQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetBusLookupsByLookupModuleCodeFilterQueryVariables = Exact<{
  filter?: Maybe<BusinessLookupFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetBusLookupsByLookupModuleCodeFilterQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetActBusLookupsByLookupCodeQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
}>;


export type GetActBusLookupsByLookupCodeQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetActBusLookupsByLookupCodeAsParentQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
}>;


export type GetActBusLookupsByLookupCodeAsParentQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type UpdateBusLookupMutationVariables = Exact<{
  model?: Maybe<UpdateBusLookupInput>;
}>;


export type UpdateBusLookupMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & { busLookup?: Maybe<(
      { __typename?: 'BusLookupMutationType' }
      & Pick<BusLookupMutationType, 'update'>
    )> }
  )> };

export type AddBusLookupMutationVariables = Exact<{
  model?: Maybe<CreateBusLookupInput>;
}>;


export type AddBusLookupMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & { busLookup?: Maybe<(
      { __typename?: 'BusLookupMutationType' }
      & Pick<BusLookupMutationType, 'add'>
    )> }
  )> };

export type ArchiveBusLookupMutationVariables = Exact<{
  lookupId: Scalars['Uuid'];
}>;


export type ArchiveBusLookupMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & { busLookup?: Maybe<(
      { __typename?: 'BusLookupMutationType' }
      & Pick<BusLookupMutationType, 'archive'>
    )> }
  )> };

export type RestoreBusLookupMutationVariables = Exact<{
  lookupId: Scalars['Uuid'];
}>;


export type RestoreBusLookupMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & { busLookup?: Maybe<(
      { __typename?: 'BusLookupMutationType' }
      & Pick<BusLookupMutationType, 'restore'>
    )> }
  )> };

export type GetBusinessTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessTypesQuery = { businessTypes?: Maybe<Array<Maybe<(
    { __typename?: 'BusinessType' }
    & Pick<BusinessType, 'id' | 'name'>
  )>>> };

export type BusinessUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'state' | 'pic'>
  & { userAgentConfigurations: Array<(
    { __typename?: 'UserAgentConfiguration' }
    & Pick<UserAgentConfiguration, 'state' | 'isLogEnable' | 'isCaptureScreen' | 'onAppSwitching' | 'captureScreenInterval' | 'useUserConfiguration'>
  )> }
);

export type GetActiveUsersQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetActiveUsersQuery = { paginatedUsers?: Maybe<(
    { __typename?: 'UserCollectionSegment' }
    & Pick<UserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & BusinessUserFragment
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetDropdownUsersQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetDropdownUsersQuery = { paginatedUsers?: Maybe<(
    { __typename?: 'UserCollectionSegment' }
    & Pick<UserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'userFullName' | 'email' | 'pic' | 'departmentId'>
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type UpdateUserAgentConfigMutationVariables = Exact<{
  userId: Scalars['Uuid'];
  model?: Maybe<UserAgentConfigurationVmInput>;
}>;


export type UpdateUserAgentConfigMutation = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentMutationType' }
    & { userAgent?: Maybe<(
      { __typename?: 'UserAgentMutationType' }
      & Pick<UserAgentMutationType, 'updateUserAgent'>
    )> }
  )> };

export type DisableUserAgentConfigMutationVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type DisableUserAgentConfigMutation = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentMutationType' }
    & { userAgent?: Maybe<(
      { __typename?: 'UserAgentMutationType' }
      & Pick<UserAgentMutationType, 'disableUserAgent'>
    )> }
  )> };

export type GetUserDetailQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetUserDetailQuery = { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic' | 'gender' | 'departmentId' | 'jobTitleId' | 'jobFunctionId'>
    & { department?: Maybe<(
      { __typename?: 'HrLookup' }
      & Pick<HrLookup, 'name'>
    )>, jobTitle?: Maybe<(
      { __typename?: 'HrLookup' }
      & Pick<HrLookup, 'name'>
    )>, jobFunction?: Maybe<(
      { __typename?: 'HrLookup' }
      & Pick<HrLookup, 'name'>
    )> }
  )> };

export type CreateBusinessUserWithoutRoleMutationVariables = Exact<{
  model?: Maybe<CreateUserInput>;
}>;


export type CreateBusinessUserWithoutRoleMutation = { user?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'createUser'>
  )> };

export type GetUsersWithYearlyQuotaQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetUsersWithYearlyQuotaQuery = { paginatedUsers?: Maybe<(
    { __typename?: 'UserCollectionSegment' }
    & Pick<UserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
      & { leaves: Array<(
        { __typename?: 'Leave' }
        & LeaveFragmentFragment
      )>, quotas: Array<(
        { __typename?: 'Quota' }
        & QuotaFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type EvaluationFragment = (
  { __typename?: 'CandidateEvaluation' }
  & Pick<CandidateEvaluation, 'id' | 'candidateId' | 'jobPositionId' | 'userId' | 'weakness' | 'strength' | 'finalComment'>
  & { jobPosition?: Maybe<(
    { __typename?: 'JobPosition' }
    & { jobTitle?: Maybe<(
      { __typename?: 'HrLookup' }
      & Pick<HrLookup, 'name'>
    )> }
  )>, scores?: Maybe<Array<Maybe<(
    { __typename?: 'CandidateScore' }
    & Pick<CandidateScore, 'candidateEvaluationId' | 'interviewCategoryId' | 'interviewCriterionId' | 'remarks' | 'score'>
  )>>> }
);

export type GetCandidateEvaluationQueryVariables = Exact<{
  candidateId: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
}>;


export type GetCandidateEvaluationQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { candidateEvaluation?: Maybe<(
      { __typename?: 'CandidateEvaluation' }
      & EvaluationFragment
    )> }
  )> };

export type CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'businessId' | 'fullName' | 'email' | 'gender' | 'pic' | 'state' | 'fatherName' | 'phone' | 'mobilePhone' | 'presentAddress' | 'currentSalary' | 'expectedSalary' | 'religion' | 'resume'>
  & { candidatePositions?: Maybe<Array<Maybe<(
    { __typename?: 'CandidatePosition' }
    & Pick<CandidatePosition, 'state' | 'interviewDateTime' | 'jobPositionId'>
    & { jobPosition?: Maybe<(
      { __typename?: 'JobPosition' }
      & { jobTitle?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )> }
    )> }
  )>>> }
);

export type GetPaginatedCandidatesQueryVariables = Exact<{
  positionId: Scalars['Uuid'];
  filter?: Maybe<CandidateFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedCandidatesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobCandidates?: Maybe<(
      { __typename?: 'CandidateCollectionSegment' }
      & Pick<CandidateCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Candidate' }
        & CandidateFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetJobCandidateQueryVariables = Exact<{
  positionId: Scalars['Uuid'];
  id: Scalars['Uuid'];
}>;


export type GetJobCandidateQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobCandidates?: Maybe<(
      { __typename?: 'CandidateCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Candidate' }
        & CandidateFragment
      )>>> }
    )> }
  )> };

export type HireCandidateMutationVariables = Exact<{
  businessId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
}>;


export type HireCandidateMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { candidates?: Maybe<(
      { __typename?: 'CandidatesMutationType' }
      & Pick<CandidatesMutationType, 'hire'>
    )> }
  )> };

export type CloseCandidateMutationVariables = Exact<{
  businessId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
}>;


export type CloseCandidateMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { candidates?: Maybe<(
      { __typename?: 'CandidatesMutationType' }
      & Pick<CandidatesMutationType, 'close'>
    )> }
  )> };

export type SendInviteMutationVariables = Exact<{
  id: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  model: SendInviteInput;
}>;


export type SendInviteMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { candidates?: Maybe<(
      { __typename?: 'CandidatesMutationType' }
      & Pick<CandidatesMutationType, 'sendInvite'>
    )> }
  )> };

export type AddFeedBackMutationVariables = Exact<{
  businessId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  model: CreateCandidateEvaluationInput;
}>;


export type AddFeedBackMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { candidates?: Maybe<(
      { __typename?: 'CandidatesMutationType' }
      & Pick<CandidatesMutationType, 'addFeedBack'>
    )> }
  )> };

export type UpdateFeedBackMutationVariables = Exact<{
  businessId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  jobPositionId: Scalars['Uuid'];
  model: UpdateCandidateEvaluationInput;
}>;


export type UpdateFeedBackMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { candidates?: Maybe<(
      { __typename?: 'CandidatesMutationType' }
      & Pick<CandidatesMutationType, 'updateFeedBack'>
    )> }
  )> };

export type CertificationsFragment = (
  { __typename?: 'Certification' }
  & Pick<Certification, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedCertificationsQueryVariables = Exact<{
  filter?: Maybe<CertificationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedCertificationsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationCollectionSegment' }
      & Pick<CertificationCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Certification' }
        & CertificationsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetCertificationsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetCertificationsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationCollectionSegment' }
      & Pick<CertificationCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Certification' }
        & Pick<Certification, 'id' | 'name' | 'code'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetCertificationQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetCertificationQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Certification' }
        & CertificationsFragment
      )>>> }
    )> }
  )> };

export type AddCertificationMutationVariables = Exact<{
  model?: Maybe<CreateCertificationInput>;
}>;


export type AddCertificationMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationsMutationType' }
      & Pick<CertificationsMutationType, 'add'>
    )> }
  )> };

export type UpdateCertificationMutationVariables = Exact<{
  model?: Maybe<UpdateCertificationInput>;
}>;


export type UpdateCertificationMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationsMutationType' }
      & Pick<CertificationsMutationType, 'update'>
    )> }
  )> };

export type ArchiveCertificationMutationVariables = Exact<{
  certificationId: Scalars['Uuid'];
}>;


export type ArchiveCertificationMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationsMutationType' }
      & Pick<CertificationsMutationType, 'archive'>
    )> }
  )> };

export type RestoreCertificationMutationVariables = Exact<{
  certificationId: Scalars['Uuid'];
}>;


export type RestoreCertificationMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { certifications?: Maybe<(
      { __typename?: 'CertificationsMutationType' }
      & Pick<CertificationsMutationType, 'restore'>
    )> }
  )> };

export type ClientsFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
  & { owners?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'pic' | 'fullName' | 'firstName' | 'lastName'>
  )>>> }
);

export type GetPaginatedClientsQueryVariables = Exact<{
  filter?: Maybe<ClientFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedClientsQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientCollectionSegment' }
      & Pick<ClientCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Client' }
        & ClientsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetClientsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetClientsQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientCollectionSegment' }
      & Pick<ClientCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetClientQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetClientQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Client' }
        & ClientsFragment
      )>>> }
    )> }
  )> };

export type AddClientMutationVariables = Exact<{
  model?: Maybe<CreateClientInput>;
}>;


export type AddClientMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientsMutationType' }
      & Pick<ClientsMutationType, 'add'>
    )> }
  )> };

export type UpdateClientMutationVariables = Exact<{
  model?: Maybe<UpdateClientInput>;
}>;


export type UpdateClientMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientsMutationType' }
      & Pick<ClientsMutationType, 'update'>
    )> }
  )> };

export type ArchiveClientMutationVariables = Exact<{
  clientId: Scalars['Uuid'];
}>;


export type ArchiveClientMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientsMutationType' }
      & Pick<ClientsMutationType, 'archive'>
    )> }
  )> };

export type RestoreClientMutationVariables = Exact<{
  clientId: Scalars['Uuid'];
}>;


export type RestoreClientMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { clients?: Maybe<(
      { __typename?: 'ClientsMutationType' }
      & Pick<ClientsMutationType, 'restore'>
    )> }
  )> };

export type ContactsFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'name' | 'phone' | 'email' | 'addressId'>
);

export type GetContactsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetContactsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedContacts?: Maybe<(
      { __typename?: 'ContactCollectionSegment' }
      & Pick<ContactCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Contact' }
        & ContactsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetContactQueryVariables = Exact<{
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetContactQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedContacts?: Maybe<(
      { __typename?: 'ContactCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Contact' }
        & ContactsFragment
      )>>> }
    )> }
  )> };

export type GetContactWithAddressQueryVariables = Exact<{
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetContactWithAddressQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedContacts?: Maybe<(
      { __typename?: 'ContactCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'addressId'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'country' | 'city' | 'state' | 'zipCode' | 'timeZone'>
        )> }
        & ContactsFragment
      )>>> }
    )> }
  )> };

export type AddContactMutationVariables = Exact<{
  model?: Maybe<CreateContactInput>;
}>;


export type AddContactMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { contacts?: Maybe<(
      { __typename?: 'ContactsMutationType' }
      & Pick<ContactsMutationType, 'createContact'>
    )> }
  )> };

export type UpdateContactMutationVariables = Exact<{
  model?: Maybe<UpdateContactInput>;
}>;


export type UpdateContactMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { contacts?: Maybe<(
      { __typename?: 'ContactsMutationType' }
      & Pick<ContactsMutationType, 'updateContact'>
    )> }
  )> };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'numericCode' | 'timezones' | 'subRegion' | 'region' | 'callingCodes' | 'isoCode' | 'languages' | 'flag' | 'currencyString'>
    & { currencies?: Maybe<Array<Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'symbol' | 'name' | 'code'>
    )>>> }
  )>>> };

export type GetAllCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCurrenciesQuery = { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'currencyString'>
    & { currencies?: Maybe<Array<Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'symbol' | 'name' | 'code'>
    )>>> }
  )>>> };

export type GetCurrenciesByCountryQueryVariables = Exact<{
  id?: Maybe<Scalars['Byte']>;
}>;


export type GetCurrenciesByCountryQuery = { countries?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'currencyString'>
    & { currencies?: Maybe<Array<Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'symbol' | 'name' | 'code'>
    )>>> }
  )>>> };

export type DepartmentsFragment = (
  { __typename?: 'Department' }
  & Pick<Department, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedDepartmentsQueryVariables = Exact<{
  filter?: Maybe<DepartmentFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedDepartmentsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentCollectionSegment' }
      & Pick<DepartmentCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Department' }
        & DepartmentsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetDepartmentsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetDepartmentsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentCollectionSegment' }
      & Pick<DepartmentCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name' | 'code'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetDepartmentQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetDepartmentQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Department' }
        & DepartmentsFragment
      )>>> }
    )> }
  )> };

export type AddDepartmentMutationVariables = Exact<{
  model?: Maybe<CreateDepartmentInput>;
}>;


export type AddDepartmentMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentsMutationType' }
      & Pick<DepartmentsMutationType, 'add'>
    )> }
  )> };

export type UpdateDepartmentMutationVariables = Exact<{
  model?: Maybe<UpdateDepartmentInput>;
}>;


export type UpdateDepartmentMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentsMutationType' }
      & Pick<DepartmentsMutationType, 'update'>
    )> }
  )> };

export type ArchiveDepartmentMutationVariables = Exact<{
  departmentId: Scalars['Uuid'];
}>;


export type ArchiveDepartmentMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentsMutationType' }
      & Pick<DepartmentsMutationType, 'archive'>
    )> }
  )> };

export type RestoreDepartmentMutationVariables = Exact<{
  departmentId: Scalars['Uuid'];
}>;


export type RestoreDepartmentMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { departments?: Maybe<(
      { __typename?: 'DepartmentsMutationType' }
      & Pick<DepartmentsMutationType, 'restore'>
    )> }
  )> };

export type EmployeeDetailFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'userId' | 'officialEmail' | 'state' | 'fatherName' | 'phone' | 'mobilePhone' | 'dateOfAvailability' | 'dateOfBirth' | 'placeOfBirth' | 'presentAddress' | 'permanentAddress' | 'bloodGroup' | 'maritalStatus' | 'nameOfSpouse' | 'drivingLicenceNumber' | 'drivingLicenceIssueDate' | 'currentSalary' | 'expectedSalary' | 'paymentMethod' | 'religion' | 'cnic' | 'cnicExpiryDate' | 'domicile' | 'reportedToId'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'departmentId' | 'jobTitleId' | 'jobFunctionId' | 'fullName' | 'email' | 'gender' | 'pic' | 'payrollAccountId'>
  )>, children: Array<(
    { __typename?: 'EmployeeChildren' }
    & Pick<EmployeeChildren, 'age' | 'employeeId' | 'gender' | 'name'>
  )>, references: Array<(
    { __typename?: 'EmployeeReferences' }
    & Pick<EmployeeReferences, 'address' | 'contactNumber' | 'id' | 'name' | 'employeeId'>
  )>, relatives: Array<(
    { __typename?: 'EmployeeRelatives' }
    & Pick<EmployeeRelatives, 'employeeId' | 'id' | 'relationship' | 'relativeId'>
  )>, educations: Array<(
    { __typename?: 'EmployeeEducation' }
    & Pick<EmployeeEducation, 'employeeId' | 'grade' | 'id' | 'instituteId' | 'passingYear' | 'certificationId'>
  )>, experiences: Array<(
    { __typename?: 'EmployeeExperience' }
    & Pick<EmployeeExperience, 'designation' | 'employeeId' | 'employer' | 'from' | 'id' | 'reasonForLeaving' | 'to'>
  )> }
);

export type GetPaginatedEmployeesQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedEmployeesQuery = { paginatedUsers?: Maybe<(
    { __typename?: 'UserCollectionSegment' }
    & Pick<UserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'state' | 'email' | 'pic' | 'firstName' | 'lastName' | 'fullName' | 'departmentId' | 'jobTitleId' | 'jobFunctionId'>
      & { department?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, jobTitle?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, jobFunction?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'expectedSalary'>
      )> }
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetOffsetPaginatedEmployeesQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetOffsetPaginatedEmployeesQuery = { paginatedUsers?: Maybe<(
    { __typename?: 'UserCollectionSegment' }
    & Pick<UserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'state' | 'email' | 'pic' | 'firstName' | 'lastName' | 'fullName' | 'departmentId' | 'jobTitleId' | 'jobFunctionId'>
      & { department?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, jobTitle?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, jobFunction?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'expectedSalary'>
      )> }
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> };

export type GetEmployeesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetEmployeesQuery = { paginatedUsers?: Maybe<(
    { __typename?: 'UserCollectionSegment' }
    & Pick<UserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetEmployeeByUserIdQueryVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type GetEmployeeByUserIdQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Employee' }
        & EmployeeDetailFragment
      )>>> }
    )> }
  )> };

export type GetEmployeeSalaryBenefitsQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetEmployeeSalaryBenefitsQuery = { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { benefits: (
      { __typename?: 'UserBenefits' }
      & { eobiBenefit?: Maybe<(
        { __typename?: 'EOBIBenefit' }
        & Pick<EobiBenefit, 'enabled' | 'amount' | 'percentage' | 'overrideAmount' | 'overridePercentage'>
      )>, pfBenefit?: Maybe<(
        { __typename?: 'PFBenefit' }
        & Pick<PfBenefit, 'enabled' | 'overrideAmount' | 'overridePercentage'>
      )> }
    ) }
  )> };

export type EmployeeReportsTomeQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeReportsTomeQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & Pick<HrSessionQueryType, 'employeesReportsToMeIds'>
    )> }
  )> };

export type AddUpdateEmployeeEobiBenefitMutationVariables = Exact<{
  employeeId: Scalars['Uuid'];
  model?: Maybe<EobiBenefitVmInput>;
}>;


export type AddUpdateEmployeeEobiBenefitMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'addUpdateEmployeeEobiBenefit'>
    )> }
  )> };

export type GeneratPayrollMutationVariables = Exact<{ [key: string]: never; }>;


export type GeneratPayrollMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'generatPayroll'>
    )> }
  )> };

export type UpdateEmployeeMutationVariables = Exact<{
  model?: Maybe<UpdateEmployeeInput>;
}>;


export type UpdateEmployeeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'update'>
    )> }
  )> };

export type ArchiveEmployeeMutationVariables = Exact<{
  employeeId: Scalars['Uuid'];
}>;


export type ArchiveEmployeeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'archive'>
    )> }
  )> };

export type RestoreEmployeeMutationVariables = Exact<{
  employeeId: Scalars['Uuid'];
}>;


export type RestoreEmployeeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'restore'>
    )> }
  )> };

export type HireOrTerminateEmployeeMutationVariables = Exact<{
  employeeId: Scalars['Uuid'];
  reasonTypeId: Scalars['Uuid'];
  joiningDate?: Maybe<Scalars['DateTime']>;
  terminationDate?: Maybe<Scalars['DateTime']>;
  state: States;
}>;


export type HireOrTerminateEmployeeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'hireOrTerminate'>
    )> }
  )> };

export type UpdateHireOrTerminateEmployeeMutationVariables = Exact<{
  id: Scalars['Uuid'];
  employeeId: Scalars['Uuid'];
  reasonTypeId: Scalars['Uuid'];
  joiningDate?: Maybe<Scalars['DateTime']>;
  terminationDate?: Maybe<Scalars['DateTime']>;
  state: States;
}>;


export type UpdateHireOrTerminateEmployeeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'updateHireOrTerminate'>
    )> }
  )> };

export type UpdateSalaryMutationVariables = Exact<{
  employeeId: Scalars['Uuid'];
  model?: Maybe<CreateSalaryVmInput>;
}>;


export type UpdateSalaryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'updateSalary'>
    )> }
  )> };

export type AddUpdateEmployeePfBenefitMutationVariables = Exact<{
  employeeId: Scalars['Uuid'];
  model?: Maybe<PfBenefitVmInput>;
}>;


export type AddUpdateEmployeePfBenefitMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { employees?: Maybe<(
      { __typename?: 'EmployeesMutationType' }
      & Pick<EmployeesMutationType, 'addUpdateEmployeePfBenefit'>
    )> }
  )> };

export type GetEmploymentHistoryQueryVariables = Exact<{
  employeeId: Scalars['Uuid'];
}>;


export type GetEmploymentHistoryQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { employmentHistories?: Maybe<Array<Maybe<(
      { __typename?: 'EmploymentHistory' }
      & Pick<EmploymentHistory, 'id' | 'reasonTypeId' | 'employeeId' | 'joiningDate' | 'terminationDate' | 'createdOn' | 'state'>
      & { reasonType?: Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'name'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'fullName'>
      )> }
    )>>> }
  )> };

export type FinancialYearsFragment = (
  { __typename?: 'FinancialYear' }
  & Pick<FinancialYear, 'id' | 'name' | 'startDate' | 'endDate' | 'isActive'>
);

export type GetFinancialYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFinancialYearsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { session?: Maybe<(
      { __typename?: 'FinanceSessionQueryType' }
      & { financialYears?: Maybe<Array<Maybe<(
        { __typename?: 'FinancialYear' }
        & FinancialYearsFragment
      )>>> }
    )> }
  )> };

export type GetFinancialYearQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetFinancialYearQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { session?: Maybe<(
      { __typename?: 'FinanceSessionQueryType' }
      & { financialYear?: Maybe<(
        { __typename?: 'FinancialYear' }
        & FinancialYearsFragment
      )> }
    )> }
  )> };

export type AddFinancialYearMutationVariables = Exact<{
  model?: Maybe<CreateFinancialYearInput>;
}>;


export type AddFinancialYearMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { financialYears?: Maybe<(
      { __typename?: 'FinancialYearsMutationType' }
      & Pick<FinancialYearsMutationType, 'createFinancialYear'>
    )> }
  )> };

export type UpdateFinancialYearMutationVariables = Exact<{
  model?: Maybe<UpdateFinancialYearInput>;
}>;


export type UpdateFinancialYearMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { financialYears?: Maybe<(
      { __typename?: 'FinancialYearsMutationType' }
      & Pick<FinancialYearsMutationType, 'updateFinancialYear'>
    )> }
  )> };

export type ActiveFinancialYearQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveFinancialYearQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { session?: Maybe<(
      { __typename?: 'FinanceSessionQueryType' }
      & { activeFinancialYear?: Maybe<(
        { __typename?: 'FinancialYear' }
        & FinancialYearsFragment
      )> }
    )> }
  )> };

export type GenericTasksFragment = (
  { __typename?: 'GenericTask' }
  & Pick<GenericTask, 'id' | 'name' | 'description' | 'updatedOn' | 'state'>
);

export type GetPaginatedGenericTasksQueryVariables = Exact<{
  filter?: Maybe<GenericTaskFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedGenericTasksQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTaskCollectionSegment' }
      & Pick<GenericTaskCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'GenericTask' }
        & GenericTasksFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetGenericTasksQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetGenericTasksQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTaskCollectionSegment' }
      & Pick<GenericTaskCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'GenericTask' }
        & Pick<GenericTask, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetGenericTaskQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetGenericTaskQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTaskCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'GenericTask' }
        & GenericTasksFragment
      )>>> }
    )> }
  )> };

export type AddGenericTaskMutationVariables = Exact<{
  model?: Maybe<CreateGenericTaskInput>;
}>;


export type AddGenericTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTasksMutationType' }
      & Pick<GenericTasksMutationType, 'add'>
    )> }
  )> };

export type UpdateGenericTaskMutationVariables = Exact<{
  model?: Maybe<UpdateGenericTaskInput>;
}>;


export type UpdateGenericTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTasksMutationType' }
      & Pick<GenericTasksMutationType, 'update'>
    )> }
  )> };

export type ArchiveGenericTaskMutationVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type ArchiveGenericTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTasksMutationType' }
      & Pick<GenericTasksMutationType, 'archive'>
    )> }
  )> };

export type RestoreGenericTaskMutationVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type RestoreGenericTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { genericTasks?: Maybe<(
      { __typename?: 'GenericTasksMutationType' }
      & Pick<GenericTasksMutationType, 'restore'>
    )> }
  )> };

export type HolidaysFragment = (
  { __typename?: 'HolidayCalander' }
  & Pick<HolidayCalander, 'id' | 'eventName' | 'fromDate' | 'toDate'>
);

export type GetPaginatedHolidayCalanderQueryVariables = Exact<{
  filter?: Maybe<HolidayCalanderFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedHolidayCalanderQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { holidayCalanders?: Maybe<(
      { __typename?: 'HolidayCalanderCollectionSegment' }
      & Pick<HolidayCalanderCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HolidayCalander' }
        & HolidaysFragment
      )>>> }
    )> }
  )> };

export type GetAllHolidayCalanderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllHolidayCalanderQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { holidayCalanders?: Maybe<(
      { __typename?: 'HolidayCalanderCollectionSegment' }
      & Pick<HolidayCalanderCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HolidayCalander' }
        & HolidaysFragment
      )>>> }
    )> }
  )> };

export type GetholidayCalanderQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetholidayCalanderQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { holidayCalanders?: Maybe<(
      { __typename?: 'HolidayCalanderCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HolidayCalander' }
        & HolidaysFragment
      )>>> }
    )> }
  )> };

export type GetHolidayCalanderByDateQueryVariables = Exact<{
  reportDate: Scalars['DateTime'];
}>;


export type GetHolidayCalanderByDateQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { holidayCalanderWithDate?: Maybe<(
      { __typename?: 'HolidayCalanderCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HolidayCalander' }
        & HolidaysFragment
      )>>> }
    )> }
  )> };

export type AddHolidayCalanderMutationVariables = Exact<{
  model?: Maybe<CreateHolidayCalanderInput>;
}>;


export type AddHolidayCalanderMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { holidayCalander?: Maybe<(
      { __typename?: 'HolidayCalanderMutationType' }
      & Pick<HolidayCalanderMutationType, 'add'>
    )> }
  )> };

export type UpdateHolidayCalanderMutationVariables = Exact<{
  model?: Maybe<UpdateHolidayCalanderInput>;
}>;


export type UpdateHolidayCalanderMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { holidayCalander?: Maybe<(
      { __typename?: 'HolidayCalanderMutationType' }
      & Pick<HolidayCalanderMutationType, 'update'>
    )> }
  )> };

export type HrLookupsFragment = (
  { __typename?: 'HrLookup' }
  & Pick<HrLookup, 'id' | 'name' | 'code' | 'updatedOn' | 'state' | 'lookupSetting' | 'lookupModuleCode' | 'parentId'>
  & { parent?: Maybe<(
    { __typename?: 'HrLookup' }
    & Pick<HrLookup, 'name' | 'code'>
  )> }
);

export type GetPaginatedHrLookupsQueryVariables = Exact<{
  filter?: Maybe<HrLookupFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedHrLookupsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { hrLookups?: Maybe<(
      { __typename?: 'HrLookupCollectionSegment' }
      & Pick<HrLookupCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HrLookup' }
        & HrLookupsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetHrLookupsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  lookupModuleCode?: Maybe<Scalars['String']>;
  lookupSetting?: Maybe<Scalars['String']>;
}>;


export type GetHrLookupsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { hrLookups?: Maybe<(
      { __typename?: 'HrLookupCollectionSegment' }
      & Pick<HrLookupCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HrLookup' }
        & HrLookupsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetHrLookupQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetHrLookupQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { hrLookups?: Maybe<(
      { __typename?: 'HrLookupCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HrLookup' }
        & HrLookupsFragment
      )>>> }
    )> }
  )> };

export type AddHrLookupMutationVariables = Exact<{
  model?: Maybe<CreateHrLookupInput>;
}>;


export type AddHrLookupMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { hrLookup?: Maybe<(
      { __typename?: 'HrLookupMutationType' }
      & Pick<HrLookupMutationType, 'add'>
    )> }
  )> };

export type UpdateHrLookupMutationVariables = Exact<{
  model?: Maybe<UpdateHrLookupInput>;
}>;


export type UpdateHrLookupMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { hrLookup?: Maybe<(
      { __typename?: 'HrLookupMutationType' }
      & Pick<HrLookupMutationType, 'update'>
    )> }
  )> };

export type ArchiveHrLookupMutationVariables = Exact<{
  hrLookupId: Scalars['Uuid'];
}>;


export type ArchiveHrLookupMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { hrLookup?: Maybe<(
      { __typename?: 'HrLookupMutationType' }
      & Pick<HrLookupMutationType, 'archive'>
    )> }
  )> };

export type RestoreHrLookupMutationVariables = Exact<{
  hrLookupId: Scalars['Uuid'];
}>;


export type RestoreHrLookupMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { hrLookup?: Maybe<(
      { __typename?: 'HrLookupMutationType' }
      & Pick<HrLookupMutationType, 'restore'>
    )> }
  )> };

export type GetAllHrLookupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllHrLookupsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { hrLookups?: Maybe<(
      { __typename?: 'HrLookupCollectionSegment' }
      & Pick<HrLookupCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'HrLookup' }
        & HrLookupsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndustriesQuery = { industries?: Maybe<Array<Maybe<(
    { __typename?: 'Industry' }
    & Pick<Industry, 'id' | 'name'>
  )>>> };

export type PaginatedInstitutesFragment = (
  { __typename?: 'Institute' }
  & Pick<Institute, 'id' | 'name' | 'createdOn' | 'updatedOn' | 'state'>
);

export type GetPaginatedInstitutesQueryVariables = Exact<{
  filter?: Maybe<InstituteFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedInstitutesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstituteCollectionSegment' }
      & Pick<InstituteCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Institute' }
        & PaginatedInstitutesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInstitutesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetInstitutesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstituteCollectionSegment' }
      & Pick<InstituteCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Institute' }
        & Pick<Institute, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInstituteQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetInstituteQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstituteCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Institute' }
        & PaginatedInstitutesFragment
      )>>> }
    )> }
  )> };

export type AddInstituteMutationVariables = Exact<{
  model?: Maybe<CreateInstituteInput>;
}>;


export type AddInstituteMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstitutesMutationType' }
      & Pick<InstitutesMutationType, 'add'>
    )> }
  )> };

export type UpdateInstituteMutationVariables = Exact<{
  model?: Maybe<UpdateInstituteInput>;
}>;


export type UpdateInstituteMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstitutesMutationType' }
      & Pick<InstitutesMutationType, 'update'>
    )> }
  )> };

export type ArchiveInstituteMutationVariables = Exact<{
  instituteId: Scalars['Uuid'];
}>;


export type ArchiveInstituteMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstitutesMutationType' }
      & Pick<InstitutesMutationType, 'archive'>
    )> }
  )> };

export type RestoreInstituteMutationVariables = Exact<{
  instituteId: Scalars['Uuid'];
}>;


export type RestoreInstituteMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { institutes?: Maybe<(
      { __typename?: 'InstitutesMutationType' }
      & Pick<InstitutesMutationType, 'restore'>
    )> }
  )> };

export type InterviewCategoriesFragment = (
  { __typename?: 'InterviewCategory' }
  & Pick<InterviewCategory, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedInterviewCategoriesQueryVariables = Exact<{
  filter?: Maybe<InterviewCategoryFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedInterviewCategoriesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoryCollectionSegment' }
      & Pick<InterviewCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'InterviewCategory' }
        & InterviewCategoriesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInterviewCategoriesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetInterviewCategoriesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoryCollectionSegment' }
      & Pick<InterviewCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'InterviewCategory' }
        & Pick<InterviewCategory, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInterviewCategoryQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetInterviewCategoryQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoryCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'InterviewCategory' }
        & InterviewCategoriesFragment
      )>>> }
    )> }
  )> };

export type AddInterviewCategoryMutationVariables = Exact<{
  model?: Maybe<CreateInterviewCategoryInput>;
}>;


export type AddInterviewCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoriesMutationType' }
      & Pick<InterviewCategoriesMutationType, 'add'>
    )> }
  )> };

export type UpdateInterviewCategoryMutationVariables = Exact<{
  model?: Maybe<UpdateInterviewCategoryInput>;
}>;


export type UpdateInterviewCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoriesMutationType' }
      & Pick<InterviewCategoriesMutationType, 'update'>
    )> }
  )> };

export type ArchiveInterviewCategoryMutationVariables = Exact<{
  interviewCategoryId: Scalars['Uuid'];
}>;


export type ArchiveInterviewCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoriesMutationType' }
      & Pick<InterviewCategoriesMutationType, 'archive'>
    )> }
  )> };

export type RestoreInterviewCategoryMutationVariables = Exact<{
  interviewCategoryId: Scalars['Uuid'];
}>;


export type RestoreInterviewCategoryMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCategories?: Maybe<(
      { __typename?: 'InterviewCategoriesMutationType' }
      & Pick<InterviewCategoriesMutationType, 'restore'>
    )> }
  )> };

export type InterviewCriteriaFragment = (
  { __typename?: 'InterviewCriterion' }
  & Pick<InterviewCriterion, 'id' | 'name' | 'code' | 'score' | 'updatedOn' | 'state' | 'categoryId'>
  & { category?: Maybe<(
    { __typename?: 'InterviewCategory' }
    & Pick<InterviewCategory, 'name'>
  )> }
);

export type GetPaginatedInterviewCriterionQueryVariables = Exact<{
  filter?: Maybe<InterviewCriterionFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedInterviewCriterionQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriterionCollectionSegment' }
      & Pick<InterviewCriterionCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'InterviewCriterion' }
        & InterviewCriteriaFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInterviewCriteriaQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetInterviewCriteriaQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriterionCollectionSegment' }
      & Pick<InterviewCriterionCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'InterviewCriterion' }
        & Pick<InterviewCriterion, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInterviewCriterionQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetInterviewCriterionQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriterionCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'InterviewCriterion' }
        & InterviewCriteriaFragment
      )>>> }
    )> }
  )> };

export type AddInterviewCriterionMutationVariables = Exact<{
  model?: Maybe<CreateInterviewCriterionInput>;
}>;


export type AddInterviewCriterionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriteriaMutationType' }
      & Pick<InterviewCriteriaMutationType, 'add'>
    )> }
  )> };

export type UpdateInterviewCriterionMutationVariables = Exact<{
  model?: Maybe<UpdateInterviewCriterionInput>;
}>;


export type UpdateInterviewCriterionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriteriaMutationType' }
      & Pick<InterviewCriteriaMutationType, 'update'>
    )> }
  )> };

export type ArchiveInterviewCriterionMutationVariables = Exact<{
  interviewCriterionId: Scalars['Uuid'];
}>;


export type ArchiveInterviewCriterionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriteriaMutationType' }
      & Pick<InterviewCriteriaMutationType, 'archive'>
    )> }
  )> };

export type RestoreInterviewCriterionMutationVariables = Exact<{
  interviewCriterionId: Scalars['Uuid'];
}>;


export type RestoreInterviewCriterionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { interviewCriteria?: Maybe<(
      { __typename?: 'InterviewCriteriaMutationType' }
      & Pick<InterviewCriteriaMutationType, 'restore'>
    )> }
  )> };

export type GetInterviewersQueryVariables = Exact<{
  positionId: Scalars['Uuid'];
}>;


export type GetInterviewersQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { interviewers?: Maybe<(
      { __typename?: 'InterviewerCollectionSegment' }
      & Pick<InterviewerCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Interviewer' }
        & Pick<Interviewer, 'id' | 'userId' | 'jobPositionId'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type PaginatedJobPositionsFragment = (
  { __typename?: 'JobPosition' }
  & Pick<JobPosition, 'id' | 'businessId' | 'positions' | 'state' | 'departmentId' | 'jobTitleId' | 'description' | 'comment'>
  & { department?: Maybe<(
    { __typename?: 'HrLookup' }
    & Pick<HrLookup, 'name'>
  )>, jobTitle?: Maybe<(
    { __typename?: 'HrLookup' }
    & Pick<HrLookup, 'name' | 'code'>
  )> }
);

export type GetPaginatedJobPositionsQueryVariables = Exact<{
  filter?: Maybe<JobPositionFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedJobPositionsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionCollectionSegment' }
      & Pick<JobPositionCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobPosition' }
        & PaginatedJobPositionsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> };

export type GetJobPositionQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetJobPositionQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobPosition' }
        & Pick<JobPosition, 'id' | 'businessId' | 'positions' | 'state' | 'departmentId' | 'jobTitleId' | 'description' | 'comment'>
        & { jobTitle?: Maybe<(
          { __typename?: 'HrLookup' }
          & Pick<HrLookup, 'name'>
        )> }
      )>>> }
    )> }
  )> };

export type AddJobPositionMutationVariables = Exact<{
  model: CreateJobPositionInput;
}>;


export type AddJobPositionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionsMutationType' }
      & Pick<JobPositionsMutationType, 'add'>
    )> }
  )> };

export type UpdateJobPositionMutationVariables = Exact<{
  model: UpdateJobPositionInput;
}>;


export type UpdateJobPositionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionsMutationType' }
      & Pick<JobPositionsMutationType, 'update'>
    )> }
  )> };

export type ApproveJobPositionMutationVariables = Exact<{
  model: PostJobPositionInput;
}>;


export type ApproveJobPositionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionsMutationType' }
      & Pick<JobPositionsMutationType, 'approve'>
    )> }
  )> };

export type RejectJobPositionMutationVariables = Exact<{
  comment: Scalars['String'];
  jobPositionId: Scalars['Uuid'];
}>;


export type RejectJobPositionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionsMutationType' }
      & Pick<JobPositionsMutationType, 'reject'>
    )> }
  )> };

export type CloseJobPositionMutationVariables = Exact<{
  comment: Scalars['String'];
  jobPositionId: Scalars['Uuid'];
}>;


export type CloseJobPositionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionsMutationType' }
      & Pick<JobPositionsMutationType, 'close'>
    )> }
  )> };

export type UpdateJobPositionInterviewersMutationVariables = Exact<{
  model?: Maybe<UpdateInterviewerInput>;
}>;


export type UpdateJobPositionInterviewersMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobPositions?: Maybe<(
      { __typename?: 'JobPositionsMutationType' }
      & Pick<JobPositionsMutationType, 'updateInterviewers'>
    )> }
  )> };

export type JobFunctionsFragment = (
  { __typename?: 'JobFunction' }
  & Pick<JobFunction, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedJobFunctionsQueryVariables = Exact<{
  filter?: Maybe<JobFunctionFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedJobFunctionsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionCollectionSegment' }
      & Pick<JobFunctionCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobFunction' }
        & JobFunctionsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetJobFunctionsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetJobFunctionsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionCollectionSegment' }
      & Pick<JobFunctionCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobFunction' }
        & Pick<JobFunction, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetJobFunctionQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetJobFunctionQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobFunction' }
        & JobFunctionsFragment
      )>>> }
    )> }
  )> };

export type AddJobFunctionMutationVariables = Exact<{
  model?: Maybe<CreateJobFunctionInput>;
}>;


export type AddJobFunctionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionsMutationType' }
      & Pick<JobFunctionsMutationType, 'add'>
    )> }
  )> };

export type UpdateJobFunctionMutationVariables = Exact<{
  model?: Maybe<UpdateJobFunctionInput>;
}>;


export type UpdateJobFunctionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionsMutationType' }
      & Pick<JobFunctionsMutationType, 'update'>
    )> }
  )> };

export type ArchiveJobFunctionMutationVariables = Exact<{
  jobFunctionId: Scalars['Uuid'];
}>;


export type ArchiveJobFunctionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionsMutationType' }
      & Pick<JobFunctionsMutationType, 'archive'>
    )> }
  )> };

export type RestoreJobFunctionMutationVariables = Exact<{
  jobFunctionId: Scalars['Uuid'];
}>;


export type RestoreJobFunctionMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobFunctions?: Maybe<(
      { __typename?: 'JobFunctionsMutationType' }
      & Pick<JobFunctionsMutationType, 'restore'>
    )> }
  )> };

export type JobTitlesFragment = (
  { __typename?: 'JobTitle' }
  & Pick<JobTitle, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedJobTitlesQueryVariables = Exact<{
  filter?: Maybe<JobTitleFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedJobTitlesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitleCollectionSegment' }
      & Pick<JobTitleCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobTitle' }
        & JobTitlesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetJobTitlesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetJobTitlesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitleCollectionSegment' }
      & Pick<JobTitleCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetJobTitleQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetJobTitleQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitleCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'JobTitle' }
        & JobTitlesFragment
      )>>> }
    )> }
  )> };

export type AddJobTitleMutationVariables = Exact<{
  model?: Maybe<CreateJobTitleInput>;
}>;


export type AddJobTitleMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitlesMutationType' }
      & Pick<JobTitlesMutationType, 'add'>
    )> }
  )> };

export type UpdateJobTitleMutationVariables = Exact<{
  model?: Maybe<UpdateJobTitleInput>;
}>;


export type UpdateJobTitleMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitlesMutationType' }
      & Pick<JobTitlesMutationType, 'update'>
    )> }
  )> };

export type ArchiveJobTitleMutationVariables = Exact<{
  jobTitleId: Scalars['Uuid'];
}>;


export type ArchiveJobTitleMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitlesMutationType' }
      & Pick<JobTitlesMutationType, 'archive'>
    )> }
  )> };

export type RestoreJobTitleMutationVariables = Exact<{
  jobTitleId: Scalars['Uuid'];
}>;


export type RestoreJobTitleMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { jobTitles?: Maybe<(
      { __typename?: 'JobTitlesMutationType' }
      & Pick<JobTitlesMutationType, 'restore'>
    )> }
  )> };

export type LeaveFragmentFragment = (
  { __typename?: 'Leave' }
  & Pick<Leave, 'id' | 'createdOn' | 'updatedOn' | 'createdById' | 'remarks' | 'state' | 'fromDate' | 'toDate' | 'weight' | 'reason' | 'isShortLeave' | 'leaveTypeId' | 'userId'>
  & { leaveType?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'name' | 'code'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email'>
  )> }
);

export type GetLeavesQueryVariables = Exact<{
  filter?: Maybe<LeaveFilterInput>;
  year?: Maybe<Scalars['String']>;
}>;


export type GetLeavesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { leaves?: Maybe<Array<Maybe<(
      { __typename?: 'Leave' }
      & LeaveFragmentFragment
    )>>> }
  )> };

export type GetLeaveQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetLeaveQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { leave?: Maybe<(
      { __typename?: 'Leave' }
      & Pick<Leave, 'leaveTypeId' | 'userId'>
      & LeaveFragmentFragment
    )> }
  )> };

export type AddLeaveMutationVariables = Exact<{
  model?: Maybe<CreateLeaveInput>;
}>;


export type AddLeaveMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaves?: Maybe<(
      { __typename?: 'LeavesMutationType' }
      & Pick<LeavesMutationType, 'add'>
    )> }
  )> };

export type AddUserLeaveMutationVariables = Exact<{
  model?: Maybe<CreateLeaveInput>;
  userId: Scalars['Uuid'];
}>;


export type AddUserLeaveMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaves?: Maybe<(
      { __typename?: 'LeavesMutationType' }
      & Pick<LeavesMutationType, 'addUserLeave'>
    )> }
  )> };

export type UpdateLeaveMutationVariables = Exact<{
  model?: Maybe<UpdateLeaveInput>;
}>;


export type UpdateLeaveMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaves?: Maybe<(
      { __typename?: 'LeavesMutationType' }
      & Pick<LeavesMutationType, 'update'>
    )> }
  )> };

export type RecallMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type RecallMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaves?: Maybe<(
      { __typename?: 'LeavesMutationType' }
      & Pick<LeavesMutationType, 'recall'>
    )> }
  )> };

export type LeaveTypesFragment = (
  { __typename?: 'BusinessLookup' }
  & BusinessLookupFragment
);

export type GetAllLeaveTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllLeaveTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { leaveTypes?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & LeaveTypesFragment
    )>>> }
  )> };

export type GetPaginatedLeaveTypesQueryVariables = Exact<{
  filter?: Maybe<BusinessLookupFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedLeaveTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { paginatedLeaveTypes?: Maybe<(
      { __typename?: 'BusinessLookupCollectionSegment' }
      & Pick<BusinessLookupCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BusinessLookup' }
        & LeaveTypesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetLeaveTypesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetLeaveTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { paginatedLeaveTypes?: Maybe<(
      { __typename?: 'BusinessLookupCollectionSegment' }
      & Pick<BusinessLookupCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BusinessLookup' }
        & Pick<BusinessLookup, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetLeaveTypeQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetLeaveTypeQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { paginatedLeaveTypes?: Maybe<(
      { __typename?: 'BusinessLookupCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'BusinessLookup' }
        & LeaveTypesFragment
      )>>> }
    )> }
  )> };

export type AddLeaveTypeMutationVariables = Exact<{
  model?: Maybe<CreateLeaveTypeInput>;
  updateExisting: Scalars['Boolean'];
}>;


export type AddLeaveTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaveTypes?: Maybe<(
      { __typename?: 'LeaveTypesMutationType' }
      & Pick<LeaveTypesMutationType, 'add'>
    )> }
  )> };

export type UpdateLeaveTypeMutationVariables = Exact<{
  model?: Maybe<UpdateLeaveTypeInput>;
  updateExisting: Scalars['Boolean'];
}>;


export type UpdateLeaveTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaveTypes?: Maybe<(
      { __typename?: 'LeaveTypesMutationType' }
      & Pick<LeaveTypesMutationType, 'update'>
    )> }
  )> };

export type ArchiveLeaveTypeMutationVariables = Exact<{
  leaveTypeId: Scalars['Uuid'];
  updateExisting: Scalars['Boolean'];
}>;


export type ArchiveLeaveTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaveTypes?: Maybe<(
      { __typename?: 'LeaveTypesMutationType' }
      & Pick<LeaveTypesMutationType, 'archive'>
    )> }
  )> };

export type RestoreLeaveTypeMutationVariables = Exact<{
  leaveTypeId: Scalars['Uuid'];
  updateExisting: Scalars['Boolean'];
}>;


export type RestoreLeaveTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { leaveTypes?: Maybe<(
      { __typename?: 'LeaveTypesMutationType' }
      & Pick<LeaveTypesMutationType, 'restore'>
    )> }
  )> };

export type LoanFragmentFragment = (
  { __typename?: 'Loan' }
  & Pick<Loan, 'id' | 'amountApplied' | 'amountApproved' | 'remarks' | 'state' | 'numberOfInstallments' | 'purposeOfLoan'>
  & { loanType?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'name'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'fullName'>
  )> }
);

export type GetMyPaginatedLoansQueryVariables = Exact<{
  filter?: Maybe<LoanFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetMyPaginatedLoansQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { loans?: Maybe<(
        { __typename?: 'LoanCollectionSegment' }
        & Pick<LoanCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Loan' }
          & LoanFragmentFragment
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
        ) }
      )> }
    )> }
  )> };

export type GetPaginatedLoansQueryVariables = Exact<{
  filter?: Maybe<LoanFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedLoansQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { loans?: Maybe<(
      { __typename?: 'LoanCollectionSegment' }
      & Pick<LoanCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Loan' }
        & LoanFragmentFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
      ) }
    )> }
  )> };

export type GetLoanQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetLoanQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { loans?: Maybe<(
      { __typename?: 'LoanCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Loan' }
        & Pick<Loan, 'userId' | 'loanTypeId'>
        & LoanFragmentFragment
      )>>> }
    )> }
  )> };

export type AddLoanMutationVariables = Exact<{
  model?: Maybe<CreateLoanInput>;
}>;


export type AddLoanMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { loans?: Maybe<(
      { __typename?: 'LoansMutationType' }
      & Pick<LoansMutationType, 'add'>
    )> }
  )> };

export type UpdateLoanMutationVariables = Exact<{
  model?: Maybe<UpdateLoanInput>;
}>;


export type UpdateLoanMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { loans?: Maybe<(
      { __typename?: 'LoansMutationType' }
      & Pick<LoansMutationType, 'update'>
    )> }
  )> };

export type LoanTypesFragment = (
  { __typename?: 'LoanType' }
  & Pick<LoanType, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedLoanTypesQueryVariables = Exact<{
  filter?: Maybe<LoanTypeFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedLoanTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypeCollectionSegment' }
      & Pick<LoanTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'LoanType' }
        & LoanTypesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetLoanTypesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetLoanTypesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypeCollectionSegment' }
      & Pick<LoanTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'LoanType' }
        & Pick<LoanType, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetLoanTypeQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetLoanTypeQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'LoanType' }
        & LoanTypesFragment
      )>>> }
    )> }
  )> };

export type AddLoanTypeMutationVariables = Exact<{
  model?: Maybe<CreateLoanTypeInput>;
}>;


export type AddLoanTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypesMutationType' }
      & Pick<LoanTypesMutationType, 'add'>
    )> }
  )> };

export type UpdateLoanTypeMutationVariables = Exact<{
  model?: Maybe<UpdateLoanTypeInput>;
}>;


export type UpdateLoanTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypesMutationType' }
      & Pick<LoanTypesMutationType, 'update'>
    )> }
  )> };

export type ArchiveLoanTypeMutationVariables = Exact<{
  loanTypeId: Scalars['Uuid'];
}>;


export type ArchiveLoanTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypesMutationType' }
      & Pick<LoanTypesMutationType, 'archive'>
    )> }
  )> };

export type RestoreLoanTypeMutationVariables = Exact<{
  loanTypeId: Scalars['Uuid'];
}>;


export type RestoreLoanTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { loanTypes?: Maybe<(
      { __typename?: 'LoanTypesMutationType' }
      & Pick<LoanTypesMutationType, 'restore'>
    )> }
  )> };

export type MedicalClaimFragmentFragment = (
  { __typename?: 'MedicalClaim' }
  & Pick<MedicalClaim, 'id' | 'userId' | 'amount' | 'amountApproved' | 'state' | 'createdOn' | 'updatedOn'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
  )> }
);

export type GetMyPaginatedMedicalClaimsQueryVariables = Exact<{
  filter?: Maybe<MedicalClaimFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetMyPaginatedMedicalClaimsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { medicalClaims?: Maybe<(
        { __typename?: 'MedicalClaimCollectionSegment' }
        & Pick<MedicalClaimCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'MedicalClaim' }
          & MedicalClaimFragmentFragment
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
        ) }
      )> }
    )> }
  )> };

export type GetPaginatedMedicalClaimsQueryVariables = Exact<{
  filter?: Maybe<MedicalClaimFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedMedicalClaimsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { medicalClaims?: Maybe<(
      { __typename?: 'MedicalClaimCollectionSegment' }
      & Pick<MedicalClaimCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'MedicalClaim' }
        & MedicalClaimFragmentFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
      ) }
    )> }
  )> };

export type GetMedicalClaimQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetMedicalClaimQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { medicalClaims?: Maybe<(
      { __typename?: 'MedicalClaimCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'MedicalClaim' }
        & Pick<MedicalClaim, 'userId' | 'balanceCf'>
        & { medicalClaimBreakdowns?: Maybe<Array<Maybe<(
          { __typename?: 'MedicalClaimBreakdown' }
          & Pick<MedicalClaimBreakdown, 'name' | 'medicalClaimId' | 'amount' | 'billNumber' | 'file'>
        )>>> }
        & MedicalClaimFragmentFragment
      )>>> }
    )> }
  )> };

export type GetMedicalClaimDetailsQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetMedicalClaimDetailsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { medicalClaim?: Maybe<(
      { __typename?: 'MedicalClaim' }
      & Pick<MedicalClaim, 'userId' | 'state' | 'balanceCf' | 'claimLookupId' | 'amount' | 'amountApproved' | 'createdOn'>
      & { medicalClaimBreakdowns?: Maybe<Array<Maybe<(
        { __typename?: 'MedicalClaimBreakdown' }
        & Pick<MedicalClaimBreakdown, 'name' | 'medicalClaimId' | 'amount' | 'billNumber' | 'file'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'fullName' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> };

export type UpdateMedicalClaimMutationVariables = Exact<{
  model?: Maybe<UpdateMedicalClaimInput>;
}>;


export type UpdateMedicalClaimMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { medicalClaims?: Maybe<(
      { __typename?: 'MedicalClaimsMutationType' }
      & Pick<MedicalClaimsMutationType, 'update'>
    )> }
  )> };

export type GetApprovedMedicalClaimAmountQueryVariables = Exact<{
  userId: Scalars['Uuid'];
  claimLookupId: Scalars['Uuid'];
}>;


export type GetApprovedMedicalClaimAmountQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & Pick<HrSessionQueryType, 'approvedAmount'>
    )> }
  )> };

export type NotesdetailFragment = (
  { __typename?: 'NoteDateil' }
  & Pick<NoteDateil, 'id' | 'objectID' | 'noteText' | 'noteModuleCode' | 'updatedOn' | 'updatedById' | 'createdOn' | 'createdById' | 'userName' | 'noteType' | 'noteTypeCode' | 'noteTypeId'>
  & { attachments?: Maybe<Array<Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'path'>
  )>>> }
);

export type GetPaginatedNotesDataQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  moduleId: Scalars['Uuid'];
  noteTypeId?: Maybe<Scalars['Uuid']>;
}>;


export type GetPaginatedNotesDataQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { notesDetails?: Maybe<(
      { __typename?: 'NoteDateilCollectionSegment' }
      & Pick<NoteDateilCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'NoteDateil' }
        & NotesdetailFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetPaginatedNotesNotQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  moduleId: Scalars['Uuid'];
  noteTypeId?: Maybe<Scalars['Uuid']>;
}>;


export type GetPaginatedNotesNotQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { notesDetails?: Maybe<(
      { __typename?: 'NoteDateilCollectionSegment' }
      & Pick<NoteDateilCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'NoteDateil' }
        & NotesdetailFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetNoteByObjectIdQueryVariables = Exact<{
  moduleId: Scalars['Uuid'];
}>;


export type GetNoteByObjectIdQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { notesDetails?: Maybe<(
      { __typename?: 'NoteDateilCollectionSegment' }
      & Pick<NoteDateilCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'NoteDateil' }
        & NotesdetailFragment
      )>>> }
    )> }
  )> };

export type NotesWithTicketStatusQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  moduleId: Scalars['Uuid'];
}>;


export type NotesWithTicketStatusQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { notesWithTicketStatus?: Maybe<(
      { __typename?: 'NoteDateilCollectionSegment' }
      & Pick<NoteDateilCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'NoteDateil' }
        & NotesdetailFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type NotificationFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'message' | 'isRead' | 'createdOn' | 'type' | 'systemObject' | 'systemObjectId'>
);

export type GetNotificationsQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetNotificationsQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { notifications?: Maybe<(
      { __typename?: 'NotificationCollectionSegment' }
      & Pick<NotificationCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & NotificationFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetLatestNotificationSubscriptionVariables = Exact<{
  businessId: Scalars['Uuid'];
  sessionId: Scalars['Uuid'];
}>;


export type GetLatestNotificationSubscription = { showLatestNotification?: Maybe<(
    { __typename?: 'Notification' }
    & NotificationFragment
  )> };

export type MarkAsReadMutationVariables = Exact<{
  notificationId: Scalars['Uuid'];
}>;


export type MarkAsReadMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & { notifications?: Maybe<(
      { __typename?: 'NotificationMutationType' }
      & Pick<NotificationMutationType, 'markAsRead'>
    )> }
  )> };

export type MarkAllAsReadMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type MarkAllAsReadMutation = { business?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & { notifications?: Maybe<(
      { __typename?: 'NotificationMutationType' }
      & Pick<NotificationMutationType, 'markAllAsRead'>
    )> }
  )> };

export type ProductListFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'number' | 'upcNumber' | 'price' | 'description' | 'name' | 'state'>
  & { category?: Maybe<(
    { __typename?: 'ThirdLevelAccount' }
    & Pick<ThirdLevelAccount, 'name'>
  )> }
);

export type ProductDetailFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'purchaseUnit' | 'saleUnit' | 'inventoryUnit' | 'incomeAccountId' | 'expenseAccountId' | 'number' | 'upcNumber' | 'price' | 'productType' | 'isCatchWeightProduct' | 'description' | 'name' | 'categoryId' | 'taxIds'>
);

export type GetPaginatedProductsQueryVariables = Exact<{
  filter?: Maybe<ProductFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedProductsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedProducts?: Maybe<(
      { __typename?: 'ProductCollectionSegment' }
      & Pick<ProductCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & ProductListFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetProductsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetProductsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { paginatedProducts?: Maybe<(
      { __typename?: 'ProductCollectionSegment' }
      & Pick<ProductCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetProductQueryVariables = Exact<{
  id?: Maybe<Scalars['Uuid']>;
}>;


export type GetProductQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & ProductDetailFragment
    )>>> }
  )> };

export type AddProductMutationVariables = Exact<{
  model?: Maybe<CreateProductInput>;
}>;


export type AddProductMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { products?: Maybe<(
      { __typename?: 'ProductsMutationType' }
      & Pick<ProductsMutationType, 'createProduct'>
    )> }
  )> };

export type UpdateProductMutationVariables = Exact<{
  model?: Maybe<UpdateProductInput>;
}>;


export type UpdateProductMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { products?: Maybe<(
      { __typename?: 'ProductsMutationType' }
      & Pick<ProductsMutationType, 'updateProduct'>
    )> }
  )> };

export type ArchiveProductMutationVariables = Exact<{
  productId: Scalars['Uuid'];
}>;


export type ArchiveProductMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { products?: Maybe<(
      { __typename?: 'ProductsMutationType' }
      & Pick<ProductsMutationType, 'archive'>
    )> }
  )> };

export type RestoreProductMutationVariables = Exact<{
  productId: Scalars['Uuid'];
}>;


export type RestoreProductMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { products?: Maybe<(
      { __typename?: 'ProductsMutationType' }
      & Pick<ProductsMutationType, 'restore'>
    )> }
  )> };

export type GetProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductTypesQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { productTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id' | 'name'>
    )>>> }
  )> };

export type GetProductUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductUnitsQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { productUnits?: Maybe<Array<Maybe<(
      { __typename?: 'ProductUnit' }
      & Pick<ProductUnit, 'id' | 'name'>
    )>>> }
  )> };

export type ProjectFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'clientId' | 'tasksCount' | 'description' | 'createdOn' | 'updatedOn' | 'state'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'name'>
  )>, projectUserRoles?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectUserRole' }
    & Pick<ProjectUserRole, 'projectRole' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
    )> }
  )>>> }
);

export type GetPaginatedProjectsQueryVariables = Exact<{
  filter?: Maybe<ProjectFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedProjectsQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectCollectionSegment' }
      & Pick<ProjectCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & ProjectFragmentFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetProjectsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetProjectsQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & { activeProjects?: Maybe<(
        { __typename?: 'ProjectCollectionSegment' }
        & Pick<ProjectCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name'>
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetMyPaginatedProjectsQueryVariables = Exact<{
  filter?: Maybe<ProjectFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetMyPaginatedProjectsQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & { projects?: Maybe<(
        { __typename?: 'ProjectCollectionSegment' }
        & Pick<ProjectCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Project' }
          & ProjectFragmentFragment
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetProjectQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetProjectQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & ProjectFragmentFragment
      )>>> }
    )> }
  )> };

export type GetMyProjectQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetMyProjectQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & { projects?: Maybe<(
        { __typename?: 'ProjectCollectionSegment' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Project' }
          & ProjectFragmentFragment
        )>>> }
      )> }
    )> }
  )> };

export type GetMyProjectMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProjectMembersQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & Pick<TimeTrackingSessionQueryType, 'getMyProjectMembers'>
    )> }
  )> };

export type GetMyRoleInProjectQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
}>;


export type GetMyRoleInProjectQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & Pick<TimeTrackingSessionQueryType, 'myRoleInProject'>
    )> }
  )> };

export type GetProjectUsersQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
}>;


export type GetProjectUsersQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projectUserRoles?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectUserRole' }
      & Pick<ProjectUserRole, 'projectRole' | 'userId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
      )> }
    )>>> }
  )> };

export type GetProjectUserRolesQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
}>;


export type GetProjectUserRolesQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projectUserRoles?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectUserRole' }
      & Pick<ProjectUserRole, 'projectRole' | 'userId'>
    )>>> }
  )> };

export type GetMyAssignedProjectRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAssignedProjectRolesQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & { projectUserRoles?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectUserRole' }
        & Pick<ProjectUserRole, 'projectId' | 'projectRole'>
      )>>> }
    )> }
  )> };

export type AssignProjectUsersMutationVariables = Exact<{
  model?: Maybe<AssignProjectUsersInput>;
}>;


export type AssignProjectUsersMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectsMutationType' }
      & Pick<ProjectsMutationType, 'assignUsers'>
    )> }
  )> };

export type AddProjectMutationVariables = Exact<{
  model?: Maybe<CreateProjectInput>;
}>;


export type AddProjectMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectsMutationType' }
      & Pick<ProjectsMutationType, 'add'>
    )> }
  )> };

export type UpdateProjectMutationVariables = Exact<{
  model?: Maybe<UpdateProjectInput>;
}>;


export type UpdateProjectMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectsMutationType' }
      & Pick<ProjectsMutationType, 'update'>
    )> }
  )> };

export type ArchiveProjectMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  clientId: Scalars['Uuid'];
}>;


export type ArchiveProjectMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectsMutationType' }
      & Pick<ProjectsMutationType, 'archive'>
    )> }
  )> };

export type RestoreProjectMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  clientId: Scalars['Uuid'];
}>;


export type RestoreProjectMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectsMutationType' }
      & Pick<ProjectsMutationType, 'restore'>
    )> }
  )> };

export type CloseProjectMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  clientId: Scalars['Uuid'];
}>;


export type CloseProjectMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projects?: Maybe<(
      { __typename?: 'ProjectsMutationType' }
      & Pick<ProjectsMutationType, 'close'>
    )> }
  )> };

export type GetProjectRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectRolesQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projectRoles?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectRole' }
      & Pick<ProjectRole, 'id' | 'name'>
    )>>> }
  )> };

export type SubTasksFragment = (
  { __typename?: 'SubTask' }
  & Pick<SubTask, 'id' | 'parentTaskId' | 'projectId' | 'name' | 'expectedEffort' | 'description' | 'updatedOn' | 'state'>
);

export type ProjectTasksFragment = (
  { __typename?: 'ProjectTask' }
  & Pick<ProjectTask, 'id' | 'projectId' | 'name' | 'expectedEffort' | 'description' | 'updatedOn' | 'state' | 'subTasksCount'>
);

export type GetPaginatedProjectTasksQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
  filter?: Maybe<ProjectTaskFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedProjectTasksQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTaskCollectionSegment' }
      & Pick<ProjectTaskCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectTask' }
        & ProjectTasksFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetProjectTasksQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetProjectTasksQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTaskCollectionSegment' }
      & Pick<ProjectTaskCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectTask' }
        & Pick<ProjectTask, 'id' | 'name' | 'subTasksCount'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetProjectTaskQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
  id: Scalars['Uuid'];
}>;


export type GetProjectTaskQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTaskCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectTask' }
        & Pick<ProjectTask, 'projectId'>
        & ProjectTasksFragment
      )>>> }
    )> }
  )> };

export type AddProjectTaskMutationVariables = Exact<{
  model?: Maybe<CreateProjectTaskInput>;
}>;


export type AddProjectTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTasksMutationType' }
      & Pick<ProjectTasksMutationType, 'add'>
    )> }
  )> };

export type UpdateProjectTaskMutationVariables = Exact<{
  model?: Maybe<UpdateProjectTaskInput>;
}>;


export type UpdateProjectTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTasksMutationType' }
      & Pick<ProjectTasksMutationType, 'update'>
    )> }
  )> };

export type ArchiveProjectTaskMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
}>;


export type ArchiveProjectTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTasksMutationType' }
      & Pick<ProjectTasksMutationType, 'archive'>
    )> }
  )> };

export type RestoreProjectTaskMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
}>;


export type RestoreProjectTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { projectTasks?: Maybe<(
      { __typename?: 'ProjectTasksMutationType' }
      & Pick<ProjectTasksMutationType, 'restore'>
    )> }
  )> };

export type GetPaginatedSubTasksQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
  parentTaskId: Scalars['Uuid'];
  filter?: Maybe<SubTaskFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedSubTasksQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTaskCollectionSegment' }
      & Pick<SubTaskCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'SubTask' }
        & SubTasksFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetSubTasksQueryVariables = Exact<{
  projectId: Scalars['Uuid'];
  parentTaskId: Scalars['Uuid'];
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetSubTasksQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTaskCollectionSegment' }
      & Pick<SubTaskCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'SubTask' }
        & Pick<SubTask, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetSubTaskQueryVariables = Exact<{
  id: Scalars['Uuid'];
  parentTaskId: Scalars['Uuid'];
  projectId: Scalars['Uuid'];
}>;


export type GetSubTaskQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTaskCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'SubTask' }
        & Pick<SubTask, 'projectId' | 'parentTaskId'>
        & SubTasksFragment
      )>>> }
    )> }
  )> };

export type AddSubTaskMutationVariables = Exact<{
  model?: Maybe<CreateSubTaskInput>;
}>;


export type AddSubTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTasksMutationType' }
      & Pick<SubTasksMutationType, 'add'>
    )> }
  )> };

export type UpdateSubTaskMutationVariables = Exact<{
  model?: Maybe<UpdateSubTaskInput>;
}>;


export type UpdateSubTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTasksMutationType' }
      & Pick<SubTasksMutationType, 'update'>
    )> }
  )> };

export type ArchiveSubTaskMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  subTaskId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
}>;


export type ArchiveSubTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTasksMutationType' }
      & Pick<SubTasksMutationType, 'archive'>
    )> }
  )> };

export type RestoreSubTaskMutationVariables = Exact<{
  projectId: Scalars['Uuid'];
  subTaskId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
}>;


export type RestoreSubTaskMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { subTasks?: Maybe<(
      { __typename?: 'SubTasksMutationType' }
      & Pick<SubTasksMutationType, 'restore'>
    )> }
  )> };

export type ReasonTypesFragment = (
  { __typename?: 'ReasonType' }
  & Pick<ReasonType, 'id' | 'name' | 'code' | 'updatedOn' | 'state'>
);

export type GetPaginatedHiringReasonsQueryVariables = Exact<{
  filter?: Maybe<ReasonTypeFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedHiringReasonsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypeCollectionSegment' }
      & Pick<ReasonTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ReasonType' }
        & ReasonTypesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetHiringReasonsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetHiringReasonsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypeCollectionSegment' }
      & Pick<ReasonTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ReasonType' }
        & Pick<ReasonType, 'id' | 'name' | 'code'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetHiringReasonQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetHiringReasonQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ReasonType' }
        & ReasonTypesFragment
      )>>> }
    )> }
  )> };

export type GetPaginatedTerminationReasonsQueryVariables = Exact<{
  filter?: Maybe<ReasonTypeFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedTerminationReasonsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypeCollectionSegment' }
      & Pick<ReasonTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ReasonType' }
        & ReasonTypesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTerminationReasonsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetTerminationReasonsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypeCollectionSegment' }
      & Pick<ReasonTypeCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ReasonType' }
        & Pick<ReasonType, 'id' | 'name' | 'code'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTerminationReasonQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetTerminationReasonQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypeCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'ReasonType' }
        & ReasonTypesFragment
      )>>> }
    )> }
  )> };

export type AddTerminationReasonTypeMutationVariables = Exact<{
  model?: Maybe<CreateReasonTypeInput>;
}>;


export type AddTerminationReasonTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypesMutationType' }
      & Pick<ReasonTypesMutationType, 'addTerminationReasonType'>
    )> }
  )> };

export type AddHiringReasonTypeMutationVariables = Exact<{
  model?: Maybe<CreateReasonTypeInput>;
}>;


export type AddHiringReasonTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypesMutationType' }
      & Pick<ReasonTypesMutationType, 'addHiringReasonType'>
    )> }
  )> };

export type UpdateReasonTypeMutationVariables = Exact<{
  model?: Maybe<UpdateReasonTypeInput>;
}>;


export type UpdateReasonTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypesMutationType' }
      & Pick<ReasonTypesMutationType, 'update'>
    )> }
  )> };

export type ArchiveReasonTypeMutationVariables = Exact<{
  reasonId: Scalars['Uuid'];
}>;


export type ArchiveReasonTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypesMutationType' }
      & Pick<ReasonTypesMutationType, 'archive'>
    )> }
  )> };

export type RestoreReasonTypeMutationVariables = Exact<{
  reasonId: Scalars['Uuid'];
}>;


export type RestoreReasonTypeMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { reasonTypes?: Maybe<(
      { __typename?: 'ReasonTypesMutationType' }
      & Pick<ReasonTypesMutationType, 'restore'>
    )> }
  )> };

export type ReimbursementFragmentFragment = (
  { __typename?: 'Reimbursement' }
  & Pick<Reimbursement, 'id' | 'userId' | 'amount' | 'amountApproved' | 'state' | 'createdOn' | 'updatedOn' | 'appliedOn' | 'authorizedOn'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
  )>, businessLookups?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'name'>
  )>, authorizedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'>
  )> }
);

export type ReimbursementDetailsFragmentFragment = (
  { __typename?: 'ReimbursementDetail' }
  & Pick<ReimbursementDetail, 'id' | 'description' | 'reimbursementId' | 'amount' | 'referenceNumber' | 'file'>
);

export type GetMyPaginatedReimbursementsQueryVariables = Exact<{
  filter?: Maybe<ReimbursementFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetMyPaginatedReimbursementsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { reimbursements?: Maybe<(
        { __typename?: 'ReimbursementCollectionSegment' }
        & Pick<ReimbursementCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Reimbursement' }
          & { reimbursementDetails?: Maybe<Array<Maybe<(
            { __typename?: 'ReimbursementDetail' }
            & ReimbursementDetailsFragmentFragment
          )>>> }
          & ReimbursementFragmentFragment
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
        ) }
      )> }
    )> }
  )> };

export type GetPaginatedReimbursementsQueryVariables = Exact<{
  filter?: Maybe<ReimbursementFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedReimbursementsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reimbursements?: Maybe<(
      { __typename?: 'ReimbursementCollectionSegment' }
      & Pick<ReimbursementCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Reimbursement' }
        & { reimbursementDetails?: Maybe<Array<Maybe<(
          { __typename?: 'ReimbursementDetail' }
          & ReimbursementDetailsFragmentFragment
        )>>> }
        & ReimbursementFragmentFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
      ) }
    )> }
  )> };

export type GetReimbursementQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetReimbursementQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reimbursements?: Maybe<(
      { __typename?: 'ReimbursementCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Reimbursement' }
        & Pick<Reimbursement, 'userId'>
        & { reimbursementDetails?: Maybe<Array<Maybe<(
          { __typename?: 'ReimbursementDetail' }
          & ReimbursementDetailsFragmentFragment
        )>>> }
        & ReimbursementFragmentFragment
      )>>> }
    )> }
  )> };

export type GetReimbursementDetailsQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetReimbursementDetailsQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { reimbursement?: Maybe<(
      { __typename?: 'Reimbursement' }
      & Pick<Reimbursement, 'userId' | 'state' | 'reimbursementTypeId' | 'amount' | 'amountApproved' | 'createdOn'>
      & { reimbursementDetails?: Maybe<Array<Maybe<(
        { __typename?: 'ReimbursementDetail' }
        & ReimbursementDetailsFragmentFragment
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'fullName' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> };

export type UpdateReimbursementMutationVariables = Exact<{
  model?: Maybe<UpdateReimbursementInput>;
}>;


export type UpdateReimbursementMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { reimbursement?: Maybe<(
      { __typename?: 'ReimbursementMutationType' }
      & Pick<ReimbursementMutationType, 'update'>
    )> }
  )> };

export type GetEmployeeSalariesQueryVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type GetEmployeeSalariesQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { salaries?: Maybe<Array<Maybe<(
      { __typename?: 'Salary' }
      & Pick<Salary, 'userId' | 'id' | 'basicSalary' | 'netSalary' | 'createdOn' | 'endDate' | 'startDate'>
      & { allowances?: Maybe<Array<Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'id' | 'name'>
      )>>>, bonuses?: Maybe<Array<Maybe<(
        { __typename?: 'HrLookup' }
        & Pick<HrLookup, 'id' | 'name' | 'lookupSetting'>
      )>>>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'email'>
      )> }
    )>>> }
  )> };

export type SitesFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'siteNo' | 'siteName' | 'address1' | 'address2' | 'city' | 'siteState' | 'zip' | 'phone' | 'longitude' | 'latitude' | 'state' | 'parentId' | 'siteManagerId'>
  & { name: Site['siteName'] }
  & { parent?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'zip' | 'phone' | 'siteNo' | 'siteName' | 'address1' | 'address2' | 'city' | 'siteState'>
  )> }
);

export type GetPaginatedSitesQueryVariables = Exact<{
  filter?: Maybe<SiteFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedSitesQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { ticketsSites?: Maybe<(
      { __typename?: 'SiteCollectionSegment' }
      & Pick<SiteCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Site' }
        & SitesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetSiteQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetSiteQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { ticketsSites?: Maybe<(
      { __typename?: 'SiteCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Site' }
        & SitesFragment
      )>>> }
    )> }
  )> };

export type GetSitesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetSitesQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { ticketsSites?: Maybe<(
      { __typename?: 'SiteCollectionSegment' }
      & Pick<SiteCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Site' }
        & SitesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetSiteNoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSiteNoQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & Pick<SupportTicketQueryType, 'siteNo'>
  )> };

export type AddSiteMutationVariables = Exact<{
  model?: Maybe<CreateSiteInput>;
}>;


export type AddSiteMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { site?: Maybe<(
      { __typename?: 'SiteMutationType' }
      & Pick<SiteMutationType, 'add'>
    )> }
  )> };

export type UpdateSiteMutationVariables = Exact<{
  model?: Maybe<UpdateSiteInput>;
}>;


export type UpdateSiteMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { site?: Maybe<(
      { __typename?: 'SiteMutationType' }
      & Pick<SiteMutationType, 'update'>
    )> }
  )> };

export type ArchiveSiteMutationVariables = Exact<{
  siteId: Scalars['Uuid'];
}>;


export type ArchiveSiteMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { site?: Maybe<(
      { __typename?: 'SiteMutationType' }
      & Pick<SiteMutationType, 'archive'>
    )> }
  )> };

export type RestoreSiteMutationVariables = Exact<{
  siteId: Scalars['Uuid'];
}>;


export type RestoreSiteMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { site?: Maybe<(
      { __typename?: 'SiteMutationType' }
      & Pick<SiteMutationType, 'restore'>
    )> }
  )> };

export type GetAgentSnapsQueryVariables = Exact<{
  filter?: Maybe<SnapshotFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAgentSnapsQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { snaps?: Maybe<(
      { __typename?: 'SnapsQueryType' }
      & { snapshots?: Maybe<(
        { __typename?: 'SnapshotCollectionSegment' }
        & Pick<SnapshotCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Snapshot' }
          & Pick<Snapshot, 'id' | 'businessId' | 'machine' | 'date' | 'iPAddress' | 'username' | 'hour' | 'networkName'>
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAgentSnapshotMachinesQueryVariables = Exact<{
  filter?: Maybe<MachineVmFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAgentSnapshotMachinesQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { snaps?: Maybe<(
      { __typename?: 'SnapsQueryType' }
      & { machines?: Maybe<(
        { __typename?: 'MachineVmCollectionSegment' }
        & Pick<MachineVmCollectionSegment, 'totalCount'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'MachineVm' }
          & Pick<MachineVm, 'name'>
        )>>>, pageInfo: (
          { __typename?: 'CollectionSegmentInfo' }
          & CollectionInfoFragment
        ) }
      )> }
    )> }
  )> };

export type GetAgentSnapshotBreakdownQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetAgentSnapshotBreakdownQuery = { trackingAgent?: Maybe<(
    { __typename?: 'TrackingAgentQueryType' }
    & { snaps?: Maybe<(
      { __typename?: 'SnapsQueryType' }
      & { snapshots?: Maybe<(
        { __typename?: 'SnapshotCollectionSegment' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Snapshot' }
          & Pick<Snapshot, 'id' | 'snapshotBreakdowns' | 'snapshotCount' | 'businessId' | 'machine' | 'date' | 'iPAddress' | 'username' | 'hour' | 'networkName'>
        )>>> }
      )> }
    )> }
  )> };

export type TaxFragmentFragment = (
  { __typename?: 'Tax' }
  & Pick<Tax, 'id' | 'name' | 'rate' | 'abbreviation'>
);

export type GetTaxesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaxesQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { session?: Maybe<(
      { __typename?: 'FinanceSessionQueryType' }
      & { taxes?: Maybe<Array<Maybe<(
        { __typename?: 'Tax' }
        & TaxFragmentFragment
      )>>> }
    )> }
  )> };

export type GetTaxQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetTaxQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { session?: Maybe<(
      { __typename?: 'FinanceSessionQueryType' }
      & { taxes?: Maybe<Array<Maybe<(
        { __typename?: 'Tax' }
        & Pick<Tax, 'description' | 'abbreviation' | 'number' | 'isCompoundTax' | 'isTaxRecoverable' | 'showTaxOnInvoices'>
        & TaxFragmentFragment
      )>>> }
    )> }
  )> };

export type AddTaxMutationVariables = Exact<{
  model?: Maybe<AddTaxInput>;
}>;


export type AddTaxMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { taxes?: Maybe<(
      { __typename?: 'TaxMutationType' }
      & Pick<TaxMutationType, 'createTax'>
    )> }
  )> };

export type UpdateTaxMutationVariables = Exact<{
  model?: Maybe<UpdateTaxInput>;
}>;


export type UpdateTaxMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { taxes?: Maybe<(
      { __typename?: 'TaxMutationType' }
      & Pick<TaxMutationType, 'updateTax'>
    )> }
  )> };

export type TicketCategoriesFragment = (
  { __typename?: 'TicketCategory' }
  & Pick<TicketCategory, 'id' | 'name' | 'updatedOn' | 'state'>
);

export type GetPaginatedTicketCategoriesQueryVariables = Exact<{
  filter?: Maybe<TicketCategoryFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedTicketCategoriesQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { ticketsCategories?: Maybe<(
      { __typename?: 'TicketCategoryCollectionSegment' }
      & Pick<TicketCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'TicketCategory' }
        & TicketCategoriesFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTicketCategoriesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetTicketCategoriesQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { ticketsCategories?: Maybe<(
      { __typename?: 'TicketCategoryCollectionSegment' }
      & Pick<TicketCategoryCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'TicketCategory' }
        & Pick<TicketCategory, 'id' | 'name'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTicketCategoryQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetTicketCategoryQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { ticketsCategories?: Maybe<(
      { __typename?: 'TicketCategoryCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'TicketCategory' }
        & TicketCategoriesFragment
      )>>> }
    )> }
  )> };

export type AddTicketCategoryMutationVariables = Exact<{
  model?: Maybe<CreateTicketCategoryVmInput>;
}>;


export type AddTicketCategoryMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { ticketCategories?: Maybe<(
      { __typename?: 'TicketCategoryMutationType' }
      & Pick<TicketCategoryMutationType, 'create'>
    )> }
  )> };

export type UpdateTicketCategoryMutationVariables = Exact<{
  model?: Maybe<UpdateTicketCategoryVmInput>;
}>;


export type UpdateTicketCategoryMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { ticketCategories?: Maybe<(
      { __typename?: 'TicketCategoryMutationType' }
      & Pick<TicketCategoryMutationType, 'update'>
    )> }
  )> };

export type ArchiveTicketCategoryMutationVariables = Exact<{
  ticketCategoryId: Scalars['Uuid'];
}>;


export type ArchiveTicketCategoryMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { ticketCategories?: Maybe<(
      { __typename?: 'TicketCategoryMutationType' }
      & Pick<TicketCategoryMutationType, 'archive'>
    )> }
  )> };

export type RestoreTicketCategoryMutationVariables = Exact<{
  ticketCategoryId: Scalars['Uuid'];
}>;


export type RestoreTicketCategoryMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { ticketCategories?: Maybe<(
      { __typename?: 'TicketCategoryMutationType' }
      & Pick<TicketCategoryMutationType, 'restore'>
    )> }
  )> };

export type TicketLaborFragment = (
  { __typename?: 'TicketLaborWithUserDetail' }
  & Pick<TicketLaborWithUserDetail, 'id' | 'ticketId' | 'hours' | 'hoursType' | 'billable' | 'rate' | 'workerId' | 'workerName'>
);

export type GetTicketLaborsQueryVariables = Exact<{
  ticketId: Scalars['Uuid'];
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetTicketLaborsQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { ticketLabor?: Maybe<(
      { __typename?: 'TicketLaborWithUserDetailCollectionSegment' }
      & Pick<TicketLaborWithUserDetailCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'TicketLaborWithUserDetail' }
        & TicketLaborFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> };

export type GetTicketLaborByWorkerQueryVariables = Exact<{
  ticketId: Scalars['Uuid'];
  workerId?: Maybe<Scalars['Uuid']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetTicketLaborByWorkerQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { ticketLabor?: Maybe<(
      { __typename?: 'TicketLaborWithUserDetailCollectionSegment' }
      & Pick<TicketLaborWithUserDetailCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'TicketLaborWithUserDetail' }
        & TicketLaborFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> };

export type UpdateTicketLaborMutationVariables = Exact<{
  model?: Maybe<UpdateTicketLaborInput>;
}>;


export type UpdateTicketLaborMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { ticketLabor?: Maybe<(
      { __typename?: 'TicketLaborMutationType' }
      & Pick<TicketLaborMutationType, 'update'>
    )> }
  )> };

export type AddTicketLaborMutationVariables = Exact<{
  model?: Maybe<CreateTicketLaborInput>;
}>;


export type AddTicketLaborMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { ticketLabor?: Maybe<(
      { __typename?: 'TicketLaborMutationType' }
      & Pick<TicketLaborMutationType, 'add'>
    )> }
  )> };

export type UpdateBillableMutationVariables = Exact<{
  model?: Maybe<UpdateTicketLaborInput>;
}>;


export type UpdateBillableMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { ticketLabor?: Maybe<(
      { __typename?: 'TicketLaborMutationType' }
      & Pick<TicketLaborMutationType, 'updateBillable'>
    )> }
  )> };

export type TicketMaterialFragment = (
  { __typename?: 'TicketMaterial' }
  & Pick<TicketMaterial, 'id' | 'taxSettings' | 'ticketId' | 'productId' | 'quantity' | 'amount' | 'description' | 'discount' | 'price' | 'tax'>
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'name' | 'price'>
  )> }
);

export type GetTicketMaterialsQueryVariables = Exact<{
  ticketId: Scalars['Uuid'];
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetTicketMaterialsQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { ticketMaterial?: Maybe<(
      { __typename?: 'TicketMaterialCollectionSegment' }
      & Pick<TicketMaterialCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'TicketMaterial' }
        & TicketMaterialFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> };

export type AddTicketMaterialsMutationVariables = Exact<{
  model?: Maybe<Array<Maybe<CreateTicketMaterialInput>> | Maybe<CreateTicketMaterialInput>>;
}>;


export type AddTicketMaterialsMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { ticketMaterial?: Maybe<(
      { __typename?: 'TicketMaterialMutationType' }
      & Pick<TicketMaterialMutationType, 'add'>
    )> }
  )> };

export type TicketsFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'id' | 'title' | 'description' | 'updatedOn' | 'state' | 'categoryId' | 'assignedUserId' | 'createdById' | 'createdOn'>
  & { attachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'path'>
  )>, category?: Maybe<(
    { __typename?: 'TicketCategory' }
    & Pick<TicketCategory, 'name'>
  )>, assignedUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
  )> }
);

export type GetPaginatedTicketsQueryVariables = Exact<{
  filter?: Maybe<TicketFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedTicketsQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketCollectionSegment' }
      & Pick<TicketCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Ticket' }
        & TicketsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTicketsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetTicketsQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketCollectionSegment' }
      & Pick<TicketCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Ticket' }
        & Pick<Ticket, 'id' | 'title'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTicketQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetTicketQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Ticket' }
        & Pick<Ticket, 'id' | 'title' | 'description' | 'updatedOn' | 'state' | 'categoryId' | 'assignedUserId' | 'createdById' | 'createdOn'>
        & { attachment?: Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'id' | 'path'>
        )>, category?: Maybe<(
          { __typename?: 'TicketCategory' }
          & Pick<TicketCategory, 'name'>
        )>, assignedUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'fullName' | 'email' | 'pic'>
        )> }
      )>>> }
    )> }
  )> };

export type AssignTicketMutationVariables = Exact<{
  assignedUserId: Scalars['Uuid'];
  ticketId: Scalars['Uuid'];
}>;


export type AssignTicketMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketMutationType' }
      & Pick<TicketMutationType, 'assign'>
    )> }
  )> };

export type WithdrawTicketMutationVariables = Exact<{
  TicketId: Scalars['Uuid'];
}>;


export type WithdrawTicketMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketMutationType' }
      & Pick<TicketMutationType, 'withdraw'>
    )> }
  )> };

export type RestoreTicketMutationVariables = Exact<{
  TicketId: Scalars['Uuid'];
}>;


export type RestoreTicketMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketMutationType' }
      & Pick<TicketMutationType, 'restore'>
    )> }
  )> };

export type ArchiveTicketMutationVariables = Exact<{
  TicketId: Scalars['Uuid'];
}>;


export type ArchiveTicketMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketMutationType' }
      & Pick<TicketMutationType, 'archive'>
    )> }
  )> };

export type ResolveTicketMutationVariables = Exact<{
  TicketId: Scalars['Uuid'];
}>;


export type ResolveTicketMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketMutationType' }
      & Pick<TicketMutationType, 'resolve'>
    )> }
  )> };

export type ProcessTicketMutationVariables = Exact<{
  TicketId: Scalars['Uuid'];
}>;


export type ProcessTicketMutation = { support?: Maybe<(
    { __typename?: 'SupportMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketMutationType' }
      & Pick<TicketMutationType, 'process'>
    )> }
  )> };

export type GetPaginatedCommentsQueryVariables = Exact<{
  ticketId: Scalars['Uuid'];
  filter?: Maybe<TicketFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedCommentsQuery = { support?: Maybe<(
    { __typename?: 'SupportQueryType' }
    & { ticketComments?: Maybe<(
      { __typename?: 'TicketCollectionSegment' }
      & Pick<TicketCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Ticket' }
        & Pick<Ticket, 'id' | 'parentTicketId' | 'description' | 'createdOn' | 'createdById'>
        & { attachment?: Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'id' | 'path'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'email' | 'fullName'>
        )> }
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type AddTicketStatusMutationVariables = Exact<{
  model?: Maybe<CreateTicketStatusChangeInput>;
}>;


export type AddTicketStatusMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { ticketStatusChange?: Maybe<(
      { __typename?: 'TicketStatusChangeMutationType' }
      & Pick<TicketStatusChangeMutationType, 'add'>
    )> }
  )> };

export type UpdateTicketStatusMutationVariables = Exact<{
  model?: Maybe<UpdateTicketStatusChangeInput>;
}>;


export type UpdateTicketStatusMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { ticketStatusChange?: Maybe<(
      { __typename?: 'TicketStatusChangeMutationType' }
      & Pick<TicketStatusChangeMutationType, 'update'>
    )> }
  )> };

export type TktTicketsFragment = (
  { __typename?: 'Tickets' }
  & Pick<Tickets, 'id' | 'executionEndDate' | 'executionStartDate' | 'firstResponseDate' | 'problemId' | 'priorityId' | 'reportedById' | 'scheduleDate' | 'ticketNo' | 'ticketDescription' | 'siteId' | 'businessId' | 'completeDate' | 'completionDuration' | 'dueDate' | 'entryDate' | 'state'>
  & { problemLookup?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'name' | 'code' | 'id' | 'setting'>
  )>, priorityLookup?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'name' | 'code' | 'id' | 'setting'>
  )>, reportedUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'fullName'>
  )>, ticketStatusChanges?: Maybe<Array<Maybe<(
    { __typename?: 'TicketStatusChange' }
    & Pick<TicketStatusChange, 'changeStatusId' | 'latitude' | 'longitude' | 'statusType' | 'changeById' | 'assignedUserId' | 'changeOn' | 'description'>
    & { statusLookup?: Maybe<(
      { __typename?: 'BusinessLookup' }
      & Pick<BusinessLookup, 'name' | 'code' | 'setting'>
    )>, changedUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'fullName'>
    )>, assignedUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'fullName'>
    )> }
  )>>>, site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'siteNo' | 'siteName' | 'address1' | 'address2' | 'city' | 'siteState' | 'zip' | 'phone' | 'longitude' | 'latitude' | 'state'>
  )> }
);

export type GetPaginatedTktTicketsQueryVariables = Exact<{
  filter?: Maybe<TicketsFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedTktTicketsQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsCollectionSegment' }
      & Pick<TicketsCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Tickets' }
        & TktTicketsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetTktTicketQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetTktTicketQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Tickets' }
        & TktTicketsFragment
      )>>> }
    )> }
  )> };

export type GetTicketNoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTicketNoQuery = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketQueryType' }
    & Pick<SupportTicketQueryType, 'ticketNo'>
  )> };

export type AddTicketsMutationVariables = Exact<{
  model?: Maybe<CreateTicketsInput>;
}>;


export type AddTicketsMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsMutationType' }
      & Pick<TicketsMutationType, 'add'>
    )> }
  )> };

export type UpdateTicketsMutationVariables = Exact<{
  model?: Maybe<UpdateTicketsInput>;
}>;


export type UpdateTicketsMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsMutationType' }
      & Pick<TicketsMutationType, 'update'>
    )> }
  )> };

export type ScheduleTicketsMutationVariables = Exact<{
  model?: Maybe<UpdateTicketsInput>;
}>;


export type ScheduleTicketsMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsMutationType' }
      & Pick<TicketsMutationType, 'scheduleTicket'>
    )> }
  )> };

export type UpdateTicketPriorityMutationVariables = Exact<{
  model?: Maybe<UpdateTicketsInput>;
}>;


export type UpdateTicketPriorityMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsMutationType' }
      & Pick<TicketsMutationType, 'ticketPriority'>
    )> }
  )> };

export type ArchiveTicketsMutationVariables = Exact<{
  ticketId: Scalars['Uuid'];
}>;


export type ArchiveTicketsMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsMutationType' }
      & Pick<TicketsMutationType, 'archive'>
    )> }
  )> };

export type RestoreTicketsMutationVariables = Exact<{
  ticketId: Scalars['Uuid'];
}>;


export type RestoreTicketsMutation = { supportTicket?: Maybe<(
    { __typename?: 'SupportTicketMutationType' }
    & { tickets?: Maybe<(
      { __typename?: 'TicketsMutationType' }
      & Pick<TicketsMutationType, 'restore'>
    )> }
  )> };

export type PaginatedTimeLogsFragment = (
  { __typename?: 'TimeLog' }
  & Pick<TimeLog, 'id' | 'projectId' | 'taskId' | 'genericTaskId' | 'subTaskId' | 'description' | 'timeSpan' | 'date'>
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, 'name' | 'state'>
  ), task: (
    { __typename?: 'ProjectTask' }
    & Pick<ProjectTask, 'name' | 'state'>
  ), genericTask?: Maybe<(
    { __typename?: 'GenericTask' }
    & Pick<GenericTask, 'name' | 'state'>
  )>, subTask?: Maybe<(
    { __typename?: 'SubTask' }
    & Pick<SubTask, 'name' | 'state'>
  )> }
);

export type GetPaginatedTimeLogsQueryVariables = Exact<{
  filter?: Maybe<TimeLogFilterInput>;
}>;


export type GetPaginatedTimeLogsQuery = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingQueryType' }
    & { session?: Maybe<(
      { __typename?: 'TimeTrackingSessionQueryType' }
      & { timeLogs?: Maybe<Array<Maybe<(
        { __typename?: 'TimeLog' }
        & PaginatedTimeLogsFragment
      )>>> }
    )> }
  )> };

export type AddTimeLogsMutationVariables = Exact<{
  model?: Maybe<CreateTimeLogInput>;
}>;


export type AddTimeLogsMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { timeLogs?: Maybe<(
      { __typename?: 'TimeLogsMutationType' }
      & Pick<TimeLogsMutationType, 'add'>
    )> }
  )> };

export type RemoveTimeLogsMutationVariables = Exact<{
  timeLogIds?: Maybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type RemoveTimeLogsMutation = { timeTracking?: Maybe<(
    { __typename?: 'TimeTrackingMutationType' }
    & { timeLogs?: Maybe<(
      { __typename?: 'TimeLogsMutationType' }
      & Pick<TimeLogsMutationType, 'remove'>
    )> }
  )> };

export type VoucherDetailsFragment = (
  { __typename?: 'Voucher' }
  & Pick<Voucher, 'voucherTypeId' | 'id' | 'notes' | 'date' | 'number'>
  & { attachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'path'>
  )>, lineItems: Array<(
    { __typename?: 'VoucherLineItem' }
    & Pick<VoucherLineItem, 'voucherLineType' | 'accountId' | 'description' | 'amount' | 'sortOrder'>
    & { account: (
      { __typename?: 'ThirdLevelAccount' }
      & Pick<ThirdLevelAccount, 'name'>
    ) }
  )> }
);

export type CashVoucherDetailsFragment = (
  { __typename?: 'CashVoucher' }
  & Pick<CashVoucher, 'id' | 'description' | 'date' | 'number' | 'systemAccountId' | 'accountId' | 'amount'>
  & { attachment?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'path'>
  )>, account?: Maybe<(
    { __typename?: 'ThirdLevelAccount' }
    & Pick<ThirdLevelAccount, 'name'>
  )> }
);

export type InvoiceDetailsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'dueDate' | 'deliveryAddressId' | 'commissionAccountId' | 'number' | 'notes' | 'accountId' | 'amount' | 'state' | 'date' | 'createdOn'>
  & { account?: Maybe<(
    { __typename?: 'ThirdLevelAccount' }
    & Pick<ThirdLevelAccount, 'name'>
  )> }
);

export type GetVoucherByIdQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetVoucherByIdQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { vouchers?: Maybe<(
      { __typename?: 'VoucherCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Voucher' }
        & VoucherDetailsFragment
      )>>> }
    )> }
  )> };

export type GetPaginatedVouchersQueryVariables = Exact<{
  filter?: Maybe<VoucherFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedVouchersQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { vouchers?: Maybe<(
      { __typename?: 'VoucherCollectionSegment' }
      & Pick<VoucherCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Voucher' }
        & VoucherDetailsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetPaginatedSalaryVouchersQueryVariables = Exact<{
  filter?: Maybe<VoucherFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  departmentId?: Maybe<Scalars['Uuid']>;
}>;


export type GetPaginatedSalaryVouchersQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { salaryVouchers?: Maybe<(
      { __typename?: 'VoucherCollectionSegment' }
      & Pick<VoucherCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Voucher' }
        & Pick<Voucher, 'voucherTypeId' | 'id' | 'notes' | 'date' | 'number'>
        & { attachment?: Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'path'>
        )>, lineItems: Array<(
          { __typename?: 'VoucherLineItem' }
          & Pick<VoucherLineItem, 'voucherLineType' | 'accountId' | 'description' | 'amount' | 'sortOrder'>
          & { account: (
            { __typename?: 'ThirdLevelAccount' }
            & Pick<ThirdLevelAccount, 'name' | 'isPayRollAccount'>
          ) }
        )> }
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type ClosingBalanceQueryVariables = Exact<{
  date: Scalars['DateTime'];
}>;


export type ClosingBalanceQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & Pick<FinanceQueryType, 'closingBalance'>
  )> };

export type CashInHandQueryVariables = Exact<{
  date: Scalars['DateTime'];
}>;


export type CashInHandQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & Pick<FinanceQueryType, 'cashInHand'>
  )> };

export type GetLatestSequenceNumberQueryVariables = Exact<{
  voucherCode?: Maybe<Scalars['String']>;
}>;


export type GetLatestSequenceNumberQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & Pick<FinanceQueryType, 'voucherSequenceNumber'>
  )> };

export type GetAccountVouchersQueryVariables = Exact<{
  accountId: Scalars['Uuid'];
  filter?: Maybe<VoucherFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetAccountVouchersQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { accountVouchers?: Maybe<(
      { __typename?: 'VoucherCollectionSegment' }
      & Pick<VoucherCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Voucher' }
        & VoucherDetailsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetCashVoucherByIdQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetCashVoucherByIdQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { cashVouchers?: Maybe<(
      { __typename?: 'CashVoucherCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'CashVoucher' }
        & CashVoucherDetailsFragment
      )>>> }
    )> }
  )> };

export type GetPaginatedCashVouchersQueryVariables = Exact<{
  filter?: Maybe<CashVoucherFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedCashVouchersQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { cashVouchers?: Maybe<(
      { __typename?: 'CashVoucherCollectionSegment' }
      & Pick<CashVoucherCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'CashVoucher' }
        & CashVoucherDetailsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetInvoiceByIdQueryVariables = Exact<{
  type: VoucherTypes;
  id: Scalars['Uuid'];
}>;


export type GetInvoiceByIdQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & { details?: Maybe<Array<Maybe<(
          { __typename?: 'InvoiceDetail' }
          & Pick<InvoiceDetail, 'id' | 'productId' | 'quantity' | 'price' | 'discount' | 'amount'>
          & { invoiceDetailTax?: Maybe<Array<Maybe<(
            { __typename?: 'InvoiceDetailTax' }
            & Pick<InvoiceDetailTax, 'id' | 'taxId' | 'invoiceDetailID' | 'taxRate' | 'taxAmount'>
          )>>>, product?: Maybe<(
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'taxIds' | 'purchaseUnit' | 'description' | 'price'>
          )> }
        )>>>, attachment?: Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'path'>
        )> }
        & InvoiceDetailsFragment
      )>>> }
    )> }
  )> };

export type GetPaginatedInvoicesQueryVariables = Exact<{
  type: VoucherTypes;
  filter: InvoiceFilterInput;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<InvoiceSortInput> | InvoiceSortInput>;
}>;


export type GetPaginatedInvoicesQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & Pick<InvoiceCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & InvoiceDetailsFragment
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type DraftPurchaseOrderMutationVariables = Exact<{
  purchaseOrderId: Scalars['Uuid'];
}>;


export type DraftPurchaseOrderMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { purchaseVouchers?: Maybe<(
      { __typename?: 'InvoicesMutationType' }
      & Pick<InvoicesMutationType, 'draftPurchaseOrder'>
    )> }
  )> };

export type ApprovePurchaseOrderMutationVariables = Exact<{
  purchaseOrderId: Scalars['Uuid'];
}>;


export type ApprovePurchaseOrderMutation = { finance?: Maybe<(
    { __typename?: 'FinanceMutationType' }
    & { purchaseVouchers?: Maybe<(
      { __typename?: 'InvoicesMutationType' }
      & Pick<InvoicesMutationType, 'approvePurchaseOrder'>
    )> }
  )> };

export type PurchaseOrdersCountQueryVariables = Exact<{
  approvedFilter: InvoiceFilterInput;
  draftFilter: InvoiceFilterInput;
  allFilter: InvoiceFilterInput;
}>;


export type PurchaseOrdersCountQuery = { approved?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & Pick<InvoiceCollectionSegment, 'totalCount'>
    )> }
  )>, draft?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & Pick<InvoiceCollectionSegment, 'totalCount'>
    )> }
  )>, all?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & Pick<InvoiceCollectionSegment, 'totalCount'>
    )> }
  )> };

export type GetInvoicesQueryVariables = Exact<{
  type: VoucherTypes;
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetInvoicesQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & Pick<InvoiceCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'number'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetApprovedPurchaseOrdersByVendorQueryVariables = Exact<{
  vendorId: Scalars['Uuid'];
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetApprovedPurchaseOrdersByVendorQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & Pick<InvoiceCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'number'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & CollectionInfoFragment
      ) }
    )> }
  )> };

export type GetApprovedPurchaseOrderByIdQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetApprovedPurchaseOrderByIdQuery = { finance?: Maybe<(
    { __typename?: 'FinanceQueryType' }
    & { invoicesByType?: Maybe<(
      { __typename?: 'InvoiceCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Invoice' }
        & { details?: Maybe<Array<Maybe<(
          { __typename?: 'InvoiceDetail' }
          & Pick<InvoiceDetail, 'id' | 'productId' | 'quantity' | 'price' | 'discount' | 'amount'>
          & { invoiceDetailTax?: Maybe<Array<Maybe<(
            { __typename?: 'InvoiceDetailTax' }
            & Pick<InvoiceDetailTax, 'id' | 'taxId' | 'invoiceDetailID' | 'taxRate' | 'taxAmount'>
          )>>>, product?: Maybe<(
            { __typename?: 'Product' }
            & Pick<Product, 'id' | 'taxIds' | 'purchaseUnit' | 'description' | 'price'>
          )> }
        )>>>, attachment?: Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'path'>
        )> }
        & InvoiceDetailsFragment
      )>>> }
    )> }
  )> };

export type QuotaFragment = (
  { __typename?: 'Quota' }
  & Pick<Quota, 'id' | 'lookupId' | 'userId' | 'year' | 'assignedQuota'>
  & { lookup?: Maybe<(
    { __typename?: 'BusinessLookup' }
    & Pick<BusinessLookup, 'name' | 'code' | 'setting' | 'parentId'>
    & { parent?: Maybe<(
      { __typename?: 'BusinessLookup' }
      & Pick<BusinessLookup, 'name' | 'code' | 'setting'>
    )> }
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & BusinessUserFragment
  )> }
);

export type GetMyCurrentYearQuotaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyCurrentYearQuotaQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { myCurrentYearQuota?: Maybe<(
        { __typename?: 'Quota' }
        & QuotaFragment
      )> }
    )> }
  )> };

export type GetMyYearlyQuotasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyYearlyQuotasQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { myYearlyQuotas?: Maybe<Array<Maybe<(
        { __typename?: 'Quota' }
        & QuotaFragment
      )>>> }
    )> }
  )> };

export type GetYearlyQuotasQueryVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type GetYearlyQuotasQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { yearlyQuotas?: Maybe<Array<Maybe<(
      { __typename?: 'Quota' }
      & QuotaFragment
    )>>> }
  )> };

export type AddYearlyQuotaMutationVariables = Exact<{
  model: CreateYearlyQuotaInput;
}>;


export type AddYearlyQuotaMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { yearlyQuotas?: Maybe<(
      { __typename?: 'YearlyQuotasMutationType' }
      & Pick<YearlyQuotasMutationType, 'add'>
    )> }
  )> };

export type UpdateYearlyQuotaMutationVariables = Exact<{
  model: UpdateYearlyQuotaInput;
}>;


export type UpdateYearlyQuotaMutation = { hr?: Maybe<(
    { __typename?: 'HrMutationType' }
    & { yearlyQuotas?: Maybe<(
      { __typename?: 'YearlyQuotasMutationType' }
      & Pick<YearlyQuotasMutationType, 'update'>
    )> }
  )> };

export type GetQuotaLookupsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetQuotaLookupsQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetQuotaLookupsByParentCodeQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  parentCode?: Maybe<Scalars['String']>;
}>;


export type GetQuotaLookupsByParentCodeQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & Pick<BusinessLookupCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & CollectionInfoFragment
    ) }
  )> };

export type GetQuotaLookupQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetQuotaLookupQuery = { paginatedBusinessLookups?: Maybe<(
    { __typename?: 'BusinessLookupCollectionSegment' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'BusinessLookup' }
      & BusinessLookupFragment
    )>>> }
  )> };

export type GetMyCurrentYearQuotaWithLookupIdQueryVariables = Exact<{
  lookupId: Scalars['Uuid'];
}>;


export type GetMyCurrentYearQuotaWithLookupIdQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { myCurrentYearQuota?: Maybe<(
        { __typename?: 'Quota' }
        & Pick<Quota, 'id' | 'lookupId' | 'userId' | 'year' | 'assignedQuota'>
        & { lookup?: Maybe<(
          { __typename?: 'BusinessLookup' }
          & Pick<BusinessLookup, 'name' | 'code' | 'setting' | 'parentId'>
          & { parent?: Maybe<(
            { __typename?: 'BusinessLookup' }
            & Pick<BusinessLookup, 'name' | 'code' | 'setting'>
          )> }
        )> }
      )> }
    )> }
  )> };

export type GetUserCurrentYearQuotaWithLookupIdQueryVariables = Exact<{
  lookupId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
}>;


export type GetUserCurrentYearQuotaWithLookupIdQuery = { hr?: Maybe<(
    { __typename?: 'HrQueryType' }
    & { session?: Maybe<(
      { __typename?: 'HrSessionQueryType' }
      & { userCurrentYearQuota?: Maybe<(
        { __typename?: 'Quota' }
        & Pick<Quota, 'id' | 'lookupId' | 'userId' | 'year' | 'assignedQuota'>
        & { lookup?: Maybe<(
          { __typename?: 'BusinessLookup' }
          & Pick<BusinessLookup, 'name' | 'code' | 'setting' | 'parentId'>
          & { parent?: Maybe<(
            { __typename?: 'BusinessLookup' }
            & Pick<BusinessLookup, 'name' | 'code' | 'setting'>
          )> }
        )> }
      )> }
    )> }
  )> };

export const FirstLevelAccountDetailFragmentDoc = gql`
    fragment firstLevelAccountDetail on FirstLevelAccount {
  id
  name
  code
  description
  sortOrder
}
    `;
export const SecondLevelAccountDetailFragmentDoc = gql`
    fragment secondLevelAccountDetail on SecondLevelAccount {
  id
  firstLevelAccountId
  name
  code
  description
  sortOrder
}
    `;
export const ThirdLevelAccountDetailFragmentDoc = gql`
    fragment thirdLevelAccountDetail on ThirdLevelAccount {
  id
  secondLevelAccountId
  name
  roleNumber
  code
  description
  sortOrder
}
    `;
export const AddressFragmentDoc = gql`
    fragment address on Address {
  id
  addressLine1
  addressLine2
  zipCode
  city
  state
  country
  timeZone
}
    `;
export const AllowanceCategoriesFragmentDoc = gql`
    fragment allowanceCategories on AllowanceCategory {
  id
  name
  code
  updatedOn
  state
}
    `;
export const AllowanceTypesFragmentDoc = gql`
    fragment allowanceTypes on AllowanceType {
  id
  name
  code
  ratio
  updatedOn
  state
  categoryId
  category {
    name
  }
}
    `;
export const CollectionInfoFragmentDoc = gql`
    fragment collectionInfo on CollectionSegmentInfo {
  hasNextPage
  hasPreviousPage
}
    `;
export const BonusCategoriesFragmentDoc = gql`
    fragment bonusCategories on BonusCategory {
  id
  name
  code
  updatedOn
  state
}
    `;
export const BonusTypesFragmentDoc = gql`
    fragment bonusTypes on BonusType {
  id
  name
  code
  percentage
  updatedOn
  state
  categoryId
  category {
    name
  }
}
    `;
export const EvaluationFragmentDoc = gql`
    fragment evaluation on CandidateEvaluation {
  id
  candidateId
  jobPositionId
  userId
  weakness
  strength
  finalComment
  jobPosition {
    jobTitle {
      name
    }
  }
  scores {
    candidateEvaluationId
    interviewCategoryId
    interviewCriterionId
    remarks
    score
  }
}
    `;
export const CandidateFragmentDoc = gql`
    fragment candidate on Candidate {
  id
  businessId
  fullName
  email
  gender
  pic
  state
  fatherName
  phone
  mobilePhone
  presentAddress
  currentSalary
  expectedSalary
  religion
  resume
  candidatePositions {
    state
    interviewDateTime
    jobPositionId
    jobPosition {
      jobTitle {
        name
      }
    }
  }
}
    `;
export const CertificationsFragmentDoc = gql`
    fragment certifications on Certification {
  id
  name
  code
  updatedOn
  state
}
    `;
export const ClientsFragmentDoc = gql`
    fragment clients on Client {
  id
  name
  code
  updatedOn
  state
  owners {
    id
    email
    pic
    fullName
    firstName
    lastName
  }
}
    `;
export const ContactsFragmentDoc = gql`
    fragment contacts on Contact {
  id
  name
  phone
  email
  addressId
}
    `;
export const DepartmentsFragmentDoc = gql`
    fragment departments on Department {
  id
  name
  code
  updatedOn
  state
}
    `;
export const EmployeeDetailFragmentDoc = gql`
    fragment employeeDetail on Employee {
  id
  userId
  user {
    departmentId
    jobTitleId
    jobFunctionId
    fullName
    email
    gender
    pic
    payrollAccountId
  }
  officialEmail
  state
  fatherName
  phone
  mobilePhone
  dateOfAvailability
  dateOfBirth
  placeOfBirth
  presentAddress
  permanentAddress
  bloodGroup
  maritalStatus
  nameOfSpouse
  drivingLicenceNumber
  drivingLicenceIssueDate
  currentSalary
  expectedSalary
  paymentMethod
  religion
  cnic
  cnicExpiryDate
  domicile
  reportedToId
  children {
    age
    employeeId
    gender
    name
  }
  references {
    address
    contactNumber
    id
    name
    employeeId
  }
  relatives {
    employeeId
    id
    relationship
    relativeId
  }
  educations {
    employeeId
    grade
    id
    instituteId
    passingYear
    certificationId
  }
  experiences {
    designation
    employeeId
    employer
    from
    id
    reasonForLeaving
    to
  }
}
    `;
export const FinancialYearsFragmentDoc = gql`
    fragment financialYears on FinancialYear {
  id
  name
  startDate
  endDate
  isActive
}
    `;
export const GenericTasksFragmentDoc = gql`
    fragment genericTasks on GenericTask {
  id
  name
  description
  updatedOn
  state
}
    `;
export const HolidaysFragmentDoc = gql`
    fragment holidays on HolidayCalander {
  id
  eventName
  fromDate
  toDate
}
    `;
export const HrLookupsFragmentDoc = gql`
    fragment hrLookups on HrLookup {
  id
  name
  code
  updatedOn
  state
  lookupSetting
  lookupModuleCode
  parentId
  parent {
    name
    code
  }
}
    `;
export const PaginatedInstitutesFragmentDoc = gql`
    fragment paginatedInstitutes on Institute {
  id
  name
  createdOn
  updatedOn
  state
}
    `;
export const InterviewCategoriesFragmentDoc = gql`
    fragment interviewCategories on InterviewCategory {
  id
  name
  code
  updatedOn
  state
}
    `;
export const InterviewCriteriaFragmentDoc = gql`
    fragment interviewCriteria on InterviewCriterion {
  id
  name
  code
  score
  updatedOn
  state
  categoryId
  category {
    name
  }
}
    `;
export const PaginatedJobPositionsFragmentDoc = gql`
    fragment paginatedJobPositions on JobPosition {
  id
  businessId
  positions
  state
  departmentId
  jobTitleId
  description
  comment
  department {
    name
  }
  jobTitle {
    name
    code
  }
}
    `;
export const JobFunctionsFragmentDoc = gql`
    fragment jobFunctions on JobFunction {
  id
  name
  code
  updatedOn
  state
}
    `;
export const JobTitlesFragmentDoc = gql`
    fragment jobTitles on JobTitle {
  id
  name
  code
  updatedOn
  state
}
    `;
export const LeaveFragmentFragmentDoc = gql`
    fragment leaveFragment on Leave {
  id
  createdOn
  updatedOn
  createdById
  remarks
  state
  fromDate
  toDate
  weight
  reason
  isShortLeave
  leaveTypeId
  userId
  leaveType {
    name
    code
  }
  user {
    firstName
    lastName
    fullName
    email
  }
}
    `;
export const BusinessLookupFragmentDoc = gql`
    fragment businessLookup on BusinessLookup {
  id
  name
  code
  createdOn
  updatedOn
  state
  isSystemDefined
  parentId
  businessId
  setting
  parent {
    id
    name
    code
    createdOn
    updatedOn
    state
    isSystemDefined
  }
}
    `;
export const LeaveTypesFragmentDoc = gql`
    fragment leaveTypes on BusinessLookup {
  ...businessLookup
}
    ${BusinessLookupFragmentDoc}`;
export const LoanFragmentFragmentDoc = gql`
    fragment loanFragment on Loan {
  id
  amountApplied
  amountApproved
  remarks
  state
  numberOfInstallments
  purposeOfLoan
  loanType {
    name
  }
  user {
    firstName
    lastName
    fullName
  }
}
    `;
export const LoanTypesFragmentDoc = gql`
    fragment loanTypes on LoanType {
  id
  name
  code
  updatedOn
  state
}
    `;
export const MedicalClaimFragmentFragmentDoc = gql`
    fragment medicalClaimFragment on MedicalClaim {
  id
  userId
  amount
  amountApproved
  state
  createdOn
  updatedOn
  user {
    id
    firstName
    lastName
    fullName
  }
}
    `;
export const NotesdetailFragmentDoc = gql`
    fragment notesdetail on NoteDateil {
  id
  objectID
  noteText
  noteModuleCode
  attachments {
    path
  }
  updatedOn
  updatedById
  createdOn
  createdById
  userName
  noteType
  noteTypeCode
  noteTypeId
}
    `;
export const NotificationFragmentDoc = gql`
    fragment notification on Notification {
  id
  message
  isRead
  createdOn
  type
  systemObject
  systemObjectId
}
    `;
export const ProductListFragmentDoc = gql`
    fragment productList on Product {
  id
  number
  upcNumber
  price
  description
  name
  state
  category {
    name
  }
}
    `;
export const ProductDetailFragmentDoc = gql`
    fragment productDetail on Product {
  id
  purchaseUnit
  saleUnit
  inventoryUnit
  incomeAccountId
  expenseAccountId
  number
  upcNumber
  price
  productType
  isCatchWeightProduct
  description
  name
  categoryId
  taxIds
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment projectFragment on Project {
  id
  name
  clientId
  client {
    name
  }
  tasksCount
  projectUserRoles {
    projectRole
    userId
    user {
      firstName
      lastName
      fullName
      email
      pic
    }
  }
  description
  createdOn
  updatedOn
  state
}
    `;
export const SubTasksFragmentDoc = gql`
    fragment subTasks on SubTask {
  id
  parentTaskId
  projectId
  name
  expectedEffort
  description
  updatedOn
  state
}
    `;
export const ProjectTasksFragmentDoc = gql`
    fragment projectTasks on ProjectTask {
  id
  projectId
  name
  expectedEffort
  description
  updatedOn
  state
  subTasksCount
}
    `;
export const ReasonTypesFragmentDoc = gql`
    fragment reasonTypes on ReasonType {
  id
  name
  code
  updatedOn
  state
}
    `;
export const ReimbursementFragmentFragmentDoc = gql`
    fragment reimbursementFragment on Reimbursement {
  id
  userId
  amount
  amountApproved
  state
  createdOn
  updatedOn
  appliedOn
  authorizedOn
  user {
    id
    firstName
    lastName
    fullName
  }
  businessLookups {
    name
  }
  authorizedBy {
    id
    firstName
    lastName
    fullName
  }
}
    `;
export const ReimbursementDetailsFragmentFragmentDoc = gql`
    fragment reimbursementDetailsFragment on ReimbursementDetail {
  id
  description
  reimbursementId
  amount
  referenceNumber
  file
}
    `;
export const SitesFragmentDoc = gql`
    fragment sites on Site {
  id
  siteNo
  name: siteName
  siteName
  address1
  address2
  city
  siteState
  zip
  phone
  longitude
  latitude
  state
  parentId
  siteManagerId
  parent {
    zip
    phone
    siteNo
    siteName
    address1
    address2
    city
    siteState
  }
}
    `;
export const TaxFragmentFragmentDoc = gql`
    fragment taxFragment on Tax {
  id
  name
  rate
  abbreviation
}
    `;
export const TicketCategoriesFragmentDoc = gql`
    fragment ticketCategories on TicketCategory {
  id
  name
  updatedOn
  state
}
    `;
export const TicketLaborFragmentDoc = gql`
    fragment ticketLabor on TicketLaborWithUserDetail {
  id
  ticketId
  hours
  hoursType
  billable
  rate
  workerId
  workerName
}
    `;
export const TicketMaterialFragmentDoc = gql`
    fragment ticketMaterial on TicketMaterial {
  id
  taxSettings
  ticketId
  productId
  product {
    name
    price
  }
  quantity
  amount
  description
  discount
  price
  tax
}
    `;
export const TicketsFragmentDoc = gql`
    fragment tickets on Ticket {
  id
  title
  description
  updatedOn
  state
  attachment {
    id
    path
  }
  categoryId
  category {
    name
  }
  assignedUserId
  assignedUser {
    firstName
    lastName
    fullName
    email
    pic
  }
  createdById
  createdBy {
    firstName
    lastName
    fullName
    email
    pic
  }
  createdOn
}
    `;
export const TktTicketsFragmentDoc = gql`
    fragment tktTickets on Tickets {
  id
  executionEndDate
  executionStartDate
  firstResponseDate
  problemId
  problemLookup {
    name
    code
    id
    setting
  }
  priorityId
  priorityLookup {
    name
    code
    id
    setting
  }
  reportedById
  reportedUser {
    firstName
    lastName
    fullName
  }
  scheduleDate
  ticketStatusChanges {
    changeStatusId
    statusLookup {
      name
      code
      setting
    }
    latitude
    longitude
    statusType
    changeById
    changedUser {
      firstName
      lastName
      fullName
    }
    assignedUserId
    assignedUser {
      firstName
      lastName
      fullName
    }
    changeOn
    description
  }
  ticketNo
  ticketDescription
  siteId
  site {
    siteNo
    siteName
    address1
    address2
    city
    siteState
    zip
    phone
    longitude
    latitude
    state
  }
  businessId
  completeDate
  scheduleDate
  completionDuration
  dueDate
  entryDate
  state
}
    `;
export const PaginatedTimeLogsFragmentDoc = gql`
    fragment paginatedTimeLogs on TimeLog {
  id
  projectId
  project {
    name
    state
  }
  taskId
  task {
    name
    state
  }
  genericTaskId
  genericTask {
    name
    state
  }
  subTaskId
  subTask {
    name
    state
  }
  description
  timeSpan
  date
}
    `;
export const VoucherDetailsFragmentDoc = gql`
    fragment voucherDetails on Voucher {
  voucherTypeId
  id
  notes
  date
  number
  attachment {
    path
  }
  lineItems {
    voucherLineType
    accountId
    account {
      name
    }
    description
    amount
    sortOrder
  }
}
    `;
export const CashVoucherDetailsFragmentDoc = gql`
    fragment cashVoucherDetails on CashVoucher {
  id
  description
  date
  number
  systemAccountId
  accountId
  attachment {
    path
  }
  amount
  account {
    name
  }
}
    `;
export const InvoiceDetailsFragmentDoc = gql`
    fragment invoiceDetails on Invoice {
  id
  dueDate
  deliveryAddressId
  commissionAccountId
  number
  notes
  accountId
  account {
    name
  }
  amount
  state
  date
  createdOn
}
    `;
export const BusinessUserFragmentDoc = gql`
    fragment businessUser on User {
  id
  firstName
  lastName
  fullName
  email
  state
  pic
  userAgentConfigurations {
    state
    isLogEnable
    isCaptureScreen
    onAppSwitching
    captureScreenInterval
    useUserConfiguration
  }
}
    `;
export const QuotaFragmentDoc = gql`
    fragment quota on Quota {
  id
  lookup {
    name
    code
    setting
    parentId
    parent {
      name
      code
      setting
    }
  }
  lookupId
  user {
    ...businessUser
  }
  userId
  year
  assignedQuota
}
    ${BusinessUserFragmentDoc}`;
export const GetBusinessSalaryAccountsDocument = gql`
    query GetBusinessSalaryAccounts {
  session {
    business {
      salaryAccounts {
        eobiAccount {
          amount
          percentage
        }
        pfAccount {
          amount
          percentage
        }
        fbrAccount {
          code
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessSalaryAccountsGQL extends Apollo.Query<GetBusinessSalaryAccountsQuery, GetBusinessSalaryAccountsQueryVariables> {
    document = GetBusinessSalaryAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSessionBusinessesDocument = gql`
    query getSessionBusinesses {
  session {
    businesses {
      id
      name
      state
      childCounts {
        departmentsCount
        usersCount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSessionBusinessesGQL extends Apollo.Query<GetSessionBusinessesQuery, GetSessionBusinessesQueryVariables> {
    document = GetSessionBusinessesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessAgentConfigDocument = gql`
    query GetBusinessAgentConfig($id: Uuid!) {
  trackingAgent {
    agent {
      businessAgent(businessId: $id) {
        state
        isLogEnable
        isCaptureScreen
        onAppSwitching
        captureScreenInterval
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessAgentConfigGQL extends Apollo.Query<GetBusinessAgentConfigQuery, GetBusinessAgentConfigQueryVariables> {
    document = GetBusinessAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserAgentConfigDocument = gql`
    query GetUserAgentConfig($id: Uuid!) {
  user(id: $id) {
    userAgentConfigurations {
      state
      isLogEnable
      isCaptureScreen
      onAppSwitching
      captureScreenInterval
      useUserConfiguration
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserAgentConfigGQL extends Apollo.Query<GetUserAgentConfigQuery, GetUserAgentConfigQueryVariables> {
    document = GetUserAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoiceConfigurationDocument = gql`
    query getInvoiceConfiguration {
  session {
    business {
      configuration
      config {
        invoiceConfig {
          showPurchaseInvoice
          showPurchaseOrder
          showQuotation
          showSaleInvoice
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoiceConfigurationGQL extends Apollo.Query<GetInvoiceConfigurationQuery, GetInvoiceConfigurationQueryVariables> {
    document = GetInvoiceConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoiceConfigurationByBusinessDocument = gql`
    query getInvoiceConfigurationByBusiness($businessId: Uuid!) {
  business(businessId: $businessId) {
    configuration
    config {
      invoiceConfig {
        showPurchaseInvoice
        showPurchaseOrder
        showQuotation
        showSaleInvoice
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoiceConfigurationByBusinessGQL extends Apollo.Query<GetInvoiceConfigurationByBusinessQuery, GetInvoiceConfigurationByBusinessQueryVariables> {
    document = GetInvoiceConfigurationByBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBusinessAgentConfigDocument = gql`
    mutation updateBusinessAgentConfig($model: UpdateAgentConfigurationInput) {
  trackingAgent {
    userAgent {
      updateBusinessAgent(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBusinessAgentConfigGQL extends Apollo.Mutation<UpdateBusinessAgentConfigMutation, UpdateBusinessAgentConfigMutationVariables> {
    document = UpdateBusinessAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInvoiceConfigDocument = gql`
    mutation updateInvoiceConfig($model: InvoiceConfigInput) {
  finance {
    purchaseVouchers {
      updateInvoiceConfiguration(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInvoiceConfigGQL extends Apollo.Mutation<UpdateInvoiceConfigMutation, UpdateInvoiceConfigMutationVariables> {
    document = UpdateInvoiceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnableBusinessAgentConfigDocument = gql`
    mutation enableBusinessAgentConfig {
  trackingAgent {
    userAgent {
      enableBusinessAgent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnableBusinessAgentConfigGQL extends Apollo.Mutation<EnableBusinessAgentConfigMutation, EnableBusinessAgentConfigMutationVariables> {
    document = EnableBusinessAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisableBusinessAgentConfigDocument = gql`
    mutation disableBusinessAgentConfig {
  trackingAgent {
    userAgent {
      disableBusinessAgent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisableBusinessAgentConfigGQL extends Apollo.Mutation<DisableBusinessAgentConfigMutation, DisableBusinessAgentConfigMutationVariables> {
    document = DisableBusinessAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessAttendanceConfigDocument = gql`
    query GetBusinessAttendanceConfig {
  attendanceManagement {
    attendance {
      myBusinessAttendanceConfig {
        state
        id
        businessId
        secondShiftId
        syncInterval
        secondShift {
          state
          closingTime
          openingTime
        }
        firstShiftId
        firstShift {
          state
          closingTime
          openingTime
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessAttendanceConfigGQL extends Apollo.Query<GetBusinessAttendanceConfigQuery, GetBusinessAttendanceConfigQueryVariables> {
    document = GetBusinessAttendanceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBusinessAttendanceConfigDocument = gql`
    mutation updateBusinessAttendanceConfig($model: UpdateAttendanceConfigurationInput) {
  attendanceManagement {
    configuration {
      updateBusinessAgent(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBusinessAttendanceConfigGQL extends Apollo.Mutation<UpdateBusinessAttendanceConfigMutation, UpdateBusinessAttendanceConfigMutationVariables> {
    document = UpdateBusinessAttendanceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisableBusinessAttendanceConfigDocument = gql`
    mutation disableBusinessAttendanceConfig {
  attendanceManagement {
    configuration {
      disableBusinessAgent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisableBusinessAttendanceConfigGQL extends Apollo.Mutation<DisableBusinessAttendanceConfigMutation, DisableBusinessAttendanceConfigMutationVariables> {
    document = DisableBusinessAttendanceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessUserAttendanceConfigDocument = gql`
    query GetBusinessUserAttendanceConfig($filter: AttendanceUserConfigurationFilterInput, $skip: Int, $take: Int) {
  attendanceManagement {
    attendance {
      usersConfig(
        where: $filter
        skip: $skip
        take: $take
        order: {attendanceId: ASC}
      ) {
        items {
          id
          attendanceId
          shiftId
          businessId
          closingTime
          openingTime
          user {
            email
            firstName
            lastName
          }
          state
          userId
          useUserConfiguration
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessUserAttendanceConfigGQL extends Apollo.Query<GetBusinessUserAttendanceConfigQuery, GetBusinessUserAttendanceConfigQueryVariables> {
    document = GetBusinessUserAttendanceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserAttendanceConfigurationsDocument = gql`
    mutation updateUserAttendanceConfigurations($model: [UserAttendanceConfigVmInput]) {
  attendanceManagement {
    configuration {
      updateUserConfig(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserAttendanceConfigurationsGQL extends Apollo.Mutation<UpdateUserAttendanceConfigurationsMutation, UpdateUserAttendanceConfigurationsMutationVariables> {
    document = UpdateUserAttendanceConfigurationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessEmailsDocument = gql`
    query getBusinessEmails {
  session {
    business {
      config {
        attendanceNotification {
          enable
          time
          emails
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessEmailsGQL extends Apollo.Query<GetBusinessEmailsQuery, GetBusinessEmailsQueryVariables> {
    document = GetBusinessEmailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBusinessEmailsDocument = gql`
    mutation updateBusinessEmails($vm: AttendanceNotificationVmInput) {
  business {
    updateEmails(vm: $vm)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBusinessEmailsGQL extends Apollo.Mutation<UpdateBusinessEmailsMutation, UpdateBusinessEmailsMutationVariables> {
    document = UpdateBusinessEmailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHrConfigurationDocument = gql`
    query getHrConfiguration {
  session {
    business {
      configuration
      config {
        hrConfig {
          loansEntitlement
          medicalClaimEntitlement
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHrConfigurationGQL extends Apollo.Query<GetHrConfigurationQuery, GetHrConfigurationQueryVariables> {
    document = GetHrConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHrConfigurationByBusinessDocument = gql`
    query getHrConfigurationByBusiness($businessId: Uuid!) {
  business(businessId: $businessId) {
    configuration
    config {
      hrConfig {
        loansEntitlement
        medicalClaimEntitlement
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHrConfigurationByBusinessGQL extends Apollo.Query<GetHrConfigurationByBusinessQuery, GetHrConfigurationByBusinessQueryVariables> {
    document = GetHrConfigurationByBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBusinessHrConfigAndWorkingDaysDocument = gql`
    mutation updateBusinessHrConfigAndWorkingDays($hrConfiguration: HrConfigurationVmInput, $workingDays: [WeekDays!]) {
  business {
    updateHrConfigurationAndWorkingDays(
      hrConfiguration: $hrConfiguration
      workingDays: $workingDays
    )
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBusinessHrConfigAndWorkingDaysGQL extends Apollo.Mutation<UpdateBusinessHrConfigAndWorkingDaysMutation, UpdateBusinessHrConfigAndWorkingDaysMutationVariables> {
    document = UpdateBusinessHrConfigAndWorkingDaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncBusinessHrConfigDocument = gql`
    mutation syncBusinessHrConfig {
  business {
    syncBusinessConfiguration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncBusinessHrConfigGQL extends Apollo.Mutation<SyncBusinessHrConfigMutation, SyncBusinessHrConfigMutationVariables> {
    document = SyncBusinessHrConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWorkingDaysDocument = gql`
    query getWorkingDays {
  session {
    business {
      configuration
      config {
        workingDays
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWorkingDaysGQL extends Apollo.Query<GetWorkingDaysQuery, GetWorkingDaysQueryVariables> {
    document = GetWorkingDaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWorkingDaysByBusinessDocument = gql`
    query getWorkingDaysByBusiness($businessId: Uuid!) {
  business(businessId: $businessId) {
    configuration
    config {
      workingDays
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWorkingDaysByBusinessGQL extends Apollo.Query<GetWorkingDaysByBusinessQuery, GetWorkingDaysByBusinessQueryVariables> {
    document = GetWorkingDaysByBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFirstLevelAccountsDocument = gql`
    query getFirstLevelAccounts {
  finance {
    firstLevelAccounts {
      ...firstLevelAccountDetail
    }
  }
}
    ${FirstLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFirstLevelAccountsGQL extends Apollo.Query<GetFirstLevelAccountsQuery, GetFirstLevelAccountsQueryVariables> {
    document = GetFirstLevelAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFirstWithSecondLevelAccountsDocument = gql`
    query getFirstWithSecondLevelAccounts {
  finance {
    firstLevelAccounts {
      ...firstLevelAccountDetail
      secondLevelAccounts {
        ...secondLevelAccountDetail
        thirdLevelAccounts {
          ...thirdLevelAccountDetail
        }
      }
    }
  }
}
    ${FirstLevelAccountDetailFragmentDoc}
${SecondLevelAccountDetailFragmentDoc}
${ThirdLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFirstWithSecondLevelAccountsGQL extends Apollo.Query<GetFirstWithSecondLevelAccountsQuery, GetFirstWithSecondLevelAccountsQueryVariables> {
    document = GetFirstWithSecondLevelAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSecondLevelAccountsDocument = gql`
    query getSecondLevelAccounts($firstLevelAccountId: Uuid!) {
  finance {
    secondLevelAccounts(where: {firstLevelAccountId: {eq: $firstLevelAccountId}}) {
      ...secondLevelAccountDetail
      thirdLevelAccounts {
        ...thirdLevelAccountDetail
      }
    }
  }
}
    ${SecondLevelAccountDetailFragmentDoc}
${ThirdLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSecondLevelAccountsGQL extends Apollo.Query<GetSecondLevelAccountsQuery, GetSecondLevelAccountsQueryVariables> {
    document = GetSecondLevelAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSecondLevelAccountsByBusinessDocument = gql`
    query getSecondLevelAccountsByBusiness {
  finance {
    secondLevelAccounts {
      ...secondLevelAccountDetail
      thirdLevelAccounts {
        ...thirdLevelAccountDetail
      }
    }
  }
}
    ${SecondLevelAccountDetailFragmentDoc}
${ThirdLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSecondLevelAccountsByBusinessGQL extends Apollo.Query<GetSecondLevelAccountsByBusinessQuery, GetSecondLevelAccountsByBusinessQueryVariables> {
    document = GetSecondLevelAccountsByBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginatedThirdAccountsDocument = gql`
    query PaginatedThirdAccounts($filter: ThirdLevelAccountFilterInput, $skip: Int, $take: Int) {
  finance {
    paginatedThirdLevelAccounts(where: $filter, skip: $skip, take: $take) {
      items {
        ...thirdLevelAccountDetail
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ThirdLevelAccountDetailFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginatedThirdAccountsGQL extends Apollo.Query<PaginatedThirdAccountsQuery, PaginatedThirdAccountsQueryVariables> {
    document = PaginatedThirdAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetThirdLevelAccountWithAddressDocument = gql`
    query getThirdLevelAccountWithAddress($id: Uuid!) {
  finance {
    thirdLevelAccounts(where: {id: {eq: $id}}) {
      businessAddressId
      businessAddress {
        ...address
      }
      deliveryAddressId
      deliveryAddress {
        ...address
      }
      billingAddressId
      billingAddress {
        ...address
      }
      ...thirdLevelAccountDetail
      businessContactId
      billingContactId
      deliveryContactId
    }
  }
}
    ${AddressFragmentDoc}
${ThirdLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetThirdLevelAccountWithAddressGQL extends Apollo.Query<GetThirdLevelAccountWithAddressQuery, GetThirdLevelAccountWithAddressQueryVariables> {
    document = GetThirdLevelAccountWithAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetThirdLevelAccountWithAddressAndRolesDocument = gql`
    query getThirdLevelAccountWithAddressAndRoles($id: Uuid) {
  finance {
    thirdLevelAccounts(where: {id: {eq: $id}}) {
      businessAddressId
      businessAddress {
        ...address
      }
      deliveryAddressId
      deliveryAddress {
        ...address
      }
      billingAddressId
      billingAddress {
        ...address
      }
      ...thirdLevelAccountDetail
      businessContactId
      billingContactId
      deliveryContactId
      roleNumber
      roles
    }
  }
}
    ${AddressFragmentDoc}
${ThirdLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetThirdLevelAccountWithAddressAndRolesGQL extends Apollo.Query<GetThirdLevelAccountWithAddressAndRolesQuery, GetThirdLevelAccountWithAddressAndRolesQueryVariables> {
    document = GetThirdLevelAccountWithAddressAndRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetThirdLevelAccountDocument = gql`
    query getThirdLevelAccount($id: Uuid) {
  finance {
    thirdLevelAccounts(where: {id: {eq: $id}}) {
      ...thirdLevelAccountDetail
    }
  }
}
    ${ThirdLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetThirdLevelAccountGQL extends Apollo.Query<GetThirdLevelAccountQuery, GetThirdLevelAccountQueryVariables> {
    document = GetThirdLevelAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSecondLevelAccountByIdDocument = gql`
    query GetSecondLevelAccountById($id: Uuid) {
  finance {
    secondLevelAccounts(where: {id: {eq: $id}}) {
      ...secondLevelAccountDetail
    }
  }
}
    ${SecondLevelAccountDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSecondLevelAccountByIdGQL extends Apollo.Query<GetSecondLevelAccountByIdQuery, GetSecondLevelAccountByIdQueryVariables> {
    document = GetSecondLevelAccountByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAccountSequenceNumberDocument = gql`
    query GetAccountSequenceNumber($accountId: Uuid!) {
  finance {
    accountSequenceNo(accountId: $accountId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAccountSequenceNumberGQL extends Apollo.Query<GetAccountSequenceNumberQuery, GetAccountSequenceNumberQueryVariables> {
    document = GetAccountSequenceNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddThirdLevelAccountDocument = gql`
    mutation addThirdLevelAccount($model: CreateThirdLevelAccountInput) {
  finance {
    thirdLevelAccounts {
      addThirdLevelAccount(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddThirdLevelAccountGQL extends Apollo.Mutation<AddThirdLevelAccountMutation, AddThirdLevelAccountMutationVariables> {
    document = AddThirdLevelAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateThirdLevelAccountDocument = gql`
    mutation updateThirdLevelAccount($model: UpdateThirdLevelAccountInput) {
  finance {
    thirdLevelAccounts {
      updateThirdLevelAccount(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateThirdLevelAccountGQL extends Apollo.Mutation<UpdateThirdLevelAccountMutation, UpdateThirdLevelAccountMutationVariables> {
    document = UpdateThirdLevelAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUpdateBusinessPfAccountDocument = gql`
    mutation addUpdateBusinessPFAccount($model: PFAccountVmInput!) {
  finance {
    thirdLevelAccounts {
      addUpdateBusinessPFAccount(pfAccount: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUpdateBusinessPfAccountGQL extends Apollo.Mutation<AddUpdateBusinessPfAccountMutation, AddUpdateBusinessPfAccountMutationVariables> {
    document = AddUpdateBusinessPfAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUpdateBusinessEobiAccountDocument = gql`
    mutation addUpdateBusinessEOBIAccount($model: EOBIAccountVmInput!) {
  finance {
    thirdLevelAccounts {
      addUpdateBusinessEOBIAccount(eobiAccount: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUpdateBusinessEobiAccountGQL extends Apollo.Mutation<AddUpdateBusinessEobiAccountMutation, AddUpdateBusinessEobiAccountMutationVariables> {
    document = AddUpdateBusinessEobiAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUpdateBusinessFbrAccountDocument = gql`
    mutation addUpdateBusinessFBRAccount($model: FBRAccountVmInput!) {
  finance {
    thirdLevelAccounts {
      addUpdateBusinessFBRAccount(eobiAccount: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUpdateBusinessFbrAccountGQL extends Apollo.Mutation<AddUpdateBusinessFbrAccountMutation, AddUpdateBusinessFbrAccountMutationVariables> {
    document = AddUpdateBusinessFbrAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetThirdLevelAccountByRoleDocument = gql`
    query getThirdLevelAccountByRole($roleNumber: Long!, $id: Uuid) {
  finance {
    paginatedThirdLevelAccountsByRole(
      roleNumber: $roleNumber
      where: {id: {eq: $id}}
    ) {
      items {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetThirdLevelAccountByRoleGQL extends Apollo.Query<GetThirdLevelAccountByRoleQuery, GetThirdLevelAccountByRoleQueryVariables> {
    document = GetThirdLevelAccountByRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetThirdLevelAccountsByRoleDocument = gql`
    query getThirdLevelAccountsByRole($roleNumber: Long!, $search: String, $skip: Int, $take: Int) {
  finance {
    paginatedThirdLevelAccountsByRole(
      roleNumber: $roleNumber
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}]}
      skip: $skip
      take: $take
      order: {code: DESC}
    ) {
      items {
        ...thirdLevelAccountDetail
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ThirdLevelAccountDetailFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetThirdLevelAccountsByRoleGQL extends Apollo.Query<GetThirdLevelAccountsByRoleQuery, GetThirdLevelAccountsByRoleQueryVariables> {
    document = GetThirdLevelAccountsByRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedAccountRolesDocument = gql`
    query getPaginatedAccountRoles($filter: AccountRoleFilterInput, $skip: Int, $take: Int) {
  finance {
    paginatedAccountRoles(
      where: $filter
      skip: $skip
      take: $take
      order: {name: ASC}
    ) {
      items {
        id
        name
        roleNumber
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedAccountRolesGQL extends Apollo.Query<GetPaginatedAccountRolesQuery, GetPaginatedAccountRolesQueryVariables> {
    document = GetPaginatedAccountRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSystemAccountsDocument = gql`
    query getSystemAccounts {
  finance {
    systemAccounts {
      name
      systemAccounts {
        name
        roleNumber
        account {
          id
          name
          code
          description
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSystemAccountsGQL extends Apollo.Query<GetSystemAccountsQuery, GetSystemAccountsQueryVariables> {
    document = GetSystemAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IsAccountExistWithRoleDocument = gql`
    query isAccountExistWithRole($roleNumber: Long!) {
  finance {
    isAccountExistWithRole(roleNumber: $roleNumber)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IsAccountExistWithRoleGQL extends Apollo.Query<IsAccountExistWithRoleQuery, IsAccountExistWithRoleQueryVariables> {
    document = IsAccountExistWithRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountRoleDocument = gql`
    mutation updateAccountRole($model: UpdateThirdLevelAccountRoleInput) {
  finance {
    thirdLevelAccounts {
      updateAccountRole(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountRoleGQL extends Apollo.Mutation<UpdateAccountRoleMutation, UpdateAccountRoleMutationVariables> {
    document = UpdateAccountRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessAddressDocument = gql`
    query GetBusinessAddress {
  session {
    addresses {
      ...address
    }
  }
}
    ${AddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessAddressGQL extends Apollo.Query<GetBusinessAddressQuery, GetBusinessAddressQueryVariables> {
    document = GetBusinessAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAddressDocument = gql`
    query GetAddress($contactId: Uuid!) {
  finance {
    address(contactId: $contactId) {
      ...address
    }
  }
}
    ${AddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAddressGQL extends Apollo.Query<GetAddressQuery, GetAddressQueryVariables> {
    document = GetAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedAllowanceCategoriesDocument = gql`
    query getPaginatedAllowanceCategories($filter: AllowanceCategoryFilterInput, $skip: Int, $take: Int) {
  hr {
    allowanceCategories(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...allowanceCategories
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${AllowanceCategoriesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedAllowanceCategoriesGQL extends Apollo.Query<GetPaginatedAllowanceCategoriesQuery, GetPaginatedAllowanceCategoriesQueryVariables> {
    document = GetPaginatedAllowanceCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllowanceCategoriesDocument = gql`
    query getAllowanceCategories($search: String, $skip: Int, $take: Int) {
  hr {
    allowanceCategories(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllowanceCategoriesGQL extends Apollo.Query<GetAllowanceCategoriesQuery, GetAllowanceCategoriesQueryVariables> {
    document = GetAllowanceCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllowanceCategoryDocument = gql`
    query getAllowanceCategory($id: Uuid!) {
  hr {
    allowanceCategories(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...allowanceCategories
      }
    }
  }
}
    ${AllowanceCategoriesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllowanceCategoryGQL extends Apollo.Query<GetAllowanceCategoryQuery, GetAllowanceCategoryQueryVariables> {
    document = GetAllowanceCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddAllowanceCategoryDocument = gql`
    mutation addAllowanceCategory($model: CreateAllowanceCategoryInput) {
  hr {
    allowanceCategories {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAllowanceCategoryGQL extends Apollo.Mutation<AddAllowanceCategoryMutation, AddAllowanceCategoryMutationVariables> {
    document = AddAllowanceCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAllowanceCategoryDocument = gql`
    mutation updateAllowanceCategory($model: UpdateAllowanceCategoryInput) {
  hr {
    allowanceCategories {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAllowanceCategoryGQL extends Apollo.Mutation<UpdateAllowanceCategoryMutation, UpdateAllowanceCategoryMutationVariables> {
    document = UpdateAllowanceCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveAllowanceCategoryDocument = gql`
    mutation archiveAllowanceCategory($allowanceCategoryId: Uuid!) {
  hr {
    allowanceCategories {
      archive(allowanceCategoryId: $allowanceCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveAllowanceCategoryGQL extends Apollo.Mutation<ArchiveAllowanceCategoryMutation, ArchiveAllowanceCategoryMutationVariables> {
    document = ArchiveAllowanceCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreAllowanceCategoryDocument = gql`
    mutation restoreAllowanceCategory($allowanceCategoryId: Uuid!) {
  hr {
    allowanceCategories {
      restore(allowanceCategoryId: $allowanceCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreAllowanceCategoryGQL extends Apollo.Mutation<RestoreAllowanceCategoryMutation, RestoreAllowanceCategoryMutationVariables> {
    document = RestoreAllowanceCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedAllowanceTypesDocument = gql`
    query getPaginatedAllowanceTypes($filter: AllowanceTypeFilterInput, $skip: Int, $take: Int) {
  hr {
    allowanceTypes(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...allowanceTypes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${AllowanceTypesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedAllowanceTypesGQL extends Apollo.Query<GetPaginatedAllowanceTypesQuery, GetPaginatedAllowanceTypesQueryVariables> {
    document = GetPaginatedAllowanceTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllowanceTypesDocument = gql`
    query getAllowanceTypes($search: String, $skip: Int, $take: Int) {
  hr {
    allowanceTypes(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
        ratio
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllowanceTypesGQL extends Apollo.Query<GetAllowanceTypesQuery, GetAllowanceTypesQueryVariables> {
    document = GetAllowanceTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllowanceTypeDocument = gql`
    query getAllowanceType($id: Uuid!) {
  hr {
    allowanceTypes(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...allowanceTypes
      }
    }
  }
}
    ${AllowanceTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllowanceTypeGQL extends Apollo.Query<GetAllowanceTypeQuery, GetAllowanceTypeQueryVariables> {
    document = GetAllowanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddAllowanceTypeDocument = gql`
    mutation addAllowanceType($model: CreateAllowanceTypeInput) {
  hr {
    allowanceTypes {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAllowanceTypeGQL extends Apollo.Mutation<AddAllowanceTypeMutation, AddAllowanceTypeMutationVariables> {
    document = AddAllowanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAllowanceTypeDocument = gql`
    mutation updateAllowanceType($model: UpdateAllowanceTypeInput) {
  hr {
    allowanceTypes {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAllowanceTypeGQL extends Apollo.Mutation<UpdateAllowanceTypeMutation, UpdateAllowanceTypeMutationVariables> {
    document = UpdateAllowanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveAllowanceTypeDocument = gql`
    mutation archiveAllowanceType($allowanceTypeId: Uuid!) {
  hr {
    allowanceTypes {
      archive(allowanceTypeId: $allowanceTypeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveAllowanceTypeGQL extends Apollo.Mutation<ArchiveAllowanceTypeMutation, ArchiveAllowanceTypeMutationVariables> {
    document = ArchiveAllowanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreAllowanceTypeDocument = gql`
    mutation restoreAllowanceType($allowanceTypeId: Uuid!) {
  hr {
    allowanceTypes {
      restore(allowanceTypeId: $allowanceTypeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreAllowanceTypeGQL extends Apollo.Mutation<RestoreAllowanceTypeMutation, RestoreAllowanceTypeMutationVariables> {
    document = RestoreAllowanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessAppsDocument = gql`
    query getBusinessApps($filter: AppFilterInput, $skip: Int, $take: Int) {
  trackingAgent {
    apps {
      paginatedBusinessApps(where: $filter, skip: $skip, take: $take) {
        items {
          id
          name
          state
          appUsageSummary {
            usersCount
            usageMinutes
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessAppsGQL extends Apollo.Query<GetBusinessAppsQuery, GetBusinessAppsQueryVariables> {
    document = GetBusinessAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAppStateDocument = gql`
    mutation updateAppState($appId: Uuid!, $state: AppStates!) {
  trackingAgent {
    apps {
      updateState(appId: $appId, state: $state)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAppStateGQL extends Apollo.Mutation<UpdateAppStateMutation, UpdateAppStateMutationVariables> {
    document = UpdateAppStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentProductiveStatesDocument = gql`
    query GetAgentProductiveStates($filter: AppUsageFilterInput, $sort: [AppUsageSortInput!], $skip: Int, $take: Int) {
  trackingAgent {
    logs {
      productiveStates(where: $filter, skip: $skip, take: $take, order: $sort) {
        items {
          businessId
          machine
          appId
          app {
            name
          }
          date
          iPAddress
          username
          hour
          description
          userNetwork
          usageBreakDown
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentProductiveStatesGQL extends Apollo.Query<GetAgentProductiveStatesQuery, GetAgentProductiveStatesQueryVariables> {
    document = GetAgentProductiveStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentIdleStatesDocument = gql`
    query GetAgentIdleStates($filter: AppUsageFilterInput, $sort: [AppUsageSortInput!], $skip: Int, $take: Int) {
  trackingAgent {
    logs {
      idleStates(where: $filter, skip: $skip, take: $take, order: $sort) {
        items {
          businessId
          machine
          appId
          app {
            name
          }
          date
          iPAddress
          username
          hour
          description
          usageBreakDown
          userNetwork
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentIdleStatesGQL extends Apollo.Query<GetAgentIdleStatesQuery, GetAgentIdleStatesQueryVariables> {
    document = GetAgentIdleStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentIdleStateApplicationsDocument = gql`
    query GetAgentIdleStateApplications($filter: AppUsageFilterInput, $sort: [AppUsageSortInput!], $skip: Int, $take: Int) {
  trackingAgent {
    logs {
      idleStates(where: $filter, skip: $skip, take: $take, order: $sort) {
        items {
          id
          appId
          app {
            name
          }
          date
          hour
          description
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentIdleStateApplicationsGQL extends Apollo.Query<GetAgentIdleStateApplicationsQuery, GetAgentIdleStateApplicationsQueryVariables> {
    document = GetAgentIdleStateApplicationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentProductiveStateApplicationsDocument = gql`
    query GetAgentProductiveStateApplications($filter: AppUsageFilterInput, $sort: [AppUsageSortInput!], $skip: Int, $take: Int) {
  trackingAgent {
    logs {
      productiveStates(where: $filter, skip: $skip, take: $take, order: $sort) {
        items {
          id
          appId
          app {
            name
          }
          date
          hour
          description
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentProductiveStateApplicationsGQL extends Apollo.Query<GetAgentProductiveStateApplicationsQuery, GetAgentProductiveStateApplicationsQueryVariables> {
    document = GetAgentProductiveStateApplicationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentProductiveMillisecondsDocument = gql`
    query GetAgentProductiveMilliseconds($startDate: DateTime!, $endDate: DateTime!) {
  trackingAgent {
    logs {
      productiveStateMilliseconds(startDate: $startDate, endDate: $endDate)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentProductiveMillisecondsGQL extends Apollo.Query<GetAgentProductiveMillisecondsQuery, GetAgentProductiveMillisecondsQueryVariables> {
    document = GetAgentProductiveMillisecondsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentIdleMillisecondsDocument = gql`
    query GetAgentIdleMilliseconds($starDate: DateTime!, $endDate: DateTime!) {
  trackingAgent {
    logs {
      idleStateMilliseconds(startDate: $starDate, endDate: $endDate)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentIdleMillisecondsGQL extends Apollo.Query<GetAgentIdleMillisecondsQuery, GetAgentIdleMillisecondsQueryVariables> {
    document = GetAgentIdleMillisecondsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentProductiveStateLiveReportDocument = gql`
    query getAgentProductiveStateLiveReport($userId: Uuid, $startDate: DateTime!, $endDate: DateTime!) {
  trackingAgent {
    logs {
      productiveStateLiveReport(
        userId: $userId
        startDate: $startDate
        endDate: $endDate
      ) {
        date
        totalMilliseconds
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentProductiveStateLiveReportGQL extends Apollo.Query<GetAgentProductiveStateLiveReportQuery, GetAgentProductiveStateLiveReportQueryVariables> {
    document = GetAgentProductiveStateLiveReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentIdleStateLiveReportDocument = gql`
    query getAgentIdleStateLiveReport($userId: Uuid, $startDate: DateTime!, $endDate: DateTime!) {
  trackingAgent {
    logs {
      idleStateLiveReport(userId: $userId, startDate: $startDate, endDate: $endDate) {
        date
        totalMilliseconds
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentIdleStateLiveReportGQL extends Apollo.Query<GetAgentIdleStateLiveReportQuery, GetAgentIdleStateLiveReportQueryVariables> {
    document = GetAgentIdleStateLiveReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAttendanceRecordsDocument = gql`
    query getAttendanceRecords($filter: UserFilterInput, $skip: Int, $take: Int, $fromDate: DateTime!, $toDate: DateTime!, $endDate: DateTime!, $departmentId: Uuid, $present: Boolean, $absent: Boolean, $onWeekend: Boolean, $leave: Boolean, $onTimeLogin: Boolean, $late: Boolean) {
  attendanceManagement {
    attendanceRecord {
      userWithAttendanceRecords(
        skip: $skip
        take: $take
        where: $filter
        order: [{firstName: ASC}]
        fromDate: $fromDate
        toDate: $toDate
        departmentId: $departmentId
        absent: $absent
        present: $present
        leave: $leave
        onWeekend: $onWeekend
        onTimeLogin: $onTimeLogin
        late: $late
      ) {
        items {
          id
          firstName
          lastName
          fullName
          email
          department {
            name
          }
          jobTitle {
            name
          }
          leaves(
            where: {and: [{fromDate: {gte: $fromDate, lte: $toDate}}, {toDate: {gte: $fromDate, lte: $toDate}}]}
          ) {
            ...leaveFragment
          }
          pic
          attendanceUserConfigurations {
            id
            attendanceId
            useUserConfiguration
            openingTime
            shiftId
            businessId
            business {
              configuration
            }
            workingDays
            weekDays
            businessShift {
              id
              openingTime
            }
            attendanceRecords(where: {inDateTime: {gte: $fromDate, lte: $endDate}}) {
              id
              inDateTime
              outDateTime
              isLate
              attendanceUserConfigurationId
              agentTime {
                idleTime
                productiveTime
                loginTime
                logoutTime
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
}
    ${LeaveFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAttendanceRecordsGQL extends Apollo.Query<GetAttendanceRecordsQuery, GetAttendanceRecordsQueryVariables> {
    document = GetAttendanceRecordsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserAttendanceConfigsDocument = gql`
    query getUserAttendanceConfigs($search: String, $skip: Int, $take: Int) {
  attendanceManagement {
    attendance {
      usersConfig(
        where: {or: [{user: {email: {contains: $search}}}, {user: {firstName: {contains: $search}}}, {user: {lastName: {contains: $search}}}], and: [{user: {state: {eq: ACTIVE}}}]}
        skip: $skip
        take: $take
        order: {user: {fullName: ASC}}
      ) {
        items {
          id
          attendanceId
          userId
          user {
            email
            firstName
            lastName
            fullName
          }
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserAttendanceConfigsGQL extends Apollo.Query<GetUserAttendanceConfigsQuery, GetUserAttendanceConfigsQueryVariables> {
    document = GetUserAttendanceConfigsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAttendanceIdsDocument = gql`
    query getAttendanceIds($filter: AttendanceUserConfigurationFilterInput, $skip: Int, $take: Int) {
  attendanceManagement {
    attendance {
      usersConfig(
        where: $filter
        skip: $skip
        take: $take
        order: {attendanceId: ASC}
      ) {
        items {
          id
          attendanceId
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAttendanceIdsGQL extends Apollo.Query<GetAttendanceIdsQuery, GetAttendanceIdsQueryVariables> {
    document = GetAttendanceIdsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserAttendanceConfigDocument = gql`
    query getUserAttendanceConfig($userId: Uuid!) {
  attendanceManagement {
    attendance {
      attendanceConfig(userId: $userId) {
        id
        attendanceId
        closingTime
        openingTime
        businessId
        business {
          configuration
        }
        workingDays
        weekDays
        state
        shiftId
        userId
        useUserConfiguration
        user {
          email
          firstName
          lastName
          fullName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserAttendanceConfigGQL extends Apollo.Query<GetUserAttendanceConfigQuery, GetUserAttendanceConfigQueryVariables> {
    document = GetUserAttendanceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const YesterdayAttendanceReportDocument = gql`
    query yesterdayAttendanceReport($date: DateTime!, $departmentId: Uuid) {
  attendanceManagement {
    attendanceRecord {
      yesterdayAttendanceReport(date: $date, departmentId: $departmentId) {
        avgCheckin
        avgCheckout
        productive
        effective
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class YesterdayAttendanceReportGQL extends Apollo.Query<YesterdayAttendanceReportQuery, YesterdayAttendanceReportQueryVariables> {
    document = YesterdayAttendanceReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TodayAttendanceReportDocument = gql`
    query todayAttendanceReport($date: DateTime!, $departmentId: Uuid) {
  attendanceManagement {
    attendanceRecord {
      todayAttendanceReport(date: $date, departmentId: $departmentId) {
        absent
        onLeave
        onWeekend
        present
        lateComers
        onTimeComers
        total
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TodayAttendanceReportGQL extends Apollo.Query<TodayAttendanceReportQuery, TodayAttendanceReportQueryVariables> {
    document = TodayAttendanceReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncAttendanceRecordsFromScratchDocument = gql`
    mutation syncAttendanceRecordsFromScratch {
  attendanceManagement {
    records {
      syncAttendanceRecordsFromScratch
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncAttendanceRecordsFromScratchGQL extends Apollo.Mutation<SyncAttendanceRecordsFromScratchMutation, SyncAttendanceRecordsFromScratchMutationVariables> {
    document = SyncAttendanceRecordsFromScratchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClearAttendanceRecordsDocument = gql`
    mutation clearAttendanceRecords {
  attendanceManagement {
    records {
      removeAttendanceRecords
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClearAttendanceRecordsGQL extends Apollo.Mutation<ClearAttendanceRecordsMutation, ClearAttendanceRecordsMutationVariables> {
    document = ClearAttendanceRecordsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditAttendanceDocument = gql`
    mutation editAttendance($model: [EditAttendanceRecordInput]) {
  attendanceManagement {
    records {
      editRecords(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditAttendanceGQL extends Apollo.Mutation<EditAttendanceMutation, EditAttendanceMutationVariables> {
    document = EditAttendanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveAttendanceDocument = gql`
    mutation removeAttendance($ids: [Uuid!]) {
  attendanceManagement {
    records {
      removeRecords(ids: $ids)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveAttendanceGQL extends Apollo.Mutation<RemoveAttendanceMutation, RemoveAttendanceMutationVariables> {
    document = RemoveAttendanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAttendanceConfigDocument = gql`
    query GetAttendanceConfig($id: Uuid!) {
  attendanceManagement {
    attendance {
      businessAttendanceConfig(businessId: $id) {
        businessId
        firstShift {
          attendanceConfigurationId
          closingTime
          openingTime
          state
          id
        }
        secondShift {
          attendanceConfigurationId
          closingTime
          openingTime
          state
          id
        }
        state
        id
        secondShiftId
        firstShiftId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAttendanceConfigGQL extends Apollo.Query<GetAttendanceConfigQuery, GetAttendanceConfigQueryVariables> {
    document = GetAttendanceConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedBonusCategoriesDocument = gql`
    query getPaginatedBonusCategories($filter: BonusCategoryFilterInput, $skip: Int, $take: Int) {
  hr {
    bonusCategories(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...bonusCategories
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${BonusCategoriesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedBonusCategoriesGQL extends Apollo.Query<GetPaginatedBonusCategoriesQuery, GetPaginatedBonusCategoriesQueryVariables> {
    document = GetPaginatedBonusCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBonusCategoriesDocument = gql`
    query getBonusCategories($search: String, $skip: Int, $take: Int) {
  hr {
    bonusCategories(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBonusCategoriesGQL extends Apollo.Query<GetBonusCategoriesQuery, GetBonusCategoriesQueryVariables> {
    document = GetBonusCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBonusCategoryDocument = gql`
    query getBonusCategory($id: Uuid!) {
  hr {
    bonusCategories(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...bonusCategories
      }
    }
  }
}
    ${BonusCategoriesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBonusCategoryGQL extends Apollo.Query<GetBonusCategoryQuery, GetBonusCategoryQueryVariables> {
    document = GetBonusCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddBonusCategoryDocument = gql`
    mutation addBonusCategory($model: CreateBonusCategoryInput) {
  hr {
    bonusCategories {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBonusCategoryGQL extends Apollo.Mutation<AddBonusCategoryMutation, AddBonusCategoryMutationVariables> {
    document = AddBonusCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBonusCategoryDocument = gql`
    mutation updateBonusCategory($model: UpdateBonusCategoryInput) {
  hr {
    bonusCategories {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBonusCategoryGQL extends Apollo.Mutation<UpdateBonusCategoryMutation, UpdateBonusCategoryMutationVariables> {
    document = UpdateBonusCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveBonusCategoryDocument = gql`
    mutation archiveBonusCategory($bonusCategoryId: Uuid!) {
  hr {
    bonusCategories {
      archive(bonusCategoryId: $bonusCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveBonusCategoryGQL extends Apollo.Mutation<ArchiveBonusCategoryMutation, ArchiveBonusCategoryMutationVariables> {
    document = ArchiveBonusCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreBonusCategoryDocument = gql`
    mutation restoreBonusCategory($bonusCategoryId: Uuid!) {
  hr {
    bonusCategories {
      restore(bonusCategoryId: $bonusCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreBonusCategoryGQL extends Apollo.Mutation<RestoreBonusCategoryMutation, RestoreBonusCategoryMutationVariables> {
    document = RestoreBonusCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedBonusTypesDocument = gql`
    query getPaginatedBonusTypes($filter: BonusTypeFilterInput, $skip: Int, $take: Int) {
  hr {
    bonusTypes(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...bonusTypes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${BonusTypesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedBonusTypesGQL extends Apollo.Query<GetPaginatedBonusTypesQuery, GetPaginatedBonusTypesQueryVariables> {
    document = GetPaginatedBonusTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBonusTypesDocument = gql`
    query getBonusTypes($search: String, $skip: Int, $take: Int) {
  hr {
    bonusTypes(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
        percentage
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBonusTypesGQL extends Apollo.Query<GetBonusTypesQuery, GetBonusTypesQueryVariables> {
    document = GetBonusTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBonusTypeDocument = gql`
    query getBonusType($id: Uuid!) {
  hr {
    bonusTypes(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...bonusTypes
      }
    }
  }
}
    ${BonusTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBonusTypeGQL extends Apollo.Query<GetBonusTypeQuery, GetBonusTypeQueryVariables> {
    document = GetBonusTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddBonusTypeDocument = gql`
    mutation addBonusType($model: CreateBonusTypeInput) {
  hr {
    bonusTypes {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBonusTypeGQL extends Apollo.Mutation<AddBonusTypeMutation, AddBonusTypeMutationVariables> {
    document = AddBonusTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBonusTypeDocument = gql`
    mutation updateBonusType($model: UpdateBonusTypeInput) {
  hr {
    bonusTypes {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBonusTypeGQL extends Apollo.Mutation<UpdateBonusTypeMutation, UpdateBonusTypeMutationVariables> {
    document = UpdateBonusTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveBonusTypeDocument = gql`
    mutation archiveBonusType($bonusTypeId: Uuid!) {
  hr {
    bonusTypes {
      archive(bonusTypeId: $bonusTypeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveBonusTypeGQL extends Apollo.Mutation<ArchiveBonusTypeMutation, ArchiveBonusTypeMutationVariables> {
    document = ArchiveBonusTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreBonusTypeDocument = gql`
    mutation restoreBonusType($bonusTypeId: Uuid!) {
  hr {
    bonusTypes {
      restore(bonusTypeId: $bonusTypeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreBonusTypeGQL extends Apollo.Mutation<RestoreBonusTypeMutation, RestoreBonusTypeMutationVariables> {
    document = RestoreBonusTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsByLookupCodeDocument = gql`
    query getBusLookupsByLookupCode($search: String, $skip: Int, $take: Int, $parentCode: String) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{parentId: {neq: null}}, {state: {eq: ACTIVE}}, {parent: {code: {eq: $parentCode}}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsByLookupCodeGQL extends Apollo.Query<GetBusLookupsByLookupCodeQuery, GetBusLookupsByLookupCodeQueryVariables> {
    document = GetBusLookupsByLookupCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupDocument = gql`
    query getBusLookup($id: Uuid!) {
  paginatedBusinessLookups(where: {id: {eq: $id}}, skip: null, take: 1) {
    items {
      ...businessLookup
    }
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupGQL extends Apollo.Query<GetBusLookupQuery, GetBusLookupQueryVariables> {
    document = GetBusLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupByCodeDocument = gql`
    query getBusLookupByCode($code: String) {
  paginatedBusinessLookups(where: {code: {eq: $code}}, skip: null, take: 1) {
    items {
      ...businessLookup
    }
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupByCodeGQL extends Apollo.Query<GetBusLookupByCodeQuery, GetBusLookupByCodeQueryVariables> {
    document = GetBusLookupByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsDocument = gql`
    query getBusLookups($search: String, $skip: Int, $take: Int) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsGQL extends Apollo.Query<GetBusLookupsQuery, GetBusLookupsQueryVariables> {
    document = GetBusLookupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsByLookupModuleCodeDocument = gql`
    query getBusLookupsByLookupModuleCode($search: String, $skip: Int, $take: Int, $lookupModuleCode: String) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{lookupModuleCode: {eq: $lookupModuleCode}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsByLookupModuleCodeGQL extends Apollo.Query<GetBusLookupsByLookupModuleCodeQuery, GetBusLookupsByLookupModuleCodeQueryVariables> {
    document = GetBusLookupsByLookupModuleCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsByLookupModuleCodeWithOutParentIdDocument = gql`
    query getBusLookupsByLookupModuleCodeWithOutParentId($search: String, $skip: Int, $take: Int, $lookupModuleCode: String, $parentId: Uuid) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{lookupModuleCode: {contains: $lookupModuleCode}}, {parentId: {eq: $parentId}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsByLookupModuleCodeWithOutParentIdGQL extends Apollo.Query<GetBusLookupsByLookupModuleCodeWithOutParentIdQuery, GetBusLookupsByLookupModuleCodeWithOutParentIdQueryVariables> {
    document = GetBusLookupsByLookupModuleCodeWithOutParentIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsByLookupModuleCodeWithParentIdDocument = gql`
    query getBusLookupsByLookupModuleCodeWithParentId($search: String, $skip: Int, $take: Int, $lookupModuleCode: String, $parentId: Uuid, $status: States) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{lookupModuleCode: {contains: $lookupModuleCode}}, {parentId: {neq: $parentId}}, {state: {eq: $status}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsByLookupModuleCodeWithParentIdGQL extends Apollo.Query<GetBusLookupsByLookupModuleCodeWithParentIdQuery, GetBusLookupsByLookupModuleCodeWithParentIdQueryVariables> {
    document = GetBusLookupsByLookupModuleCodeWithParentIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsBySelectedInDocument = gql`
    query getBusLookupsBySelectedIn($search: String, $skip: Int, $take: Int, $idList: [Uuid!]) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: {id: {in: $idList}}}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsBySelectedInGQL extends Apollo.Query<GetBusLookupsBySelectedInQuery, GetBusLookupsBySelectedInQueryVariables> {
    document = GetBusLookupsBySelectedInDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusLookupsByLookupModuleCodeFilterDocument = gql`
    query getBusLookupsByLookupModuleCodeFilter($filter: BusinessLookupFilterInput, $skip: Int, $take: Int) {
  paginatedBusinessLookups(
    where: $filter
    skip: $skip
    take: $take
    order: {createdOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusLookupsByLookupModuleCodeFilterGQL extends Apollo.Query<GetBusLookupsByLookupModuleCodeFilterQuery, GetBusLookupsByLookupModuleCodeFilterQueryVariables> {
    document = GetBusLookupsByLookupModuleCodeFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActBusLookupsByLookupCodeDocument = gql`
    query getActBusLookupsByLookupCode($search: String, $skip: Int, $take: Int, $lookupModuleCode: String) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{parentId: {neq: null}}, {state: {eq: ACTIVE}}, {lookupModuleCode: {eq: $lookupModuleCode}}]}
    skip: $skip
    take: $take
    order: {createdOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActBusLookupsByLookupCodeGQL extends Apollo.Query<GetActBusLookupsByLookupCodeQuery, GetActBusLookupsByLookupCodeQueryVariables> {
    document = GetActBusLookupsByLookupCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActBusLookupsByLookupCodeAsParentDocument = gql`
    query getActBusLookupsByLookupCodeAsParent($search: String, $skip: Int, $take: Int, $lookupModuleCode: String) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{parentId: {eq: null}}, {state: {eq: ACTIVE}}, {lookupModuleCode: {eq: $lookupModuleCode}}]}
    skip: $skip
    take: $take
    order: {createdOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActBusLookupsByLookupCodeAsParentGQL extends Apollo.Query<GetActBusLookupsByLookupCodeAsParentQuery, GetActBusLookupsByLookupCodeAsParentQueryVariables> {
    document = GetActBusLookupsByLookupCodeAsParentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBusLookupDocument = gql`
    mutation updateBusLookup($model: UpdateBusLookupInput) {
  business {
    busLookup {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBusLookupGQL extends Apollo.Mutation<UpdateBusLookupMutation, UpdateBusLookupMutationVariables> {
    document = UpdateBusLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddBusLookupDocument = gql`
    mutation addBusLookup($model: CreateBusLookupInput) {
  business {
    busLookup {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBusLookupGQL extends Apollo.Mutation<AddBusLookupMutation, AddBusLookupMutationVariables> {
    document = AddBusLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveBusLookupDocument = gql`
    mutation archiveBusLookup($lookupId: Uuid!) {
  business {
    busLookup {
      archive(lookupId: $lookupId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveBusLookupGQL extends Apollo.Mutation<ArchiveBusLookupMutation, ArchiveBusLookupMutationVariables> {
    document = ArchiveBusLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreBusLookupDocument = gql`
    mutation restoreBusLookup($lookupId: Uuid!) {
  business {
    busLookup {
      restore(lookupId: $lookupId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreBusLookupGQL extends Apollo.Mutation<RestoreBusLookupMutation, RestoreBusLookupMutationVariables> {
    document = RestoreBusLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBusinessTypesDocument = gql`
    query getBusinessTypes {
  businessTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBusinessTypesGQL extends Apollo.Query<GetBusinessTypesQuery, GetBusinessTypesQueryVariables> {
    document = GetBusinessTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActiveUsersDocument = gql`
    query getActiveUsers($filter: UserFilterInput, $skip: Int, $take: Int) {
  paginatedUsers(
    where: $filter
    skip: $skip
    take: $take
    order: [{firstName: ASC}]
  ) {
    items {
      ...businessUser
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${BusinessUserFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActiveUsersGQL extends Apollo.Query<GetActiveUsersQuery, GetActiveUsersQueryVariables> {
    document = GetActiveUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDropdownUsersDocument = gql`
    query getDropdownUsers($filter: UserFilterInput, $skip: Int, $take: Int) {
  paginatedUsers(where: $filter, skip: $skip, take: $take) {
    items {
      id
      firstName
      lastName
      fullName
      userFullName
      email
      pic
      departmentId
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDropdownUsersGQL extends Apollo.Query<GetDropdownUsersQuery, GetDropdownUsersQueryVariables> {
    document = GetDropdownUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserAgentConfigDocument = gql`
    mutation updateUserAgentConfig($userId: Uuid!, $model: UserAgentConfigurationVmInput) {
  trackingAgent {
    userAgent {
      updateUserAgent(userId: $userId, model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserAgentConfigGQL extends Apollo.Mutation<UpdateUserAgentConfigMutation, UpdateUserAgentConfigMutationVariables> {
    document = UpdateUserAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisableUserAgentConfigDocument = gql`
    mutation disableUserAgentConfig($userId: Uuid!) {
  trackingAgent {
    userAgent {
      disableUserAgent(userId: $userId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisableUserAgentConfigGQL extends Apollo.Mutation<DisableUserAgentConfigMutation, DisableUserAgentConfigMutationVariables> {
    document = DisableUserAgentConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDetailDocument = gql`
    query getUserDetail($id: Uuid!) {
  user(id: $id) {
    id
    firstName
    lastName
    fullName
    email
    pic
    gender
    departmentId
    department {
      name
    }
    jobTitleId
    jobTitle {
      name
    }
    jobFunctionId
    jobFunction {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserDetailGQL extends Apollo.Query<GetUserDetailQuery, GetUserDetailQueryVariables> {
    document = GetUserDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBusinessUserWithoutRoleDocument = gql`
    mutation createBusinessUserWithoutRole($model: CreateUserInput) {
  user {
    createUser(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBusinessUserWithoutRoleGQL extends Apollo.Mutation<CreateBusinessUserWithoutRoleMutation, CreateBusinessUserWithoutRoleMutationVariables> {
    document = CreateBusinessUserWithoutRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersWithYearlyQuotaDocument = gql`
    query getUsersWithYearlyQuota($filter: UserFilterInput, $skip: Int, $take: Int) {
  paginatedUsers(where: $filter, skip: $skip, take: $take) {
    items {
      id
      firstName
      lastName
      fullName
      email
      pic
      leaves {
        ...leaveFragment
      }
      quotas(order: {year: DESC}) {
        ...quota
      }
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${LeaveFragmentFragmentDoc}
${QuotaFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersWithYearlyQuotaGQL extends Apollo.Query<GetUsersWithYearlyQuotaQuery, GetUsersWithYearlyQuotaQueryVariables> {
    document = GetUsersWithYearlyQuotaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCandidateEvaluationDocument = gql`
    query getCandidateEvaluation($candidateId: Uuid!, $jobPositionId: Uuid!) {
  hr {
    candidateEvaluation(candidateId: $candidateId, jobPositionId: $jobPositionId) {
      ...evaluation
    }
  }
}
    ${EvaluationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCandidateEvaluationGQL extends Apollo.Query<GetCandidateEvaluationQuery, GetCandidateEvaluationQueryVariables> {
    document = GetCandidateEvaluationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedCandidatesDocument = gql`
    query getPaginatedCandidates($positionId: Uuid!, $filter: CandidateFilterInput, $skip: Int, $take: Int) {
  hr {
    jobCandidates(
      jobPositionId: $positionId
      skip: $skip
      take: $take
      where: $filter
    ) {
      items {
        ...candidate
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CandidateFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedCandidatesGQL extends Apollo.Query<GetPaginatedCandidatesQuery, GetPaginatedCandidatesQueryVariables> {
    document = GetPaginatedCandidatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobCandidateDocument = gql`
    query getJobCandidate($positionId: Uuid!, $id: Uuid!) {
  hr {
    jobCandidates(
      jobPositionId: $positionId
      where: {id: {eq: $id}}
      skip: 0
      take: 1
    ) {
      items {
        ...candidate
      }
    }
  }
}
    ${CandidateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobCandidateGQL extends Apollo.Query<GetJobCandidateQuery, GetJobCandidateQueryVariables> {
    document = GetJobCandidateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HireCandidateDocument = gql`
    mutation hireCandidate($businessId: Uuid!, $id: Uuid!, $jobPositionId: Uuid!) {
  hr {
    candidates {
      hire(businessId: $businessId, id: $id, jobPositionId: $jobPositionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HireCandidateGQL extends Apollo.Mutation<HireCandidateMutation, HireCandidateMutationVariables> {
    document = HireCandidateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseCandidateDocument = gql`
    mutation closeCandidate($businessId: Uuid!, $id: Uuid!, $jobPositionId: Uuid!) {
  hr {
    candidates {
      close(businessId: $businessId, id: $id, jobPositionId: $jobPositionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseCandidateGQL extends Apollo.Mutation<CloseCandidateMutation, CloseCandidateMutationVariables> {
    document = CloseCandidateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendInviteDocument = gql`
    mutation sendInvite($id: Uuid!, $jobPositionId: Uuid!, $model: SendInviteInput!) {
  hr {
    candidates {
      sendInvite(id: $id, jobPositionId: $jobPositionId, model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendInviteGQL extends Apollo.Mutation<SendInviteMutation, SendInviteMutationVariables> {
    document = SendInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddFeedBackDocument = gql`
    mutation addFeedBack($businessId: Uuid!, $id: Uuid!, $jobPositionId: Uuid!, $model: CreateCandidateEvaluationInput!) {
  hr {
    candidates {
      addFeedBack(
        businessId: $businessId
        id: $id
        jobPositionId: $jobPositionId
        model: $model
      )
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddFeedBackGQL extends Apollo.Mutation<AddFeedBackMutation, AddFeedBackMutationVariables> {
    document = AddFeedBackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFeedBackDocument = gql`
    mutation updateFeedBack($businessId: Uuid!, $id: Uuid!, $jobPositionId: Uuid!, $model: UpdateCandidateEvaluationInput!) {
  hr {
    candidates {
      updateFeedBack(
        businessId: $businessId
        id: $id
        jobPositionId: $jobPositionId
        model: $model
      )
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFeedBackGQL extends Apollo.Mutation<UpdateFeedBackMutation, UpdateFeedBackMutationVariables> {
    document = UpdateFeedBackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedCertificationsDocument = gql`
    query getPaginatedCertifications($filter: CertificationFilterInput, $skip: Int, $take: Int) {
  hr {
    certifications(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...certifications
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CertificationsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedCertificationsGQL extends Apollo.Query<GetPaginatedCertificationsQuery, GetPaginatedCertificationsQueryVariables> {
    document = GetPaginatedCertificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCertificationsDocument = gql`
    query getCertifications($search: String, $skip: Int, $take: Int) {
  hr {
    certifications(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
        code
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCertificationsGQL extends Apollo.Query<GetCertificationsQuery, GetCertificationsQueryVariables> {
    document = GetCertificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCertificationDocument = gql`
    query getCertification($id: Uuid!) {
  hr {
    certifications(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...certifications
      }
    }
  }
}
    ${CertificationsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCertificationGQL extends Apollo.Query<GetCertificationQuery, GetCertificationQueryVariables> {
    document = GetCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCertificationDocument = gql`
    mutation addCertification($model: CreateCertificationInput) {
  hr {
    certifications {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCertificationGQL extends Apollo.Mutation<AddCertificationMutation, AddCertificationMutationVariables> {
    document = AddCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCertificationDocument = gql`
    mutation updateCertification($model: UpdateCertificationInput) {
  hr {
    certifications {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCertificationGQL extends Apollo.Mutation<UpdateCertificationMutation, UpdateCertificationMutationVariables> {
    document = UpdateCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveCertificationDocument = gql`
    mutation archiveCertification($certificationId: Uuid!) {
  hr {
    certifications {
      archive(certificationId: $certificationId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveCertificationGQL extends Apollo.Mutation<ArchiveCertificationMutation, ArchiveCertificationMutationVariables> {
    document = ArchiveCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreCertificationDocument = gql`
    mutation restoreCertification($certificationId: Uuid!) {
  hr {
    certifications {
      restore(certificationId: $certificationId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreCertificationGQL extends Apollo.Mutation<RestoreCertificationMutation, RestoreCertificationMutationVariables> {
    document = RestoreCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedClientsDocument = gql`
    query getPaginatedClients($filter: ClientFilterInput, $skip: Int, $take: Int) {
  timeTracking {
    clients(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...clients
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ClientsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedClientsGQL extends Apollo.Query<GetPaginatedClientsQuery, GetPaginatedClientsQueryVariables> {
    document = GetPaginatedClientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClientsDocument = gql`
    query getClients($search: String, $skip: Int, $take: Int) {
  timeTracking {
    clients(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClientsGQL extends Apollo.Query<GetClientsQuery, GetClientsQueryVariables> {
    document = GetClientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClientDocument = gql`
    query getClient($id: Uuid!) {
  timeTracking {
    clients(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...clients
      }
    }
  }
}
    ${ClientsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClientGQL extends Apollo.Query<GetClientQuery, GetClientQueryVariables> {
    document = GetClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddClientDocument = gql`
    mutation addClient($model: CreateClientInput) {
  timeTracking {
    clients {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddClientGQL extends Apollo.Mutation<AddClientMutation, AddClientMutationVariables> {
    document = AddClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClientDocument = gql`
    mutation updateClient($model: UpdateClientInput) {
  timeTracking {
    clients {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClientGQL extends Apollo.Mutation<UpdateClientMutation, UpdateClientMutationVariables> {
    document = UpdateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveClientDocument = gql`
    mutation archiveClient($clientId: Uuid!) {
  timeTracking {
    clients {
      archive(clientId: $clientId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveClientGQL extends Apollo.Mutation<ArchiveClientMutation, ArchiveClientMutationVariables> {
    document = ArchiveClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreClientDocument = gql`
    mutation restoreClient($clientId: Uuid!) {
  timeTracking {
    clients {
      restore(clientId: $clientId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreClientGQL extends Apollo.Mutation<RestoreClientMutation, RestoreClientMutationVariables> {
    document = RestoreClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactsDocument = gql`
    query getContacts($search: String, $skip: Int, $take: Int) {
  finance {
    paginatedContacts(
      where: {or: [{name: {contains: $search}}, {phone: {contains: $search}}, {email: {contains: $search}}]}
      skip: $skip
      take: $take
      order: {createdOn: DESC, name: ASC}
    ) {
      items {
        ...contacts
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ContactsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactsGQL extends Apollo.Query<GetContactsQuery, GetContactsQueryVariables> {
    document = GetContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactDocument = gql`
    query getContact($id: Uuid) {
  finance {
    paginatedContacts(where: {id: {eq: $id}}) {
      items {
        ...contacts
      }
    }
  }
}
    ${ContactsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactGQL extends Apollo.Query<GetContactQuery, GetContactQueryVariables> {
    document = GetContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactWithAddressDocument = gql`
    query getContactWithAddress($id: Uuid) {
  finance {
    paginatedContacts(where: {id: {eq: $id}}) {
      items {
        ...contacts
        addressId
        address {
          id
          addressLine1
          addressLine2
          country
          city
          state
          zipCode
          timeZone
        }
      }
    }
  }
}
    ${ContactsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactWithAddressGQL extends Apollo.Query<GetContactWithAddressQuery, GetContactWithAddressQueryVariables> {
    document = GetContactWithAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddContactDocument = gql`
    mutation addContact($model: CreateContactInput) {
  finance {
    contacts {
      createContact(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddContactGQL extends Apollo.Mutation<AddContactMutation, AddContactMutationVariables> {
    document = AddContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContactDocument = gql`
    mutation updateContact($model: UpdateContactInput) {
  finance {
    contacts {
      updateContact(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContactGQL extends Apollo.Mutation<UpdateContactMutation, UpdateContactMutationVariables> {
    document = UpdateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    id
    name
    numericCode
    timezones
    subRegion
    region
    callingCodes
    isoCode
    languages
    flag
    currencyString
    currencies {
      symbol
      name
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesGQL extends Apollo.Query<GetCountriesQuery, GetCountriesQueryVariables> {
    document = GetCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllCurrenciesDocument = gql`
    query getAllCurrencies {
  countries {
    currencyString
    currencies {
      symbol
      name
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllCurrenciesGQL extends Apollo.Query<GetAllCurrenciesQuery, GetAllCurrenciesQueryVariables> {
    document = GetAllCurrenciesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrenciesByCountryDocument = gql`
    query getCurrenciesByCountry($id: Byte) {
  countries(where: {id: {eq: $id}}) {
    currencyString
    currencies {
      symbol
      name
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrenciesByCountryGQL extends Apollo.Query<GetCurrenciesByCountryQuery, GetCurrenciesByCountryQueryVariables> {
    document = GetCurrenciesByCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedDepartmentsDocument = gql`
    query getPaginatedDepartments($filter: DepartmentFilterInput, $skip: Int, $take: Int) {
  hr {
    departments(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...departments
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${DepartmentsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedDepartmentsGQL extends Apollo.Query<GetPaginatedDepartmentsQuery, GetPaginatedDepartmentsQueryVariables> {
    document = GetPaginatedDepartmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDepartmentsDocument = gql`
    query getDepartments($search: String, $skip: Int, $take: Int) {
  hr {
    departments(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
        code
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDepartmentsGQL extends Apollo.Query<GetDepartmentsQuery, GetDepartmentsQueryVariables> {
    document = GetDepartmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDepartmentDocument = gql`
    query getDepartment($id: Uuid!) {
  hr {
    departments(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...departments
      }
    }
  }
}
    ${DepartmentsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDepartmentGQL extends Apollo.Query<GetDepartmentQuery, GetDepartmentQueryVariables> {
    document = GetDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddDepartmentDocument = gql`
    mutation addDepartment($model: CreateDepartmentInput) {
  hr {
    departments {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddDepartmentGQL extends Apollo.Mutation<AddDepartmentMutation, AddDepartmentMutationVariables> {
    document = AddDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDepartmentDocument = gql`
    mutation updateDepartment($model: UpdateDepartmentInput) {
  hr {
    departments {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDepartmentGQL extends Apollo.Mutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables> {
    document = UpdateDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveDepartmentDocument = gql`
    mutation archiveDepartment($departmentId: Uuid!) {
  hr {
    departments {
      archive(departmentId: $departmentId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveDepartmentGQL extends Apollo.Mutation<ArchiveDepartmentMutation, ArchiveDepartmentMutationVariables> {
    document = ArchiveDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreDepartmentDocument = gql`
    mutation restoreDepartment($departmentId: Uuid!) {
  hr {
    departments {
      restore(departmentId: $departmentId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreDepartmentGQL extends Apollo.Mutation<RestoreDepartmentMutation, RestoreDepartmentMutationVariables> {
    document = RestoreDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedEmployeesDocument = gql`
    query getPaginatedEmployees($filter: UserFilterInput, $skip: Int, $take: Int) {
  paginatedUsers(
    where: $filter
    skip: $skip
    take: $take
    order: {createdOn: DESC}
  ) {
    items {
      id
      state
      email
      pic
      firstName
      lastName
      fullName
      departmentId
      jobTitleId
      jobFunctionId
      department {
        name
      }
      jobTitle {
        name
      }
      jobFunction {
        name
      }
      employee {
        expectedSalary
      }
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedEmployeesGQL extends Apollo.Query<GetPaginatedEmployeesQuery, GetPaginatedEmployeesQueryVariables> {
    document = GetPaginatedEmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOffsetPaginatedEmployeesDocument = gql`
    query getOffsetPaginatedEmployees($filter: UserFilterInput, $skip: Int, $take: Int) {
  paginatedUsers(
    skip: $skip
    take: $take
    where: $filter
    order: {createdOn: DESC}
  ) {
    items {
      id
      state
      email
      pic
      firstName
      lastName
      fullName
      departmentId
      jobTitleId
      jobFunctionId
      department {
        name
      }
      jobTitle {
        name
      }
      jobFunction {
        name
      }
      employee {
        expectedSalary
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOffsetPaginatedEmployeesGQL extends Apollo.Query<GetOffsetPaginatedEmployeesQuery, GetOffsetPaginatedEmployeesQueryVariables> {
    document = GetOffsetPaginatedEmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmployeesDocument = gql`
    query getEmployees($search: String, $skip: Int, $take: Int) {
  paginatedUsers(
    where: {or: [{firstName: {contains: $search}, lastName: {contains: $search}, email: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
    skip: $skip
    take: $take
    order: {firstName: ASC}
  ) {
    items {
      id
      firstName
      lastName
      fullName
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmployeesGQL extends Apollo.Query<GetEmployeesQuery, GetEmployeesQueryVariables> {
    document = GetEmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmployeeByUserIdDocument = gql`
    query getEmployeeByUserId($userId: Uuid!) {
  hr {
    employees(where: {userId: {eq: $userId}}) {
      items {
        ...employeeDetail
      }
    }
  }
}
    ${EmployeeDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmployeeByUserIdGQL extends Apollo.Query<GetEmployeeByUserIdQuery, GetEmployeeByUserIdQueryVariables> {
    document = GetEmployeeByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmployeeSalaryBenefitsDocument = gql`
    query GetEmployeeSalaryBenefits($id: Uuid!) {
  user(id: $id) {
    id
    benefits {
      eobiBenefit {
        enabled
        amount
        percentage
        overrideAmount
        overridePercentage
      }
      pfBenefit {
        enabled
        overrideAmount
        overridePercentage
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmployeeSalaryBenefitsGQL extends Apollo.Query<GetEmployeeSalaryBenefitsQuery, GetEmployeeSalaryBenefitsQueryVariables> {
    document = GetEmployeeSalaryBenefitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeeReportsTomeDocument = gql`
    query employeeReportsTome {
  hr {
    session {
      employeesReportsToMeIds
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeeReportsTomeGQL extends Apollo.Query<EmployeeReportsTomeQuery, EmployeeReportsTomeQueryVariables> {
    document = EmployeeReportsTomeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUpdateEmployeeEobiBenefitDocument = gql`
    mutation addUpdateEmployeeEobiBenefit($employeeId: Uuid!, $model: EOBIBenefitVmInput) {
  hr {
    employees {
      addUpdateEmployeeEobiBenefit(employeeId: $employeeId, eobiAccount: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUpdateEmployeeEobiBenefitGQL extends Apollo.Mutation<AddUpdateEmployeeEobiBenefitMutation, AddUpdateEmployeeEobiBenefitMutationVariables> {
    document = AddUpdateEmployeeEobiBenefitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratPayrollDocument = gql`
    mutation generatPayroll {
  hr {
    employees {
      generatPayroll
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratPayrollGQL extends Apollo.Mutation<GeneratPayrollMutation, GeneratPayrollMutationVariables> {
    document = GeneratPayrollDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($model: UpdateEmployeeInput) {
  hr {
    employees {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeGQL extends Apollo.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> {
    document = UpdateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveEmployeeDocument = gql`
    mutation archiveEmployee($employeeId: Uuid!) {
  hr {
    employees {
      archive(employeeId: $employeeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveEmployeeGQL extends Apollo.Mutation<ArchiveEmployeeMutation, ArchiveEmployeeMutationVariables> {
    document = ArchiveEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreEmployeeDocument = gql`
    mutation restoreEmployee($employeeId: Uuid!) {
  hr {
    employees {
      restore(employeeId: $employeeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreEmployeeGQL extends Apollo.Mutation<RestoreEmployeeMutation, RestoreEmployeeMutationVariables> {
    document = RestoreEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HireOrTerminateEmployeeDocument = gql`
    mutation hireOrTerminateEmployee($employeeId: Uuid!, $reasonTypeId: Uuid!, $joiningDate: DateTime, $terminationDate: DateTime, $state: States!) {
  hr {
    employees {
      hireOrTerminate(
        employeeId: $employeeId
        reasonTypeId: $reasonTypeId
        joiningDate: $joiningDate
        terminationDate: $terminationDate
        state: $state
      )
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HireOrTerminateEmployeeGQL extends Apollo.Mutation<HireOrTerminateEmployeeMutation, HireOrTerminateEmployeeMutationVariables> {
    document = HireOrTerminateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHireOrTerminateEmployeeDocument = gql`
    mutation updateHireOrTerminateEmployee($id: Uuid!, $employeeId: Uuid!, $reasonTypeId: Uuid!, $joiningDate: DateTime, $terminationDate: DateTime, $state: States!) {
  hr {
    employees {
      updateHireOrTerminate(
        id: $id
        employeeId: $employeeId
        reasonTypeId: $reasonTypeId
        joiningDate: $joiningDate
        terminationDate: $terminationDate
        state: $state
      )
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHireOrTerminateEmployeeGQL extends Apollo.Mutation<UpdateHireOrTerminateEmployeeMutation, UpdateHireOrTerminateEmployeeMutationVariables> {
    document = UpdateHireOrTerminateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSalaryDocument = gql`
    mutation updateSalary($employeeId: Uuid!, $model: CreateSalaryVMInput) {
  hr {
    employees {
      updateSalary(employeeId: $employeeId, vm: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSalaryGQL extends Apollo.Mutation<UpdateSalaryMutation, UpdateSalaryMutationVariables> {
    document = UpdateSalaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUpdateEmployeePfBenefitDocument = gql`
    mutation addUpdateEmployeePfBenefit($employeeId: Uuid!, $model: PFBenefitVmInput) {
  hr {
    employees {
      addUpdateEmployeePfBenefit(employeeId: $employeeId, pfAccount: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUpdateEmployeePfBenefitGQL extends Apollo.Mutation<AddUpdateEmployeePfBenefitMutation, AddUpdateEmployeePfBenefitMutationVariables> {
    document = AddUpdateEmployeePfBenefitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmploymentHistoryDocument = gql`
    query getEmploymentHistory($employeeId: Uuid!) {
  hr {
    employmentHistories(employeeId: $employeeId) {
      id
      reasonTypeId
      reasonType {
        name
      }
      employeeId
      joiningDate
      terminationDate
      createdBy {
        firstName
        lastName
        fullName
      }
      createdOn
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmploymentHistoryGQL extends Apollo.Query<GetEmploymentHistoryQuery, GetEmploymentHistoryQueryVariables> {
    document = GetEmploymentHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFinancialYearsDocument = gql`
    query getFinancialYears {
  finance {
    session {
      financialYears {
        ...financialYears
      }
    }
  }
}
    ${FinancialYearsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFinancialYearsGQL extends Apollo.Query<GetFinancialYearsQuery, GetFinancialYearsQueryVariables> {
    document = GetFinancialYearsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFinancialYearDocument = gql`
    query getFinancialYear($id: Uuid!) {
  finance {
    session {
      financialYear(id: $id) {
        ...financialYears
      }
    }
  }
}
    ${FinancialYearsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFinancialYearGQL extends Apollo.Query<GetFinancialYearQuery, GetFinancialYearQueryVariables> {
    document = GetFinancialYearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddFinancialYearDocument = gql`
    mutation addFinancialYear($model: CreateFinancialYearInput) {
  finance {
    financialYears {
      createFinancialYear(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddFinancialYearGQL extends Apollo.Mutation<AddFinancialYearMutation, AddFinancialYearMutationVariables> {
    document = AddFinancialYearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFinancialYearDocument = gql`
    mutation updateFinancialYear($model: UpdateFinancialYearInput) {
  finance {
    financialYears {
      updateFinancialYear(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFinancialYearGQL extends Apollo.Mutation<UpdateFinancialYearMutation, UpdateFinancialYearMutationVariables> {
    document = UpdateFinancialYearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActiveFinancialYearDocument = gql`
    query activeFinancialYear {
  finance {
    session {
      activeFinancialYear {
        ...financialYears
      }
    }
  }
}
    ${FinancialYearsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ActiveFinancialYearGQL extends Apollo.Query<ActiveFinancialYearQuery, ActiveFinancialYearQueryVariables> {
    document = ActiveFinancialYearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedGenericTasksDocument = gql`
    query getPaginatedGenericTasks($filter: GenericTaskFilterInput, $skip: Int, $take: Int) {
  timeTracking {
    genericTasks(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...genericTasks
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${GenericTasksFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedGenericTasksGQL extends Apollo.Query<GetPaginatedGenericTasksQuery, GetPaginatedGenericTasksQueryVariables> {
    document = GetPaginatedGenericTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGenericTasksDocument = gql`
    query getGenericTasks($search: String, $skip: Int, $take: Int) {
  timeTracking {
    genericTasks(
      where: {or: [{name: {contains: $search}}, {description: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {name: ASC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGenericTasksGQL extends Apollo.Query<GetGenericTasksQuery, GetGenericTasksQueryVariables> {
    document = GetGenericTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGenericTaskDocument = gql`
    query getGenericTask($id: Uuid!) {
  timeTracking {
    genericTasks(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...genericTasks
      }
    }
  }
}
    ${GenericTasksFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGenericTaskGQL extends Apollo.Query<GetGenericTaskQuery, GetGenericTaskQueryVariables> {
    document = GetGenericTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddGenericTaskDocument = gql`
    mutation addGenericTask($model: CreateGenericTaskInput) {
  timeTracking {
    genericTasks {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddGenericTaskGQL extends Apollo.Mutation<AddGenericTaskMutation, AddGenericTaskMutationVariables> {
    document = AddGenericTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGenericTaskDocument = gql`
    mutation updateGenericTask($model: UpdateGenericTaskInput) {
  timeTracking {
    genericTasks {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGenericTaskGQL extends Apollo.Mutation<UpdateGenericTaskMutation, UpdateGenericTaskMutationVariables> {
    document = UpdateGenericTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveGenericTaskDocument = gql`
    mutation archiveGenericTask($taskId: Uuid!) {
  timeTracking {
    genericTasks {
      archive(taskId: $taskId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveGenericTaskGQL extends Apollo.Mutation<ArchiveGenericTaskMutation, ArchiveGenericTaskMutationVariables> {
    document = ArchiveGenericTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreGenericTaskDocument = gql`
    mutation restoreGenericTask($taskId: Uuid!) {
  timeTracking {
    genericTasks {
      restore(taskId: $taskId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreGenericTaskGQL extends Apollo.Mutation<RestoreGenericTaskMutation, RestoreGenericTaskMutationVariables> {
    document = RestoreGenericTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedHolidayCalanderDocument = gql`
    query getPaginatedHolidayCalander($filter: HolidayCalanderFilterInput, $skip: Int, $take: Int) {
  hr {
    holidayCalanders(
      where: $filter
      skip: $skip
      take: $take
      order: {fromDate: DESC}
    ) {
      items {
        ...holidays
      }
      totalCount
    }
  }
}
    ${HolidaysFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedHolidayCalanderGQL extends Apollo.Query<GetPaginatedHolidayCalanderQuery, GetPaginatedHolidayCalanderQueryVariables> {
    document = GetPaginatedHolidayCalanderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllHolidayCalanderDocument = gql`
    query getAllHolidayCalander {
  hr {
    holidayCalanders {
      items {
        ...holidays
      }
      totalCount
    }
  }
}
    ${HolidaysFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllHolidayCalanderGQL extends Apollo.Query<GetAllHolidayCalanderQuery, GetAllHolidayCalanderQueryVariables> {
    document = GetAllHolidayCalanderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetholidayCalanderDocument = gql`
    query getholidayCalander($id: Uuid!) {
  hr {
    holidayCalanders(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...holidays
      }
    }
  }
}
    ${HolidaysFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetholidayCalanderGQL extends Apollo.Query<GetholidayCalanderQuery, GetholidayCalanderQueryVariables> {
    document = GetholidayCalanderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHolidayCalanderByDateDocument = gql`
    query getHolidayCalanderByDate($reportDate: DateTime!) {
  hr {
    holidayCalanderWithDate(reportDate: $reportDate, order: {fromDate: ASC}) {
      items {
        ...holidays
      }
    }
  }
}
    ${HolidaysFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHolidayCalanderByDateGQL extends Apollo.Query<GetHolidayCalanderByDateQuery, GetHolidayCalanderByDateQueryVariables> {
    document = GetHolidayCalanderByDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddHolidayCalanderDocument = gql`
    mutation addHolidayCalander($model: CreateHolidayCalanderInput) {
  hr {
    holidayCalander {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddHolidayCalanderGQL extends Apollo.Mutation<AddHolidayCalanderMutation, AddHolidayCalanderMutationVariables> {
    document = AddHolidayCalanderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHolidayCalanderDocument = gql`
    mutation updateHolidayCalander($model: UpdateHolidayCalanderInput) {
  hr {
    holidayCalander {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHolidayCalanderGQL extends Apollo.Mutation<UpdateHolidayCalanderMutation, UpdateHolidayCalanderMutationVariables> {
    document = UpdateHolidayCalanderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedHrLookupsDocument = gql`
    query getPaginatedHrLookups($filter: HrLookupFilterInput, $skip: Int, $take: Int) {
  hr {
    hrLookups(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...hrLookups
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${HrLookupsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedHrLookupsGQL extends Apollo.Query<GetPaginatedHrLookupsQuery, GetPaginatedHrLookupsQueryVariables> {
    document = GetPaginatedHrLookupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHrLookupsDocument = gql`
    query getHrLookups($search: String, $skip: Int, $take: Int, $lookupModuleCode: String, $lookupSetting: String) {
  hr {
    hrLookups(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}, {lookupModuleCode: {eq: $lookupModuleCode}}, {lookupSetting: {contains: $lookupSetting}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        ...hrLookups
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${HrLookupsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHrLookupsGQL extends Apollo.Query<GetHrLookupsQuery, GetHrLookupsQueryVariables> {
    document = GetHrLookupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHrLookupDocument = gql`
    query getHrLookup($id: Uuid!) {
  hr {
    hrLookups(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...hrLookups
      }
    }
  }
}
    ${HrLookupsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHrLookupGQL extends Apollo.Query<GetHrLookupQuery, GetHrLookupQueryVariables> {
    document = GetHrLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddHrLookupDocument = gql`
    mutation addHrLookup($model: CreateHrLookupInput) {
  hr {
    hrLookup {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddHrLookupGQL extends Apollo.Mutation<AddHrLookupMutation, AddHrLookupMutationVariables> {
    document = AddHrLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHrLookupDocument = gql`
    mutation updateHrLookup($model: UpdateHrLookupInput) {
  hr {
    hrLookup {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHrLookupGQL extends Apollo.Mutation<UpdateHrLookupMutation, UpdateHrLookupMutationVariables> {
    document = UpdateHrLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveHrLookupDocument = gql`
    mutation archiveHrLookup($hrLookupId: Uuid!) {
  hr {
    hrLookup {
      archive(hrLookupId: $hrLookupId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveHrLookupGQL extends Apollo.Mutation<ArchiveHrLookupMutation, ArchiveHrLookupMutationVariables> {
    document = ArchiveHrLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreHrLookupDocument = gql`
    mutation restoreHrLookup($hrLookupId: Uuid!) {
  hr {
    hrLookup {
      restore(hrLookupId: $hrLookupId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreHrLookupGQL extends Apollo.Mutation<RestoreHrLookupMutation, RestoreHrLookupMutationVariables> {
    document = RestoreHrLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllHrLookupsDocument = gql`
    query getAllHrLookups {
  hr {
    hrLookups {
      items {
        ...hrLookups
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${HrLookupsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllHrLookupsGQL extends Apollo.Query<GetAllHrLookupsQuery, GetAllHrLookupsQueryVariables> {
    document = GetAllHrLookupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetIndustriesDocument = gql`
    query getIndustries {
  industries {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIndustriesGQL extends Apollo.Query<GetIndustriesQuery, GetIndustriesQueryVariables> {
    document = GetIndustriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedInstitutesDocument = gql`
    query getPaginatedInstitutes($filter: InstituteFilterInput, $skip: Int, $take: Int) {
  hr {
    institutes(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...paginatedInstitutes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${PaginatedInstitutesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedInstitutesGQL extends Apollo.Query<GetPaginatedInstitutesQuery, GetPaginatedInstitutesQueryVariables> {
    document = GetPaginatedInstitutesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInstitutesDocument = gql`
    query getInstitutes($search: String, $skip: Int, $take: Int) {
  hr {
    institutes(
      where: {or: [{name: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInstitutesGQL extends Apollo.Query<GetInstitutesQuery, GetInstitutesQueryVariables> {
    document = GetInstitutesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInstituteDocument = gql`
    query getInstitute($id: Uuid!) {
  hr {
    institutes(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...paginatedInstitutes
      }
    }
  }
}
    ${PaginatedInstitutesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInstituteGQL extends Apollo.Query<GetInstituteQuery, GetInstituteQueryVariables> {
    document = GetInstituteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddInstituteDocument = gql`
    mutation addInstitute($model: CreateInstituteInput) {
  hr {
    institutes {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddInstituteGQL extends Apollo.Mutation<AddInstituteMutation, AddInstituteMutationVariables> {
    document = AddInstituteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInstituteDocument = gql`
    mutation updateInstitute($model: UpdateInstituteInput) {
  hr {
    institutes {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInstituteGQL extends Apollo.Mutation<UpdateInstituteMutation, UpdateInstituteMutationVariables> {
    document = UpdateInstituteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveInstituteDocument = gql`
    mutation archiveInstitute($instituteId: Uuid!) {
  hr {
    institutes {
      archive(instituteId: $instituteId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveInstituteGQL extends Apollo.Mutation<ArchiveInstituteMutation, ArchiveInstituteMutationVariables> {
    document = ArchiveInstituteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreInstituteDocument = gql`
    mutation restoreInstitute($instituteId: Uuid!) {
  hr {
    institutes {
      restore(instituteId: $instituteId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreInstituteGQL extends Apollo.Mutation<RestoreInstituteMutation, RestoreInstituteMutationVariables> {
    document = RestoreInstituteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedInterviewCategoriesDocument = gql`
    query getPaginatedInterviewCategories($filter: InterviewCategoryFilterInput, $skip: Int, $take: Int) {
  hr {
    interviewCategories(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...interviewCategories
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${InterviewCategoriesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedInterviewCategoriesGQL extends Apollo.Query<GetPaginatedInterviewCategoriesQuery, GetPaginatedInterviewCategoriesQueryVariables> {
    document = GetPaginatedInterviewCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInterviewCategoriesDocument = gql`
    query getInterviewCategories($search: String, $skip: Int, $take: Int) {
  hr {
    interviewCategories(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInterviewCategoriesGQL extends Apollo.Query<GetInterviewCategoriesQuery, GetInterviewCategoriesQueryVariables> {
    document = GetInterviewCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInterviewCategoryDocument = gql`
    query getInterviewCategory($id: Uuid!) {
  hr {
    interviewCategories(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...interviewCategories
      }
    }
  }
}
    ${InterviewCategoriesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInterviewCategoryGQL extends Apollo.Query<GetInterviewCategoryQuery, GetInterviewCategoryQueryVariables> {
    document = GetInterviewCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddInterviewCategoryDocument = gql`
    mutation addInterviewCategory($model: CreateInterviewCategoryInput) {
  hr {
    interviewCategories {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddInterviewCategoryGQL extends Apollo.Mutation<AddInterviewCategoryMutation, AddInterviewCategoryMutationVariables> {
    document = AddInterviewCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInterviewCategoryDocument = gql`
    mutation updateInterviewCategory($model: UpdateInterviewCategoryInput) {
  hr {
    interviewCategories {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInterviewCategoryGQL extends Apollo.Mutation<UpdateInterviewCategoryMutation, UpdateInterviewCategoryMutationVariables> {
    document = UpdateInterviewCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveInterviewCategoryDocument = gql`
    mutation archiveInterviewCategory($interviewCategoryId: Uuid!) {
  hr {
    interviewCategories {
      archive(interviewCategoryId: $interviewCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveInterviewCategoryGQL extends Apollo.Mutation<ArchiveInterviewCategoryMutation, ArchiveInterviewCategoryMutationVariables> {
    document = ArchiveInterviewCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreInterviewCategoryDocument = gql`
    mutation restoreInterviewCategory($interviewCategoryId: Uuid!) {
  hr {
    interviewCategories {
      restore(interviewCategoryId: $interviewCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreInterviewCategoryGQL extends Apollo.Mutation<RestoreInterviewCategoryMutation, RestoreInterviewCategoryMutationVariables> {
    document = RestoreInterviewCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedInterviewCriterionDocument = gql`
    query getPaginatedInterviewCriterion($filter: InterviewCriterionFilterInput, $skip: Int, $take: Int) {
  hr {
    interviewCriteria(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...interviewCriteria
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${InterviewCriteriaFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedInterviewCriterionGQL extends Apollo.Query<GetPaginatedInterviewCriterionQuery, GetPaginatedInterviewCriterionQueryVariables> {
    document = GetPaginatedInterviewCriterionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInterviewCriteriaDocument = gql`
    query getInterviewCriteria($search: String, $skip: Int, $take: Int) {
  hr {
    interviewCriteria(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInterviewCriteriaGQL extends Apollo.Query<GetInterviewCriteriaQuery, GetInterviewCriteriaQueryVariables> {
    document = GetInterviewCriteriaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInterviewCriterionDocument = gql`
    query getInterviewCriterion($id: Uuid!) {
  hr {
    interviewCriteria(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...interviewCriteria
      }
    }
  }
}
    ${InterviewCriteriaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInterviewCriterionGQL extends Apollo.Query<GetInterviewCriterionQuery, GetInterviewCriterionQueryVariables> {
    document = GetInterviewCriterionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddInterviewCriterionDocument = gql`
    mutation addInterviewCriterion($model: CreateInterviewCriterionInput) {
  hr {
    interviewCriteria {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddInterviewCriterionGQL extends Apollo.Mutation<AddInterviewCriterionMutation, AddInterviewCriterionMutationVariables> {
    document = AddInterviewCriterionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInterviewCriterionDocument = gql`
    mutation updateInterviewCriterion($model: UpdateInterviewCriterionInput) {
  hr {
    interviewCriteria {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInterviewCriterionGQL extends Apollo.Mutation<UpdateInterviewCriterionMutation, UpdateInterviewCriterionMutationVariables> {
    document = UpdateInterviewCriterionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveInterviewCriterionDocument = gql`
    mutation archiveInterviewCriterion($interviewCriterionId: Uuid!) {
  hr {
    interviewCriteria {
      archive(interviewCriterionId: $interviewCriterionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveInterviewCriterionGQL extends Apollo.Mutation<ArchiveInterviewCriterionMutation, ArchiveInterviewCriterionMutationVariables> {
    document = ArchiveInterviewCriterionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreInterviewCriterionDocument = gql`
    mutation restoreInterviewCriterion($interviewCriterionId: Uuid!) {
  hr {
    interviewCriteria {
      restore(interviewCriterionId: $interviewCriterionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreInterviewCriterionGQL extends Apollo.Mutation<RestoreInterviewCriterionMutation, RestoreInterviewCriterionMutationVariables> {
    document = RestoreInterviewCriterionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInterviewersDocument = gql`
    query getInterviewers($positionId: Uuid!) {
  hr {
    interviewers(skip: 0, where: {jobPositionId: {eq: $positionId}}) {
      items {
        id
        userId
        jobPositionId
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInterviewersGQL extends Apollo.Query<GetInterviewersQuery, GetInterviewersQueryVariables> {
    document = GetInterviewersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedJobPositionsDocument = gql`
    query getPaginatedJobPositions($filter: JobPositionFilterInput, $skip: Int, $take: Int) {
  hr {
    jobPositions(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...paginatedJobPositions
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
}
    ${PaginatedJobPositionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedJobPositionsGQL extends Apollo.Query<GetPaginatedJobPositionsQuery, GetPaginatedJobPositionsQueryVariables> {
    document = GetPaginatedJobPositionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobPositionDocument = gql`
    query getJobPosition($id: Uuid!) {
  hr {
    jobPositions(where: {id: {eq: $id}}, skip: null, take: 1) {
      items {
        id
        businessId
        positions
        state
        departmentId
        jobTitleId
        description
        comment
        jobTitle {
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobPositionGQL extends Apollo.Query<GetJobPositionQuery, GetJobPositionQueryVariables> {
    document = GetJobPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddJobPositionDocument = gql`
    mutation addJobPosition($model: CreateJobPositionInput!) {
  hr {
    jobPositions {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddJobPositionGQL extends Apollo.Mutation<AddJobPositionMutation, AddJobPositionMutationVariables> {
    document = AddJobPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobPositionDocument = gql`
    mutation updateJobPosition($model: UpdateJobPositionInput!) {
  hr {
    jobPositions {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobPositionGQL extends Apollo.Mutation<UpdateJobPositionMutation, UpdateJobPositionMutationVariables> {
    document = UpdateJobPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApproveJobPositionDocument = gql`
    mutation approveJobPosition($model: PostJobPositionInput!) {
  hr {
    jobPositions {
      approve(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApproveJobPositionGQL extends Apollo.Mutation<ApproveJobPositionMutation, ApproveJobPositionMutationVariables> {
    document = ApproveJobPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RejectJobPositionDocument = gql`
    mutation rejectJobPosition($comment: String!, $jobPositionId: Uuid!) {
  hr {
    jobPositions {
      reject(comment: $comment, jobPositionId: $jobPositionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectJobPositionGQL extends Apollo.Mutation<RejectJobPositionMutation, RejectJobPositionMutationVariables> {
    document = RejectJobPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseJobPositionDocument = gql`
    mutation closeJobPosition($comment: String!, $jobPositionId: Uuid!) {
  hr {
    jobPositions {
      close(comment: $comment, jobPositionId: $jobPositionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseJobPositionGQL extends Apollo.Mutation<CloseJobPositionMutation, CloseJobPositionMutationVariables> {
    document = CloseJobPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobPositionInterviewersDocument = gql`
    mutation updateJobPositionInterviewers($model: UpdateInterviewerInput) {
  hr {
    jobPositions {
      updateInterviewers(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobPositionInterviewersGQL extends Apollo.Mutation<UpdateJobPositionInterviewersMutation, UpdateJobPositionInterviewersMutationVariables> {
    document = UpdateJobPositionInterviewersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedJobFunctionsDocument = gql`
    query getPaginatedJobFunctions($filter: JobFunctionFilterInput, $skip: Int, $take: Int) {
  hr {
    jobFunctions(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...jobFunctions
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${JobFunctionsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedJobFunctionsGQL extends Apollo.Query<GetPaginatedJobFunctionsQuery, GetPaginatedJobFunctionsQueryVariables> {
    document = GetPaginatedJobFunctionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobFunctionsDocument = gql`
    query getJobFunctions($search: String, $skip: Int, $take: Int) {
  hr {
    jobFunctions(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobFunctionsGQL extends Apollo.Query<GetJobFunctionsQuery, GetJobFunctionsQueryVariables> {
    document = GetJobFunctionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobFunctionDocument = gql`
    query getJobFunction($id: Uuid!) {
  hr {
    jobFunctions(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...jobFunctions
      }
    }
  }
}
    ${JobFunctionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobFunctionGQL extends Apollo.Query<GetJobFunctionQuery, GetJobFunctionQueryVariables> {
    document = GetJobFunctionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddJobFunctionDocument = gql`
    mutation addJobFunction($model: CreateJobFunctionInput) {
  hr {
    jobFunctions {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddJobFunctionGQL extends Apollo.Mutation<AddJobFunctionMutation, AddJobFunctionMutationVariables> {
    document = AddJobFunctionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobFunctionDocument = gql`
    mutation updateJobFunction($model: UpdateJobFunctionInput) {
  hr {
    jobFunctions {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobFunctionGQL extends Apollo.Mutation<UpdateJobFunctionMutation, UpdateJobFunctionMutationVariables> {
    document = UpdateJobFunctionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveJobFunctionDocument = gql`
    mutation archiveJobFunction($jobFunctionId: Uuid!) {
  hr {
    jobFunctions {
      archive(jobFunctionId: $jobFunctionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveJobFunctionGQL extends Apollo.Mutation<ArchiveJobFunctionMutation, ArchiveJobFunctionMutationVariables> {
    document = ArchiveJobFunctionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreJobFunctionDocument = gql`
    mutation restoreJobFunction($jobFunctionId: Uuid!) {
  hr {
    jobFunctions {
      restore(jobFunctionId: $jobFunctionId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreJobFunctionGQL extends Apollo.Mutation<RestoreJobFunctionMutation, RestoreJobFunctionMutationVariables> {
    document = RestoreJobFunctionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedJobTitlesDocument = gql`
    query getPaginatedJobTitles($filter: JobTitleFilterInput, $skip: Int, $take: Int) {
  hr {
    jobTitles(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...jobTitles
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${JobTitlesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedJobTitlesGQL extends Apollo.Query<GetPaginatedJobTitlesQuery, GetPaginatedJobTitlesQueryVariables> {
    document = GetPaginatedJobTitlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobTitlesDocument = gql`
    query getJobTitles($search: String, $skip: Int, $take: Int) {
  hr {
    jobTitles(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobTitlesGQL extends Apollo.Query<GetJobTitlesQuery, GetJobTitlesQueryVariables> {
    document = GetJobTitlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobTitleDocument = gql`
    query getJobTitle($id: Uuid!) {
  hr {
    jobTitles(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...jobTitles
      }
    }
  }
}
    ${JobTitlesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobTitleGQL extends Apollo.Query<GetJobTitleQuery, GetJobTitleQueryVariables> {
    document = GetJobTitleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddJobTitleDocument = gql`
    mutation addJobTitle($model: CreateJobTitleInput) {
  hr {
    jobTitles {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddJobTitleGQL extends Apollo.Mutation<AddJobTitleMutation, AddJobTitleMutationVariables> {
    document = AddJobTitleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateJobTitleDocument = gql`
    mutation updateJobTitle($model: UpdateJobTitleInput) {
  hr {
    jobTitles {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateJobTitleGQL extends Apollo.Mutation<UpdateJobTitleMutation, UpdateJobTitleMutationVariables> {
    document = UpdateJobTitleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveJobTitleDocument = gql`
    mutation archiveJobTitle($jobTitleId: Uuid!) {
  hr {
    jobTitles {
      archive(jobTitleId: $jobTitleId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveJobTitleGQL extends Apollo.Mutation<ArchiveJobTitleMutation, ArchiveJobTitleMutationVariables> {
    document = ArchiveJobTitleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreJobTitleDocument = gql`
    mutation restoreJobTitle($jobTitleId: Uuid!) {
  hr {
    jobTitles {
      restore(jobTitleId: $jobTitleId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreJobTitleGQL extends Apollo.Mutation<RestoreJobTitleMutation, RestoreJobTitleMutationVariables> {
    document = RestoreJobTitleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLeavesDocument = gql`
    query getLeaves($filter: LeaveFilterInput, $year: String) {
  hr {
    leaves(year: $year, where: $filter, order: {createdOn: DESC}) {
      ...leaveFragment
    }
  }
}
    ${LeaveFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLeavesGQL extends Apollo.Query<GetLeavesQuery, GetLeavesQueryVariables> {
    document = GetLeavesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLeaveDocument = gql`
    query getLeave($id: Uuid!) {
  hr {
    leave(id: $id) {
      ...leaveFragment
      leaveTypeId
      userId
    }
  }
}
    ${LeaveFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLeaveGQL extends Apollo.Query<GetLeaveQuery, GetLeaveQueryVariables> {
    document = GetLeaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddLeaveDocument = gql`
    mutation addLeave($model: CreateLeaveInput) {
  hr {
    leaves {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddLeaveGQL extends Apollo.Mutation<AddLeaveMutation, AddLeaveMutationVariables> {
    document = AddLeaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUserLeaveDocument = gql`
    mutation addUserLeave($model: CreateLeaveInput, $userId: Uuid!) {
  hr {
    leaves {
      addUserLeave(model: $model, userId: $userId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUserLeaveGQL extends Apollo.Mutation<AddUserLeaveMutation, AddUserLeaveMutationVariables> {
    document = AddUserLeaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLeaveDocument = gql`
    mutation updateLeave($model: UpdateLeaveInput) {
  hr {
    leaves {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLeaveGQL extends Apollo.Mutation<UpdateLeaveMutation, UpdateLeaveMutationVariables> {
    document = UpdateLeaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecallDocument = gql`
    mutation recall($id: Uuid!) {
  hr {
    leaves {
      recall(id: $id)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecallGQL extends Apollo.Mutation<RecallMutation, RecallMutationVariables> {
    document = RecallDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllLeaveTypesDocument = gql`
    query getAllLeaveTypes {
  hr {
    leaveTypes {
      ...leaveTypes
    }
  }
}
    ${LeaveTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllLeaveTypesGQL extends Apollo.Query<GetAllLeaveTypesQuery, GetAllLeaveTypesQueryVariables> {
    document = GetAllLeaveTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedLeaveTypesDocument = gql`
    query getPaginatedLeaveTypes($filter: BusinessLookupFilterInput, $skip: Int, $take: Int) {
  hr {
    paginatedLeaveTypes(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...leaveTypes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${LeaveTypesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedLeaveTypesGQL extends Apollo.Query<GetPaginatedLeaveTypesQuery, GetPaginatedLeaveTypesQueryVariables> {
    document = GetPaginatedLeaveTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLeaveTypesDocument = gql`
    query getLeaveTypes($search: String, $skip: Int, $take: Int) {
  hr {
    paginatedLeaveTypes(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLeaveTypesGQL extends Apollo.Query<GetLeaveTypesQuery, GetLeaveTypesQueryVariables> {
    document = GetLeaveTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLeaveTypeDocument = gql`
    query getLeaveType($id: Uuid!) {
  hr {
    paginatedLeaveTypes(where: {id: {eq: $id}}, skip: null, take: 1) {
      items {
        ...leaveTypes
      }
    }
  }
}
    ${LeaveTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLeaveTypeGQL extends Apollo.Query<GetLeaveTypeQuery, GetLeaveTypeQueryVariables> {
    document = GetLeaveTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddLeaveTypeDocument = gql`
    mutation addLeaveType($model: CreateLeaveTypeInput, $updateExisting: Boolean!) {
  hr {
    leaveTypes {
      add(model: $model, updateExisting: $updateExisting)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddLeaveTypeGQL extends Apollo.Mutation<AddLeaveTypeMutation, AddLeaveTypeMutationVariables> {
    document = AddLeaveTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLeaveTypeDocument = gql`
    mutation updateLeaveType($model: UpdateLeaveTypeInput, $updateExisting: Boolean!) {
  hr {
    leaveTypes {
      update(model: $model, updateExisting: $updateExisting)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLeaveTypeGQL extends Apollo.Mutation<UpdateLeaveTypeMutation, UpdateLeaveTypeMutationVariables> {
    document = UpdateLeaveTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveLeaveTypeDocument = gql`
    mutation archiveLeaveType($leaveTypeId: Uuid!, $updateExisting: Boolean!) {
  hr {
    leaveTypes {
      archive(leaveTypeId: $leaveTypeId, updateExisting: $updateExisting)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveLeaveTypeGQL extends Apollo.Mutation<ArchiveLeaveTypeMutation, ArchiveLeaveTypeMutationVariables> {
    document = ArchiveLeaveTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreLeaveTypeDocument = gql`
    mutation restoreLeaveType($leaveTypeId: Uuid!, $updateExisting: Boolean!) {
  hr {
    leaveTypes {
      restore(leaveTypeId: $leaveTypeId, updateExisting: $updateExisting)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreLeaveTypeGQL extends Apollo.Mutation<RestoreLeaveTypeMutation, RestoreLeaveTypeMutationVariables> {
    document = RestoreLeaveTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyPaginatedLoansDocument = gql`
    query getMyPaginatedLoans($filter: LoanFilterInput, $skip: Int, $take: Int) {
  hr {
    session {
      loans(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
        items {
          ...loanFragment
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
      }
    }
  }
}
    ${LoanFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyPaginatedLoansGQL extends Apollo.Query<GetMyPaginatedLoansQuery, GetMyPaginatedLoansQueryVariables> {
    document = GetMyPaginatedLoansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedLoansDocument = gql`
    query getPaginatedLoans($filter: LoanFilterInput, $skip: Int, $take: Int) {
  hr {
    loans(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...loanFragment
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
}
    ${LoanFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedLoansGQL extends Apollo.Query<GetPaginatedLoansQuery, GetPaginatedLoansQueryVariables> {
    document = GetPaginatedLoansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLoanDocument = gql`
    query getLoan($id: Uuid!) {
  hr {
    loans(where: {id: {eq: $id}}) {
      items {
        ...loanFragment
        userId
        loanTypeId
      }
    }
  }
}
    ${LoanFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLoanGQL extends Apollo.Query<GetLoanQuery, GetLoanQueryVariables> {
    document = GetLoanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddLoanDocument = gql`
    mutation addLoan($model: CreateLoanInput) {
  hr {
    loans {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddLoanGQL extends Apollo.Mutation<AddLoanMutation, AddLoanMutationVariables> {
    document = AddLoanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLoanDocument = gql`
    mutation updateLoan($model: UpdateLoanInput) {
  hr {
    loans {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLoanGQL extends Apollo.Mutation<UpdateLoanMutation, UpdateLoanMutationVariables> {
    document = UpdateLoanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedLoanTypesDocument = gql`
    query getPaginatedLoanTypes($filter: LoanTypeFilterInput, $skip: Int, $take: Int) {
  hr {
    loanTypes(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...loanTypes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${LoanTypesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedLoanTypesGQL extends Apollo.Query<GetPaginatedLoanTypesQuery, GetPaginatedLoanTypesQueryVariables> {
    document = GetPaginatedLoanTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLoanTypesDocument = gql`
    query getLoanTypes($search: String, $skip: Int, $take: Int) {
  hr {
    loanTypes(
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLoanTypesGQL extends Apollo.Query<GetLoanTypesQuery, GetLoanTypesQueryVariables> {
    document = GetLoanTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLoanTypeDocument = gql`
    query getLoanType($id: Uuid!) {
  hr {
    loanTypes(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...loanTypes
      }
    }
  }
}
    ${LoanTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLoanTypeGQL extends Apollo.Query<GetLoanTypeQuery, GetLoanTypeQueryVariables> {
    document = GetLoanTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddLoanTypeDocument = gql`
    mutation addLoanType($model: CreateLoanTypeInput) {
  hr {
    loanTypes {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddLoanTypeGQL extends Apollo.Mutation<AddLoanTypeMutation, AddLoanTypeMutationVariables> {
    document = AddLoanTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLoanTypeDocument = gql`
    mutation updateLoanType($model: UpdateLoanTypeInput) {
  hr {
    loanTypes {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLoanTypeGQL extends Apollo.Mutation<UpdateLoanTypeMutation, UpdateLoanTypeMutationVariables> {
    document = UpdateLoanTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveLoanTypeDocument = gql`
    mutation archiveLoanType($loanTypeId: Uuid!) {
  hr {
    loanTypes {
      archive(loanTypeId: $loanTypeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveLoanTypeGQL extends Apollo.Mutation<ArchiveLoanTypeMutation, ArchiveLoanTypeMutationVariables> {
    document = ArchiveLoanTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreLoanTypeDocument = gql`
    mutation restoreLoanType($loanTypeId: Uuid!) {
  hr {
    loanTypes {
      restore(loanTypeId: $loanTypeId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreLoanTypeGQL extends Apollo.Mutation<RestoreLoanTypeMutation, RestoreLoanTypeMutationVariables> {
    document = RestoreLoanTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyPaginatedMedicalClaimsDocument = gql`
    query getMyPaginatedMedicalClaims($filter: MedicalClaimFilterInput, $skip: Int, $take: Int) {
  hr {
    session {
      medicalClaims(
        where: $filter
        skip: $skip
        take: $take
        order: {createdOn: DESC}
      ) {
        items {
          ...medicalClaimFragment
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
      }
    }
  }
}
    ${MedicalClaimFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyPaginatedMedicalClaimsGQL extends Apollo.Query<GetMyPaginatedMedicalClaimsQuery, GetMyPaginatedMedicalClaimsQueryVariables> {
    document = GetMyPaginatedMedicalClaimsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedMedicalClaimsDocument = gql`
    query getPaginatedMedicalClaims($filter: MedicalClaimFilterInput, $skip: Int, $take: Int) {
  hr {
    medicalClaims(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...medicalClaimFragment
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
}
    ${MedicalClaimFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedMedicalClaimsGQL extends Apollo.Query<GetPaginatedMedicalClaimsQuery, GetPaginatedMedicalClaimsQueryVariables> {
    document = GetPaginatedMedicalClaimsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMedicalClaimDocument = gql`
    query getMedicalClaim($id: Uuid!) {
  hr {
    medicalClaims(where: {id: {eq: $id}}) {
      items {
        ...medicalClaimFragment
        userId
        medicalClaimBreakdowns {
          name
          medicalClaimId
          amount
          billNumber
          file
        }
        balanceCf
      }
    }
  }
}
    ${MedicalClaimFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMedicalClaimGQL extends Apollo.Query<GetMedicalClaimQuery, GetMedicalClaimQueryVariables> {
    document = GetMedicalClaimDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMedicalClaimDetailsDocument = gql`
    query getMedicalClaimDetails($id: Uuid!) {
  hr {
    medicalClaim(id: $id) {
      userId
      medicalClaimBreakdowns {
        name
        medicalClaimId
        amount
        billNumber
        file
      }
      state
      balanceCf
      claimLookupId
      amount
      amountApproved
      createdOn
      user {
        fullName
        firstName
        lastName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMedicalClaimDetailsGQL extends Apollo.Query<GetMedicalClaimDetailsQuery, GetMedicalClaimDetailsQueryVariables> {
    document = GetMedicalClaimDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMedicalClaimDocument = gql`
    mutation updateMedicalClaim($model: UpdateMedicalClaimInput) {
  hr {
    medicalClaims {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMedicalClaimGQL extends Apollo.Mutation<UpdateMedicalClaimMutation, UpdateMedicalClaimMutationVariables> {
    document = UpdateMedicalClaimDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApprovedMedicalClaimAmountDocument = gql`
    query getApprovedMedicalClaimAmount($userId: Uuid!, $claimLookupId: Uuid!) {
  hr {
    session {
      approvedAmount(userId: $userId, claimLookupId: $claimLookupId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApprovedMedicalClaimAmountGQL extends Apollo.Query<GetApprovedMedicalClaimAmountQuery, GetApprovedMedicalClaimAmountQueryVariables> {
    document = GetApprovedMedicalClaimAmountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedNotesDataDocument = gql`
    query getPaginatedNotesData($skip: Int, $take: Int, $moduleId: Uuid!, $noteTypeId: Uuid) {
  session {
    notesDetails(
      where: {and: {noteTypeId: {eq: $noteTypeId}}}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
      objectId: $moduleId
    ) {
      items {
        ...notesdetail
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${NotesdetailFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedNotesDataGQL extends Apollo.Query<GetPaginatedNotesDataQuery, GetPaginatedNotesDataQueryVariables> {
    document = GetPaginatedNotesDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedNotesNotDocument = gql`
    query getPaginatedNotesNot($skip: Int, $take: Int, $moduleId: Uuid!, $noteTypeId: Uuid) {
  session {
    notesDetails(
      where: {and: {noteTypeId: {neq: $noteTypeId}}}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
      objectId: $moduleId
    ) {
      items {
        ...notesdetail
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${NotesdetailFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedNotesNotGQL extends Apollo.Query<GetPaginatedNotesNotQuery, GetPaginatedNotesNotQueryVariables> {
    document = GetPaginatedNotesNotDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNoteByObjectIdDocument = gql`
    query getNoteByObjectId($moduleId: Uuid!) {
  session {
    notesDetails(order: {updatedOn: DESC}, objectId: $moduleId) {
      items {
        ...notesdetail
      }
      totalCount
    }
  }
}
    ${NotesdetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNoteByObjectIdGQL extends Apollo.Query<GetNoteByObjectIdQuery, GetNoteByObjectIdQueryVariables> {
    document = GetNoteByObjectIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotesWithTicketStatusDocument = gql`
    query notesWithTicketStatus($skip: Int, $take: Int, $moduleId: Uuid!) {
  session {
    notesWithTicketStatus(
      skip: $skip
      take: $take
      order: {createdOn: DESC}
      objectId: $moduleId
    ) {
      items {
        ...notesdetail
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${NotesdetailFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotesWithTicketStatusGQL extends Apollo.Query<NotesWithTicketStatusQuery, NotesWithTicketStatusQueryVariables> {
    document = NotesWithTicketStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNotificationsDocument = gql`
    query getNotifications($skip: Int) {
  session {
    notifications(order: {createdOn: DESC}, take: 12, skip: $skip) {
      items {
        ...notification
      }
      totalCount
      pageInfo {
        ...collectionInfo
      }
    }
  }
}
    ${NotificationFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotificationsGQL extends Apollo.Query<GetNotificationsQuery, GetNotificationsQueryVariables> {
    document = GetNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLatestNotificationDocument = gql`
    subscription getLatestNotification($businessId: Uuid!, $sessionId: Uuid!) {
  showLatestNotification(businessId: $businessId, sessionId: $sessionId) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestNotificationGQL extends Apollo.Subscription<GetLatestNotificationSubscription, GetLatestNotificationSubscriptionVariables> {
    document = GetLatestNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkAsReadDocument = gql`
    mutation markAsRead($notificationId: Uuid!) {
  business {
    notifications {
      markAsRead(id: $notificationId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkAsReadGQL extends Apollo.Mutation<MarkAsReadMutation, MarkAsReadMutationVariables> {
    document = MarkAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkAllAsReadDocument = gql`
    mutation markAllAsRead($ids: [Uuid!]) {
  business {
    notifications {
      markAllAsRead(ids: $ids)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkAllAsReadGQL extends Apollo.Mutation<MarkAllAsReadMutation, MarkAllAsReadMutationVariables> {
    document = MarkAllAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedProductsDocument = gql`
    query getPaginatedProducts($filter: ProductFilterInput, $skip: Int, $take: Int) {
  finance {
    paginatedProducts(where: $filter, skip: $skip, take: $take) {
      items {
        ...productList
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ProductListFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedProductsGQL extends Apollo.Query<GetPaginatedProductsQuery, GetPaginatedProductsQueryVariables> {
    document = GetPaginatedProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsDocument = gql`
    query getProducts($search: String, $skip: Int, $take: Int) {
  finance {
    paginatedProducts(
      where: {or: [{name: {contains: $search}}, {number: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsGQL extends Apollo.Query<GetProductsQuery, GetProductsQueryVariables> {
    document = GetProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductDocument = gql`
    query getProduct($id: Uuid) {
  finance {
    products(where: {id: {eq: $id}}) {
      ...productDetail
    }
  }
}
    ${ProductDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductGQL extends Apollo.Query<GetProductQuery, GetProductQueryVariables> {
    document = GetProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddProductDocument = gql`
    mutation addProduct($model: CreateProductInput) {
  finance {
    products {
      createProduct(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProductGQL extends Apollo.Mutation<AddProductMutation, AddProductMutationVariables> {
    document = AddProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation updateProduct($model: UpdateProductInput) {
  finance {
    products {
      updateProduct(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductGQL extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    document = UpdateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveProductDocument = gql`
    mutation archiveProduct($productId: Uuid!) {
  finance {
    products {
      archive(productId: $productId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveProductGQL extends Apollo.Mutation<ArchiveProductMutation, ArchiveProductMutationVariables> {
    document = ArchiveProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreProductDocument = gql`
    mutation restoreProduct($productId: Uuid!) {
  finance {
    products {
      restore(productId: $productId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreProductGQL extends Apollo.Mutation<RestoreProductMutation, RestoreProductMutationVariables> {
    document = RestoreProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductTypesDocument = gql`
    query getProductTypes {
  finance {
    productTypes {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductTypesGQL extends Apollo.Query<GetProductTypesQuery, GetProductTypesQueryVariables> {
    document = GetProductTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductUnitsDocument = gql`
    query getProductUnits {
  finance {
    productUnits {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductUnitsGQL extends Apollo.Query<GetProductUnitsQuery, GetProductUnitsQueryVariables> {
    document = GetProductUnitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedProjectsDocument = gql`
    query getPaginatedProjects($filter: ProjectFilterInput, $skip: Int, $take: Int) {
  timeTracking {
    projects(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...projectFragment
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedProjectsGQL extends Apollo.Query<GetPaginatedProjectsQuery, GetPaginatedProjectsQueryVariables> {
    document = GetPaginatedProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectsDocument = gql`
    query getProjects($search: String, $skip: Int, $take: Int) {
  timeTracking {
    session {
      activeProjects(
        where: {or: [{name: {contains: $search}}, {description: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
        skip: $skip
        take: $take
        order: {name: ASC}
      ) {
        items {
          id
          name
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectsGQL extends Apollo.Query<GetProjectsQuery, GetProjectsQueryVariables> {
    document = GetProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyPaginatedProjectsDocument = gql`
    query getMyPaginatedProjects($filter: ProjectFilterInput, $skip: Int, $take: Int) {
  timeTracking {
    session {
      projects(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
        items {
          ...projectFragment
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyPaginatedProjectsGQL extends Apollo.Query<GetMyPaginatedProjectsQuery, GetMyPaginatedProjectsQueryVariables> {
    document = GetMyPaginatedProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectDocument = gql`
    query getProject($id: Uuid!) {
  timeTracking {
    projects(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...projectFragment
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectGQL extends Apollo.Query<GetProjectQuery, GetProjectQueryVariables> {
    document = GetProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyProjectDocument = gql`
    query GetMyProject($id: Uuid!) {
  timeTracking {
    session {
      projects(where: {id: {eq: $id}}, skip: 0, take: 1) {
        items {
          ...projectFragment
        }
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyProjectGQL extends Apollo.Query<GetMyProjectQuery, GetMyProjectQueryVariables> {
    document = GetMyProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyProjectMembersDocument = gql`
    query getMyProjectMembers {
  timeTracking {
    session {
      getMyProjectMembers
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyProjectMembersGQL extends Apollo.Query<GetMyProjectMembersQuery, GetMyProjectMembersQueryVariables> {
    document = GetMyProjectMembersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyRoleInProjectDocument = gql`
    query getMyRoleInProject($projectId: Uuid!) {
  timeTracking {
    session {
      myRoleInProject(projectId: $projectId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyRoleInProjectGQL extends Apollo.Query<GetMyRoleInProjectQuery, GetMyRoleInProjectQueryVariables> {
    document = GetMyRoleInProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectUsersDocument = gql`
    query getProjectUsers($projectId: Uuid!) {
  timeTracking {
    projectUserRoles(projectId: $projectId) {
      projectRole
      userId
      user {
        firstName
        lastName
        fullName
        email
        pic
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectUsersGQL extends Apollo.Query<GetProjectUsersQuery, GetProjectUsersQueryVariables> {
    document = GetProjectUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectUserRolesDocument = gql`
    query getProjectUserRoles($projectId: Uuid!) {
  timeTracking {
    projectUserRoles(projectId: $projectId) {
      projectRole
      userId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectUserRolesGQL extends Apollo.Query<GetProjectUserRolesQuery, GetProjectUserRolesQueryVariables> {
    document = GetProjectUserRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyAssignedProjectRolesDocument = gql`
    query getMyAssignedProjectRoles {
  timeTracking {
    session {
      projectUserRoles {
        projectId
        projectRole
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyAssignedProjectRolesGQL extends Apollo.Query<GetMyAssignedProjectRolesQuery, GetMyAssignedProjectRolesQueryVariables> {
    document = GetMyAssignedProjectRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignProjectUsersDocument = gql`
    mutation assignProjectUsers($model: AssignProjectUsersInput) {
  timeTracking {
    projects {
      assignUsers(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignProjectUsersGQL extends Apollo.Mutation<AssignProjectUsersMutation, AssignProjectUsersMutationVariables> {
    document = AssignProjectUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddProjectDocument = gql`
    mutation addProject($model: CreateProjectInput) {
  timeTracking {
    projects {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectGQL extends Apollo.Mutation<AddProjectMutation, AddProjectMutationVariables> {
    document = AddProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectDocument = gql`
    mutation updateProject($model: UpdateProjectInput) {
  timeTracking {
    projects {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
    document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveProjectDocument = gql`
    mutation archiveProject($projectId: Uuid!, $clientId: Uuid!) {
  timeTracking {
    projects {
      archive(projectId: $projectId, clientId: $clientId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveProjectGQL extends Apollo.Mutation<ArchiveProjectMutation, ArchiveProjectMutationVariables> {
    document = ArchiveProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreProjectDocument = gql`
    mutation restoreProject($projectId: Uuid!, $clientId: Uuid!) {
  timeTracking {
    projects {
      restore(projectId: $projectId, clientId: $clientId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreProjectGQL extends Apollo.Mutation<RestoreProjectMutation, RestoreProjectMutationVariables> {
    document = RestoreProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseProjectDocument = gql`
    mutation closeProject($projectId: Uuid!, $clientId: Uuid!) {
  timeTracking {
    projects {
      close(clientId: $clientId, projectId: $projectId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseProjectGQL extends Apollo.Mutation<CloseProjectMutation, CloseProjectMutationVariables> {
    document = CloseProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectRolesDocument = gql`
    query getProjectRoles {
  timeTracking {
    projectRoles {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectRolesGQL extends Apollo.Query<GetProjectRolesQuery, GetProjectRolesQueryVariables> {
    document = GetProjectRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedProjectTasksDocument = gql`
    query getPaginatedProjectTasks($projectId: Uuid!, $filter: ProjectTaskFilterInput, $skip: Int, $take: Int) {
  timeTracking {
    projectTasks(
      projectId: $projectId
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...projectTasks
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ProjectTasksFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedProjectTasksGQL extends Apollo.Query<GetPaginatedProjectTasksQuery, GetPaginatedProjectTasksQueryVariables> {
    document = GetPaginatedProjectTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectTasksDocument = gql`
    query getProjectTasks($projectId: Uuid!, $search: String, $skip: Int, $take: Int) {
  timeTracking {
    projectTasks(
      projectId: $projectId
      where: {or: [{name: {contains: $search}}, {description: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        id
        name
        subTasksCount
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectTasksGQL extends Apollo.Query<GetProjectTasksQuery, GetProjectTasksQueryVariables> {
    document = GetProjectTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectTaskDocument = gql`
    query getProjectTask($projectId: Uuid!, $id: Uuid!) {
  timeTracking {
    projectTasks(projectId: $projectId, where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        projectId
        ...projectTasks
      }
    }
  }
}
    ${ProjectTasksFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectTaskGQL extends Apollo.Query<GetProjectTaskQuery, GetProjectTaskQueryVariables> {
    document = GetProjectTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddProjectTaskDocument = gql`
    mutation addProjectTask($model: CreateProjectTaskInput) {
  timeTracking {
    projectTasks {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectTaskGQL extends Apollo.Mutation<AddProjectTaskMutation, AddProjectTaskMutationVariables> {
    document = AddProjectTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectTaskDocument = gql`
    mutation updateProjectTask($model: UpdateProjectTaskInput) {
  timeTracking {
    projectTasks {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectTaskGQL extends Apollo.Mutation<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables> {
    document = UpdateProjectTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveProjectTaskDocument = gql`
    mutation archiveProjectTask($projectId: Uuid!, $taskId: Uuid!) {
  timeTracking {
    projectTasks {
      archive(projectId: $projectId, id: $taskId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveProjectTaskGQL extends Apollo.Mutation<ArchiveProjectTaskMutation, ArchiveProjectTaskMutationVariables> {
    document = ArchiveProjectTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreProjectTaskDocument = gql`
    mutation restoreProjectTask($projectId: Uuid!, $taskId: Uuid!) {
  timeTracking {
    projectTasks {
      restore(projectId: $projectId, id: $taskId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreProjectTaskGQL extends Apollo.Mutation<RestoreProjectTaskMutation, RestoreProjectTaskMutationVariables> {
    document = RestoreProjectTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedSubTasksDocument = gql`
    query getPaginatedSubTasks($projectId: Uuid!, $parentTaskId: Uuid!, $filter: SubTaskFilterInput, $skip: Int, $take: Int) {
  timeTracking {
    subTasks(
      projectId: $projectId
      parentTaskId: $parentTaskId
      where: $filter
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        ...subTasks
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${SubTasksFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedSubTasksGQL extends Apollo.Query<GetPaginatedSubTasksQuery, GetPaginatedSubTasksQueryVariables> {
    document = GetPaginatedSubTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubTasksDocument = gql`
    query getSubTasks($projectId: Uuid!, $parentTaskId: Uuid!, $search: String, $skip: Int, $take: Int) {
  timeTracking {
    subTasks(
      projectId: $projectId
      parentTaskId: $parentTaskId
      where: {or: [{name: {contains: $search}}, {description: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubTasksGQL extends Apollo.Query<GetSubTasksQuery, GetSubTasksQueryVariables> {
    document = GetSubTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubTaskDocument = gql`
    query getSubTask($id: Uuid!, $parentTaskId: Uuid!, $projectId: Uuid!) {
  timeTracking {
    subTasks(
      projectId: $projectId
      parentTaskId: $parentTaskId
      where: {id: {eq: $id}}
      skip: 0
      take: 1
    ) {
      items {
        ...subTasks
        projectId
        parentTaskId
      }
    }
  }
}
    ${SubTasksFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubTaskGQL extends Apollo.Query<GetSubTaskQuery, GetSubTaskQueryVariables> {
    document = GetSubTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddSubTaskDocument = gql`
    mutation addSubTask($model: CreateSubTaskInput) {
  timeTracking {
    subTasks {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddSubTaskGQL extends Apollo.Mutation<AddSubTaskMutation, AddSubTaskMutationVariables> {
    document = AddSubTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubTaskDocument = gql`
    mutation updateSubTask($model: UpdateSubTaskInput) {
  timeTracking {
    subTasks {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubTaskGQL extends Apollo.Mutation<UpdateSubTaskMutation, UpdateSubTaskMutationVariables> {
    document = UpdateSubTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveSubTaskDocument = gql`
    mutation archiveSubTask($projectId: Uuid!, $subTaskId: Uuid!, $taskId: Uuid!) {
  timeTracking {
    subTasks {
      archive(projectId: $projectId, subTaskId: $subTaskId, taskId: $taskId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveSubTaskGQL extends Apollo.Mutation<ArchiveSubTaskMutation, ArchiveSubTaskMutationVariables> {
    document = ArchiveSubTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreSubTaskDocument = gql`
    mutation restoreSubTask($projectId: Uuid!, $subTaskId: Uuid!, $taskId: Uuid!) {
  timeTracking {
    subTasks {
      restore(projectId: $projectId, subTaskId: $subTaskId, taskId: $taskId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreSubTaskGQL extends Apollo.Mutation<RestoreSubTaskMutation, RestoreSubTaskMutationVariables> {
    document = RestoreSubTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedHiringReasonsDocument = gql`
    query getPaginatedHiringReasons($filter: ReasonTypeFilterInput, $skip: Int, $take: Int) {
  hr {
    reasonTypes(
      type: HIRING
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...reasonTypes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ReasonTypesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedHiringReasonsGQL extends Apollo.Query<GetPaginatedHiringReasonsQuery, GetPaginatedHiringReasonsQueryVariables> {
    document = GetPaginatedHiringReasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHiringReasonsDocument = gql`
    query getHiringReasons($search: String, $skip: Int, $take: Int) {
  hr {
    reasonTypes(
      type: HIRING
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
        code
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHiringReasonsGQL extends Apollo.Query<GetHiringReasonsQuery, GetHiringReasonsQueryVariables> {
    document = GetHiringReasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHiringReasonDocument = gql`
    query getHiringReason($id: Uuid!) {
  hr {
    reasonTypes(type: HIRING, where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...reasonTypes
      }
    }
  }
}
    ${ReasonTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHiringReasonGQL extends Apollo.Query<GetHiringReasonQuery, GetHiringReasonQueryVariables> {
    document = GetHiringReasonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedTerminationReasonsDocument = gql`
    query getPaginatedTerminationReasons($filter: ReasonTypeFilterInput, $skip: Int, $take: Int) {
  hr {
    reasonTypes(
      type: TERMINATION
      where: $filter
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        ...reasonTypes
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${ReasonTypesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedTerminationReasonsGQL extends Apollo.Query<GetPaginatedTerminationReasonsQuery, GetPaginatedTerminationReasonsQueryVariables> {
    document = GetPaginatedTerminationReasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTerminationReasonsDocument = gql`
    query getTerminationReasons($search: String, $skip: Int, $take: Int) {
  hr {
    reasonTypes(
      type: TERMINATION
      where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
        code
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTerminationReasonsGQL extends Apollo.Query<GetTerminationReasonsQuery, GetTerminationReasonsQueryVariables> {
    document = GetTerminationReasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTerminationReasonDocument = gql`
    query getTerminationReason($id: Uuid!) {
  hr {
    reasonTypes(
      type: TERMINATION
      where: {and: [{id: {eq: $id}}]}
      skip: 0
      take: 1
    ) {
      items {
        ...reasonTypes
      }
    }
  }
}
    ${ReasonTypesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTerminationReasonGQL extends Apollo.Query<GetTerminationReasonQuery, GetTerminationReasonQueryVariables> {
    document = GetTerminationReasonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTerminationReasonTypeDocument = gql`
    mutation addTerminationReasonType($model: CreateReasonTypeInput) {
  hr {
    reasonTypes {
      addTerminationReasonType(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTerminationReasonTypeGQL extends Apollo.Mutation<AddTerminationReasonTypeMutation, AddTerminationReasonTypeMutationVariables> {
    document = AddTerminationReasonTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddHiringReasonTypeDocument = gql`
    mutation addHiringReasonType($model: CreateReasonTypeInput) {
  hr {
    reasonTypes {
      addHiringReasonType(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddHiringReasonTypeGQL extends Apollo.Mutation<AddHiringReasonTypeMutation, AddHiringReasonTypeMutationVariables> {
    document = AddHiringReasonTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReasonTypeDocument = gql`
    mutation updateReasonType($model: UpdateReasonTypeInput) {
  hr {
    reasonTypes {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReasonTypeGQL extends Apollo.Mutation<UpdateReasonTypeMutation, UpdateReasonTypeMutationVariables> {
    document = UpdateReasonTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveReasonTypeDocument = gql`
    mutation archiveReasonType($reasonId: Uuid!) {
  hr {
    reasonTypes {
      archive(reasonId: $reasonId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveReasonTypeGQL extends Apollo.Mutation<ArchiveReasonTypeMutation, ArchiveReasonTypeMutationVariables> {
    document = ArchiveReasonTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreReasonTypeDocument = gql`
    mutation restoreReasonType($reasonId: Uuid!) {
  hr {
    reasonTypes {
      restore(reasonId: $reasonId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreReasonTypeGQL extends Apollo.Mutation<RestoreReasonTypeMutation, RestoreReasonTypeMutationVariables> {
    document = RestoreReasonTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyPaginatedReimbursementsDocument = gql`
    query getMyPaginatedReimbursements($filter: ReimbursementFilterInput, $skip: Int, $take: Int) {
  hr {
    session {
      reimbursements(
        where: $filter
        skip: $skip
        take: $take
        order: {createdOn: DESC}
      ) {
        items {
          ...reimbursementFragment
          reimbursementDetails {
            ...reimbursementDetailsFragment
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
      }
    }
  }
}
    ${ReimbursementFragmentFragmentDoc}
${ReimbursementDetailsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyPaginatedReimbursementsGQL extends Apollo.Query<GetMyPaginatedReimbursementsQuery, GetMyPaginatedReimbursementsQueryVariables> {
    document = GetMyPaginatedReimbursementsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedReimbursementsDocument = gql`
    query getPaginatedReimbursements($filter: ReimbursementFilterInput, $skip: Int, $take: Int) {
  hr {
    reimbursements(where: $filter, skip: $skip, take: $take) {
      items {
        ...reimbursementFragment
        reimbursementDetails {
          ...reimbursementDetailsFragment
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
}
    ${ReimbursementFragmentFragmentDoc}
${ReimbursementDetailsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedReimbursementsGQL extends Apollo.Query<GetPaginatedReimbursementsQuery, GetPaginatedReimbursementsQueryVariables> {
    document = GetPaginatedReimbursementsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReimbursementDocument = gql`
    query getReimbursement($id: Uuid!) {
  hr {
    reimbursements(where: {id: {eq: $id}}) {
      items {
        ...reimbursementFragment
        userId
        reimbursementDetails {
          ...reimbursementDetailsFragment
        }
      }
    }
  }
}
    ${ReimbursementFragmentFragmentDoc}
${ReimbursementDetailsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReimbursementGQL extends Apollo.Query<GetReimbursementQuery, GetReimbursementQueryVariables> {
    document = GetReimbursementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReimbursementDetailsDocument = gql`
    query getReimbursementDetails($id: Uuid!) {
  hr {
    reimbursement(id: $id) {
      userId
      reimbursementDetails {
        ...reimbursementDetailsFragment
      }
      state
      reimbursementTypeId
      amount
      amountApproved
      createdOn
      user {
        fullName
        firstName
        lastName
      }
    }
  }
}
    ${ReimbursementDetailsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReimbursementDetailsGQL extends Apollo.Query<GetReimbursementDetailsQuery, GetReimbursementDetailsQueryVariables> {
    document = GetReimbursementDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReimbursementDocument = gql`
    mutation UpdateReimbursement($model: UpdateReimbursementInput) {
  hr {
    reimbursement {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReimbursementGQL extends Apollo.Mutation<UpdateReimbursementMutation, UpdateReimbursementMutationVariables> {
    document = UpdateReimbursementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmployeeSalariesDocument = gql`
    query getEmployeeSalaries($userId: Uuid!) {
  hr {
    salaries(userId: $userId, order: {startDate: DESC}) {
      userId
      id
      basicSalary
      netSalary
      createdOn
      endDate
      startDate
      allowances {
        id
        name
      }
      bonuses {
        id
        name
        lookupSetting
      }
      createdBy {
        firstName
        lastName
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmployeeSalariesGQL extends Apollo.Query<GetEmployeeSalariesQuery, GetEmployeeSalariesQueryVariables> {
    document = GetEmployeeSalariesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedSitesDocument = gql`
    query getPaginatedSites($filter: SiteFilterInput, $skip: Int, $take: Int) {
  supportTicket {
    ticketsSites(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...sites
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${SitesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedSitesGQL extends Apollo.Query<GetPaginatedSitesQuery, GetPaginatedSitesQueryVariables> {
    document = GetPaginatedSitesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSiteDocument = gql`
    query getSite($id: Uuid!) {
  supportTicket {
    ticketsSites(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...sites
      }
    }
  }
}
    ${SitesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSiteGQL extends Apollo.Query<GetSiteQuery, GetSiteQueryVariables> {
    document = GetSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSitesDocument = gql`
    query getSites($search: String, $skip: Int, $take: Int) {
  supportTicket {
    ticketsSites(
      where: {or: [{siteNo: {contains: $search}}, {siteName: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        ...sites
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${SitesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSitesGQL extends Apollo.Query<GetSitesQuery, GetSitesQueryVariables> {
    document = GetSitesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSiteNoDocument = gql`
    query getSiteNo {
  supportTicket {
    siteNo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSiteNoGQL extends Apollo.Query<GetSiteNoQuery, GetSiteNoQueryVariables> {
    document = GetSiteNoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddSiteDocument = gql`
    mutation addSite($model: CreateSiteInput) {
  supportTicket {
    site {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddSiteGQL extends Apollo.Mutation<AddSiteMutation, AddSiteMutationVariables> {
    document = AddSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSiteDocument = gql`
    mutation updateSite($model: UpdateSiteInput) {
  supportTicket {
    site {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSiteGQL extends Apollo.Mutation<UpdateSiteMutation, UpdateSiteMutationVariables> {
    document = UpdateSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveSiteDocument = gql`
    mutation archiveSite($siteId: Uuid!) {
  supportTicket {
    site {
      archive(siteId: $siteId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveSiteGQL extends Apollo.Mutation<ArchiveSiteMutation, ArchiveSiteMutationVariables> {
    document = ArchiveSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreSiteDocument = gql`
    mutation restoreSite($siteId: Uuid!) {
  supportTicket {
    site {
      restore(siteId: $siteId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreSiteGQL extends Apollo.Mutation<RestoreSiteMutation, RestoreSiteMutationVariables> {
    document = RestoreSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentSnapsDocument = gql`
    query GetAgentSnaps($filter: SnapshotFilterInput, $skip: Int, $take: Int) {
  trackingAgent {
    snaps {
      snapshots(where: $filter, skip: $skip, take: $take) {
        items {
          id
          businessId
          machine
          date
          iPAddress
          username
          hour
          networkName
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentSnapsGQL extends Apollo.Query<GetAgentSnapsQuery, GetAgentSnapsQueryVariables> {
    document = GetAgentSnapsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentSnapshotMachinesDocument = gql`
    query GetAgentSnapshotMachines($filter: MachineVmFilterInput, $skip: Int, $take: Int) {
  trackingAgent {
    snaps {
      machines(where: $filter, skip: $skip, take: $take) {
        items {
          name
        }
        pageInfo {
          ...collectionInfo
        }
        totalCount
      }
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentSnapshotMachinesGQL extends Apollo.Query<GetAgentSnapshotMachinesQuery, GetAgentSnapshotMachinesQueryVariables> {
    document = GetAgentSnapshotMachinesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAgentSnapshotBreakdownDocument = gql`
    query GetAgentSnapshotBreakdown($id: Uuid!) {
  trackingAgent {
    snaps {
      snapshots(where: {id: {eq: $id}}) {
        items {
          id
          snapshotBreakdowns
          snapshotCount
          businessId
          machine
          date
          iPAddress
          username
          hour
          networkName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAgentSnapshotBreakdownGQL extends Apollo.Query<GetAgentSnapshotBreakdownQuery, GetAgentSnapshotBreakdownQueryVariables> {
    document = GetAgentSnapshotBreakdownDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaxesDocument = gql`
    query getTaxes {
  finance {
    session {
      taxes {
        ...taxFragment
      }
    }
  }
}
    ${TaxFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaxesGQL extends Apollo.Query<GetTaxesQuery, GetTaxesQueryVariables> {
    document = GetTaxesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaxDocument = gql`
    query getTax($id: Uuid!) {
  finance {
    session {
      taxes(where: {id: {eq: $id}}) {
        ...taxFragment
        description
        abbreviation
        number
        isCompoundTax
        isTaxRecoverable
        showTaxOnInvoices
      }
    }
  }
}
    ${TaxFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaxGQL extends Apollo.Query<GetTaxQuery, GetTaxQueryVariables> {
    document = GetTaxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTaxDocument = gql`
    mutation addTax($model: AddTaxInput) {
  finance {
    taxes {
      createTax(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTaxGQL extends Apollo.Mutation<AddTaxMutation, AddTaxMutationVariables> {
    document = AddTaxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaxDocument = gql`
    mutation updateTax($model: UpdateTaxInput) {
  finance {
    taxes {
      updateTax(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaxGQL extends Apollo.Mutation<UpdateTaxMutation, UpdateTaxMutationVariables> {
    document = UpdateTaxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedTicketCategoriesDocument = gql`
    query getPaginatedTicketCategories($filter: TicketCategoryFilterInput, $skip: Int, $take: Int) {
  support {
    ticketsCategories(
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...ticketCategories
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${TicketCategoriesFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedTicketCategoriesGQL extends Apollo.Query<GetPaginatedTicketCategoriesQuery, GetPaginatedTicketCategoriesQueryVariables> {
    document = GetPaginatedTicketCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketCategoriesDocument = gql`
    query getTicketCategories($search: String, $skip: Int, $take: Int) {
  support {
    ticketsCategories(
      where: {or: [{name: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        name
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketCategoriesGQL extends Apollo.Query<GetTicketCategoriesQuery, GetTicketCategoriesQueryVariables> {
    document = GetTicketCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketCategoryDocument = gql`
    query getTicketCategory($id: Uuid!) {
  support {
    ticketsCategories(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...ticketCategories
      }
    }
  }
}
    ${TicketCategoriesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketCategoryGQL extends Apollo.Query<GetTicketCategoryQuery, GetTicketCategoryQueryVariables> {
    document = GetTicketCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTicketCategoryDocument = gql`
    mutation addTicketCategory($model: CreateTicketCategoryVMInput) {
  support {
    ticketCategories {
      create(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTicketCategoryGQL extends Apollo.Mutation<AddTicketCategoryMutation, AddTicketCategoryMutationVariables> {
    document = AddTicketCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTicketCategoryDocument = gql`
    mutation updateTicketCategory($model: UpdateTicketCategoryVMInput) {
  support {
    ticketCategories {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTicketCategoryGQL extends Apollo.Mutation<UpdateTicketCategoryMutation, UpdateTicketCategoryMutationVariables> {
    document = UpdateTicketCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveTicketCategoryDocument = gql`
    mutation archiveTicketCategory($ticketCategoryId: Uuid!) {
  support {
    ticketCategories {
      archive(categoryId: $ticketCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveTicketCategoryGQL extends Apollo.Mutation<ArchiveTicketCategoryMutation, ArchiveTicketCategoryMutationVariables> {
    document = ArchiveTicketCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreTicketCategoryDocument = gql`
    mutation restoreTicketCategory($ticketCategoryId: Uuid!) {
  support {
    ticketCategories {
      restore(categoryId: $ticketCategoryId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreTicketCategoryGQL extends Apollo.Mutation<RestoreTicketCategoryMutation, RestoreTicketCategoryMutationVariables> {
    document = RestoreTicketCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketLaborsDocument = gql`
    query getTicketLabors($ticketId: Uuid!, $skip: Int, $take: Int) {
  supportTicket {
    ticketLabor(
      ticketId: $ticketId
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...ticketLabor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
}
    ${TicketLaborFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketLaborsGQL extends Apollo.Query<GetTicketLaborsQuery, GetTicketLaborsQueryVariables> {
    document = GetTicketLaborsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketLaborByWorkerDocument = gql`
    query getTicketLaborByWorker($ticketId: Uuid!, $workerId: Uuid, $skip: Int, $take: Int) {
  supportTicket {
    ticketLabor(
      ticketId: $ticketId
      where: {workerId: {eq: $workerId}}
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        ...ticketLabor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
}
    ${TicketLaborFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketLaborByWorkerGQL extends Apollo.Query<GetTicketLaborByWorkerQuery, GetTicketLaborByWorkerQueryVariables> {
    document = GetTicketLaborByWorkerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTicketLaborDocument = gql`
    mutation updateTicketLabor($model: UpdateTicketLaborInput) {
  supportTicket {
    ticketLabor {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTicketLaborGQL extends Apollo.Mutation<UpdateTicketLaborMutation, UpdateTicketLaborMutationVariables> {
    document = UpdateTicketLaborDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTicketLaborDocument = gql`
    mutation addTicketLabor($model: CreateTicketLaborInput) {
  supportTicket {
    ticketLabor {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTicketLaborGQL extends Apollo.Mutation<AddTicketLaborMutation, AddTicketLaborMutationVariables> {
    document = AddTicketLaborDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBillableDocument = gql`
    mutation updateBillable($model: UpdateTicketLaborInput) {
  supportTicket {
    ticketLabor {
      updateBillable(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBillableGQL extends Apollo.Mutation<UpdateBillableMutation, UpdateBillableMutationVariables> {
    document = UpdateBillableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketMaterialsDocument = gql`
    query getTicketMaterials($ticketId: Uuid!, $skip: Int, $take: Int) {
  supportTicket {
    ticketMaterial(
      ticketId: $ticketId
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        ...ticketMaterial
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
}
    ${TicketMaterialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketMaterialsGQL extends Apollo.Query<GetTicketMaterialsQuery, GetTicketMaterialsQueryVariables> {
    document = GetTicketMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTicketMaterialsDocument = gql`
    mutation addTicketMaterials($model: [CreateTicketMaterialInput]) {
  supportTicket {
    ticketMaterial {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTicketMaterialsGQL extends Apollo.Mutation<AddTicketMaterialsMutation, AddTicketMaterialsMutationVariables> {
    document = AddTicketMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedTicketsDocument = gql`
    query getPaginatedTickets($filter: TicketFilterInput, $skip: Int, $take: Int) {
  support {
    tickets(where: $filter, skip: $skip, take: $take, order: {createdOn: DESC}) {
      items {
        ...tickets
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${TicketsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedTicketsGQL extends Apollo.Query<GetPaginatedTicketsQuery, GetPaginatedTicketsQueryVariables> {
    document = GetPaginatedTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketsDocument = gql`
    query getTickets($search: String, $skip: Int, $take: Int) {
  support {
    tickets(
      where: {or: [{title: {contains: $search}}, {description: {contains: $search}}], and: [{state: {eq: ACTIVE}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        title
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketsGQL extends Apollo.Query<GetTicketsQuery, GetTicketsQueryVariables> {
    document = GetTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketDocument = gql`
    query getTicket($id: Uuid!) {
  support {
    tickets(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        id
        title
        description
        updatedOn
        state
        attachment {
          id
          path
        }
        categoryId
        category {
          name
        }
        assignedUserId
        assignedUser {
          firstName
          lastName
          fullName
          email
          pic
        }
        createdById
        createdBy {
          firstName
          lastName
          fullName
          email
          pic
        }
        createdOn
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketGQL extends Apollo.Query<GetTicketQuery, GetTicketQueryVariables> {
    document = GetTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignTicketDocument = gql`
    mutation assignTicket($assignedUserId: Uuid!, $ticketId: Uuid!) {
  support {
    tickets {
      assign(assignedUserId: $assignedUserId, ticketId: $ticketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignTicketGQL extends Apollo.Mutation<AssignTicketMutation, AssignTicketMutationVariables> {
    document = AssignTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WithdrawTicketDocument = gql`
    mutation withdrawTicket($TicketId: Uuid!) {
  support {
    tickets {
      withdraw(ticketId: $TicketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WithdrawTicketGQL extends Apollo.Mutation<WithdrawTicketMutation, WithdrawTicketMutationVariables> {
    document = WithdrawTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreTicketDocument = gql`
    mutation restoreTicket($TicketId: Uuid!) {
  support {
    tickets {
      restore(ticketId: $TicketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreTicketGQL extends Apollo.Mutation<RestoreTicketMutation, RestoreTicketMutationVariables> {
    document = RestoreTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveTicketDocument = gql`
    mutation archiveTicket($TicketId: Uuid!) {
  support {
    tickets {
      archive(ticketId: $TicketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveTicketGQL extends Apollo.Mutation<ArchiveTicketMutation, ArchiveTicketMutationVariables> {
    document = ArchiveTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveTicketDocument = gql`
    mutation resolveTicket($TicketId: Uuid!) {
  support {
    tickets {
      resolve(ticketId: $TicketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveTicketGQL extends Apollo.Mutation<ResolveTicketMutation, ResolveTicketMutationVariables> {
    document = ResolveTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProcessTicketDocument = gql`
    mutation processTicket($TicketId: Uuid!) {
  support {
    tickets {
      process(ticketId: $TicketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProcessTicketGQL extends Apollo.Mutation<ProcessTicketMutation, ProcessTicketMutationVariables> {
    document = ProcessTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedCommentsDocument = gql`
    query getPaginatedComments($ticketId: Uuid!, $filter: TicketFilterInput, $skip: Int, $take: Int) {
  support {
    ticketComments(
      ticketId: $ticketId
      where: $filter
      skip: $skip
      take: $take
      order: {createdOn: DESC}
    ) {
      items {
        id
        parentTicketId
        description
        attachment {
          id
          path
        }
        createdOn
        createdById
        createdBy {
          firstName
          lastName
          email
          fullName
        }
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedCommentsGQL extends Apollo.Query<GetPaginatedCommentsQuery, GetPaginatedCommentsQueryVariables> {
    document = GetPaginatedCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTicketStatusDocument = gql`
    mutation addTicketStatus($model: CreateTicketStatusChangeInput) {
  supportTicket {
    ticketStatusChange {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTicketStatusGQL extends Apollo.Mutation<AddTicketStatusMutation, AddTicketStatusMutationVariables> {
    document = AddTicketStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTicketStatusDocument = gql`
    mutation updateTicketStatus($model: UpdateTicketStatusChangeInput) {
  supportTicket {
    ticketStatusChange {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTicketStatusGQL extends Apollo.Mutation<UpdateTicketStatusMutation, UpdateTicketStatusMutationVariables> {
    document = UpdateTicketStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedTktTicketsDocument = gql`
    query getPaginatedTktTickets($filter: TicketsFilterInput, $skip: Int, $take: Int) {
  supportTicket {
    tickets(where: $filter, skip: $skip, take: $take, order: {entryDate: DESC}) {
      items {
        ...tktTickets
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${TktTicketsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedTktTicketsGQL extends Apollo.Query<GetPaginatedTktTicketsQuery, GetPaginatedTktTicketsQueryVariables> {
    document = GetPaginatedTktTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTktTicketDocument = gql`
    query getTktTicket($id: Uuid!) {
  supportTicket {
    tickets(where: {id: {eq: $id}}, skip: 0, take: 1) {
      items {
        ...tktTickets
      }
    }
  }
}
    ${TktTicketsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTktTicketGQL extends Apollo.Query<GetTktTicketQuery, GetTktTicketQueryVariables> {
    document = GetTktTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketNoDocument = gql`
    query getTicketNo {
  supportTicket {
    ticketNo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketNoGQL extends Apollo.Query<GetTicketNoQuery, GetTicketNoQueryVariables> {
    document = GetTicketNoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTicketsDocument = gql`
    mutation addTickets($model: CreateTicketsInput) {
  supportTicket {
    tickets {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTicketsGQL extends Apollo.Mutation<AddTicketsMutation, AddTicketsMutationVariables> {
    document = AddTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTicketsDocument = gql`
    mutation updateTickets($model: UpdateTicketsInput) {
  supportTicket {
    tickets {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTicketsGQL extends Apollo.Mutation<UpdateTicketsMutation, UpdateTicketsMutationVariables> {
    document = UpdateTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScheduleTicketsDocument = gql`
    mutation scheduleTickets($model: UpdateTicketsInput) {
  supportTicket {
    tickets {
      scheduleTicket(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduleTicketsGQL extends Apollo.Mutation<ScheduleTicketsMutation, ScheduleTicketsMutationVariables> {
    document = ScheduleTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTicketPriorityDocument = gql`
    mutation updateTicketPriority($model: UpdateTicketsInput) {
  supportTicket {
    tickets {
      ticketPriority(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTicketPriorityGQL extends Apollo.Mutation<UpdateTicketPriorityMutation, UpdateTicketPriorityMutationVariables> {
    document = UpdateTicketPriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveTicketsDocument = gql`
    mutation archiveTickets($ticketId: Uuid!) {
  supportTicket {
    tickets {
      archive(ticketId: $ticketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveTicketsGQL extends Apollo.Mutation<ArchiveTicketsMutation, ArchiveTicketsMutationVariables> {
    document = ArchiveTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreTicketsDocument = gql`
    mutation restoreTickets($ticketId: Uuid!) {
  supportTicket {
    tickets {
      restore(ticketId: $ticketId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreTicketsGQL extends Apollo.Mutation<RestoreTicketsMutation, RestoreTicketsMutationVariables> {
    document = RestoreTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedTimeLogsDocument = gql`
    query getPaginatedTimeLogs($filter: TimeLogFilterInput) {
  timeTracking {
    session {
      timeLogs(where: $filter) {
        ...paginatedTimeLogs
      }
    }
  }
}
    ${PaginatedTimeLogsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedTimeLogsGQL extends Apollo.Query<GetPaginatedTimeLogsQuery, GetPaginatedTimeLogsQueryVariables> {
    document = GetPaginatedTimeLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTimeLogsDocument = gql`
    mutation addTimeLogs($model: CreateTimeLogInput) {
  timeTracking {
    timeLogs {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTimeLogsGQL extends Apollo.Mutation<AddTimeLogsMutation, AddTimeLogsMutationVariables> {
    document = AddTimeLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTimeLogsDocument = gql`
    mutation removeTimeLogs($timeLogIds: [Uuid!]) {
  timeTracking {
    timeLogs {
      remove(timeLogIds: $timeLogIds)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTimeLogsGQL extends Apollo.Mutation<RemoveTimeLogsMutation, RemoveTimeLogsMutationVariables> {
    document = RemoveTimeLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetVoucherByIdDocument = gql`
    query getVoucherById($id: Uuid!) {
  finance {
    vouchers(where: {id: {eq: $id}}) {
      items {
        ...voucherDetails
      }
    }
  }
}
    ${VoucherDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVoucherByIdGQL extends Apollo.Query<GetVoucherByIdQuery, GetVoucherByIdQueryVariables> {
    document = GetVoucherByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedVouchersDocument = gql`
    query getPaginatedVouchers($filter: VoucherFilterInput, $skip: Int, $take: Int) {
  finance {
    vouchers(where: $filter, skip: $skip, take: $take, order: {date: DESC}) {
      items {
        ...voucherDetails
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${VoucherDetailsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedVouchersGQL extends Apollo.Query<GetPaginatedVouchersQuery, GetPaginatedVouchersQueryVariables> {
    document = GetPaginatedVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedSalaryVouchersDocument = gql`
    query getPaginatedSalaryVouchers($filter: VoucherFilterInput, $skip: Int, $take: Int, $departmentId: Uuid) {
  finance {
    salaryVouchers(
      where: $filter
      skip: $skip
      take: $take
      departmentId: $departmentId
      order: {date: DESC}
    ) {
      items {
        voucherTypeId
        id
        notes
        date
        number
        attachment {
          path
        }
        lineItems {
          voucherLineType
          accountId
          description
          amount
          sortOrder
          account {
            name
            isPayRollAccount
          }
        }
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedSalaryVouchersGQL extends Apollo.Query<GetPaginatedSalaryVouchersQuery, GetPaginatedSalaryVouchersQueryVariables> {
    document = GetPaginatedSalaryVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClosingBalanceDocument = gql`
    query closingBalance($date: DateTime!) {
  finance {
    closingBalance(date: $date)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClosingBalanceGQL extends Apollo.Query<ClosingBalanceQuery, ClosingBalanceQueryVariables> {
    document = ClosingBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CashInHandDocument = gql`
    query cashInHand($date: DateTime!) {
  finance {
    cashInHand(date: $date)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CashInHandGQL extends Apollo.Query<CashInHandQuery, CashInHandQueryVariables> {
    document = CashInHandDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLatestSequenceNumberDocument = gql`
    query getLatestSequenceNumber($voucherCode: String) {
  finance {
    voucherSequenceNumber(voucherCode: $voucherCode)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestSequenceNumberGQL extends Apollo.Query<GetLatestSequenceNumberQuery, GetLatestSequenceNumberQueryVariables> {
    document = GetLatestSequenceNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAccountVouchersDocument = gql`
    query getAccountVouchers($accountId: Uuid!, $filter: VoucherFilterInput, $skip: Int, $take: Int) {
  finance {
    accountVouchers(
      accountId: $accountId
      where: $filter
      skip: $skip
      take: $take
      order: {date: DESC}
    ) {
      items {
        ...voucherDetails
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${VoucherDetailsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAccountVouchersGQL extends Apollo.Query<GetAccountVouchersQuery, GetAccountVouchersQueryVariables> {
    document = GetAccountVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCashVoucherByIdDocument = gql`
    query getCashVoucherById($id: Uuid!) {
  finance {
    cashVouchers(where: {id: {eq: $id}}) {
      items {
        ...cashVoucherDetails
      }
    }
  }
}
    ${CashVoucherDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCashVoucherByIdGQL extends Apollo.Query<GetCashVoucherByIdQuery, GetCashVoucherByIdQueryVariables> {
    document = GetCashVoucherByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedCashVouchersDocument = gql`
    query getPaginatedCashVouchers($filter: CashVoucherFilterInput, $skip: Int, $take: Int) {
  finance {
    cashVouchers(where: $filter, skip: $skip, take: $take, order: {date: DESC}) {
      items {
        ...cashVoucherDetails
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CashVoucherDetailsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedCashVouchersGQL extends Apollo.Query<GetPaginatedCashVouchersQuery, GetPaginatedCashVouchersQueryVariables> {
    document = GetPaginatedCashVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoiceByIdDocument = gql`
    query getInvoiceById($type: VoucherTypes!, $id: Uuid!) {
  finance {
    invoicesByType(type: $type, where: {id: {eq: $id}}) {
      items {
        ...invoiceDetails
        details {
          id
          productId
          invoiceDetailTax: invoiceDetailTaxs {
            id
            taxId
            invoiceDetailID
            taxRate
            taxAmount
          }
          product {
            id
            taxIds
            purchaseUnit
            description
            price
          }
          quantity
          price
          discount
          amount
        }
        attachment {
          path
        }
      }
    }
  }
}
    ${InvoiceDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoiceByIdGQL extends Apollo.Query<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables> {
    document = GetInvoiceByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedInvoicesDocument = gql`
    query getPaginatedInvoices($type: VoucherTypes!, $filter: InvoiceFilterInput!, $skip: Int, $take: Int, $order: [InvoiceSortInput!]) {
  finance {
    invoicesByType(
      type: $type
      where: $filter
      skip: $skip
      take: $take
      order: $order
    ) {
      items {
        ...invoiceDetails
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${InvoiceDetailsFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedInvoicesGQL extends Apollo.Query<GetPaginatedInvoicesQuery, GetPaginatedInvoicesQueryVariables> {
    document = GetPaginatedInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DraftPurchaseOrderDocument = gql`
    mutation draftPurchaseOrder($purchaseOrderId: Uuid!) {
  finance {
    purchaseVouchers {
      draftPurchaseOrder(purchaseOrderId: $purchaseOrderId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DraftPurchaseOrderGQL extends Apollo.Mutation<DraftPurchaseOrderMutation, DraftPurchaseOrderMutationVariables> {
    document = DraftPurchaseOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApprovePurchaseOrderDocument = gql`
    mutation approvePurchaseOrder($purchaseOrderId: Uuid!) {
  finance {
    purchaseVouchers {
      approvePurchaseOrder(purchaseOrderId: $purchaseOrderId)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApprovePurchaseOrderGQL extends Apollo.Mutation<ApprovePurchaseOrderMutation, ApprovePurchaseOrderMutationVariables> {
    document = ApprovePurchaseOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PurchaseOrdersCountDocument = gql`
    query purchaseOrdersCount($approvedFilter: InvoiceFilterInput!, $draftFilter: InvoiceFilterInput!, $allFilter: InvoiceFilterInput!) {
  approved: finance {
    invoicesByType(type: PURCHASE__ORDER, where: $approvedFilter) {
      totalCount
    }
  }
  draft: finance {
    invoicesByType(type: PURCHASE__ORDER, where: $draftFilter) {
      totalCount
    }
  }
  all: finance {
    invoicesByType(type: PURCHASE__ORDER, where: $allFilter) {
      totalCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrdersCountGQL extends Apollo.Query<PurchaseOrdersCountQuery, PurchaseOrdersCountQueryVariables> {
    document = PurchaseOrdersCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoicesDocument = gql`
    query getInvoices($type: VoucherTypes!, $search: String, $skip: Int, $take: Int) {
  finance {
    invoicesByType(
      type: $type
      where: {or: [{number: {contains: $search}}, {notes: {contains: $search}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        number
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoicesGQL extends Apollo.Query<GetInvoicesQuery, GetInvoicesQueryVariables> {
    document = GetInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApprovedPurchaseOrdersByVendorDocument = gql`
    query getApprovedPurchaseOrdersByVendor($vendorId: Uuid!, $search: String, $skip: Int, $take: Int) {
  finance {
    invoicesByType(
      type: PURCHASE__ORDER
      where: {or: [{number: {contains: $search}}, {notes: {contains: $search}}], and: [{state: {eq: APPROVED}, accountId: {eq: $vendorId}}]}
      skip: $skip
      take: $take
      order: {updatedOn: DESC}
    ) {
      items {
        id
        number
      }
      pageInfo {
        ...collectionInfo
      }
      totalCount
    }
  }
}
    ${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApprovedPurchaseOrdersByVendorGQL extends Apollo.Query<GetApprovedPurchaseOrdersByVendorQuery, GetApprovedPurchaseOrdersByVendorQueryVariables> {
    document = GetApprovedPurchaseOrdersByVendorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApprovedPurchaseOrderByIdDocument = gql`
    query getApprovedPurchaseOrderById($id: Uuid!) {
  finance {
    invoicesByType(type: PURCHASE__ORDER, where: {id: {eq: $id}}) {
      items {
        ...invoiceDetails
        details {
          id
          productId
          invoiceDetailTax: invoiceDetailTaxs {
            id
            taxId
            invoiceDetailID
            taxRate
            taxAmount
          }
          product {
            id
            taxIds
            purchaseUnit
            description
            price
          }
          quantity
          price
          discount
          amount
        }
        attachment {
          path
        }
      }
    }
  }
}
    ${InvoiceDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApprovedPurchaseOrderByIdGQL extends Apollo.Query<GetApprovedPurchaseOrderByIdQuery, GetApprovedPurchaseOrderByIdQueryVariables> {
    document = GetApprovedPurchaseOrderByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyCurrentYearQuotaDocument = gql`
    query getMyCurrentYearQuota {
  hr {
    session {
      myCurrentYearQuota {
        ...quota
      }
    }
  }
}
    ${QuotaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyCurrentYearQuotaGQL extends Apollo.Query<GetMyCurrentYearQuotaQuery, GetMyCurrentYearQuotaQueryVariables> {
    document = GetMyCurrentYearQuotaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyYearlyQuotasDocument = gql`
    query getMyYearlyQuotas {
  hr {
    session {
      myYearlyQuotas {
        ...quota
      }
    }
  }
}
    ${QuotaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyYearlyQuotasGQL extends Apollo.Query<GetMyYearlyQuotasQuery, GetMyYearlyQuotasQueryVariables> {
    document = GetMyYearlyQuotasDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetYearlyQuotasDocument = gql`
    query getYearlyQuotas($userId: Uuid!) {
  hr {
    yearlyQuotas(where: {userId: {eq: $userId}}, order: {year: DESC}) {
      ...quota
    }
  }
}
    ${QuotaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetYearlyQuotasGQL extends Apollo.Query<GetYearlyQuotasQuery, GetYearlyQuotasQueryVariables> {
    document = GetYearlyQuotasDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddYearlyQuotaDocument = gql`
    mutation addYearlyQuota($model: CreateYearlyQuotaInput!) {
  hr {
    yearlyQuotas {
      add(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddYearlyQuotaGQL extends Apollo.Mutation<AddYearlyQuotaMutation, AddYearlyQuotaMutationVariables> {
    document = AddYearlyQuotaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateYearlyQuotaDocument = gql`
    mutation updateYearlyQuota($model: UpdateYearlyQuotaInput!) {
  hr {
    yearlyQuotas {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateYearlyQuotaGQL extends Apollo.Mutation<UpdateYearlyQuotaMutation, UpdateYearlyQuotaMutationVariables> {
    document = UpdateYearlyQuotaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuotaLookupsDocument = gql`
    query getQuotaLookups($search: String, $skip: Int, $take: Int) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}, {parentId: {neq: null}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuotaLookupsGQL extends Apollo.Query<GetQuotaLookupsQuery, GetQuotaLookupsQueryVariables> {
    document = GetQuotaLookupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuotaLookupsByParentCodeDocument = gql`
    query getQuotaLookupsByParentCode($search: String, $skip: Int, $take: Int, $parentCode: String) {
  paginatedBusinessLookups(
    where: {or: [{name: {contains: $search}}, {code: {contains: $search}}], and: [{state: {eq: ACTIVE}}, {parentId: {neq: null}}, {parent: {code: {eq: $parentCode}}}]}
    skip: $skip
    take: $take
    order: {updatedOn: DESC}
  ) {
    items {
      ...businessLookup
    }
    pageInfo {
      ...collectionInfo
    }
    totalCount
  }
}
    ${BusinessLookupFragmentDoc}
${CollectionInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuotaLookupsByParentCodeGQL extends Apollo.Query<GetQuotaLookupsByParentCodeQuery, GetQuotaLookupsByParentCodeQueryVariables> {
    document = GetQuotaLookupsByParentCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuotaLookupDocument = gql`
    query getQuotaLookup($id: Uuid!) {
  paginatedBusinessLookups(where: {id: {eq: $id}}, skip: null, take: 1) {
    items {
      ...businessLookup
    }
  }
}
    ${BusinessLookupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuotaLookupGQL extends Apollo.Query<GetQuotaLookupQuery, GetQuotaLookupQueryVariables> {
    document = GetQuotaLookupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyCurrentYearQuotaWithLookupIdDocument = gql`
    query getMyCurrentYearQuotaWithLookupId($lookupId: Uuid!) {
  hr {
    session {
      myCurrentYearQuota(where: {lookupId: {eq: $lookupId}}) {
        id
        lookup {
          name
          code
          setting
          parentId
          parent {
            name
            code
            setting
          }
        }
        lookupId
        userId
        year
        assignedQuota
        year
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyCurrentYearQuotaWithLookupIdGQL extends Apollo.Query<GetMyCurrentYearQuotaWithLookupIdQuery, GetMyCurrentYearQuotaWithLookupIdQueryVariables> {
    document = GetMyCurrentYearQuotaWithLookupIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserCurrentYearQuotaWithLookupIdDocument = gql`
    query getUserCurrentYearQuotaWithLookupId($lookupId: Uuid!, $userId: Uuid!) {
  hr {
    session {
      userCurrentYearQuota(where: {lookupId: {eq: $lookupId}}, userId: $userId) {
        id
        lookup {
          name
          code
          setting
          parentId
          parent {
            name
            code
            setting
          }
        }
        lookupId
        userId
        year
        assignedQuota
        year
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserCurrentYearQuotaWithLookupIdGQL extends Apollo.Query<GetUserCurrentYearQuotaWithLookupIdQuery, GetUserCurrentYearQuotaWithLookupIdQueryVariables> {
    document = GetUserCurrentYearQuotaWithLookupIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }