import {Injectable} from '@angular/core';
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {ObjectUtils} from "@core/utils/object-utils";
import { NotificationModel } from '@core/model/notification';

@Injectable()
export class AppUtilService {
  constructor() {}
  transformTitle(name: string, length?: number): string {
    let str = '';
    if (name) {
      const strArray = name.trim().split(' ');
      if (length) {
        if (length === 1 || strArray.length === 1) {
          str = name.slice(0, 1).toUpperCase();
        } else {
          if (length > strArray.length)
            length = strArray.length;
          for (let index = 0; index < length; index++) {
            str = str + strArray[index][0]?.toUpperCase();
          }
        }
      } else {
        length = strArray.length > 5 ? 5 : strArray.length;
        str =
          str +
          strArray
            .slice(0, length)
            .map((x) => x[0]?.toUpperCase())
            .join('');
      }
    }
    return str;
  }
  getRandomColor(str: string): string {
    if (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        // tslint:disable-next-line:no-bitwise
        const value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return this.hexToHSLA(color, 90);
    }
  }
  getTextColor(str: string): string {
    if (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        // tslint:disable-next-line:no-bitwise
        const value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return this.hexToHSLA(color, 40);
    }
  }
  hexToHSLA(H, light = 20): string {
    // Convert hex to RGB first
    // tslint:disable-next-line:one-variable-per-declaration
    let r: any, g: any, b: any;
    if (H.length === 4) {
      r = '0x' + H[1] + H[1];
      g = '0x' + H[2] + H[2];
      b = '0x' + H[3] + H[3];
    } else if (H.length === 7) {
      r = '0x' + H[1] + H[2];
      g = '0x' + H[3] + H[4];
      b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    // tslint:disable-next-line:one-variable-per-declaration
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin;
    // tslint:disable-next-line:one-variable-per-declaration
    let h = 0,
      s = 0,
      l = 0;

    // tslint:disable-next-line:max-line-length
    if (delta === 0) {
      h = 0;
    } else if (cmax === r) {
      h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
      h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return 'hsl(' + h + ',' + s + '%,' + light + '%,' + 100 + ')';
  }
  getNgbStruct(date: Date): any {
    if(!date) return null;
    date = new Date(date);
    const ngbDate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    return ngbDate;
  }
  getNgbDate(ngbDate: NgbDate): any {
    if(!ngbDate) return null;
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    date.setMinutes(
      new Date().getMinutes() - new Date().getTimezoneOffset()
    );
    return date;
  }
  getObjectUrl(param: NotificationModel){
    let url = '';
    let object = ObjectUtils.toCamelCase(param.systemObject);
    switch (object) {
      case 'profile':
        url = 'profile/personal-info';
        break;
      case 'user':
        break;
      case 'role':
        break;
      case 'account':
        break;
      case 'business':
        break;
      case 'journalVoucher':
        break;
      case 'cashVoucher':
        break;
      case 'cashReceiveVoucher':
        break;
      case 'cashPaymentVoucher':
        break;
      case 'department':
        break;
      case 'interviewCategory':
        break;
      case 'interviewCriterion':
        break;
      case 'jobFunction':
        break;
      case 'jobTitle':
        break;
      case 'leaveType':
        break;
      case 'leave':
        url = `/hr/leaves${param.systemObjectId ? `?id=${param.systemObjectId}` : ''}`;
        break;
      case 'loanType':
        break;
      case 'loan':
        break;
      case 'medicalClaim':
        break;
      case 'bonusCategory':
        break;
      case 'bonusType':
        break;
      case 'certification':
        break;
      case 'institute':
        break;
      case 'hiringReason':
        break;
      case 'terminationReason':
        break;
      case 'allowanceCategory':
        break;
      case 'allowanceType':
        break;
      case 'employee':
        break;
      case 'salary':
        break;
      case 'invitation':
        break;
      case 'timesheet':
        break;
      case 'financialYear':
        break;
      case 'product':
        break;
      case 'client':
        break;
      case 'project':
        break;
      case 'projectTask':
        break;
      case 'subTask':
        break;
      case 'genericTask':
        break;
      case 'purchaseOrder':
        break;
      case 'purchaseInvoice':
        break;
      case 'saleInvoice':
        break;
      case 'quotation':
        break;
      case 'attendanceRecord':
        break;
      case 'password':
        break;
      case 'agent':
        break;
      case 'agentConfiguration':
        break;
      case 'attendanceConfiguration':
        break;
      case 'invoiceCustomization':
        break;
      case 'hrConfig':
        break;
      case 'businessEmail':
        break;
      case 'systemAccount':
        break;
      case 'tax':
        break;
      case 'app':
        break;
      case 'ticketCategory':
        break;
      case 'ticket':
        break;
      case 'ticketComment':
        break;
    }
    return url;
  }
}

export function deepCopy<T>(obj: T): T {
  const stObj = JSON.stringify(obj);
  try {
    return JSON.parse(stObj);
  } catch (err) {
    return { ...{}, ...obj };
  }
}

