import { AppPolicies } from '@core/app.config';
import { ApplyPolicy } from '@generated/queries';

export const DefaultMenuConfig = {
  // items: [
  //   {
  //     title: 'Dashboard',
  //     icon: 'flaticon2-architecture-and-city',
  //     svg: './assets/media/svg/icons/Design/Layers.svg',
  //     page: '/dashboard',
  //     translate: 'MENU.DASHBOARD',
  //     bullet: 'dot',
  //   },
  // ],
};
export const AsideMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      svg: './assets/media/brickclay-svg/sidebar/Dashboard.svg',
      page: '/dashboard',
    },
    // Start Finance Management
    {
      title: 'Finance',
      svg: './assets/media/svg/icons/Shopping/Dollar.svg',
      page: '/finance',
      bullet: 'dot',
      permissions: [AppPolicies.FinanceManager],
      submenu: [
        { section: 'Finance Management' },
        {
          title: 'Accounts',
          bullet: 'dot',
          icon: 'flaticon2-user-outline-symbol',
          svg: './assets/media/brickclay-svg/topBar/Accounts.svg',
          permissions: [AppPolicies.FinanceManager],
          submenu: [
            {
              title: 'Chart of Accounts',
              page: 'finance/accounts/details',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Customers',
              page: 'finance/accounts/customers',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Vendors',
              page: 'finance/accounts/vendors',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Bank Accounts',
              page: 'finance/accounts/bank-accounts',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Commission Agents',
              page: 'finance/accounts/commission-agents',
              permissions: [AppPolicies.FinanceManager],
            },
          ],
        },
        {
          title: 'Products',
          svg: './assets/media/brickclay-svg/mega-bar/Add.svg',
          page: 'finance/products',
          permissions: [AppPolicies.FinanceManager],
        },
        {
          title: 'Orders',
          bullet: 'dot',
          svg: './assets/media/brickclay-svg/topBar/Vouchers.svg',
          permissions: [AppPolicies.FinanceManager],
          submenu: [
            {
              title: 'Purchase Orders',
              page: 'finance/invoices/purchase-orders',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Purchase Invoices',
              page: 'finance/invoices/purchase-invoices',
              permissions: [AppPolicies.FinanceManager],
            },
          ],
        },
        {
          title: 'Invoices',
          bullet: 'dot',
          svg: './assets/media/brickclay-svg/topBar/Vouchers.svg',
          permissions: [AppPolicies.FinanceManager],
          submenu: [
            {
              title: 'Quotations',
              page: 'finance/invoices/quotations',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Sale Invoices',
              page: 'finance/invoices/sale-invoices',
              permissions: [AppPolicies.FinanceManager],
            },
          ],
        },
        {
          title: 'Vouchers',
          bullet: 'dot',
          svg: './assets/media/brickclay-svg/topBar/Vouchers.svg',
          permissions: [AppPolicies.FinanceManager],
          submenu: [
            {
              title: 'Journal Voucher',
              page: '/finance/vouchers/journals',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Cash Vouchers',
              page: '/finance/vouchers/cash',
              permissions: [AppPolicies.FinanceManager],
            },
          ],
        },
        {
          title: 'Reports',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/CompanySetup.svg',
          permissions: [AppPolicies.FinanceManager],
          submenu: [
            {
              title: 'Chart Of Accounts',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/chart-of-accounts',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Cashbook',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/cashbook',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Ledger',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/ledger',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Trial Balance',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/trial-balance',
              permissions: [AppPolicies.FinanceManager],
            },
            // {
            //   title: 'Journal Vouchers',
            //   bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/brickclay-svg/topBar/Users.svg',
            //   page: 'finance/reports/journal-vouchers',
            //   permissions: [AppPolicies.FinanceManager],
            // },
            {
              title: 'Purchase Orders',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/purchase-orders',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Invoice Voucher',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/invoice-voucher',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Cash Voucher',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/cash-voucher',
              permissions: [AppPolicies.FinanceManager],
            },
            // {
            //   title: 'Purchase Invoices',
            //   bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/brickclay-svg/topBar/Users.svg',
            //   page: 'finance/reports/purchase-invoices',
            //   permissions: [AppPolicies.FinanceManager],
            // },
            {
              title: 'Quotations',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/quotations',
              permissions: [AppPolicies.FinanceManager],
            },
            // {
            //   title: 'Sale Invoices',
            //   bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/brickclay-svg/topBar/Users.svg',
            //   page: 'finance/reports/sale-invoices',
            //   permissions: [AppPolicies.FinanceManager],
            // },
            {
              title: 'Purchase Register',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/purchase-register',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Sale Register',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/sale-register',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Vendor Purchase Register',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/vendor-purchase-register',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Customer Sale Register',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/customer-sale-register',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Product Purchase Register',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/product-purchase-register',
              permissions: [AppPolicies.FinanceManager],
            },
            {
              title: 'Product Sale Register',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'finance/reports/product-sale-register',
              permissions: [AppPolicies.FinanceManager],
            },
            // {
            //   title: 'Payrolls',
            //   bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/brickclay-svg/topBar/Users.svg',
            //   page: 'finance/reports/SalarySlipReport',
            //   permissions: [AppPolicies.FinanceManager],
            // },
          ],
        },
      ],
    },
    // End Finance Management

    // start::HR Management
    {
      title: 'HR',
      bullet: 'dot',
      page: '/hr',
      icon: 'flaticon2-list-2',
      svg: './assets/media/brickclay-svg/sidebar/hr.svg',
      permissions: [AppPolicies.HrMember],
      submenu: [
        { section: 'HR Management' },
        // {
        //   title: 'Support',
        //   bullet: 'dot',
        //   icon: 'flaticon2-list-2',
        //   svg: './assets/media/brickclay-svg/topBar/Leaves.svg',
        //   page: 'hr/support',
        //   permissions: [AppPolicies.HrMember],
        // },
        // {
        //   title: 'Ticket Category',
        //   bullet: 'dot',
        //   icon: 'flaticon2-list-2',
        //   svg: './assets/media/brickclay-svg/topBar/Leaves.svg',
        //   page: 'hr/support/ticket-categories',
        // },
        
        {
          title: 'Attendance',
          bullet: 'dot',
          svg: './assets/media/brickclay-svg/sidebar/attendance.svg',

          permissions: [AppPolicies.HrManager],
          submenu: [
            {
              title: 'Daily',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              page: 'hr/attendance/daily',
            },
            {
              title: 'Weekly',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              page: 'hr/attendance/weekly',
            },
            {
              title: 'Monthly',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              page: 'hr/attendance/monthly',
            },
          ],
        },

        {
          title: 'Employees',
          bullet: 'dot',
          page: 'hr/employees',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Employees.svg',
          permissions: [AppPolicies.HrManager],
        },
        {
          title: 'Job Positions',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/JobPositions.svg',
          page: 'hr/job-positions',
          permissions: [AppPolicies.HrManager],
        },
        // {
        //   title: 'Loans',
        //   bullet: 'dot',
        //   icon: 'flaticon2-list-2',
        //   svg: './assets/media/brickclay-svg/topBar/Loans.svg',
        //   page: 'hr/loans',
        //   permissions: [AppPolicies.HrMember],
        // },
        {
          title: 'Leaves',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Leaves.svg',
          page: 'hr/leaves',
          permissions: [AppPolicies.HrMember],
        },
        {
          title: 'Medical Expense Claims',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/MedicalExpenseClaims.svg',
          page: 'hr/medical-claims',
          permissions: [AppPolicies.HrMember],
        },
        {
          title: 'Reimbursement',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Finance.svg',
          page: 'hr/reimbursements',
          permissions: [AppPolicies.HrMember],
        },
        {
          title: 'Payrolls',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/mega-bar/payroll.svg',
          page: 'hr/payroll-setup',
          permissions: [AppPolicies.HrManager],
        },
        {
          title: 'Holiday Calander',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/mega-bar/payroll.svg',
          page: 'hr/holiday-calanders',
          permissions: [AppPolicies.HrManager],
        },
        {
          title: 'HR Setup',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/CompanySetup.svg',
          permissions: [AppPolicies.HrManager],
          submenu: [
            {
              title: 'Departments',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/departments',
            },
            {
              title: 'Job Titles',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/job-titles',
            },
            {
              title: 'Job Functions',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/job-functions',
            },
            // {
            //   title: 'Loan Types',
            //   bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/svg/icons/Shopping/Cart3.svg',
            //   permission: 'accessToECommerceModule',
            //   page: 'hr/setup/loan-types',
            // },
            {
              title: 'Certifications',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/certifications',
            },
            {
              title: 'Institutes',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/institutes',
            },
            {
              title: 'Interview Categories',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/interview-categories',
            },
            {
              title: 'Interview Criteria',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/interview-criteria',
            },
            {
              title: 'Bonus Categories',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/bonus-categories',
            },
            {
              title: 'Bonus Types',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/bonus-types',
            },
            {
              title: 'Allowance Categories',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/allowance-categories',
            },
            {
              title: 'Allowance Types',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/allowance-types',
            },
            {
              title: 'Reason Types',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              submenu: [
                {
                  title: 'Hiring Reasons',
                  bullet: 'dot',
                  icon: 'flaticon2-list-2',
                  svg: './assets/media/svg/icons/Shopping/Cart3.svg',
                  permission: 'accessToECommerceModule',
                  page: 'hr/setup/reasons/hiring',
                },
                {
                  title: 'Termination Reasons',
                  bullet: 'dot',
                  icon: 'flaticon2-list-2',
                  svg: './assets/media/svg/icons/Shopping/Cart3.svg',
                  permission: 'accessToECommerceModule',
                  page: 'hr/setup/reasons/termination',
                },
              ],
            },
            {
              title: 'Note Types',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/note-types',
            },
            {
              title: 'Reimbursement Types',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              permission: 'accessToECommerceModule',
              page: 'hr/setup/reimbursement-types',
            },
          ],
        },
        {
          title: 'Agent',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Agent.svg',
          permissions: [AppPolicies.TrackingAgentManager],
          submenu: [
            {
              title: 'Snapshots',
              page: '/agent/snaps',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
            },
            {
              title: 'Live Report',
              page: '/agent/report',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
            },
            {
              title: 'Productivity',
              page: '/agent/productivity',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
            },
          ],
        },
        {
          title: 'Reports',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/CompanySetup.svg',
          permissions: [AppPolicies.HrManager],
          submenu: [
            {
              title: 'Job Offer Letter',
              //bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'hr/reports/offer-letter',
            },
            // {
            //   title: 'Salary Reports',
            //   //bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/brickclay-svg/topBar/Users.svg',
            //   page: 'hr/reports/payrolls',
            // },
            {
              title: 'Employee Attendance',
              //bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'hr/reports/attendance',
            },
            {
              title: 'Leaves',
              //bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'hr/reports/leaves',
            },
            {
              title: 'Employee Leaves',
              //bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'hr/reports/employee-leave',
            },
            {
              title: 'Medical Claims',
              //bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'hr/reports/medical-claims',
            },
            {
              title: 'Employee Medical Claims',
              //bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'hr/reports/employee-medical-claims',
            },
          ],
        },
      ],
    },

    // Start Time Tracking
    {
      title: 'Time Tracking',
      bullet: 'dot',
      icon: 'flaticon2-list-2',
      svg: './assets/media/brickclay-svg/sidebar/time-tracking-grey.svg',
      page: '/time-tracking',
      permissions: [AppPolicies.TimeTrackingMember],
      submenu: [
        { section: 'Time Tracking' },
        {
          title: 'Clients',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Clients.svg',
          page: 'time-tracking/clients',
          permissions: [AppPolicies.TimeTrackingMember],
        },
        {
          title: 'Tasks',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Tasks.svg',
          page: 'time-tracking/tasks',
          permissions: [AppPolicies.TimeTrackingMember],
        },
        {
          title: 'Projects',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Projects.svg',
          page: 'time-tracking/projects',
          permissions: [AppPolicies.TimeTrackingMember],
        },
        {
          title: 'Time Sheets',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/mega-bar/time-tracking.svg',
          page: 'time-tracking/timesheets',
          permissions: [AppPolicies.TimeTrackingMember],
        },
        {
          title: 'Reports',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/CompanySetup.svg',
          permissions: [AppPolicies.TimeTrackingMember],
          submenu: [
            {
              title: 'Effort Summary',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'time-tracking/reports/effort-summary',
              permissions: [AppPolicies.TimeTrackingMember],
            },
            {
              title: 'Time Logs',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/brickclay-svg/topBar/Users.svg',
              page: 'time-tracking/reports/time-logs',
              permissions: [AppPolicies.TimeTrackingManager],
            },
          ],
        },
      ],
    },
    // End Time Tracking

    //Start Support Ticket
    {
      title: 'Support',
      bullet: 'dot',
      page: '/support-ticket',
      icon: 'flaticon2-list-2',
      svg: './assets/media/brickclay-svg/sidebar/hr.svg',
      right:'supportticket',
      submenu: [
        { section: 'Support Management' },
        {
          title: 'Tickets',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/brickclay-svg/topBar/Leaves.svg',
          page: 'hr/support-ticket/ticket',
          right:'read:ticket',
        },
        
        {
          title: 'Setup',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          svg: './assets/media/svg/icons/Shopping/Cart3.svg',
          right:'read:ticketsetup',
          submenu: [
            {
              title: 'Site',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              page: 'hr/support-ticket/site',
              right:'read:site',
            },
            {
              title: 'Priority',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              page: 'hr/support-ticket/priority',
              right:'read:priority',
            },
            {
              title: 'Problems',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              page: 'hr/support-ticket/problem',
              right:'read:problem',
            },
            {
              title: 'Status',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              page: 'hr/support-ticket/status',
              right:'read:ticketstatus',
            },
            // {
            //   title: 'Post Status',
            //   bullet: 'dot',
            //   icon: 'flaticon2-list-2',
            //   svg: './assets/media/svg/icons/Shopping/Cart3.svg',
            //   page: 'hr/support-ticket/post-status',

            // },
            {
              title: 'Note Types',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              svg: './assets/media/svg/icons/Shopping/Cart3.svg',
              page: 'hr/support-ticket/note-type',
              right:'read:notetype',
            },
          ],
        },

        
      ],
    },
    //End Support Ticket
  ],
};
