import { Gender } from '../../enums/gender.enum';
import { Expose, Type } from 'class-transformer';
import {
  AttendanceUserConfiguration, BusinessLookup, CreateYearlyQuotaInput, Quota, States, UpdateYearlyQuotaInput,
  UserAgentConfiguration,
  UserBenefits
} from '@generated/queries';
import { UpdatePasswordInput, UpdateProfileInput } from '@generated/auth';
import { Employee } from '../employee/employee';
import { Loan } from '../loan/loan';
import { Leave } from '../leave/leave';
import { MedicalClaim } from '../medical-claim/medical-claim';
import { Salary } from '../employee/salary';
import { ThirdLevelAccount } from '../accounts/thirdLevelAccount.model';
import { HrLookup } from '../hr-lookup/hr-lookup';

export class User {
  @Expose() id: string | undefined;
  @Expose() lastName?: string | undefined;
  @Expose() firstName?: string | undefined;
  @Expose() fullName?: string | undefined;
  @Expose() userFullName?: string | undefined;
  @Expose() displayName?: string | undefined;
  @Expose() email?: string | undefined;
  @Expose() gender: Gender | undefined;
  @Expose() pic?: string | undefined;
  @Expose() employee?: Employee | undefined;
  @Expose() state: States;
  @Expose() country?: string | undefined;
  @Expose() province?: string | undefined;
  @Expose() city?: string | undefined;
  @Expose() zipCode?: string | undefined;
  @Expose() dateOfBirth?: Date;
  @Expose() createdOn: Date;
  @Expose() updatedOn: Date;
  isExternal: boolean | undefined;
  @Expose() userAgentConfigurations: UserAgentConfiguration[] | [];
  @Expose() attendanceUserConfigurations: AttendanceUserConfiguration[];
  @Expose() loans: Loan[] | [];
  @Expose() leaves: Leave[] | undefined;
  @Expose() medicalClaims: MedicalClaim[] | [];
  @Expose() medicalLoanEntitlement: number | undefined;
  @Expose() jobTitleId?: string | undefined;
  @Expose() jobFunctionId?: string | undefined;
  @Expose() departmentId?: string | undefined;
  @Expose() payrollAccountId?: string | undefined;
  @Expose() department?: HrLookup | undefined;
  @Expose() jobTitle?: HrLookup | undefined;
  @Expose() jobFunction?: HrLookup | undefined;
  @Expose() benefits: UserBenefits | undefined;
  @Expose() salaries: Salary | undefined;
  @Expose() payrollAccount: ThirdLevelAccount | undefined;
  @Expose() availableBalance: number | undefined;
  @Expose() quotas: YearlyQuota[] | undefined;
}
export class UpdateUserProfile implements UpdateProfileInput {
  id: any;
  businessId: any;
  @Expose() firstName: string;
  @Expose() lastName: string;
  @Expose() dateOfBirth?: Date;
  @Expose() pic?: string;
  @Expose() country: string;
  @Expose() province?: string;
  @Expose() city?: string;
  @Expose() zipCode?: string;
}
export class UpdatePassword implements UpdatePasswordInput {
  @Expose() email: string;
  id: any;
  businessId: any;
  @Expose() oldPassword: string;
  @Expose() newPassword: string;
}
export class AssignBusinessEmail {
  businessId: any;
  emails: string[];
}
export class BusinessLookupModel implements BusinessLookup {
  @Expose() businessId: string;
  @Expose() code: string;
  @Expose() createdOn: Date;
  @Expose() id: string;
  @Expose() name: string;
  @Expose() isSystemDefined: boolean;
  @Expose() parent?: BusinessLookupModel | undefined;
  @Expose() parentId: string | undefined;
  @Expose() setting: string | undefined
  @Expose() state: States;
  @Expose() updatedOn: Date;
}
export class YearlyQuota {
  @Expose() id: string;
  @Expose() year?: string;
  userId: string;
  @Expose() lookupId: string;
  @Expose() lookup?: BusinessLookupModel | undefined;
  @Expose() assignedQuota: number;
}
export class CreateYearlyQuota implements CreateYearlyQuotaInput{
  id: string;
  @Expose() year: string;
  businessId: number;
  @Expose() userId: number;
  @Expose() lookupId: number;
  @Expose() assignedQuota: number;
}
export class UpdateYearlyQuota implements UpdateYearlyQuotaInput {
  @Expose() id: string;
  @Expose() year: string;
  businessId: number;
  @Expose() userId: number;
  @Expose() lookupId: number;
  @Expose() assignedQuota: number;
}
